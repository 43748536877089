<template>
    <div class="footerWrapper"> 
 
       

        <p style="font-size: 12px;">2024 © Safelyio</p>
        
        

    </div>
</template>

<script setup>

</script>

<style scoped>


.footerWrapper{
    background-color: rgb(255, 255, 255);
    height: 50px;
    padding: 20px;
    bottom: 0px;
}



</style>