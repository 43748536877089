<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">
                        <div>Sub Managers</div>

                        <button @click="subManagerStore.openSendInviteToSubManagerModal" class="button-6" role="button">
                            New Sub Manager +
                        </button>
                    </div>



                    <div v-if="subManagerStore.subManagers.length > 0">

                        <div class="tableWrapper">

                            <table class="table table-hover my-custom-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(subManager, index) in subManagerStore.subManagers" :key="index">


                                        <td style=" width: 300px;">

                                            {{ subManager.user ? subManager.user.name : 'N/A' }}

                                        </td>

                                        <td>

                                            <span class="badge rounded-pill bg-success">
                                                Active
                                            </span>

                                        </td>

                                        <td>
                                            <i v-if="subManager.user"
                                                @click="() => { subManagerStore.setCurrentSubManager(subManager.user); subManagerStore.openDeleteSubManagerModal(); }"
                                                class="bi bi-trash" style="cursor: pointer;"></i>
                                        </td>

                                    </tr>
                                </tbody>




                            </table>




                        </div>

                    </div>


                    <div class="noTeamBox" v-else>

                        No sub managers created yet.

                    </div>









                </div>
            </div>
        </div>


        <div v-if="subManagerStore.showDeleteSubManagerModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to archive this sub manager?</b>
                    </div>

                    <div>
                        <i @click="subManagerStore.closeDeleteSubManagerModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <p v-if="subManagerStore.currentSubManager"> <b style="color: red;">Very Important Note:</b> Deleting
                        <b>{{ subManagerStore.currentSubManager.name }}</b> also
                        remove all data related to this sub manager including talks and team data. This also deletes any
                        campaigns and teams created by this sub manager.
                        This data cannot be recovered when deleted.
                    </p>



                    <div>
                        <button type="button" @click="subManagerStore.archiveSubManager" class="btn btn-danger createBTN">
                            <b>Yes, Delete!</b>
                        </button>
                    </div>



                </div>
            </div>
        </div>


        <div v-if="subManagerStore.showSendInviteToSubManagerModal" class="modal-overlay">

            <div class="modal-box" style="width: 500px">
                <div class="model-box-header">
                    <div style="font-size: 18px">
                        <b>Invite Sub Manager To Create Account</b>
                    </div>

                    <div>
                        <i @click="subManagerStore.closeSendInviteToSubManagerModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">

                    <form @submit.prevent="subManagerStore.sendEmailInvite">
                        <div class="mb-3">
                            <label class="form-label"><b>Email</b></label>
                            <input v-model="subManagerStore.inviteFormData.email" type="email" class="form-control"
                                required>
                            <small id="emailHelp" class="form-text text-muted">Please make sure the email is correct</small>
                        </div>


                        <div>
                            <button v-if="subManagerStore.isLoading" class="btn btn-dark createBTN">
                                <b>Sending...</b>
                            </button>
                            <button v-else type="submit" class="btn btn-dark createBTN">
                                <b>Send Invite</b>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>



    </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted } from "vue";
import { useSubManagerStore } from "@/stores/SubManagerStore";

const subManagerStore = useSubManagerStore();


onMounted(() => {

    subManagerStore.fetchAllSubManagers()


})



</script>

<style scoped> .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     justify-content: space-between;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
 }

 .searchBarWrapper {
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 350px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }

 .button-6 {
     align-items: center;
     /* background-color: #FFFFFF; */
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     border: 1px solid rgba(0, 0, 0, 0.1);
     border-radius: 0.25rem;
     box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
     box-sizing: border-box;
     color: rgba(0, 0, 0, 0.85);
     cursor: pointer;
     display: inline-flex;
     font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
         Arial, sans-serif;
     font-size: 16px;
     font-weight: 600;
     justify-content: center;
     line-height: 1.25;
     margin: 0;
     min-height: 3rem;
     padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
     position: relative;
     text-decoration: none;
     transition: all 250ms;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: baseline;
     width: auto;
 }

 .button-6:hover,
 .button-6:focus {
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
     color: rgba(0, 0, 0, 0.65);
 }

 .button-6:hover {
     transform: translateY(-1px);
 }

 .button-6:active {
     background-color: #f0f0f1;
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
     color: rgba(0, 0, 0, 0.65);
     transform: translateY(0);
 }

 .tableWrapper {
     margin-top: 20px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
 }

 .inviteInput {
     width: 500px;
     font-size: 13px;
     cursor: pointer;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }
</style>