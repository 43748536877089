import apiClient from "./MyClient";

export default {

  getAllTeams() {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get("/api/teams");
  },

  getATeam(randomString) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get("/api/team/" + randomString);
  },

  saveNewTeam(name) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post("/api/team/store", name);
  },

  deleteTeam(randomString) {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.delete("/api/team/delete/" + randomString);
  },

  updateTeam(randomString, updatedData) {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.put("/api/team/update/" + randomString, updatedData);
  },

  getAllEmployees() {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get("/api/manager/employees");
  },

  showTalksForThisEmployee(uuid) {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get("/api/manager/employee/" + uuid);
  },

  deleteAEmployeeFromTeam(formData) {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/team/delete/employee/", formData);
  },

  deleteAListOfEmployees(formData) {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/team/delete/list-of-employees", formData);
  },


  deleteEmployeeCompletelyAcrossAllApps(formData) {
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/delete/employee/completely", formData);
  },


  copyEmployeesToThisTeam(formData) { 
    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
    
    return apiClient.post("/api/team/copy/emploees/", formData); 
  },


};
