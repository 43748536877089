<template>
    <div>
        <div class="mainWrapper">
            <MainSideBar />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">
                        <div>Users</div>


                        <div class="theRightBox">



                            <div class="dropdown">
                                <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Add Users
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                    <li @click="templateStore.openInspectionAddUsersModal"><a class="dropdown-item"
                                            href="#">Import users already in a Safelyio</a></li>

                                    <li><a class="dropdown-item" href="#">Add brand new users</a></li>
                                    <li><a class="dropdown-item" href="#">Groups</a></li>
                                </ul>
                            </div>



                        </div>


                    </div>


                    <div v-if="templateStore.employeesUsingInspectionApp.length > 0">

                        <div class="searchBarWrapper">
                            <input type="text" class="form-control theBar" placeholder="Search users..." />
                        </div>


                        <div class="tableWrapper">

                            <table class="table table-hover my-custom-table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="font-size: 13px;">Name</th>
                                        <th scope="col" style="font-size: 13px;">Type</th>
                                        <th scope="col" style="font-size: 13px;"></th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(appUser, index) in templateStore.employeesUsingInspectionApp" :key="index">


                                        <td style=" width: 300px;">

                                            <div v-if="appUser.user">

                                                {{appUser.user.name}}

                                            </div>
                                            
                                        </td>

                                        <td style=" width: 300px;">

                                             <div v-if="appUser.user" >


                                                <span v-if="appUser.user.role_id === 3" class="badge rounded-pill text-bg-dark">Regular user</span>

                                                <span v-if="appUser.user.role_id === 2" class="badge rounded-pill text-bg-success">Success</span>


                                            </div>

                                        </td>



                                        <td style="width: 200px;">
                                            <div class="iconsRow">


                                                <i class="bi bi-pencil theActionIcon"></i>

                                                <i class="bi bi-trash theActionIcon"></i>



                                            </div>
                                        </td>
                                    </tr>
                                </tbody>




                            </table>


                        </div>

                    </div>


                    <div v-else>

                        No users added yet.

                    </div>










                </div>


            </div>
        </div>



        <div v-if="templateStore.showInspectionAddUsersModal" class="modal-overlay">

            <div class="modal-box" style="width: 500px; height: 700px;">
                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Import users already in Safelyio</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeInspectionAddUsersModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">



                    <div class="theSearchBox">


                        <input type="text" class="form-control" placeholder="Search by name">


                    </div>


                    <div class="theTableBox">


                        <!-- {{ templateStore.usersToAddToInspectionAppArray }} -->


                        <div v-if="templateStore.allEmployees.length > 0">

                            <table class="table">

                                <tbody>

                                    <tr v-for="(user, index) in templateStore.allEmployees" :key="index">

                                        <td style="width: 10%;">

                                            <div class="form-check">
                                                <input @click="templateStore.addThisUser(user)" class="form-check-input"
                                                    type="checkbox" value="" id="flexCheckDefault">

                                            </div>

                                        </td>


                                        <td style="width: 90%; text-align: left;">{{ user.name }}</td>

                                    </tr>

                                </tbody>
                            </table>

                        </div>


                        <div v-else>

                            No users found in Safelyio

                        </div>


                    </div>


                    <div class="addUsersBox">

                        <button @click="templateStore.importTheseUsersToInspectionApp" class="btn btn-success"
                            style="width: 100%;"><b>Import Selected Users</b></button>

                    </div>



                </div>
            </div>
        </div>









    </div>
</template>

<script setup>
import MainSideBar from "@/components/Inspections/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted } from 'vue';
import { useTemplateStore } from "@/stores/TemplateStore";


const templateStore = useTemplateStore();



onMounted(() => {

    templateStore.fetchAllEmployeesForInsectionApp()


})



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
}

.inviteInput {
    width: 500px;
    font-size: 13px;
    cursor: pointer;
}

.tabsWrapper {
    display: flex;
    width: 100%;
    /* background-color: #a9baba; */
}


.tabItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}

.tabItem:hover {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}



.iconDrop {
    cursor: pointer;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.emptyTableBox {
    margin-top: 100px;
}


.groupBtn {
    border: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 15px;
    font-size: 15px;
    margin-right: 25px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.groupBtn:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.theRightBox {
    display: flex;
    /* background-color: gray; */
}

.dropdown-item:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    color: black;
}


.theSearchBox {
    width: 100%;
    /* background-color: rgb(255, 136, 0); */
    padding-bottom: 20px
}

.theTableBox {
    width: 100%;
    overflow-y: auto;
    height: 500px;

}



.addUsersBox {
    /* background-color: green; */
    width: 100%;
    padding-top: 20px;


}
</style>