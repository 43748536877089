<template>
    <div class="topNavBar">

        <div class="navItem"></div> 

        <div class="navItem">
            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />  
        </div>

        <div class="navItem">
            
            <div class="profilePic" @click="authStore.goToAccountPageForEmployee">

                
                <img class="roundProfilePic" v-if="authStore.currentUser.profile_pic"
                :src="authStore.currentUser.profile_pic"
                />

                <i v-else class="bi bi-person-circle" style="font-size: 30px; color: #959795;"></i>

            </div>

        </div>

    </div>

    <!-- Rest of your content -->
    <div class="content">
        <!-- Your main content goes here -->
    </div>
</template>

<script setup>


import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from "vue";

const authStore = useAuthStore();



onMounted(() => {

    authStore.getUser()

})




</script>

<style scoped>
.topNavBar {
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-around; /* Evenly space out the nav items */
    align-items: center; /* Vertically center the items */
    position: fixed; /* Fix the nav bar to the top */
    top: 0; /* Align to the top */
    left: 0;
    width: 100%; /* Full width */
    background-color: white; /* Example background color, change as needed */
    padding: 10px 0; /* Some padding on top and bottom */
    z-index: 1000; /* Ensure the navbar is above other content */

}

.navItem {
    text-align: center; /* Center the text inside each nav item */
    flex-grow: 1; /* Each nav item takes up equal space */
    /* background-color: rgb(201, 186, 186); */
}

.content {
    padding-top: 60px; /* Adjust as needed, to ensure the content doesn't go under the nav bar */
    /* Rest of your content styling */
}

.imgLogo{
    width: 80px;
}


.usernameBox, .downArrow, .profilePic {
    display: flex;
    align-items: center;      /* Center content of each box vertically */
    justify-content: flex-end;  /* Center content of each box horizontally */
    margin: 0 5px;            /* Optional: add some space between the items */
}

/* Additional specific styles for each class if needed */
.profilePic {
    font-size: 30px;
    color: #959795;
    margin-right: 10px;
    cursor: pointer;
}


.roundProfilePic {
    border-radius: 60%; 
    border: 1.5px solid #c3d4cb; 
    object-fit: cover; /* Ensures the image covers the area without stretching */
    width: 35px; /* Set the width as required */
    height: 35px; /* Set the height as required */
}

</style>
