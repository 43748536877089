<template>
    <div class="mainContainer">

        <div class="headerSection">

            <div class="leftTop">

                <div class="tempNameCont">
                    {{ liveTemplateStore.template.name }}
                </div>

                <div class="templateInfoCont" v-if="liveTemplateStore.uniqueTemplate.user">

                    Last edited by: {{ liveTemplateStore.uniqueTemplate.user.name }} on {{
                        liveTemplateStore.uniqueTemplate.updated_at }}

                </div>


            </div>




            <div class="rightTop">


                <!-- <div class="mobileCTABtn">
                    <button>Open in Mobile App</button>
                </div> -->

                <div class="closeTemplate" @click="liveTemplateStore.goBackToViewAllTemplates">
                    <i class="bi bi-x-circle-fill"></i>
                </div>

            </div>


        </div>

        <!-- {{ liveTemplateStore.currentPageIndex }} -->

        <div class="thePageNavCont">


            <div class="outerPageNavBox">




                <div class="pageNavy" @click="liveTemplateStore.togglePagesNavDropdown">


                    <div class="thePageCountBox">

                        <i v-if="liveTemplateStore.showPagesNav" class="bi bi-chevron-up"
                            style="margin-right: 7px;"></i>
                        <i v-else class="bi bi-chevron-down" style="margin-right: 7px;"></i>

                        Page {{ liveTemplateStore.currentPageIndex + 1 }} of {{ liveTemplateStore.templatePages.length
                        }}
                    </div>

                    <div class="theSectionNameBox">
                        {{ liveTemplateStore.currentPage.title }}
                    </div>

                    <div v-if="liveTemplateStore.showPagesNav" class="pagesListBox">

                        <div class="pageItem" v-for="(page, index) in liveTemplateStore.templatePages" :key="index"
                            @click="liveTemplateStore.setCurrentPage(page, index)">
                            {{ page.title }}
                        </div>


                    </div>

                </div>

                <div class="completeBox">

                    <div class="leftComplete">
                         {{ liveTemplateStore.completed_elements }} of {{ liveTemplateStore.total_elements }} items
                    </div>


                    <div class="rightComplete">
                         {{liveTemplateStore.progressPercentage}}% Complete
                    </div>
                </div>

                <div>
                    <InpsectionWizardProgressBar />
                </div>

                





            </div>






        </div>


        <div class="formSection">


            
            <!-- <button @click="liveTemplateStore.recalculateProgressPercentage">test</button> -->


            <!-- <h1>{{ liveTemplateStore.deviceType }}</h1> -->



            <div class="theFormCont">


                <!-- {{ liveTemplateStore.currentElement }} -->

                
                                <!-- {{ liveTemplateStore.currentElement.form_input_value }} -->




                <div v-for="(page, pageIndex) in liveTemplateStore.templatePages" :key="pageIndex">

                    <div v-show="pageIndex === liveTemplateStore.currentPageIndex">


                        <div v-for="(element, elementIndex) in page.elements" :key="elementIndex">

                            <div class="elementItem" v-if="element.element_type !== 'section_header'"
                                @click="liveTemplateStore.setCurrentElement(element, elementIndex)">

                                <div v-if="element.response_type === 'text_answer'" class="theTextAnswerBlock">

                                    <div class="questionArea">
                                        {{ element.template_question.question_text }}
                                    </div>

                                    <div class="inputArea">


                                        <input type="text" class="form-control theInput"
                                            v-model="element.form_input_value.string_value"
                                            @keyup="liveTemplateStore.saveInputFieldValue(element)">

                                     </div>


                                </div>


                                <div v-if="element.response_type === 'number'" class="theNumberAnswerBlock">

                                    <div class="questionArea">
                                        {{ element.template_question.question_text }}
                                    </div>

                                    <div class="inputArea">

                                        <input type="number" class="form-control theInput"
                                            v-model="element.form_input_value.number_value"
                                            @keyup="liveTemplateStore.saveInputFieldValue(element)">

                                    </div>


                                </div>

                                <div v-if="element.response_type === 'checkbox'" style="width: 100%;">

                                    <div class="checkboxCont">



                                        <div class="form-check checkyBox">



                                            <input v-if="element.form_input_value.is_checkbox_selected === 1" checked
                                                class="form-check-input theCheckBox" type="checkbox" @click="() => {
                                                    liveTemplateStore.toggleCheckboxOnInput(element);
                                                    liveTemplateStore.saveInputFieldValue(element);
                                                }" id="flexCheckDefault">

                                            <input v-else class="form-check-input theCheckBox" type="checkbox" @click="() => {
                                                liveTemplateStore.toggleCheckboxOnInput(element);
                                                liveTemplateStore.saveInputFieldValue(element);
                                            }" id="flexCheckDefault">




                                            <label class="form-check-label" for="flexCheckDefault">
                                                {{ element.template_question.question_text }}
                                            </label>

                                        </div>

                                    </div>




                                </div>



                                <div v-if="element.response_type === 'datetime'" class="theDateTimeBlock">

                                    <div class="dateTimeArea">

                                        {{ element.template_question.question_text }}


                                        <input v-model="element.form_input_value.the_date"
                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                            class="form-control theInput" type="date"
                                            style="margin-bottom: 10px; margin-top: 10px;">

                                        <input v-model="element.form_input_value.the_time"
                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                            class="form-control theInput" type="time" style="margin-bottom: 18px;">


                                    </div>

                                </div>

                                <div v-if="element.response_type === 'time'" class="theDateTimeBlock">

                                    <div class="dateTimeArea">

                                        {{ element.template_question.question_text }}


                                        <input v-model="element.form_input_value.the_time"
                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                            class="form-control theInput" type="time"
                                            style="margin-bottom: 10px; margin-top: 10px;">


                                    </div>

                                </div>

                                <div v-if="element.response_type === 'date'" class="theDateTimeBlock">

                                    <div class="dateTimeArea">

                                        {{ element.template_question.question_text }}


                                        <input v-model="element.form_input_value.the_date"
                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                            class="form-control theInput" type="date"
                                            style="margin-bottom: 10px; margin-top: 10px;">


                                    </div>

                                </div>



                                <div v-if="element.response_type === 'media'" class="theMediaBlock">


                                    <div class="theMediaArea">

                                        {{ element.template_question.question_text }}


                                        <button class="mediaBtn" id="btnDialog" @click="liveTemplateStore.triggerInput">

                                            <i class="bi bi-cloud-arrow-up"
                                                style="font-size: 19px; margin-right: 10px;"></i>

                                            <span>
                                                Add Media
                                            </span>

                                        </button> 


                                    </div>



                                </div>

                                <div v-if="element.response_type === 'slider'" class="theSliderBlock">

                                    <div class="sliderArea">

                                        <div style="text-align: left">
                                            {{ element.template_question.question_text }}
                                        </div>



                                        <div class="theSilderBox">


                                            <input class="theRange" type="range"
                                                v-model="element.form_input_value.number_value" :min="element.min_range"
                                                :max="element.max_range" step="1"
                                                @change="liveTemplateStore.saveInputFieldValue(element)" />


                                            <div class="sliderRangeArea">

                                                <div class="theSide">
                                                    {{ element.min_range }}
                                                </div>

                                                <div style="font-size: 18px;" v-if="element.form_input_value">
                                                    <b>{{ element.form_input_value.number_value }}</b>
                                                </div>

                                                <div class="theSide">
                                                    {{ element.max_range }}
                                                </div>



                                            </div>




                                        </div>



                                    </div>

                                </div>


                                <div v-if="element.response_type === 'instruction'" class="instructionBox">

                                    <div class="textTop">
                                        {{ element.template_question.question_text }}
                                    </div>

                                    <div class="fileBottom"
                                        v-if="element.instruction_files && element.instruction_files.length > 0">

                                        <div v-if="element.instruction_files[0].type === 'image'">
                                            <img class="theAttachImg" :src="element.instruction_files[0].file_url"
                                                alt="" srcset="">
                                        </div>

                                        <div class="pdfName" v-if="element.instruction_files[0].type === 'pdf'"
                                            @click="liveTemplateStore.attachmentDownloadPDFfile(element.instruction_files[0])">

                                            {{ element.instruction_files[0].name }}

                                        </div>




                                    </div>

                                </div>
 

                                <div v-if="element.response_type === 'dropdown'" class="parentContainer">

                                    <div class="textTop">
                                        {{ element.template_question.question_text }}
                                    </div>

                                    <div class="dropDownCont" @click="liveTemplateStore.toggleDropdown(element)">


                                        <div class="optionName"
                                            v-if="element.form_input_value && element.form_input_value.dropdown_options.length > 0">


                                            <div class="theCircle" :style="{
                                                backgroundColor:
                                                    element.form_input_value.dropdown_options[0].the_option.color_code
                                            }">

                                            </div>

                                            <b>{{ element.form_input_value.dropdown_options[0].the_option.option_text
                                                }}</b>



                                        </div>

                                        <div v-else class="optionName">


                                            Select option


                                        </div>



                                        <div>

                                            <i v-if="element.show_dropdown === 0" class="bi bi-chevron-down"></i>

                                            <i v-else class="bi bi-chevron-up"></i>

                                        </div>


                                    </div>

                                    <div class="theDropdownCont" v-show="element.show_dropdown === 1">



                                        <div v-for="(dropItem, index) in element.dropdown_options" :key="index"
                                            :class="{ optionItemSeelected: element.dropdown_option_id === dropItem.id }"
                                            @click="() => { liveTemplateStore.setSelectedDropdownOption(element, dropItem); liveTemplateStore.toggleDropdown(element); }">


                                            <div class="optionItem">

                                                <div class="dotBox">

                                                    <div class="dropCircle"
                                                        :style="{ backgroundColor: dropItem.color_code }">
                                                    </div>

                                                </div>

                                                <div class="wordsBox">
                                                    {{ dropItem.option_text }}
                                                </div>

                                            </div>


                                        </div>



                                    </div>

                                </div>


                                <div v-if="element.response_type === 'multiple_choice'" class="choiceCont">

                                    <div class="textTop">
                                        {{ element.template_question.question_text }}
                                    </div>

                                    <div class="choiceItem" v-for="(choice, index) in element.multiple_choice_options"
                                        :key="index" :style="{
                                            'background-color': choice.form_input_record !== null ?
                                                choice.color_code : 'white',
                                            'color': choice.form_input_record !== null ? 'white' : 'black',
                                        }" @click="element.is_multiple_answer_selection === 'yes' ? liveTemplateStore.selectAMultipleChoice(element, choice) : liveTemplateStore.selectASingleChoice(element, choice)">
                                        {{ choice.option_text }}
                                    </div>

                                </div>


                                <div v-if="element.response_type === 'signature'" class="theSignatureBlock">


                                    <div class="signatureTextTop">
                                        {{ element.template_question.question_text }}
                                    </div>


                                    <button class="addSignatureBtn" @click="liveTemplateStore.openShowSignatureModal">

                                        <i class="bi bi-pen" style="margin-right: 5px;"></i>

                                        <span v-if="element.form_input_value.the_file_url !== null">Replace
                                            Signature</span>
                                        <span v-else>Add Signature</span>

                                    </button>


                                    <div class="signImgCont" v-if="element.form_input_value.the_file_url !== null">

                                        <div class="imgSignBox">
                                            <img @click="liveTemplateStore.openSignatureFileModal"
                                                class="theActualSignImg" :src="element.form_input_value.the_file_url">
                                        </div>

                                        <div class="dateText"
                                            v-if="element.form_input_value.the_date && element.form_input_value.the_time">
                                            {{ liveTemplateStore.formatDate(element.form_input_value.the_date) }} {{
                                            liveTemplateStore.formatTime(element.form_input_value.the_time) }}
                                        </div>



                                    </div>

                                </div>


                                <div v-show="element.response_type === 'geostamp'" class="theGeoStampBlock">



                                    <div class="geoStampWrapper">

                                        <div class="theGeoTextbox">

                                            <input v-model="element.form_input_value.formatted_address" type="text"
                                                @change="liveTemplateStore.saveInputFieldValue(element)"
                                                class="form-control theMapInput" placeholder="Location">

                                        </div>


                                        <div v-if="element.show_map === 0" class="theGeoMapBtnBox"
                                            @click="() => { liveTemplateStore.toggleShowMap(element); loadMap(); }">

                                            <button class="mapBtn"><i class="bi bi-geo-alt-fill"></i>
                                                Map</button>


                                        </div>

                                        <div v-else class="theGeoMapBtnBox" @click="loadMap">


                                            <button class="locateMeBtn">Find Me</button>

                                        </div>

                                    </div>



                                    <div class="geoIframeBox" v-show="element.show_map === 1">



                                        <div :id="'mapContainer' + element.id" style="width: 100%; height: 100%;">
                                        </div>


                                        <div class="mapBtnsWrapper">

                                            <button class="closeMapBtn" @click="element.show_map = 0">Close
                                                Map</button>

                                            <button
                                                @click="() => { liveTemplateStore.saveInputFieldValue(element); liveTemplateStore.toggleShowMap(element); }"
                                                class="saveLocationBtn">Save Location</button>

                                        </div>



                                    </div>



                                </div>


                                <div class="theMessageBox" v-if="element.form_input_value.the_error_message !== null">

                                    
                                    <div v-for="(message, index) in element.form_input_value.the_error_message" :key="index">

                                        {{ message }}

                                    </div>

                                </div>


                                <div class="bottomOptionsArea"
                                    v-if="element.response_type !== 'instruction' && element.element_type !== 'section_header'">


                                    <div class="loadingState" v-if="element.is_file_uploading === 'true'">

                                        <div class="spinner-grow spinner-grow-sm" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                    </div>


                                    <div class="filesArea"
                                        v-if="element.form_input_value.media_files && element.form_input_value.media_files.length > 0">



                                        <div v-for="(file, index) in liveTemplateStore.getImageAndVideoFilesOnly(element)"
                                            :key="index" class="fileItem"
                                            @click="() => { liveTemplateStore.setCurrentElement(element, elementIndex); liveTemplateStore.setCurrentFileIndex(file, element); }">


                                            <img v-if="file.type === 'image'" class="theImg" :src="file.file_url">


                                            <div v-if="file.type === 'video'" class="theVideo">

                                                <i class="bi bi-play-circle" style="font-size: 26px;"></i>

                                            </div>


                                        </div>




                                    </div>


                                    <div v-if="liveTemplateStore.getPdfFiles(element).length > 0">

                                        <div class="pdfsArea"
                                            v-for="(file, index) in liveTemplateStore.getPdfFiles(element)"
                                            :key="index">

                                            <div class="pdfItem" v-if="file.type === 'pdf'">

                                                <i class="bi bi-filetype-pdf"
                                                    style="margin-right: 7px; font-size: 20px;"></i>

                                                <div class="thePDFname"
                                                    @click="liveTemplateStore.downloadPDFfile(file)">
                                                    {{ file.name }}
                                                </div>

                                                <div>
                                                    <i @click="() => { liveTemplateStore.setCurrentElement(element, elementIndex); liveTemplateStore.deletePdfFile(file); }"
                                                        class="bi bi-trash theTrash"></i>
                                                </div>

                                            </div>

                                        </div>

                                    </div>


                                    <div class="theNoteWrapper" v-if="element.form_input_value && element.form_input_value.note_details">

                                        <div v-if="element.show_note === 'yes'" class="editNote">


                                            <textarea v-model="element.form_input_value.note_details.the_note" class="form-control textyArea"
                                                cols="30" rows="3"></textarea>

                                            <div class="noteBtnsArea">

                                                <div>

                                                    <button class="saveBtn"
                                                    @click="() => { liveTemplateStore.toggleOffEditMote(element);  liveTemplateStore.saveThisNote(); }"
                                                        >
                                                        Save
                                                    </button>


                                                </div>
                                                


                                                <div>
                                                    <i @click="liveTemplateStore.deleteThisNote" class="bi bi-trash theTrash"></i>
                                                </div>



                                            </div>




                                        </div>

                                        <div v-else @click="liveTemplateStore.toggleOnEditMote(element)"
                                        class="noteView" 
                                        >
                                            
                                            {{ element.form_input_value.note_details.the_note }}

                                        </div>

                               
                                    </div>


                                    <div class="buttonsArea">

                                        <div class="addNoteBtn" @click="liveTemplateStore.toggleOnEditMote(element)">
                                            <i class="bi bi-pencil"></i> Note
                                        </div>

                                        <div class="addMediaBtn" v-if="element.response_type !== 'media'"
                                        @click="liveTemplateStore.triggerInput"
                                        >
                                            <i class="bi bi-images"></i> Media
                                        </div>

                                        <div class="addActionBtn" @click="liveTemplateStore.openNewActionModal">
                                            <i class="bi bi-plus-lg"></i> Action 
                                        </div>


                                    </div>




                                </div>

                            </div>

                            <div v-if="element.element_type === 'section_header'">


                                <div class="sectionScrollItem" @click="liveTemplateStore.toggleShowSection(element)">

                                    <div class="theSectioTab">


                                        <div class="nameSection">

                                            <div style="margin-right: 7px;;">
                                                <i v-if="element.show_section === 0" class="bi bi-chevron-right"></i>
                                                <i v-else class="bi bi-chevron-down"></i>
                                            </div>

                                            <div>
                                                Section Name
                                            </div>

                                        </div>

                                        <div>

                                        </div>



                                    </div>



                                </div>

                                <div v-if="element.show_section === 1">

                                    <div v-if="element.section_layouts && element.section_layouts.length > 0">


                                        <div v-for="(element, elementIndex) in element.section_layouts"
                                            :key="elementIndex"
                                            @click="liveTemplateStore.setCurrentElement(element, elementIndex)">

                                            <div class="elementItem">

                                                <div v-if="element.response_type === 'text_answer'"
                                                    class="theTextAnswerBlock">

                                                    <div class="questionArea">
                                                        {{ element.template_question.question_text }}
                                                    </div>

                                                    <div class="inputArea">

                                                        <input type="text" class="form-control theInput"
                                                            v-model="element.form_input_value.string_value"
                                                            @keyup="liveTemplateStore.saveInputFieldValue(element)">

                                                    </div>


                                                </div>

                                                <div v-if="element.response_type === 'number'"
                                                    class="theNumberAnswerBlock">

                                                    <div class="questionArea">
                                                        {{ element.template_question.question_text }}
                                                    </div>

                                                    <div class="inputArea">

                                                        <input type="number" class="form-control theInput"
                                                            v-model="element.form_input_value.number_value"
                                                            @keyup="liveTemplateStore.saveInputFieldValue(element)">

                                                    </div>




                                                </div>

                                                <div v-if="element.response_type === 'checkbox'" style="width: 100%;"> 

                                                    <div class="checkboxCont">

                                                         <div class="form-check checkyBox">


                                                            <input
                                                                v-if="element.form_input_value.is_checkbox_selected === 1"
                                                                checked class="form-check-input theCheckBox"
                                                                type="checkbox" @click="() => {
                                                                liveTemplateStore.toggleCheckboxOnInput(element);
                                                                liveTemplateStore.saveInputFieldValue(element);
                                                            }" id="flexCheckDefault">

                                                            <input v-else class="form-check-input theCheckBox"
                                                                type="checkbox" @click="() => {
                                                                liveTemplateStore.toggleCheckboxOnInput(element);
                                                                liveTemplateStore.saveInputFieldValue(element);
                                                            }" id="flexCheckDefault">

                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                {{ element.template_question.question_text }}
                                                            </label>
                                                        </div>

                                                    </div>




                                                </div>



                                                <div v-if="element.response_type === 'datetime'"
                                                    class="theDateTimeBlock">

                                                    <div class="dateTimeArea">

                                                        {{ element.template_question.question_text }}


                                                        <input v-model="element.form_input_value.the_date"
                                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                                            class="form-control theInput" type="date"
                                                            style="margin-bottom: 10px; margin-top: 10px;">

                                                        <input v-model="element.form_input_value.the_time"
                                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                                            class="form-control theInput" type="time"
                                                            style="margin-bottom: 18px;">


                                                    </div>

                                                </div>

                                                <div v-if="element.response_type === 'date'" class="theDateTimeBlock">

                                                    <div class="dateTimeArea">

                                                        {{ element.template_question.question_text }}


                                                        <input v-model="element.form_input_value.the_date"
                                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                                            class="form-control theInput" type="date"
                                                            style="margin-bottom: 10px; margin-top: 10px;">


                                                    </div>

                                                </div>

                                                <div v-if="element.response_type === 'time'" class="theDateTimeBlock">

                                                    <div class="dateTimeArea">

                                                        {{ element.template_question.question_text }}


                                                        <input v-model="element.form_input_value.the_time"
                                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                                            class="form-control theInput" type="time"
                                                            style="margin-bottom: 10px; margin-top: 10px;">


                                                    </div>

                                                </div>

                                                <div v-if="element.response_type === 'media'" class="theMediaBlock">


                                                    <div class="theMediaArea">

                                                        {{ element.template_question.question_text }}


                                                        <button class="mediaBtn" id="btnDialog"
                                                            @click="liveTemplateStore.triggerInput">

                                                            <i class="bi bi-cloud-arrow-up"
                                                                style="font-size: 19px; margin-right: 10px;"></i>

                                                            <span>
                                                                Add Media
                                                            </span>

                                                        </button>


                                                    </div>



                                                </div>

                                                <div v-if="element.response_type === 'slider'" class="theSliderBlock">

                                                    <div class="sliderArea">

                                                        <div style="text-align: left">
                                                            {{ element.template_question.question_text }}
                                                        </div>



                                                        <div class="theSilderBox">


                                                            <input class="theRange" type="range"
                                                                v-model="element.form_input_value.number_value"
                                                                :min="element.min_range" :max="element.max_range"
                                                                step="1"
                                                                @change="liveTemplateStore.saveInputFieldValue(element)" />


                                                            <div class="sliderRangeArea">

                                                                <div class="theSide">
                                                                    {{ element.min_range }}
                                                                </div>

                                                                <div style="font-size: 18px;"
                                                                    v-if="element.form_input_value">
                                                                    <b>{{ element.form_input_value.number_value }}</b>
                                                                </div>

                                                                <div class="theSide">
                                                                    {{ element.max_range }}
                                                                </div>



                                                            </div>




                                                        </div>



                                                    </div>

                                                </div>


                                                <div v-if="element.response_type === 'instruction'"
                                                    class="instructionBox">

                                                    <div class="textTop">
                                                        {{ element.template_question.question_text }}
                                                    </div>

                                                    <div class="fileBottom"
                                                        v-if="element.instruction_files && element.instruction_files.length > 0">

                                                        <div v-if="element.instruction_files[0].type === 'image'">
                                                            <img class="theAttachImg"
                                                                :src="element.instruction_files[0].file_url" alt=""
                                                                srcset="">
                                                        </div>

                                                        <div class="pdfName"
                                                            v-if="element.instruction_files[0].type === 'pdf'"
                                                            @click="liveTemplateStore.attachmentDownloadPDFfile(element.instruction_files[0])">

                                                            {{ element.instruction_files[0].name }}

                                                        </div>




                                                    </div>

                                                </div>


                                                <div v-if="element.response_type === 'dropdown'"
                                                    class="parentContainer">

                                                    <div class="textTop">
                                                        {{ element.template_question.question_text }}
                                                    </div>

                                                    <div class="dropDownCont"
                                                        @click="liveTemplateStore.toggleDropdown(element)">


                                                        <div class="optionName"
                                                            v-if="element.form_input_value && element.form_input_value.dropdown_options.length > 0">


                                                            <div class="theCircle" :style="{
                        backgroundColor:
                            element.form_input_value.dropdown_options[0].the_option.color_code
                    }">

                                                            </div>

                                                            <b>{{
                        element.form_input_value.dropdown_options[0].the_option.option_text
                    }}</b>



                                                        </div>

                                                        <div v-else class="optionName">


                                                            Select option


                                                        </div>



                                                        <div>

                                                            <i v-if="element.show_dropdown === 0"
                                                                class="bi bi-chevron-down"></i>

                                                            <i v-else class="bi bi-chevron-up"></i>

                                                        </div>


                                                    </div>



                                                    <div class="theDropdownCont" v-show="element.show_dropdown === 1">



                                                        <div v-for="(dropItem, index) in element.dropdown_options"
                                                            :key="index"
                                                            :class="{ optionItemSeelected: element.dropdown_option_id === dropItem.id }"
                                                            @click="() => { liveTemplateStore.setSelectedDropdownOption(element, dropItem); liveTemplateStore.toggleDropdown(element); }">


                                                            <div class="optionItem">

                                                                <div class="dotBox">

                                                                    <div class="dropCircle"
                                                                        :style="{ backgroundColor: dropItem.color_code }">
                                                                    </div>

                                                                </div>

                                                                <div class="wordsBox">
                                                                    {{ dropItem.option_text }}
                                                                </div>

                                                            </div>


                                                        </div>



                                                    </div>

                                                </div>


                                                <div v-if="element.response_type === 'multiple_choice'"
                                                    class="choiceCont">

                                                    <div class="textTop">
                                                        {{ element.template_question.question_text }}
                                                    </div>

                                                    <div class="choiceItem"
                                                        v-for="(choice, index) in element.multiple_choice_options"
                                                                :key="index" :style="{
                                                                'background-color': choice.form_input_record !== null ?
                                                                choice.color_code : 'white',
                                                                'color': choice.form_input_record !== null ? 'white' : 'black',
                                                        }" @click="element.is_multiple_answer_selection === 'yes' ? liveTemplateStore.selectAMultipleChoice(element, choice) : liveTemplateStore.selectASingleChoice(element, choice)">
                                                        {{ choice.option_text }}
                                                    </div>

                                                </div>


                                                <div v-if="element.response_type === 'signature'"
                                                    class="theSignatureBlock">


                                                    <div class="signatureTextTop">
                                                        {{ element.template_question.question_text }}
                                                    </div>


                                                    <button class="addSignatureBtn"
                                                        @click="liveTemplateStore.openShowSignatureModal">

                                                        <i class="bi bi-pen" style="margin-right: 5px;"></i>

                                                        <span
                                                            v-if="element.form_input_value.the_file_url !== null">Replace
                                                            Signature</span>
                                                        <span v-else>Add Signature</span>

                                                    </button>


                                                    <div class="signImgCont"
                                                        v-if="element.form_input_value.the_file_url !== null">

                                                        <div class="imgSignBox">
                                                            <img @click="liveTemplateStore.openSignatureFileModal"
                                                                class="theActualSignImg"
                                                                :src="element.form_input_value.the_file_url">
                                                        </div>

                                                        <div class="dateText"
                                                            v-if="element.form_input_value.the_date && element.form_input_value.the_time">
                                                            {{
                        liveTemplateStore.formatDate(element.form_input_value.the_date)
                    }} {{
                            liveTemplateStore.formatTime(element.form_input_value.the_time)
                        }}
                                                        </div>



                                                    </div>

                                                </div>


                                                <div v-show="element.response_type === 'geostamp'"
                                                    class="theGeoStampBlock">



                                                    <div class="geoStampWrapper">

                                                        <div class="theGeoTextbox">

                                                            <input v-model="element.form_input_value.formatted_address"
                                                            @change="liveTemplateStore.saveInputFieldValue(element)"
                                                                type="text" class="form-control theMapInput"
                                                                placeholder="Location">

                                                        </div>


                                                        <div v-if="element.show_map === 0" class="theGeoMapBtnBox"
                                                            @click="() => { liveTemplateStore.toggleShowMap(element); loadMap(); }">

                                                            <button class="mapBtn"><i class="bi bi-geo-alt-fill"></i>
                                                                Map</button>


                                                        </div>

                                                        <div v-else class="theGeoMapBtnBox" @click="loadMap">


                                                            <button class="locateMeBtn">Find Me</button>

                                                        </div>

                                                    </div>



                                                    <div class="geoIframeBox" v-show="element.show_map === 1">



                                                        <div :id="'mapContainer' + element.id"
                                                            style="width: 100%; height: 100%;">
                                                        </div>


                                                        <div class="mapBtnsWrapper">

                                                            <button class="closeMapBtn"
                                                                @click="element.show_map = 0">Close
                                                                Map</button>

                                                            <button
                                                                @click="() => { liveTemplateStore.saveInputFieldValue(element); liveTemplateStore.toggleShowMap(element); }"
                                                                class="saveLocationBtn">Save Location</button>

                                                        </div>



                                                    </div>



                                                </div>


                                                <div class="theMessageBox" v-if="element.form_input_value.the_error_message !== null">

                                    
                                                    <div v-for="(message, index) in element.form_input_value.the_error_message" :key="index">

                                                        {{ message }}

                                                    </div>

                                                </div>



                                                <div class="bottomOptionsArea">

                                                    <div class="loadingState"
                                                        v-if="element.is_file_uploading === 'true'">

                                                        <div class="spinner-grow spinner-grow-sm" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>

                                                    </div>

                                                    <div class="filesArea"
                                                        v-if="element.form_input_value.media_files && element.form_input_value.media_files.length > 0">



                                                        <div v-for="(file, index) in liveTemplateStore.getImageAndVideoFilesOnly(element)"
                                                            :key="index" class="fileItem"
                                                            @click="() => { liveTemplateStore.setCurrentElement(element, elementIndex); liveTemplateStore.setCurrentFileIndex(file, element); }">


                                                            <img v-if="file.type === 'image'" class="theImg"
                                                                :src="file.file_url">


                                                            <div v-if="file.type === 'video'" class="theVideo">

                                                                <i class="bi bi-play-circle"
                                                                    style="font-size: 26px;"></i>

                                                            </div>


                                                        </div>




                                                    </div>


                                                    <div v-if="liveTemplateStore.getPdfFiles(element).length > 0">

                                                        <div class="pdfsArea"
                                                            v-for="(file, index) in liveTemplateStore.getPdfFiles(element)"
                                                            :key="index">

                                                            <div class="pdfItem" v-if="file.type === 'pdf'">

                                                                <i class="bi bi-filetype-pdf"
                                                                    style="margin-right: 7px; font-size: 20px;"></i>

                                                                <div class="thePDFname"
                                                                    @click="liveTemplateStore.downloadPDFfile(file)">
                                                                    {{ file.name }}
                                                                </div>

                                                                <div>
                                                                    <i @click="() => { liveTemplateStore.setCurrentElement(element, elementIndex); liveTemplateStore.deletePdfFile(file); }"
                                                                        class="bi bi-trash theTrash"></i>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="theNoteWrapper" v-if="element.form_input_value && element.form_input_value.note_details">

                                                        <div v-if="element.show_note === 'yes'" class="editNote">


                                                            <textarea v-model="element.form_input_value.note_details.the_note" class="form-control textyArea"
                                                                cols="30" rows="3"></textarea>

                                                            <div class="noteBtnsArea">

                                                                <div>

                                                                    <button class="saveBtn"
                                                                    @click="() => { liveTemplateStore.toggleOffEditMote(element);  liveTemplateStore.saveThisNote(); }"
                                                                        >
                                                                        Save
                                                                    </button>


                                                                </div>
                                                                


                                                                <div>
                                                                    <i @click="liveTemplateStore.deleteThisNote" class="bi bi-trash theTrash"></i>
                                                                </div>



                                                            </div>




                                                        </div>

                                                        <div v-else @click="liveTemplateStore.toggleOnEditMote(element)"
                                                        class="noteView" 
                                                        >
                                                            
                                                            {{ element.form_input_value.note_details.the_note }}

                                                        </div>

                                            
                                                    </div>

                                                    <div class="buttonsArea">

                                                        <div class="addNoteBtn" @click="liveTemplateStore.toggleOnEditMote(element)">
                                                            <i class="bi bi-pencil"></i> Note
                                                        </div>

                                                        <div class="addMediaBtn"
                                                            v-if="element.response_type !== 'media'"
                                                            @click="liveTemplateStore.triggerInput"
                                                            >
                                                            <i class="bi bi-images"></i> Media
                                                        </div>

                                                        <div class="addActionBtn">
                                                            <i class="bi bi-plus-lg"></i> Action
                                                        </div>

                                                    </div>





                                                </div>

                                            </div>





                                        </div>


                                    </div>

                                </div>



                            </div>


                        </div>




                    </div>



                   

                </div>
 

                 <div class="btnNavWrapper">


                        <div class="leftBtnCont"
                        v-show="liveTemplateStore.currentPageIndex > 0"
                        @click="liveTemplateStore.prevPage"
                        > 

                            <i class="bi bi-chevron-left"></i> Previous 
                             
                        </div>

                        <div class="midGap">

                      
                        
                        </div>

                        <div class="rightBtnCont"
                        @click="liveTemplateStore.completeTheInspection"
                        v-if="liveTemplateStore.currentPageIndex === (liveTemplateStore.templatePages.length - 1)"
                        > 

                           Complete Inspection <i class="bi bi-chevron-right"></i>
                             
                        </div>

                        <div v-else class="rightBtnCont"
                        @click="liveTemplateStore.nextPage"
                        >

                           Next <i class="bi bi-chevron-right"></i>

                        </div>



                </div>





            </div>


        </div>


        <!-- <button @click="liveTemplateStore.openShowSignatureModal">Test files modal</button> -->


        <div v-if="liveTemplateStore.showImageAndVideoGalleryModal" class="files-modal-overlay">



            <div class="topModalHeader">

                <div class="topLeftSide">

                    {{
                        liveTemplateStore.limitFileNameText('lsndjnwfnsadkfjnsdfksdjnfkjsdfkjbsdfkbsdkfjbskdfbsdfbbskdfbksdbfbkbsdfkbsdfkbsdfkjbsdkfjbskdjfbsdf')
                    }}

                </div>

                <div class="topRightSide">

                    <i class="bi bi-box-arrow-in-down" @click="liveTemplateStore.downloadFileFromGallery"
                        style="font-size: 22px; margin-right: 25px; cursor: pointer;"></i>

                    <i @click="liveTemplateStore.deleteFileFromGallery" class="bi bi-trash"
                        style="font-size: 20px; margin-right: 25px; cursor: pointer;"></i>

                    <i class="bi bi-x-lg" style="font-size: 20px; cursor: pointer;"
                        @click="liveTemplateStore.closeShowImageAndVideoGalleryModal"></i>

                </div>

            </div>

            <div class="galleryConatiner">


                <div>
                    <i @click="liveTemplateStore.prevFile" class="bi bi-chevron-left"
                        style="font-size: 30px; cursor: pointer;"></i>
                </div>

                <div class="middleGallery">


                    <div v-for="(file, index) in liveTemplateStore.galleryFiles" :key="index">

                        <div v-if="index === liveTemplateStore.currentFileIndexInGallery">


                            <img v-if="file.type === 'image'" class="imgInGallery" :src="file.file_url">

                            <div v-if="file.type === 'video'">

                                <video class="galleryVideo" controls>
                                    <source :src="file.file_url" type="video/mp4">
                                    Your browser does not support HTML video.
                                </video>

                            </div>


                        </div>


                    </div>


                </div>


                <div>
                    <i @click="liveTemplateStore.nextFile" class="bi bi-chevron-right"
                        style="font-size: 30px; cursor: pointer;"></i>
                </div>


            </div>




        </div>

        <div v-if="liveTemplateStore.showSignatureModal" class="signature-modal-overlay">



            <div class="topSignatureModalHeader" style="border: none;">

                <div class="topLeftSide">

                    <!-- <h2>showSignatureModal</h2> -->


                </div>

                <div class="topRightSide">

                    <!-- <i class="bi bi-trash"
                        style="font-size: 23px; margin-right: 25px; cursor: pointer; color: white;"></i> -->

                    <i class="bi bi-x" style="font-size: 40px; cursor: pointer; color: white;"
                        @click="liveTemplateStore.closeShowSignatureModal"></i>



                </div>

            </div>

            <div class="signatureWrapper">


                <div class="innerSignArea">




                    <div class="fixedArea" :style="{
                        'width': liveTemplateStore.deviceType === 'desktop' ? '600px' : '100%'
                    }">


                        <div class="signTabsHeader">

                            <div class="theTab" v-for="(signOption, index) in liveTemplateStore.signOptions"
                                :key="index" @click="liveTemplateStore.setCurentSignOption(signOption)"
                                :class="{ selectedSignTab: signOption.type === liveTemplateStore.currentSignatureType }">

                                {{ signOption.name }}

                            </div>

                        </div>


                        <div class="theSignInputAreaBox">

                            <div class="text_sign_box" v-if="liveTemplateStore.currentSignatureType === 'text_sign'">


                                <input type="text" class="form-control theSignInput" ref="theSignInput">


                                <div class="padbuttons">

                                    <button v-if="liveTemplateStore.isLoading" class="signActionBtn"
                                        style="width: 100%;">Loading...</button>
                                    <button v-else class="signActionBtn" style="width: 100%;"
                                        @click="saveTextSignAsImage">Save</button>


                                </div>

                            </div>


                            <div class="digital_sign_box"
                                v-if="liveTemplateStore.currentSignatureType === 'digital_sign'">



                                <div class="canvas-container">

                                    <VueSignaturePad class="thePad" ref="signaturePad" />


                                </div>


                                <div style="padding: 20px;" v-if="liveTemplateStore.isLoading">

                                    <b>Loading...</b>

                                </div>

                                <div v-else class="padbuttons">


                                    <button @click="saveDigiSignature" class="signActionBtn">Save</button>

                                    <button @click="clearDigiSignature" class="clearActionBtn">Clear</button>

                                </div>



                            </div>


                        </div>

                    </div>








                </div>






            </div>




        </div>

        <div v-if="liveTemplateStore.showSignatureFileModal" class="signature-modal-overlay">



            <div class="topSignatureModalHeader" style="border: none;">

                <div class="topLeftSide">

                    <!-- <h2>showSignatureFileModal</h2> -->


                </div>

                <div class="topRightSide">

                    <i class="bi bi-trash" @click="liveTemplateStore.deleteThisSignatureFile"
                        style="font-size: 23px; margin-right: 25px; cursor: pointer; color: white;"></i>

                    <i class="bi bi-x" style="font-size: 40px; cursor: pointer; color: white;"
                        @click="liveTemplateStore.closeSignatureFileModal"></i>



                </div>

            </div>

            <div class="signatureWrapper">


                <div class="innerSignArea">




                    <div class="fixedArea" :style="{
                        'width': liveTemplateStore.deviceType === 'desktop' ? '600px' : '100%'
                    }">

                        <div class="theImageBox" v-if="liveTemplateStore.currentElement.form_input_value && 
                        liveTemplateStore.currentElement.form_input_value.the_file_url">

                            <img width="300" :src="liveTemplateStore.currentElement.form_input_value.the_file_url">

                        </div>



                    </div>








                </div>






            </div>




        </div>

        <div v-if="liveTemplateStore.showNewActionModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>New Action</b>
                    </div>

                    <div>
                        <i @click="liveTemplateStore.closeNewActionModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content"
                    style="text-align: left; max-height: 600px; overflow-y: auto; padding: 20px;">


                    <form>

                        <div class="mb-3">
                            <label class="form-label"><b>Title</b></label>
                            <input type="text" class="form-control theInputStyle">
                        </div>

                        <div class="mb-3">

                            <div class="form-floating">
                                <textarea class="form-control theTextareaStyle"></textarea>
                                <label>Add description</label>
                            </div>

                        </div>

                        
                        <div class="mb-3">

                            <label class="form-label"><b>Priority</b></label> 

                            <select class="form-select">
                                
                                
                                <option v-for="(priority, index) in liveTemplateStore.priorityLevels"
                                :key="index" :value="priority.type">
                                    {{ priority.name }}
                                </option>
                      
                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Due Date</b></label>
                            <input type="date" class="form-control theInputStyle" style="margin-bottom: 5px;">
                            <input type="time" class="form-control theInputStyle">

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Assignees</b></label> 

                            <p>Click button show modal to select a user or group</p>

                        </div>
                        

                    </form>

                  

                    


                </div>
            </div>
        </div>

    </div>
</template>

<script setup>

import { defineProps, onMounted, ref } from 'vue';
import { useLiveTemplateStore } from "@/stores/LiveTemplateStore";
import { Loader } from '@googlemaps/js-api-loader';
import InpsectionWizardProgressBar from "@/components/InpsectionWizardProgressBar.vue";


const liveTemplateStore = useLiveTemplateStore();

const props = defineProps({
    random_string: String
})


// // Initial device type
// var deviceType = ref();

const signaturePad = ref(null);
const theSignInput = ref(null);

// eslint-disable-next-line 
const saveDigiSignature = () => {

    const { data } = signaturePad.value.saveSignature();


    liveTemplateStore.uploadThisDigitalSignature(data)



};


const clearDigiSignature = () => {

    signaturePad.value.clearSignature(); // Clear the signature pad

};




const saveTextSignAsImage = () => {

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas dimensions to match the text input box
    // You might need to adjust these values based on your layout
    canvas.width = 400; // Adjust width as needed
    canvas.height = 100; // Adjust height as needed

    // Style the canvas to match the background of the text input box
    ctx.fillStyle = 'white'; // Match the background color of the text input box
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Set the font properties to match the text input box
    ctx.font = '25px cursive'; // Adjust font size and family as needed
    ctx.fillStyle = 'black'; // Set the text color

    // Center the text horizontally and vertically
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Get the text from the input box
    const text = theSignInput.value.value;

    // Draw the text onto the canvas
    // The x and y coordinates are set to the center of the canvas
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);

    // Convert the canvas to a base64 image
    const base64Image = canvas.toDataURL('image/png');

    // // Log the base64 image
    // console.log(base64Image);


    liveTemplateStore.uploadThisDigitalSignature(base64Image)


}


// Function to determine the device type
const getDeviceType = () => {
    if (window.innerWidth <= 715) {

        liveTemplateStore.deviceType = 'mobile'

        return 'mobile';

    } else if (window.innerWidth <= 924) {


        liveTemplateStore.deviceType = 'tablet'

        return 'tablet';

    } else {

        liveTemplateStore.deviceType = 'desktop'


        return 'desktop';
    }
};

// Update the device type when the window is resized
window.addEventListener('resize', () => {
    liveTemplateStore.deviceType = getDeviceType();
});


const triggerGoogleMap = async () => {

    if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition(async (position) => { // Make this callback async

            liveTemplateStore.currentElement.form_input_value.latitude = position.coords.latitude;
            liveTemplateStore.currentElement.form_input_value.longitude = position.coords.longitude;

            if (
                liveTemplateStore.currentElement.form_input_value.latitude !== null &&
                liveTemplateStore.currentElement.form_input_value.longitude !== null
            ) {


                const loader = new Loader({
                    apiKey: 'AIzaSyCpDlAFp1SQFtPfDoDgEAgUt7IQq-EqNxU',
                    version: 'weekly',
                });

                await loader.load();


                const mapContainer = document.getElementById("mapContainer" + liveTemplateStore.currentElement.id);


                let currentPosition = {
                    lat: liveTemplateStore.currentElement.form_input_value.latitude,
                    lng: liveTemplateStore.currentElement.form_input_value.longitude,
                }

                // eslint-disable-next-line
                const map = new google.maps.Map(mapContainer, {
                    center: currentPosition,
                    zoom: 15,
                    mapTypeControl: false, // Disable the Map and Satellite options
                    streetViewControl: false, // Disable the Pegman control,
                    fullscreenControl: false,
                });

                // Add a marker to the map
                // eslint-disable-next-line
                const marker = new google.maps.Marker({
                    position: currentPosition,
                    map: map,
                    title: 'Your Location',
                });





                // Get the address from the latitude and longitude
                const address = await getAddressFromLatLng(currentPosition.lat, currentPosition.lng);

                //populate the current element with address, lat and long to save in db
                liveTemplateStore.currentElement.form_input_value.latitude = currentPosition.lat
                liveTemplateStore.currentElement.form_input_value.longitude = currentPosition.lng
                liveTemplateStore.currentElement.form_input_value.formatted_address = address




                // console.log(address); // Log the address to the console


            }
        }, (err) => {
            alert(err.message);
        });
    } else {
        console.log('Geolocation is not supported by your browser');
    }
};



// const triggerGoogleMap = async () => {

//     if (navigator.geolocation) {

//         navigator.geolocation.getCurrentPosition((position) => {

//             liveTemplateStore.currentElement.form_input_value.latitude = position.coords.latitude,
//             liveTemplateStore.currentElement.form_input_value.longitude = position.coords.longitude

//             if (
//                 liveTemplateStore.currentElement.form_input_value.latitude !== null &&
//                 liveTemplateStore.currentElement.form_input_value.longitude !== null) {




//                 const loader = new Loader({
//                     apiKey: 'AIzaSyCpDlAFp1SQFtPfDoDgEAgUt7IQq-EqNxU',
//                     version: 'weekly',
//                 });

//                 await loader.load();


//                 const mapContainer = document.getElementById("mapContainer" + liveTemplateStore.currentElement.id);


//                 let currentPosition = {
//                     lat: liveTemplateStore.currentElement.form_input_value.latitude,
//                     lng: liveTemplateStore.currentElement.form_input_value.longitude,
//                 }

//                 // eslint-disable-next-line
//                 const map = new google.maps.Map(mapContainer, {
//                     center: currentPosition,
//                     zoom: 15,
//                     mapTypeControl: false, // Disable the Map and Satellite options
//                     streetViewControl: false, // Disable the Pegman control,
//                     fullscreenControl: false,
//                 });

//                 // Add a marker to the map
//                 // eslint-disable-next-line
//                 const marker = new google.maps.Marker({
//                     position: currentPosition,
//                     map: map,
//                     title: 'Your Location',
//                 });


//                 // Get the address from the latitude and longitude
//                 const address = await getAddressFromLatLng(currentPosition.lat, currentPosition.lng);

//                 //populate the current element with address, lat and long to save in db
//                 liveTemplateStore.currentElement.form_input_value.latitude = currentPosition.lat
//                 liveTemplateStore.currentElement.form_input_value.longitude = currentPosition.lng
//                 liveTemplateStore.currentElement.form_input_value.formatted_address = address


//                 console.log(address); // Log the address to the console



//             }




//         },
//             (err) => {
//                 alert(err.message)
//             })

//     } else {

//         console.log('Geolocation is not supported by your browser')

//     }





// };


// eslint-disable-next-line
const getAddressFromLatLng = async (lat, lng) => {

    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCpDlAFp1SQFtPfDoDgEAgUt7IQq-EqNxU`);

    const data = await response.json();

    if (data.status === 'OK') {
        return data.results[0].formatted_address;
    } else {
        console.error('Geocoding failed:', data.status);
        return null;
    }
};


// eslint-disable-next-line
const loadMap = async () => {


    await triggerGoogleMap();


};










onMounted(() => {


    getDeviceType()

    liveTemplateStore.fetchLiveTemplate(props.random_string)

})





</script>

<style scoped>
.mainContainer {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    height: 100vh;
}

.headerSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: yellow; */
    padding: 15px;
    /* border-bottom: 1px solid black; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.leftTop {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: aqua; */
}

.tempNameCont {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    /* background-color: red; */
}

.templateInfoCont {
    width: 80%;
    /* background-color: rgb(255, 255, 255); */
    text-align: left;
    font-size: 13px;
}



.rightTop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    /* background-color: rgb(255, 208, 0); */
}


.mobileCTABtn {
    margin-right: 25px;
}

.closeTemplate {
    font-size: 25px;
    margin-right: 10px;
}


.formSection {
    display: flex;
    justify-content: center;
    /* padding: 20px; */
    width: 100%;
    /* background-color: rgb(245, 211, 211); */
    /* margin-top: 20px; */
    z-index: 1;
}


.theFormCont {
    width: 800px;
    height: 600px;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* border-radius: 10px; */
    padding: 15px;

}

.thePageNavCont {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    width: 100%;
    /* margin-top: 15px; */
    /* background-color: rgb(240, 240, 20); */
}

.pageNavy {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f7f6f6;
    padding: 15px;
    /* border-radius: 5px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.pageNavy:hover {
    background-color: #f2fbf2;
}

.thePageCountBox {
    /* background-color: aliceblue; */
    width: 100%;
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
}

.theSectionNameBox {
    /* background-color: rgb(46, 147, 235); */
    width: 100%;
    text-align: left;
    font-weight: bold;
}


.elementItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 18px;
    /* border: 1px solid rgb(239, 237, 237); */
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    /* background-color: rgb(192, 192, 192); */
}


.questionArea {
    width: 100%;
    /* background-color: rgb(234, 234, 234); */
    text-align: left;
    margin-bottom: 10px;
}

.inputArea {
    width: 100%;
    /* background-color: rgb(255, 200, 200); */
    margin-bottom: 18px;
    margin-bottom: 25px;
}



.addNoteBtn {
    font-size: 14px;
    margin-right: 30px;
    cursor: pointer;
}

.addMediaBtn {
    font-size: 14px;
    margin-right: 30px;
    cursor: pointer;
}

.addActionBtn {
    font-size: 14px;
    cursor: pointer;
}

.pagesListBox {
    background-color: #ffffff;
    width: 100%;
    /* position: absolute;
    z-index: 2; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    max-height: 400px;
    overflow-y: auto;
    margin-top: 15px;

}

.outerPageNavBox {
    width: 800px;
    border-radius: 5px;
    /* background-color: green; */
}


.pageItem {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
    text-align: left;
    font-size: 14px;
}


.pageItem:hover {
    background-color: #f0f5f0;
}

.checkyBox {
    display: flex;
    /* background-color: #c3d4cb; */
    padding-top: 5px;
    padding-bottom: 18px;
    text-align: left;
}


.theCheckBox {
    border: 2px solid #151a0b;
    margin-right: 10px;
    cursor: pointer;
}

.checkboxCont {
    width: 100%;
    /* background-color: rgb(69, 66, 66); */
}


.theTextAnswerBlock {
    width: 100%;
    /* background-color: red; */
}

.theDateTimeBlock {
    width: 100%;
}

.theNumberAnswerBlock {
    width: 100%;
}

.theMediaBlock {
    width: 100%;
}

.theSliderBlock {
    width: 100%;
}

.theInput {
    border: 1px solid #c9c9c9;
}

.dateTimeArea {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 20px;
}

.theMediaArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.mediaBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: black;
    color: white;
    padding: 7px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    font-size: 13px;

}


.sliderArea {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

}

.theSilderBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: rgb(249, 240, 240); */
    /* padding: 20px; */
}


.theRange {
    width: 100%;
    height: 50px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    cursor: pointer;
}

.theRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: #4CAF50;
    cursor: pointer;
}

.theRange::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #4CAF50;
    cursor: pointer;
}


.sliderRangeArea {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color: gray; */
}

.theSide {
    font-size: 13px;
    color: #737373;
}


.instructionBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: #737373; */
}

.textTop {
    text-align: left;
    margin-bottom: 18px;
}

.instructionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* background-color: rgb(241, 233, 233); */
}

.fileBottom {
    width: 100%;
    margin-bottom: 25px;
    /* background-color: #e0e3e0; */
}

.theAttachImg {
    width: 100%;
    border-radius: 10px;
}

.pdfName {
    font-size: 12px;
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    word-break: break-all;
    cursor: pointer;

}


.pdfName:hover {
    color: #2b712c;
    text-decoration: underline;
    cursor: pointer;
}


.parentContainer {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}

.dropDownCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;



}

.optionName {
    display: flex;
    align-items: center;
    font-size: 13px;
}


.theCircle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 6px;
}

.theDropdownCont {
    position: absolute;
    top: 100%;
    /* Position it right below .dropDownCont */
    width: 100%;
    /* Match the width of .dropDownCont */
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 5px;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;

    /* width: 230px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    position: absolute;
    z-index: 2;
    margin-top: 5px;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto; */
}

.optionItem {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    /* padding-left: 10px; */
    padding-right: 10px;
    border-bottom: 1px solid #e2e3e3;
    cursor: pointer;
    word-wrap: break-word;

}

.optionItem:hover {
    background-color: #f1f3f3;
    cursor: pointer;
    border-radius: 3px;

}


.optionItemSeelected {
    background-color: #f1f3f3;
    cursor: pointer;
    border-radius: 3px;
}

.dotBox {
    width: 10%;
}

.wordsBox {
    width: 90%;
}

.dropCircle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: red;
    margin-right: 10px;
}


.choiceCont {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.choiceItem {
    width: 100%;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #bcbcbc;
    margin-bottom: 8px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 13px;
}

.choiceItem:hover {
    cursor: pointer;
}

.choiceItemSelected {
    width: 100%;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #bcbcbc;
    margin-bottom: 8px;
    cursor: pointer;
    background-color: red;
}

.addSignatureBtn {
    width: 100%;
    background-color: #000000;
    color: white;
    padding: 7px;
    border-radius: 5px;
    border: none;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 10px;
    font-weight: bold;
    font-size: 13px;
}


.signatureConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90%;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: #f1fbf6; */
}

.theSignWrapper {
    width: 100%;
    height: auto;

    background-color: white;
}

.signHeader {
    display: flex;
    /* background-color: yellow; */
    height: 20%;
}

.signBody {
    display: flex;
    align-items: center;
    /* background-color: rgb(224, 153, 38); */
    padding: 5px;
    height: 90%;
    color: black;
}


.myWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.theTab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    padding: 6px;
    /* border: 1px solid black; */
    font-size: 15px;
    cursor: pointer;
    color: black;

}

.selectedSignTab {
    width: 100%;

    padding: 6px;
    /* border: 1px solid black; */
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    color: black;


}


.text_sign_box {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* background-color: #d41c1c; */
    width: 100%;


}


.actionBox {
    /* background-color: #8ad28a; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    color: black;
    cursor: pointer;

}

.theSignInput {
    height: 60px;
    margin-bottom: 18px;
    background-color: #f4f4f4;
    font-size: 20px;
    font-family: cursive;
    font-weight: bold;
    text-align: center;
    color: black;
    border: 2px solid #d1d1d1;
    /* font-family:"Great Vibes"; */
}


.digital_sign_box {
    display: flex;
    flex-direction: column;

    /* background-color: #16eb13; */
    width: 100%;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.canvas-container {
    width: 100%;
    height: 190px;
    /* background-color: yellow; */
}

.thePad {
    width: 100%;
    height: 100px;
    background-color: white;
    border: 2px solid rgb(219, 219, 219);
    border-radius: 5px;
}

.padbuttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 3px;
    /* gap: 15px; */
}

.signActionBtn {
    width: 50%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    color: rgb(3, 2, 2);
    border-radius: 10px;
    padding: 6px;
    margin-right: 10px;
    border: none;
    font-weight: bold;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}

.clearActionBtn {
    width: 50%;
    background: white;
    color: rgb(3, 2, 2);
    border-radius: 10px;
    padding: 6px;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.signImgCont {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;

}

.imgSignBox {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    /* border: 1px solid black; */
}

.dateText {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 13px;
    color: black;

}

.theActualSignImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgb(198, 198, 198);

}


.theTrash {
    cursor: pointer;
}


.theSignatureBlock {
    width: 100%;
    margin-bottom: 20px;
}

.signatureTextTop {
    text-align: left;
    margin-bottom: 9px;
}

.geoStampWrapper {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 15px;
    /* background-color: #d0eb08; */
}


.theGeoTextbox {
    width: 70%;
    /* background-color: #bcbcbc; */
}

.theGeoMapBtnBox {
    width: 30%;
    /* background-color: #ed2222; */
}

.mapBtn {
    cursor: pointer;
    width: 100%;
    border: none;
    color: #040202;
    border-radius: 5px;
    font-size: 14px;
    ;
    /* background-color: #2b712c; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.locateMeBtn {
    width: 100%;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    font-size: 14px;
    ;
    /* background-color: #2b712c; */
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    padding: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.theMapInput {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}


.geoIframeBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(238, 238, 238); */
    height: 400px;

}

.mapBtnsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: gray; */
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 15px;
}

.closeMapBtn {
    width: 100%;
    border: none;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 12px;
}

.saveLocationBtn {
    width: 100%;
    border: none;
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
}

.theGeoStampBlock {
    width: 100%;
    margin-bottom: 30px;

}



.sectionScrollItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 10px;
    padding: 15px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-top: 10px;
    margin-bottom: 12px;
    cursor: pointer;
}


.theSectioTab {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.nameSection {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    /* background-color: #4b6cb7; */
}

.buttonsArea {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}


.loadingState {
    display: flex;
    justify-content: center;
    width: 100%;
}

.filesArea {
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    width: 100%;
    /* background-color: #c3d4cb; */
    margin-bottom: 9px;

}

.fileItem {
    /* background-color: rgb(237, 167, 167); */
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    flex: 0 1 calc(33.333% - 10px);
    margin: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;

}

.theImg {

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.theVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.loadingState {
    display: flex;
    justify-content: center;
    width: 100%;

}

.pdfsArea {
    width: 100%;
    /* background-color: yellow; */
    /* margin-top: 15px; */
    margin-bottom: 10px;
}


.pdfItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    text-align: left;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* margin-bottom: 9px; */


}


.thePDFname {
    font-weight: bold;
    font-size: 13px;
    word-break: break-all;
    margin-right: 10px;
    cursor: pointer;
}

.thePDFname:hover {
    color: green;
    text-decoration: underline;
}

.theTrash {
    cursor: pointer;
}


.bottomOptionsArea {
    display: flex;
    flex-direction: column;
    /* background-color: rgb(138, 177, 229); */
    width: 100%;
    justify-content: space-between;
}

.files-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    /* background-color: rgba(0, 0, 0, 0.7); */
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

.signature-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 999;
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}


.topModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    border-bottom: 1px solid #e0e0e0;
    /* background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); */
}

.galleryConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90%;
    /* background-color: #f1fbf6; */
    padding-left: 20px;
    padding-right: 20px;
}

.topLeftSide {
    width: 50%;
    /* background-color: yellow; */
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
}

.topRightSide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding-right: 10px;
    /* background-color: rgb(167, 167, 159); */
}

.middleGallery {
    display: flex;
    align-items: center;
    /* background-color: rgb(185, 185, 185); */
    height: 650px;
    padding-left: 25px;
    padding-right: 25px;
    overflow-y: auto;
}


.imgInGallery {
    width: 100%;
    height: auto;
    border-radius: 10px;
}


.galleryVideo {
    width: 100%;
    border-radius: 10px;
}


.topSignatureModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    border-bottom: 1px solid #e0e0e0;
    /* background: yellow; */
}

.signatureWrapper {
    display: flex;
    align-items: center;
    /* background-color: white; */
    height: 90%;
    /* background-color: green; */
    padding: 20px;
}

.innerSignArea {
    display: flex;
    align-items: center;
    /* background-color: purple; */
    max-width: 500px;
    height: 100%;

}

.signTabsHeader {
    display: flex;
    width: 100%;
    background-color: #ffffff;
    height: 20%;
    padding-top: 10px;
}

.theSignInputAreaBox {
    width: 100%;
    background-color: white;
    height: 90%;
    padding-bottom: 10px;

}


.fixedArea {
    /* height: 250px; */
    /* width: 500px; */

    /* background-color: orange; */
}


.textSignTab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: gray; */
    font-size: 15px;
}

.digitalSignTab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(238, 56, 56); */
    font-size: 15px;
}

.theImageBox {
    background-color: white;
}

.theNoteWrapper {
    margin-bottom: 15px;
}

.noteView {
    text-align: left;
    /* padding: 7px; */
    color: black;
    word-break: normal;
    cursor: pointer;
}


.noteView:hover {
    background-color: rgb(248, 249, 248);
    border-radius: 4px;
    cursor: pointer;
}




.editNote {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: #535353; */
    text-align: left;
}

.noteBtnsArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
 
}

.textyArea {
    border: 1px solid #8ad28a;
    margin-bottom: 15px;
}


.saveBtn {
    border: none;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 10px;
    margin-right: 15px;
    color: black;
    border-radius: 10px;
    font-weight: bold;
    width: 80px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.saveBtn:hover {
    background: rgb(203, 234, 205);
}

.cancelBtn {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    color: black;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
    cursor: pointer;
}

.cancelBtn:hover {
    background: rgb(252, 253, 253);
}

.completeBox{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.leftComplete{
    font-size: 14px;
}

.rightComplete{
    font-size: 13px;
    
}

.theMessageBox{
    width: 100%;
    /* background-color: rgb(220, 220, 220); */
    color: red;
    font-weight: bold;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}


.btnNavWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: rgb(199, 194, 194); */
    margin-top: 20px;
    
}


.leftBtnCont, .rightBtnCont{
    background-color: black;
    width: 50%;
    padding: 15px;
    font-weight: bold;
    font-size: 13px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.leftBtnCont:hover{
    background-color: rgb(14, 113, 44);
}
.rightBtnCont:hover{
    background-color: rgb(14, 113, 44);
}

.midGap{
    width: 15px;
    /* background-color: red; */
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}


.theInputStyle{
    border: 1px solid rgb(186, 186, 186);
}

.theTextareaStyle{
    border: 1px solid rgb(186, 186, 186);
    resize: none;
    height: 90px !important;

}

</style>