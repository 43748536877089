<template>
    <div class="topNavBar">



        <div class="navItem">

            <i class="bi bi-hdd-stack menuIcon" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"></i>

        </div>

        <div class="navItem">
            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />
        </div>

        <div class="navItem">

            <div class="profilePic">


                <img class="roundProfilePic" v-if="authStore.currentUser.profile_pic"
                    :src="authStore.currentUser.profile_pic" />

                <i v-else class="bi bi-person-circle" style="font-size: 30px; color: #959795;"></i>

            </div>

        </div>




        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">

            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">


                <div class="mainMenuWrapper">




                    <router-link class="linkStyle" :to="{ name: 'AppsHome' }">

                        <div class="menuItemRow">


                            <div class="iconArea">

                                <i class="bi bi-grid"></i>

                            </div>

                            <div class="labelArea">

                                <span style="float: left; margin-left: 0px;">Apps</span>

                            </div>

                        </div>

                    </router-link>


                    <div class="menuItemRow">


                        <div class="iconArea">


                            <i class="bi bi-layers"></i>

                        </div>

                        <div class="labelArea">

                            <span style="float: left; margin-left: 0px;">Documents</span>

                        </div>

                    </div>






                    <div class="menuItemRow" @click="authStore.logoutUser">


                        <div class="iconArea">

                            <i class="bi bi-box-arrow-right"></i>

                        </div>

                        <div class="labelArea">

                            <span style="float: left; margin-left: 0px;">Logout</span>

                        </div>

                    </div>





                </div>



            </div>
        </div>





    </div>
</template>

<script setup>


import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from "vue";

const authStore = useAuthStore();



onMounted(() => {

    authStore.getUser()

})




</script>

<style scoped>
.topNavBar {

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-around;
    /* Evenly space out the nav items */
    align-items: center;
    /* Vertically center the items */
    position: fixed;
    /* Fix the nav bar to the top */
    top: 0;
    /* Align to the top */
    left: 0;
    width: 100%;
    /* Full width */
    background-color: white;
    /* Example background color, change as needed */
    padding: 10px 0;
    /* Some padding on top and bottom */
    z-index: 1000;
    /* Ensure the navbar is above other content */

}

.navItem {
    text-align: center;
    /* Center the text inside each nav item */
    flex-grow: 1;
    /* Each nav item takes up equal space */
    /* background-color: rgb(201, 186, 186); */
}

.content {
    padding-top: 60px;
    /* Adjust as needed, to ensure the content doesn't go under the nav bar */
    /* Rest of your content styling */
}

.imgLogo {
    width: 80px;
}


.usernameBox,
.downArrow,
.profilePic {
    display: flex;
    align-items: center;
    /* Center content of each box vertically */
    justify-content: flex-end;
    /* Center content of each box horizontally */
    margin: 0 5px;
    /* Optional: add some space between the items */
}

/* Additional specific styles for each class if needed */
.profilePic {
    font-size: 30px;
    color: #959795;
    margin-right: 10px;
    cursor: pointer;
}


.roundProfilePic {
    border-radius: 60%;
    border: 1.5px solid #c3d4cb;
    object-fit: cover;
    /* Ensures the image covers the area without stretching */
    width: 35px;
    /* Set the width as required */
    height: 35px;
    /* Set the height as required */
}

.menuIcon {
    float: left;
    margin-left: 20px;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}


#offcanvasExample {
    max-width: 200px;
}


.mainMenuWrapper {
    /* background-color: aqua; */
    width: 100%;
    margin-top: 20px;
    /* padding: 20px; */
}

.menuItemRow {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;

}


.iconArea {
    /* background-color: #27c664; */
    width: 20%;
    display: flex;
    align-items: center;
}

.labelArea {

    /* background-color: #dee136; */
    width: 80%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}


.menuItemRow:hover {
    background-color: rgb(232, 243, 234);
}

.menuItemRowSelected {
    background-color: rgb(232, 243, 234);
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}
</style>
