<template>
    <div>
        <div class="mainWrapper">
            <MainSideBar />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">
                        <div>Actions</div>

                        <div>

                            <button @click="templateStore.goToActionSettings" class="settingsBtn" role="button">
                                <i class="bi bi-gear"></i> Settings
                            </button>

                            <button @click="templateStore.openNewActionModal" class="button-6" role="button">
                                New Action +
                            </button>

                        </div>


                    </div>


                    <div class="searchBarWrapper">
                        <input type="text" class="form-control theBar" placeholder="Search actions..." />
                    </div>



                    <div class="actionsList">

                        <div v-for="index in 8" :key="index" class="actionItem">

                            <div class="topSection">

                                <div class="checkArea">

                                    <input class="myCheckbox" type="checkbox">

                                </div>

                                <div class="actionNameArea">

                                    <div style=" font-size: 15px;">
                                        <span class="badge rounded-pill bg-danger">To do</span>
                                    </div>

                                    <div style=" font-size: 13px;">

                                    </div>

                                </div>


                            </div>

                            <div class="middleSection">

                                <div style="font-size: 16px; ">
                                    <b>Clean engines for the tractors ob Miami site</b>
                                </div>

                            </div>

                            <div class="bottomSection">

                                <div class="bottomLeft">



                                    <div>
                                        <span style="color: red;"><b>High Priority</b></span>
                                    </div>

                                    <div>
                                        <i class="bi bi-calendar3"></i> Due 9:00am on June 20th 2024
                                    </div>

                                    <div>
                                        Assigned to Martin Black
                                    </div>

                                    <div>
                                        <i class="bi bi-geo-alt-fill"></i> Miami
                                    </div>

                                </div>

                                <div class="bottomRight">

                                    <div>
                                        Updated 2 mins ago
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>




                </div>
            </div>
        </div>



        <div v-if="templateStore.showNewActionModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>New Action</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeNewActionModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content"
                    style="text-align: left; max-height: 600px; overflow-y: auto; padding: 20px;">


                    <form>
 
                        <div class="mb-3">
                            <label class="form-label"><b>Title</b></label>
                            <input type="text" class="form-control theInputStyle">
                        </div>

                        <div class="mb-3">

                            <div class="form-floating">
                                <textarea class="form-control theTextareaStyle"></textarea>
                                <label>Add description</label>
                            </div>

                        </div>


                        <div class="mb-3">

                            <label class="form-label"><b>Priority</b></label>

                            <select class="form-select">


                                <option v-for="(priority, index) in templateStore.priorityLevels" :key="index"
                                    :value="priority.type">
                                    {{ priority.name }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Due Date</b></label>
                            <input type="date" class="form-control theInputStyle" style="margin-bottom: 5px;">
                            <input type="time" class="form-control theInputStyle">

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Assignees</b></label>

                            <p>Click button show modal to select a user or group</p>

                        </div>

                        <div class="mb-3">

                            <label class="form-label"><b>Site</b></label>

                            <select class="form-select">


                                <option v-for="(site, index) in templateStore.sites" :key="index"
                                    :value="site.id">
                                    {{ site.name }}
                                </option>

                            </select>

                        </div>


                        <div class="mb-3">

                            <label class="form-label"><b>Asset</b></label>

                            <select class="form-select">


                                <option v-for="(asset, index) in templateStore.assets" :key="index"
                                    :value="asset.id">
                                    {{ asset.name }}
                                </option>

                            </select>

                        </div>

                         <div class="mb-3">

                            <label class="form-label"><b>Label</b></label>

                            <select class="form-select">


                                <option v-for="(label, index) in templateStore.labels" :key="index"
                                    :value="label.id">
                                    {{ label.name }}
                                </option>

                            </select>

                        </div>


                        <button class="btn btn-dark" style="width: 100%; margin-top: 20px;"><b>Create</b></button>


                    </form>






                </div>
            </div>
        </div>






    </div>
</template>

<script setup>
import MainSideBar from "@/components/Inspections/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted } from 'vue';
import { useTemplateStore } from "@/stores/TemplateStore";


// eslint-disable-next-line 
const templateStore = useTemplateStore();



onMounted(() => { 


    templateStore.fetchAllActions()


})



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
}

.inviteInput {
    width: 500px;
    font-size: 13px;
    cursor: pointer;
}

.tabsWrapper {
    display: flex;
    width: 100%;
    /* background-color: #a9baba; */
}


.tabItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}

.tabItem:hover {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}



.iconDrop {
    cursor: pointer;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.emptyTableBox {
    margin-top: 100px;
}

.actionsList {
    width: 100%;
    /* background-color: gray; */
}

.actionItem {
    display: flex;
    flex-direction: column;
    width: 100%;

    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.topSection {
    display: flex;
    width: 100%;
    background-color: rgb(247, 244, 244);
    border-radius: 10px;

}

.checkArea {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    width: 3%;
    /* background-color: #d5d9d9; */
}




.actionNameArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97%;
    text-align: left;
    padding: 10px;
    /* background-color: #95e8e8; */
}

.middleSection {
    display: flex;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 10px;
}

.bottomSection {
    display: flex;
    width: 100%;
    /* background-color: rgb(219, 237, 204); */
}

.bottomLeft {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 50%;
    font-size: 14px;

}

.bottomRight {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    width: 50%;
    font-size: 13px;
    /* background: rgb(245, 151, 151); */

}

.settingsBtn {
    background-color: white;
    padding: 10px;
    font-size: 13px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-right: 15px;
    border: none;
    height: 100%;
    cursor: pointer;
}

.settingsBtn:hover {
    background-color: rgb(238, 244, 234);
}

.theInputStyle{
    border: 1px solid rgb(186, 186, 186);
}

.theTextareaStyle{
    border: 1px solid rgb(186, 186, 186);
    resize: none;
    height: 90px !important;

}
</style>