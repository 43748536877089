/* eslint-disable */ 
import SuperAdminService from "@/services/SuperAdminService";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import moment from "moment";


export const useSuperAdminStore = defineStore("SuperAdminStore", {

  state: () => ({


    talks: [],

    talkTypes: [
      {
        id: 1,
        type: "text",
        button_name: "TEXT",
      },

      {
        id: 2,
        type: "video",
        button_name: "VIDEO",
      },

      // {
      //   id: 3,
      //   type: "pdf",
      //   button_name: "PDF",
      // },
    ],

    showCreateNewTalkModal: false,

    post: { 
      content_type: "",
      title: "",
      body: "",

    },

    postDetails: {},

    textPost: {},

    showVideoEmbedModal: false,

    videoPost: {

      content_type: "",
      title: "",
      video_embed_code: ""

    },

    currentTalk: {},

    showPreviewTalkModal: false,

    managers: [],
    manager: {},
    subManagers: [],
    employees: [],
    teams: [],


    showManageAccessModal: false,
    showBillingModal: false,

    newManagerAppAccessFormData: {
      manager_id: null,
      safelyio_app_id: null,
      team_size_limit: null,
      start_date: null,
      end_date: null,
      sub_admin_limit: null,
      is_allowed_subadmin_feature: "no",
      has_access: 'yes',
    },

    apps: [],

    tabs: [
      {
        id: 1, name: 'Apps List', type: 'apps_list'
      },
      {
        id: 2, name: 'Employees', type: 'employees'
      }
    ],

    currentTab: 'apps_list',

    appAccessList: [],

    showEditAppAccessModal: false,

    currentAppAccess: {},

    showBulkUploadEmployeesModal: false,

    newBulkUpload: {
      company_id: null,
      team_id: null,
      file: null
    },

    isLoading: false,


  }),




  actions: {


    fetchAllTalks() {

      SuperAdminService.getAllTalksByAdmin()
        .then((response) => {

          this.talks = response.data.talks;

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    openCreateNewTalkModal() {
      this.showCreateNewTalkModal = true
    },

    closeCreateNewTalkModal() {
      this.showCreateNewTalkModal = false
    },

    saveTalk() {



      SuperAdminService.saveTalkToDB(this.post)
        .then((response) => {


          toast("saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          this.post.title = ""
          this.post.body = ""


          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });


    },

    saveVideoTalk() {


      SuperAdminService.saveTalkToDB(this.videoPost)
        .then((response) => {


          toast("saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          this.videoPost.title = ""
          this.videoPost.video_embed_code = ""


          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });


    },


    saveTextTalk() {
      SuperAdminService.updateTalk(this.textPost.id, this.textPost)
        .then((response) => {


          toast("updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });


          // // eslint-disable-next-line no-console
          // console.log(response.data);

        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    updateVideoTalk() {


      SuperAdminService.updateTalk(this.postDetails.id, this.postDetails)
        .then((response) => {


          toast("updated!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // // eslint-disable-next-line no-console
          // console.log(response.data);

        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });


    },



    openVideoEmbedModal(){
      this.showVideoEmbedModal = true
    },

    closeVideoEmbedModal(){
      this.showVideoEmbedModal = false
    },


    setCurrentTalk(talk){
      this.currentTalk = talk
    },

    deleteTalk() {
      if (
        confirm(
          "Are you sure you want to delete this talk?"
        )
      ) {


        SuperAdminService.deleteThisTalk(this.currentTalk.id)
        .then((response) => {


          this.fetchAllTalks()

          toast("deleted!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // // eslint-disable-next-line no-console
          // console.log(response.data)
          
        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)


        });
  

        console.log('delete talk')
      }
    },


    openShowPreviewTalkModal(){
      this.showPreviewTalkModal = true
    },


    closeShowPreviewTalkModal(){
      this.showPreviewTalkModal = false
    },


    fetchAllManagers(){
      SuperAdminService.getAllManagerAccounts()
        .then((response) => {

          this.managers = response.data
          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });
    },


    fetchAManager(uuid){
      
      SuperAdminService.getAManagerAccountDetails(uuid)
        .then((response) => {

          this.manager = response.data.manager
          this.subManagers = response.data.subManagers
          this.employees = response.data.employees
          this.apps = response.data.apps
          this.appAccessList = response.data.appAccessList
          this.teams = response.data.teams

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });
    },


    formatDate(date) {

      return moment(date).format("MMM Do YYYY");

    },

    resetForm(){
      this.newManagerAppAccessFormData.manager_id = null
      this.newManagerAppAccessFormData.safelyio_app_id = null
      this.newManagerAppAccessFormData.team_size_limit = null
      this.newManagerAppAccessFormData.start_date = null
      this.newManagerAppAccessFormData.end_date = null
      this.newManagerAppAccessFormData.sub_admin_limit = null
      this.newManagerAppAccessFormData.is_allowed_subadmin_feature =  "no"
      this.newManagerAppAccessFormData.has_access = "yes"
    },

    openManageAppAccessModal(){
      this.resetForm()
      this.showManageAccessModal = true
    },

    closeManageAppAccessModal(){
      this.showManageAccessModal = false
    },

    openBillingModal(){
      this.showBillingModal = true
    },

    closeBillingModal(){
      this.showBillingModal = false
    },



    addManagerAccessForApp(){

      this.newManagerAppAccessFormData.manager_id = this.manager.id

      SuperAdminService.addAppAccess(this.newManagerAppAccessFormData)
        .then((response) => {

          if(response.data.message === 'alreay_added'){

            alert("This manager account was already added for this app's access!")

          }

          if(response.data.message === 'success'){

            toast("Success!", {
              position: "bottom-right",
              autoClose: 1000,
            });

            this.fetchAManager(this.manager.uuid)
            this.closeManageAppAccessModal()

          }
          

          
        }).catch((err) => {

          console.log(err)
          
        });

      

    },

    toggleSubAdminFeature(stringValue){
      
      this.newManagerAppAccessFormData.is_allowed_subadmin_feature = stringValue

    },





    openShowEditAppAccessModal(){
      this.showEditAppAccessModal = true
    },

    closeShowEditAppAccessModal(){ 
      this.showEditAppAccessModal = false
    },

    setCurrentAppAccess(appAccess){
      this.currentAppAccess = appAccess
    },

    toggleNOSubAdminForEditModal(){

      this.currentAppAccess.is_allowed_subadmin_feature = 'no'
      this.currentAppAccess.sub_admin_limit = null

 

    },


    openBulkUploadEmployeesModal(){


      this.newBulkUpload.company_id = null
      this.newBulkUpload.team_id = null
      this.newBulkUpload.file = null

      this.showBulkUploadEmployeesModal = true
    },

    closeBulkUploadEmployeesModal(){
      this.showBulkUploadEmployeesModal = false
    },


    triggerInput() {

      let input = document.createElement('input');
      input.type = 'file';
      input.accept = '.xls, .xlsx, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv';



      input.onchange = () => {

        let files = Array.from(input.files); // Correctly accessing files

        this.newBulkUpload.file = files[0];

        console.log(files[0]);
      };

      input.click();

    },

    uploadEmployeesInBulk(){

      this.isLoading = true

      if(this.newBulkUpload.file === null){
        alert("Upload the excel file first!")

        this.isLoading = false

        return;
      }

      if(this.newBulkUpload.team_id === null){ 
        alert("Select a team")

        this.isLoading = false

        return;
      }


      if (confirm("Are you sure all emails are valid? Once this process starts it cannot be undone. Please make sure the email list is cleaned before proceding.")
      ) {

        const formData = {
          company_id: this.manager.id,
          team_id: this.newBulkUpload.team_id,
          file: this.newBulkUpload.file
        }

        SuperAdminService.processExcelFile(formData) 
          .then((response) => {

            this.isLoading = false
            this.closeBulkUploadEmployeesModal()

            toast("File was sent for processing!", {
              position: "bottom-right",
              autoClose: 1000,
            });

            console.log(response.data)
            
          }).catch((err) => {

            this.isLoading = false

            console.log(err)
            
          });

        console.log(formData)
    
      }

      

    },

    saveAppAccessUpdate(){


      const formData = {
        manager_id: this.currentAppAccess.manager_id,
        safelyio_app_id: this.currentAppAccess.safelyio_app_id,
        team_size_limit: this.currentAppAccess.team_size_limit,
        start_date: this.currentAppAccess.start_date,
        end_date: this.currentAppAccess.end_date,
        sub_admin_limit: this.currentAppAccess.sub_admin_limit,
        is_allowed_subadmin_feature: this.currentAppAccess.is_allowed_subadmin_feature,
        has_access: this.currentAppAccess.has_access,
      }

      SuperAdminService.updateAppAccess(formData, this.currentAppAccess.uuid)
        .then((response) => {

          this.closeShowEditAppAccessModal()

          toast("saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });


          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });
      
      

    },

 
    deleteThisAppAccess(){  


      if (
        confirm(
          "Are you sure you want to delete this app access? both manager and employees will no longer see the app on the apps page!"
        )
      ) {

        const formData = {
          app_access_id: this.currentAppAccess.id
        }

        SuperAdminService.deleteAppAccess(formData)
            .then((response) => {

              this.fetchAManager(this.manager.uuid)
              this.closeShowEditAppAccessModal()
              this.currentAppAccess = {}

              console.log(response.data)

              
            }).catch((err) => {

              console.log(err)
              
            });

        // console.log(this.currentAppAccess)

      }


      

    }




  },


});
