<template>
    <div class="loginWrapper">

        <div class="topNav">

            <!-- <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" /> -->

        </div>

        <div class="formWrapper">

    

            <div v-if="authStore.errorMessage"  class="dangerAlert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>


            <form @submit.prevent="authStore.resetPassword">

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Email address</label>
                    <input v-model="authStore.resetPasswordData.email" type="email" class="form-control" required>
                </div>


                <div class="mb-3">
                    <label class="form-label" style="float: left;">Password</label>
                    <input v-model="authStore.resetPasswordData.password" type="password" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Confirm Password</label>
                    <input v-model="authStore.resetPasswordData.password_confirmation" type="password" class="form-control"
                        required>
                </div>



                <div>
                    <button type="submit" class="btn btn-primary loginBTN">Update Password</button>
                </div>

            </form>

            <div style="margin-top: 25px;">



            </div>





        </div>

    </div>
</template>

<script setup>

import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const authStore = useAuthStore();
const router = useRoute();


onMounted(() => {

    const token = router.query.token;

    //assign to resetPasswordData
    authStore.resetPasswordData.token = token;


})

</script>

<style scoped>
.imgLogo {
    float: left;
    width: 110px;
}

.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: aliceblue; */
}

.topNav {
    display: flex;
    justify-content: center;
    padding-top: 170px;
    /* background-color: rgb(192, 111, 111); */
    width: 100%;
    position: fixed;
    top: 0;
}

.formWrapper {
    /* Styling for form wrapper */
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}


.form-control {
    background-color: rgb(243, 246, 246);
}

.dangerAlert{
    /* background-color: rgb(164, 22, 22); */
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    font-weight: bold;
    color: rgb(4, 1, 1);
}
</style>