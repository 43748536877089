import TemplateService from "@/services/TemplateService";
import moment from "moment";
import { defineStore } from "pinia";
import { debounce } from 'lodash';

export const useLiveTemplateStore = defineStore("LiveTemplateStore", {

  state: () => ({


    template: {},
    uniqueTemplate: {},
    templatePages: [],

    showPagesNav: false,

    currentPageIndex: 0,
    currentPage: {},

    currentElement: {},
    galleryFiles: [],

    currentFileIndexInGallery: null,
    showImageAndVideoGalleryModal: false,


    showSignatureModal: false,
    currentSignatureType: 'text_sign',
    showSignatureFileModal: false,

    signOptions: [
      { id: 1, name: 'Text Signature', type: 'text_sign' },
      { id: 2, name: 'Digital Signature', type: 'digital_sign' },
    ],


    deviceType: null,

    isLoading: false,

    progressPercentage: 0,
    completed_elements: 0,
    total_elements: 0,

    finalMessages: [],

    newAction: {
      unique_template_id: null,
      title: null,
      desc: null,
      priority: null,
      due_date: null,
      label_id: null,
      site_id: null,
    },

    showNewActionModal: false,

    priorityLevels: [
      {id: 1, name: 'Low Priority', type: 'low_priority' },
      {id: 2, name: 'Medium Priority', type: 'medium_priority' },
      {id: 3, name: 'High Priority', type: 'high_priority' }
    ]


  }),


  getters: {


    getImageAndVideoFilesOnly: () => {
      return (element) => {
        if (!element.form_input_value.media_files || element.form_input_value.media_files.length === 0) {
          return []; // Return an empty array if media_files does not exist or is empty
        }
        return element.form_input_value.media_files.filter(file => file.type === 'image' || file.type === 'video');
      };
    },

    getPdfFiles: () => {
      return (element) => {
        // Check if media_files exist and is not empty before filtering
        if (!element.form_input_value.media_files || element.form_input_value.media_files.length === 0) {
          return []; // Return an empty array if media_files does not exist or is empty
        }
        return element.form_input_value.media_files.filter(file => file.type === 'pdf');
      };
    },


  },

  actions: {



    fetchLiveTemplate(randomString) {


      TemplateService.getLiveTemplate(randomString)
        .then((response) => {


          this.template = response.data.template
          this.uniqueTemplate = response.data.uniqueTemplate
          this.templatePages = response.data.templatePages

          //set current page to 0
          this.currentPage = this.templatePages[0]

          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements
          this.total_elements = response.data.total_elements


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });




    },

    togglePagesNavDropdown() {
      this.showPagesNav = !this.showPagesNav
    },


    goBackToViewAllTemplates() {

      this.router.push({
        name: "ViewAllTemplates"
      });

    },


    incrementProgressPercentage() {
      this.progressPercentage = this.progressPercentage + 10
    },

    decrementProgressPercentage() {
      this.progressPercentage = this.progressPercentage - 10
    },


    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    formatTime(time) {
      var formatedTime = moment(time, "HH:mm").format("h:mm a");

      return formatedTime;
    },

    setCurrentPage(page, index) {
      this.currentPage = page
      this.currentPageIndex = index
    },

    toggleDropdown(element) {

      if (element.show_dropdown === 1) {

        element.show_dropdown = 0

      } else {
        element.show_dropdown = 1
      }



    },

    showSelectedOption(element) {

      let theOption = {
        option_text: null,
        color_code: null
      }


      theOption.option_text = element.form_input_value.dropdown_options[0].the_option.option_text
      theOption.color_code = element.form_input_value.dropdown_options[0].the_option.color_code



      // Check if the string exceeds 30 characters
      if (theOption.option_text.length > 20) {
        theOption.option_text = theOption.option_text.slice(0, 20) + '...';
      }

      // element.form_input_value.dropdown_options.forEach((drop) => {

      //   if (drop.id === element.dropdown_option_id) {


      //     theOption.option_text = drop.option_text
      //     theOption.color_code = drop.color_code



      //     // Check if the string exceeds 30 characters
      //     if (theOption.option_text.length > 20) {
      //       theOption.option_text = theOption.option_text.slice(0, 20) + '...';
      //     }

      //   }
      // });



      return theOption;

    },

    setSelectedDropdownOption(element, dropItem) {


      this.saveDropdownOption(element, dropItem)

    },


    toggleShowSection(element) {

      if (element.show_section === 1) {

        element.show_section = 0

      } else {

        element.show_section = 1

      }

    },

    toggleShowMap(element) {

      if (element.show_map === 1) {

        element.show_map = 0

      } else {

        element.show_map = 1

      }

    },


    toggleCheckboxOnInput(element) {

      element.form_input_value.is_checkbox_selected = !element.form_input_value.is_checkbox_selected

    },


    setCurrentElement(element, elementIndex) {

      this.currentElement = {} //reset

      this.currentElement = element
      this.currentElementIndex = elementIndex

      this.galleryFiles = this.getImageAndVideoFilesOnly(this.currentElement)


    },

    setCurrentFileIndex(theFile) {

      //set the array
      this.galleryFiles = []
      this.galleryFiles = this.getImageAndVideoFilesOnly(this.currentElement)

      //find the index of the matching file id
      const fileIndex = this.galleryFiles.findIndex(
        (file) => file.name === theFile.name
      );

      this.currentFileIndexInGallery = fileIndex


      this.openShowImageAndVideoGalleryModal()

      // console.log(this.currentFileIndexInGallery)
      // console.log(theFile)




    },

    openShowImageAndVideoGalleryModal() {

      this.showImageAndVideoGalleryModal = true
    },

    closeShowImageAndVideoGalleryModal() {

      this.showImageAndVideoGalleryModal = false

      //reset gallery array
      this.galleryFiles = []

    },

    triggerInput() {

      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = 'image/*,video/*,application/pdf';


      input.onchange = () => {
        let files = Array.from(input.files);
        this.uploadFiles(files)
        // console.log(files);
      };

      input.click();

    },


    uploadFiles(files) {


      // Maximum file size allowed (in bytes)
      const maxFileSize = 30 * 1024 * 1024; // 30 MB

      // Check file sizes
      const oversizedFiles = files.filter(file => file.size > maxFileSize);

      // If there are oversized files, show an alert and return
      if (oversizedFiles.length > 0) {
        alert("Maximum file size accepted is 30 MB");
        return;
      }


      //show loading state 
      this.currentElement.is_file_uploading = 'true'

      const formData = new FormData();

      // Add template_id and template_page_layout_id to formData
      formData.append('unique_template_id', this.uniqueTemplate.id);
      formData.append('template_form_input_value_id', this.currentElement.form_input_value.id);

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });


      // console.log(formData)

      TemplateService.uploadFilesToDOForAElement(formData)
        .then((response) => {

          let filesArray = response.data.mediaFiles

          filesArray.forEach((file) => {

            // Create a deep copy of the file object
            let fileCopy = JSON.parse(JSON.stringify(file));


            // Push the copy into the array
            this.currentElement.form_input_value.media_files.push(fileCopy);

          });

          this.currentElement.is_file_uploading = 'false'

          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements

          console.log(response.data);

        }).catch((err) => {
          console.log(err);
        });


    },



    attachmentDownloadPDFfile(pdfFile) {

      let downloadURL = pdfFile.file_url;

      fetch(downloadURL)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error("There has been a problem with your fetch operation:", error);
        });



    },



    downloadPDFfile(pdfFile) {

      let downloadURL = pdfFile.file_url;

      fetch(downloadURL)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error("There has been a problem with your fetch operation:", error);
        });



    },

    deletePdfFile(pdfFile) {


      //find pdf file by name in media_files and delete
      const pdfToDelete = this.currentElement.form_input_value.media_files.findIndex(
        (pdf) => pdf.name === pdfFile.name
      );

      if (pdfToDelete !== -1) {
        this.currentElement.form_input_value.media_files.splice(pdfToDelete, 1);
      }


      //make api call to delete file
      const formData = {
        temnplate_media_file_id: pdfFile.id,
        unique_template_id: this.uniqueTemplate.id,
      }

      TemplateService.deleteAMediaFileFromGallery(formData)
        .then((response) => {

          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });





    },

    downloadFileFromGallery() {

      let theFile = this.galleryFiles[this.currentFileIndexInGallery]


      if (theFile.type === 'image') {

        let downloadURL = theFile.file_url;

        fetch(downloadURL)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = theFile.name; // Assuming theFile has a name property
            link.click();
          })
          .catch(console.error);



      }

      if (theFile.type === 'video') {


        let downloadURL = theFile.file_url;

        fetch(downloadURL)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = theFile.name; // Assuming theFile has a name property
            link.click();
          })
          .catch(console.error);


      }


    },

    deleteFileFromGallery() {

      if (this.currentFileIndexInGallery !== -1) {

        let theFile = this.galleryFiles[this.currentFileIndexInGallery]

        //delete file from currentElement media_file by name
        const theFileInMediaFilesToDeleteIndex = this.currentElement.form_input_value.media_files.findIndex(
          (file) => file.name === theFile.name
        );

        if (theFileInMediaFilesToDeleteIndex !== -1) {
          this.currentElement.form_input_value.media_files.splice(theFileInMediaFilesToDeleteIndex, 1);
        }


        //make api call to delete file
        const formData = {
          temnplate_media_file_id: theFile.id,
          unique_template_id: this.uniqueTemplate.id,
        }

        TemplateService.deleteAMediaFileFromGallery(formData)
          .then((response) => {


            this.progressPercentage = response.data.progressPercentage
            this.completed_elements = response.data.completed_elements

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });



        //delete from gallery array too
        this.galleryFiles.splice(this.currentFileIndexInGallery, 1);

        //set to last file in array
        let lastIndex = this.galleryFiles.length - 1;
        this.currentFileIndexInGallery = lastIndex

        //rerun the function that filters the img and video
        this.getImageAndVideoFilesOnly(this.currentElement)


        // if no more files left in array close the modal
        if (this.galleryFiles.length === 0) {

          this.closeShowImageAndVideoGalleryModal()

        }

      }
    },



    nextFile() {
      if (this.currentFileIndexInGallery < this.galleryFiles.length - 1) {
        this.currentFileIndexInGallery++;
      }

    },

    prevFile() {
      if (this.currentFileIndexInGallery > 0) {
        this.currentFileIndexInGallery--;
      }
    },

    limitFileNameText(body) {
      if (body.length > 10) {
        return body.substring(0, 10) + "...";
      }

      return body;
    },



    saveDropdownOption(element, dropItem) {

      const formData = {
        template_form_input_value_id: element.form_input_value.id,
        dropdown_option_id: dropItem.id,
        unique_template_id: this.uniqueTemplate.id
      }

      //run automation check
      this.runAutomationTriggerCheckForDropdownElement(element, dropItem)

      TemplateService.saveFormInputDropdownOption(formData)
        .then((response) => {

          let formInputDropdownoOption = response.data.formInputDropdownoOption

          element.form_input_value.dropdown_options[0] = formInputDropdownoOption

          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    selectAMultipleChoice(element, choice) {

      choice.is_selected = choice.is_selected === 0 ? 1 : 0; // Toggle the selection


      this.runAutomationTriggerCheckForMultipleChoiceElement(element, choice)


      //save to db
      this.saveMultipleChoiceInDB(element, choice)





    },


    selectASingleChoice(element, choice) {

      // Only one answer can be selected
      let count = 0;

      element.multiple_choice_options.forEach((cho) => {
        if (cho.form_input_record !== null) {
          count++;
        }
      });


      if (count > 0 && choice.form_input_record === null) {

        // User wants to unselect the currently selected choice to select a different one
        element.multiple_choice_options.forEach((cho) => {
          cho.form_input_record = null; // Unselect all choices
        });


        this.saveSingleChoiceInDB(element, choice)



      } else {



        this.saveSingleChoiceInDB(element, choice)



      }





    },


    saveMultipleChoiceInDB(element, choice) {



      const formData = {
        the_element: element,
        the_choice: choice,
        unique_template_id: this.uniqueTemplate.id

      }

      TemplateService.saveFormInputMultipleChoice(formData)
        .then((response) => {

          let message = response.data.message

          if (message === 'created') {

            let newRecord = response.data.newRecord
            choice.form_input_record = newRecord


            //run automation check
            this.runAutomationTriggerCheckForMultipleChoiceElement(element, choice)


            // console.log('add the form_input_record object to the the_choice')


          }

          if (message === 'deleted') {


            //run automation check
            this.runAutomationTriggerCheckForMultipleChoiceElement(element, choice)


            choice.form_input_record = null

            console.log('set the form_input_record object for the the_choice to null')

          }


          //update progress bar
          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements






          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },

    saveSingleChoiceInDB(element, choice) {

      //run automation check
      this.runAutomationTriggerCheckForMultipleChoiceElement(element, choice)

      const formData = {
        the_element: element,
        the_choice: choice,
        unique_template_id: this.uniqueTemplate.id

      }

      TemplateService.saveFormInputSingleChoice(formData)
        .then((response) => {

          let message = response.data.message

          if (message === 'updated') {

            let newRecord = response.data.newRecord
            choice.form_input_record = newRecord



          }

          if (message === 'created') {

            let newRecord = response.data.newRecord
            choice.form_input_record = newRecord

          }

          //update progress bar
          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },


    openShowSignatureModal() {
      this.showSignatureModal = true;
    },

    closeShowSignatureModal() {
      this.showSignatureModal = false;
    },


    setCurentSignOption(signOption) {
      this.currentSignatureType = signOption.type
    },

    getSignatureCurrentTime() {

      const formattedTime = moment().format('h:mm A');
      return formattedTime;

    },

    getSignatureCurrentDate() {

      const formattedDate = moment().format('Do MMM YYYY');
      return formattedDate;

    },


    getSignatureCurrentTimeFormatted() {
      const formattedTime = moment().format('HH:mm');
      return formattedTime;
    },

    getSignatureCurrentDateFormatted() {
      const formattedDate = moment().format('YYYY-MM-DD');
      return formattedDate;
    },



    uploadThisDigitalSignature(base64Image) {

      this.isLoading = true

      const formData = {
        manager_id: this.template.manager_id,
        template_form_input_value_id: this.currentElement.form_input_value.id,
        unique_template_id: this.uniqueTemplate.id,
        file: base64Image,
        the_date: this.getSignatureCurrentDateFormatted(),
        the_time: this.getSignatureCurrentTimeFormatted(),

      }


      TemplateService.uploadSignatureImageToDO(formData)
        .then((response) => {

          let img_url = response.data.img_url

          this.currentElement.form_input_value.the_file_url = img_url
          this.currentElement.form_input_value.the_date = formData.the_date
          this.currentElement.form_input_value.the_time = formData.the_time

          this.closeShowSignatureModal()


          this.isLoading = false

          //update progress bar
          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

          this.isLoading = false

        });

    },

    openSignatureFileModal() {
      this.showSignatureFileModal = true
    },

    closeSignatureFileModal() {
      this.showSignatureFileModal = false
    },


    deleteThisSignatureFile() {


      if (confirm("Are you sure you want to delete this file?")
      ) {


        this.showSignatureFileModal = false

        const formData = {
          manager_id: this.template.manager_id,
          unique_template_id: this.uniqueTemplate.id,
          template_form_input_value_id: this.currentElement.form_input_value.id,
        }

        TemplateService.deleteASignatureFile(formData)
          .then((response) => {

            this.currentElement.form_input_value.the_file_url = null
            this.currentElement.form_input_value.the_date = null
            this.currentElement.form_input_value.the_time = null

            this.closeSignatureFileModal()


            //update progress bar
            this.progressPercentage = response.data.progressPercentage
            this.completed_elements = response.data.completed_elements


            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });


      }



    },


    async getGeoCoordinates() {


      if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition((position) => {

          this.currentElement.form_input_value.latitude = position.coords.latitude,
            this.currentElement.form_input_value.longitude = position.coords.longitude

          console.log(this.currentElement.form_input_value)


        },
          (err) => {
            alert(err.message)
          })

      } else {

        console.log('Geolocation is not supported by your browser')

      }



    },


    toggleOffEditMote(element) {

      element.show_note = 'no'
    },

    toggleOnEditMote(element) {

      element.show_note = 'yes'


    },


    deleteThisNote() {



      if (confirm("Are you sure you want to delete this note?")
      ) {

        const formData = {
          form_input_note_id: this.currentElement.form_input_value.note_details.id
        }

        TemplateService.deleteNote(formData)
          .then((response) => {

            this.currentElement.form_input_value.note_details.the_note = ''
            this.currentElement.show_note = 'no'

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });





      }

    },


    saveThisNote() {

      const formdata = {
        the_element: this.currentElement
      }


      TemplateService.saveNote(formdata)
        .then((response) => {

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    recalculateProgressPercentage() {


      // // eslint-disable-next-line 
      // let total_elements_count = 0;


      let amt_elements_completed = 0;

      this.templatePages.forEach((page) => {

        page.elements.forEach((ele) => {

          if (ele.element_type !== 'section_header') {

            if (ele.response_type === 'text_answer') {

              if (ele.form_input_value.string_value !== null) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'number') {

              if (ele.form_input_value.number_value !== null) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'checkbox') {

              if (ele.form_input_value.is_checkbox_selected) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'datetime') {

              if (ele.form_input_value.the_date !== null && ele.form_input_value.the_time !== null) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'time') {

              if (ele.form_input_value.the_time !== null) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'date') {

              if (ele.form_input_value.the_date !== null) {

                amt_elements_completed++;

              }


            }


            if (ele.response_type === 'media') {

              if (ele.form_input_value.media_files.length > 0) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'dropdown') {

              if (ele.form_input_value.dropdown_options.length > 0) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'multiple_choice') {


              ele.multiple_choice_options.forEach((choice) => {

                if (choice.form_input_record !== null) {

                  amt_elements_completed++;

                }

              })


            }

            if (ele.response_type === 'signature') {

              if (ele.form_input_value.the_file_url !== null) {

                amt_elements_completed++;

              }


            }

            if (ele.response_type === 'geostamp') {

              if (ele.form_input_value.formatted_address !== null) {

                amt_elements_completed++;

              }


            }

          }


          if (ele.element_type === 'section_header') {

            ele.section_layouts.forEach((ele) => {

              if (ele.response_type === 'text_answer') {

                if (ele.form_input_value.string_value !== null) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'number') {

                if (ele.form_input_value.number_value !== null) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'checkbox') {

                if (ele.form_input_value.is_checkbox_selected) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'datetime') {

                if (ele.form_input_value.the_date !== null && ele.form_input_value.the_time !== null) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'time') {

                if (ele.form_input_value.the_time !== null) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'date') {

                if (ele.form_input_value.the_date !== null) {

                  amt_elements_completed++;

                }


              }


              if (ele.response_type === 'media') {

                if (ele.form_input_value.media_files.length > 0) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'dropdown') {

                if (ele.form_input_value.dropdown_options.length > 0) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'multiple_choice') {


                ele.multiple_choice_options.forEach((choice) => {

                  if (choice.form_input_record !== null) {

                    amt_elements_completed++;

                  }

                })


              }

              if (ele.response_type === 'signature') {

                if (ele.form_input_value.the_file_url !== null) {

                  amt_elements_completed++;

                }


              }

              if (ele.response_type === 'geostamp') {

                if (ele.form_input_value.formatted_address !== null) {

                  amt_elements_completed++;

                }


              }



            })

          }



        })

      })


      console.log('amt_elements_completed', amt_elements_completed)
      console.log('total_elements', this.total_elements)

      console.log('currentElement.form_input_value', this.currentElement.form_input_value)

      // let newProgressPercentage = (amt_elements_completed / this.total_elements ) * 100


    },


    runAutomationTriggerCheck(element) {



      //check if this current element has automations, if yes show the triggers for each
      if (element.automations.length > 0) {


        let messages = [];

        element.automations.forEach((auto) => {


          if (element.response_type === 'text_answer') {


            if (auto.logic_condition === 'is_equal_to') {

              if (auto.string_value.toLowerCase() === element.form_input_value.string_value.toLowerCase()) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('string is is_equal_to therefore require_action', auto.string_value)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('string is is_equal_to therefore require_media', auto.string_value)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('string is is_equal_to therefore notify: Send email notification on backend', auto.string_value)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('string is is_equal_to therefore ask_follow_up_question', auto.string_value)

                }



              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null

            }

            if (auto.logic_condition === 'is_not_equal_to') {


              if (auto.string_value.toLowerCase() !== element.form_input_value.string_value.toLowerCase()) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('require_action: string is is_not_equal_to', auto.string_value)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('require_media: string is is_not_equal_to ', auto.string_value)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('notify: string is is_not_equal_to ', auto.string_value)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('ask_follow_up_question: string is is_not_equal_to ', auto.string_value)

                }



              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null


            }



          }


          if (element.response_type === 'number') {


            if (auto.logic_condition === 'is_equal_to') {

              if (auto.number_value === element.form_input_value.number_value) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('number is is_equal_to therefore require_action', auto.number_value)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('number is is_equal_to therefore require_media', auto.number_value)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('number is is_equal_to therefore notify: Send email notification on backend', auto.number_value)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('number is is_equal_to therefore ask_follow_up_question', auto.number_value)

                }



              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null


            }

            if (auto.logic_condition === 'is_not_equal_to') {

              if (auto.number_value !== element.form_input_value.number_value) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('number is is_not_equal_to therefore require_action', auto.number_value)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('number is is_not_equal_to therefore require_media', auto.number_value)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('number is is_not_equal_to therefore notify: Send email notification on backend', auto.number_value)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('number is is_not_equal_to therefore ask_follow_up_question', auto.number_value)

                }



              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null


            }

            if (auto.logic_condition === 'is_less_than') {

              if (element.form_input_value.number_value < auto.number_value) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('number is is_not_equal_to therefore require_action', auto.number_value)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('number is is_not_equal_to therefore require_media', auto.number_value)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('number is is_not_equal_to therefore notify: Send email notification on backend', auto.number_value)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('number is is_not_equal_to therefore ask_follow_up_question', auto.number_value)

                }



              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null


            }

            if (auto.logic_condition === 'is_greater_than') {

              if (element.form_input_value.number_value > auto.number_value) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('number is is_not_equal_to therefore require_action', auto.number_value)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('number is is_not_equal_to therefore require_media', auto.number_value)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('number is is_not_equal_to therefore notify: Send email notification on backend', auto.number_value)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('number is is_not_equal_to therefore ask_follow_up_question', auto.number_value)

                }



              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null


            }






          }


          if (element.response_type === 'checkbox') {

            if (auto.string_value === 'checked') {

              if (auto.trigger_type === 'require_action') {

                const theToDo = "You're required to create an action"

                messages.push(theToDo)

                console.log('checked therefore require_action', auto.string_value)

              }

              if (auto.trigger_type === 'require_media') {

                const theToDo = "You're required to add media and a note"

                messages.push(theToDo)

                console.log('checked therefore require_media', auto.string_value)

              }

              if (auto.trigger_type === 'notify') {

                console.log('checked therefore notify: Send email notification on backend', auto.string_value)

              }

              if (auto.trigger_type === 'ask_follow_up_question') {

                console.log('checked therefore ask_follow_up_question', auto.string_value)

              }


              element.form_input_value.the_error_message = messages.length > 0 ? messages : null


            } else {

              if (auto.trigger_type === 'require_action') {

                const theToDo = "You're required to create an action"

                messages.push(theToDo)

                console.log('checked therefore require_action', auto.string_value)

              }

              if (auto.trigger_type === 'require_media') {

                const theToDo = "You're required to add media and a note"

                messages.push(theToDo)

                console.log('checked therefore require_media', auto.string_value)

              }

              if (auto.trigger_type === 'notify') {

                console.log('checked therefore notify: Send email notification on backend', auto.string_value)

              }

              if (auto.trigger_type === 'ask_follow_up_question') {

                console.log('checked therefore ask_follow_up_question', auto.string_value)

              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null

            }



          }








        });

      }

    },

    runAutomationTriggerCheckForMultipleChoiceElement(element, choice) {

      // console.log(element)

      if (element.automations.length > 0) {


        let messages = [];
        element.form_input_value.the_error_message = null //reset message

        element.automations.forEach((auto) => {

          if (element.is_multiple_answer_selection === 'no') {


            if (auto.logic_condition === 'is_selected') {

              if (auto.selected_choice_id === choice.id) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log('is_selected therefore require_action', choice.option_text)

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log('is_selected therefore require_media', choice.option_text)

                }

                if (auto.trigger_type === 'notify') {

                  console.log('is_selected therefore notify: Send email notification on backend', choice.option_text)

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log('is_selected therefore ask_follow_up_question', choice.option_text)

                }

                element.form_input_value.the_error_message = messages.length > 0 ? messages : null




              }


            }

            if (auto.logic_condition === 'is_not_selected') {

              if (auto.selected_choice_id !== choice.id) {


                if (auto.trigger_type === 'require_action') {

                  const theToDo = "You're required to create an action"

                  messages.push(theToDo)

                  console.log(auto.selected_choice_id, 'is_not_selected therefore require_action')

                }

                if (auto.trigger_type === 'require_media') {

                  const theToDo = "You're required to add media and a note"

                  messages.push(theToDo)

                  console.log(auto.selected_choice_id, 'is_not_selected therefore require_media')

                }

                if (auto.trigger_type === 'notify') {

                  console.log(auto.selected_choice_id, 'is_not_selected therefore notify: Send email notification on backend')

                }

                if (auto.trigger_type === 'ask_follow_up_question') {

                  console.log(auto.selected_choice_id, 'is_not_selected therefore ask_follow_up_question')

                }

                element.form_input_value.the_error_message = messages.length > 0 ? messages : null




              }


            }



          } else {


            //loop over multiple_choice_options and the choices where form_input_record is not null check if the auto.selected_choice_id is one of 
            //or not one of 



            if (auto.logic_condition === 'is_one_of_selected') {


              element.multiple_choice_options.forEach((cho) => {

                if (cho.form_input_record !== null) {


                  if (cho.id === auto.selected_choice_id) {

                    if (auto.trigger_type === 'require_action') {

                      const theToDo = "You're required to create an action"

                      messages.push(theToDo)

                      console.log('is_one_of_selected therefore require_action', choice.option_text)

                    }

                    if (auto.trigger_type === 'require_media') {

                      const theToDo = "You're required to add media and a note"

                      messages.push(theToDo)

                      console.log('is_one_of_selected therefore require_media', choice.option_text)

                    }

                    if (auto.trigger_type === 'notify') {

                      console.log('is_one_of_selected therefore notify: Send email notification on backend', choice.option_text)

                    }

                    if (auto.trigger_type === 'ask_follow_up_question') {

                      console.log('is_one_of_selected therefore ask_follow_up_question', choice.option_text)

                    }

                    element.form_input_value.the_error_message = messages.length > 0 ? messages : null

                  }

                }

              })


            }


            if (auto.logic_condition === 'is_not_one_of_selected') {


              element.multiple_choice_options.forEach((cho) => {

                if (cho.form_input_record !== null) {



                  if (cho.id === auto.selected_choice_id) {

                    console.log('target selected no message needed!')

                  }


                  if (cho.id !== auto.selected_choice_id) {

                    if (auto.trigger_type === 'require_action') {

                      const theToDo = "You're required to create an action"

                      messages.push(theToDo)

                      console.log('is_one_of_selected therefore require_action', choice.option_text)

                    }

                    if (auto.trigger_type === 'require_media') {

                      const theToDo = "You're required to add media and a note"

                      messages.push(theToDo)

                      console.log('is_one_of_selected therefore require_media', choice.option_text)

                    }

                    if (auto.trigger_type === 'notify') {

                      console.log('is_one_of_selected therefore notify: Send email notification on backend', choice.option_text)

                    }

                    if (auto.trigger_type === 'ask_follow_up_question') {

                      console.log('is_one_of_selected therefore ask_follow_up_question', choice.option_text)

                    }

                    // Remove duplicates from messages
                    messages = [...new Set(messages)];

                    element.form_input_value.the_error_message = messages.length > 0 ? messages : null

                  }

                }

              })


            }






          }




        })



      }



    },


    runAutomationTriggerCheckForDropdownElement(element, choice) {


      if (element.automations.length > 0) {


        let messages = [];
        element.form_input_value.the_error_message = null //reset message

        element.automations.forEach((auto) => {




          if (auto.logic_condition === 'is_selected') {

            if (auto.template_dropdown_option_id === choice.id) {


              if (auto.trigger_type === 'require_action') {

                const theToDo = "You're required to create an action"

                messages.push(theToDo)

                console.log('is_selected therefore require_action', choice.option_text)

              }

              if (auto.trigger_type === 'require_media') {

                const theToDo = "You're required to add media and a note"

                messages.push(theToDo)

                console.log('is_selected therefore require_media', choice.option_text)

              }

              if (auto.trigger_type === 'notify') {

                console.log('is_selected therefore notify: Send email notification on backend', choice.option_text)

              }

              if (auto.trigger_type === 'ask_follow_up_question') {

                console.log('is_selected therefore ask_follow_up_question', choice.option_text)

              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null




            }


          }

          if (auto.logic_condition === 'is_not_selected') {

            if (auto.template_dropdown_option_id !== choice.id) {


              if (auto.trigger_type === 'require_action') {

                const theToDo = "You're required to create an action"

                messages.push(theToDo)

                console.log(auto.selected_choice_id, 'is_not_selected therefore require_action')

              }

              if (auto.trigger_type === 'require_media') {

                const theToDo = "You're required to add media and a note"

                messages.push(theToDo)

                console.log(auto.selected_choice_id, 'is_not_selected therefore require_media')

              }

              if (auto.trigger_type === 'notify') {

                console.log(auto.selected_choice_id, 'is_not_selected therefore notify: Send email notification on backend')

              }

              if (auto.trigger_type === 'ask_follow_up_question') {

                console.log(auto.selected_choice_id, 'is_not_selected therefore ask_follow_up_question')

              }

              element.form_input_value.the_error_message = messages.length > 0 ? messages : null




            }


          }







        })



      }



    },


    nextPage() {
      this.currentPageIndex++
    },

    prevPage() {
      this.currentPageIndex--
    },


    checkIfAutomationsAreStatisfied() {

      // loop over each page and check each element, if the element has automations check each and see the the conditions for each automation was satisifed

      this.templatePages.forEach((page) => {

        page.elements.forEach((element) => {

          if (element.automations.length > 0) {

            element.automations.forEach((auto) => {

              if (element.response_type === 'text_answer') {


                if (auto.logic_condition === 'is_equal_to') {

                  if (auto.string_value.toLowerCase() === element.form_input_value.string_value.toLowerCase()) {


                    if (auto.trigger_type === 'require_action') {


                      //check if action relation
                      //I STOPPED HERE - LOOOOOOOOOOOOK I CONTINUR FROM HEREEEEEEE!!!!!!!!!!

                      // const theToDo = "You're required to create an action"

                      // let theWarning = {
                      //   thePage: page,
                      //   message: theToDo
                      // }

                    
                      // messages.push(theWarning)

                      console.log('string is is_equal_to therefore require_action', auto.string_value)

                    }

                    if (auto.trigger_type === 'require_media') {

                      // const theToDo = "You're required to add media and a note"


                      console.log('string is is_equal_to therefore require_media', auto.string_value)

                    }

                    if (auto.trigger_type === 'notify') {

                      console.log('string is is_equal_to therefore notify: Send email notification on backend', auto.string_value)

                    }

                    if (auto.trigger_type === 'ask_follow_up_question') {

                      console.log('string is is_equal_to therefore ask_follow_up_question', auto.string_value)

                    }



                  }

                  
                  

                }

                // if (auto.logic_condition === 'is_not_equal_to') {


                //   if (auto.string_value.toLowerCase() !== element.form_input_value.string_value.toLowerCase()) {


                //     if (auto.trigger_type === 'require_action') {

                //       const theToDo = "You're required to create an action"


                //       console.log('require_action: string is is_not_equal_to', auto.string_value)

                //     }

                //     if (auto.trigger_type === 'require_media') {

                //       const theToDo = "You're required to add media and a note"


                //       console.log('require_media: string is is_not_equal_to ', auto.string_value)

                //     }

                //     if (auto.trigger_type === 'notify') {

                //       console.log('notify: string is is_not_equal_to ', auto.string_value)

                //     }

                //     if (auto.trigger_type === 'ask_follow_up_question') {

                //       console.log('ask_follow_up_question: string is is_not_equal_to ', auto.string_value)

                //     }



                //   }


                // }



              }




            })








          }


        });

      });

    },


    completeTheInspection() {

      //run final automations checks in checkIfAutomationsAreStatisfied()
      this.checkIfAutomationsAreStatisfied()


      //make sure all required field is filled check is_required


      //done: folloe SC logic keep it simple

      console.log('completed!')

    },


    openNewActionModal(){
      this.showNewActionModal = true
    },

    closeNewActionModal(){
      this.showNewActionModal = false
    },


    createNewActionFromLiveForm(){

    },


    saveInputFieldValue: debounce(function (element) {


      const formData = {
        unique_template_id: this.uniqueTemplate.id,
        the_element: element
      }


      //run automation check
      this.runAutomationTriggerCheck(element)

      //then check if the element's automation trigger condition is satistifed

      TemplateService.saveFormInputValue(formData)
        .then((response) => {


          //update progress bar
          this.progressPercentage = response.data.progressPercentage
          this.completed_elements = response.data.completed_elements

          console.log('element value updated')


        }).catch((err) => {

          console.log(err)

        });



    }, 500),




  },


});
