/* eslint-disable */

import CampaignService from "@/services/CampaignService";
import GroupTalkService from "@/services/GroupTalkService";
import LibraryTalkService from "@/services/LibraryTalkService";
import SuperAdminService from "@/services/SuperAdminService";
import moment from "moment";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";

export const useGroupTalkStore = defineStore("GroupTalkStore", {

  state: () => ({ 


    messageGoals: [
      {
        id: 1,
        name: "Create Talk With Text Editor",
        type: "text_editor",
        icon: "bi bi-pencil theOptionIcon",
        clicked: false,
      },

      {
        id: 2,
        name: "Upload PDF",
        type: "pdf",
        icon: "bi bi-file-earmark-pdf theOptionIcon",
        clicked: false,
      },

      {
        id: 3,
        name: "Video",
        type: "video",
        icon: "bi bi-play theOptionIcon",
        clicked: false,
      },
    ],

    wizardSteps: [
      {
        id: 1,
        step: "message_goal_step", 
      },

      {
        id: 2,
        step: "text_editor_step",
      },
      {
        id: 3,
        step: "pdf_step",
      },

      {
        id: 4,
        step: "video_step",
      },

      {
        id: 5,
        step: "message_details_step",
      },


      {
        id: 6,
        step: "save_or_publish_step",
      },
    ],

    currentStep: "message_goal_step",
    progressPercentage: 10,

    post: {
      postData: {
        title: "",
        content: "",
        has_quiz: "",
        message_goal_id: 1, //set text as default
        pdf_url_path: "",
        video_embed_code: "",
        send_date: "",
        send_time: "",
        message: "",
        supervisors: [],
        // supervisor_user_id: "",
        // team_id: ""

      },
    },

    statusArray: [
      // {
      //   id: 1,
      //   label: "SAVE AS DRAFT",
      //   value: 0,
      // },
      {
        id: 2,
        label: "PUBLISH",
        value: 1,
      },
    ],

    groupTalks: [],
    timezones: [],

    formData: {
      name: '',
      timezone_id: '',
    },

    updateFormData: {
      name: '',
    },

    currentGroupTalk: {},

    teams: [],
    


    showCreateNewGroupTalkModal: false,
    showUpdateGroupTalkModal: false,
    showDeleteGroupTalkModal: false,


    groupTalk: {},
    groupTalkPosts: [],
    groupTalkPost: {},
    groupTalkAcknowledgements: [],

    currentGroupTalkPost: {},

    currentGroupTalkUUID: "", //for group talk detail

    showTextTalksLibraryModal: false,

    isLoading: false,

    allTextTalks: [],

    currentSafetyTalkTemplate: {},

    showPreviewTalkFromLibraryModal: false,

    source1: "",

    renderVideoEmbedCode: "",
    pastedEmbedVideoCode: "",

    showVideoLibraryModal: false,
    showEmbedVideoModal: false,

    allVideoSafetyTalks: [],

    showDeletePDFmodal: false,
    showPDFlibraryModal: false,

    allPDFSafetyTalks: [],


    pdf: "",
    pdf_name: "",

    updated_pdf_title: "",
    updated_pdf_name: "",
    updated_pdf: "",
    old_pdf_name: "",

    postDetails: {},

    motherArray: [],

    isPublishedSelected: false,

    thisVideoIsYoutube: false,

    doesPDFNameExistInLibrary: "",

    employees: [],
    employeesCheck: [],

    showMarkTalkAsCompleteModal: false,
    showUpdateEmployeeAckStatusModal: false,


    employeeData: {
      employeesCheck: [],
      time_acknowledged: ''
    },

    updateEmployeesCheck: [],


    showDeleteGroupTalkPostContentModal: false,


    showEditMessageModal: false,

    currentGroupTalkCampaign: {},


    showSelectSupervisorsModal: false,

    supervisorsCheckArray: [],

    supervisorsWithGroupTalkPosts: [],


    ack_records: [],
    talksCount: null,
    acknowledgementsCompletedCount: null,
    acknowledgementsNotCompletedCount: null,

    allSupervisors: [],  

    showSearchOptionsModal: false, 

    searchFormData: {
      supervisor_id: null,
      start_date: null,
      end_date: null,
      completion_type: 'all'
    },

    isLoading: false,

    showExportButton: false,

    showAIpromptModal: false,
    showAIresultsModal: false,

    languages: [
      { id: 1, type: "English" },
      { id: 2, type: "Spanish" },
    ],

    promptContent: {
      language: "English", //default
      prompt: "",
    },

    theAIGenerateTalk: {},

  }),




  actions: {


    fetchAllGroupTalks() {

      GroupTalkService.getAllGroupTalks()
        .then((response) => {

          this.groupTalks = response.data.groupTalks
          this.timezones = response.data.timezones

          // this.teams = response.data.teams

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

    saveNewGroupTalk() {



      GroupTalkService.saveThisNewGroupTalk(this.formData)
        .then((response) => {


          this.formData.name = ''

          this.closeCreateGroupTalkModal()  

          this.fetchAllGroupTalks()

          // // eslint-disable-next-line no-console
          // console.log(response.data) 

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });



    },



    fetchGroupTalk(uuid) {


      GroupTalkService.getAGroupTalkDetails(uuid)
        .then((response) => {


          this.groupTalk = response.data.groupTalkCampaign
          this.groupTalkPosts = response.data.groupTalkPosts

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });


    },


    fetchGroupTalkForWizard(uuid) {

      GroupTalkService.getAGroupTalkDetails(uuid)
        .then((response) => {


          this.groupTalk = response.data.groupTalkCampaign 
          this.teams = response.data.teams

          //reset supervisors n repopulate
          let supervisors = response.data.supervisors
          this.post.postData.supervisors = []

          //map to employeesCheck array to uncheck those not present
          supervisors.forEach(obj => {

            this.post.postData.supervisors.push({
              user: obj.user,
              team: obj.team,
              checked: 1
            });

          })

 
          this.resetAIFormPromptBox()
          this.resetWizard()

          // // eslint-disable-next-line no-console
          // console.log('let supervisors', response.data.supervisors)
          // console.log('this.post.postData.supervisors', response.data.supervisors)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });


    },

    
    fetchAllSupervisorsForThisGroupTalkPost(uuid){

      GroupTalkService.getAllSupervisorsForAGroupTalkPost(uuid)
        .then((response) => {


          this.groupTalkPost = response.data.groupTalkPost
          this.supervisorsWithGroupTalkPosts = response.data.supervisors
          

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },


    openCreateGroupTalkModal() {
      this.showCreateNewGroupTalkModal = true
    },

    closeCreateGroupTalkModal() {
      this.showCreateNewGroupTalkModal = false
    },


    openUpdateGroupTalkModal() {
      this.showUpdateGroupTalkModal = true
    },

    closeUpdateGroupTalkModal() {
      this.showUpdateGroupTalkModal = false
    },


    openDeleteGroupTalkModal() {
      this.showDeleteGroupTalkModal = true
    },

    closeDeleteGroupTalkModal() {
      this.showDeleteGroupTalkModal = false
    },


    formatDate(date) {

      return moment(date).format("MMMM Do YYYY");

    },

    formatTime(time) {
      var formatedTime = moment(time, "HH:mm").format("h:mma");

      return formatedTime;
    },


    setCurrentGroupTalk(groupTalk) {
      this.currentGroupTalk = groupTalk
      this.formData.name = groupTalk.name

      // console.log(this.currentGroupTalk)
    },


    updateGroupTalk() {

      GroupTalkService.updateThisNewGroupTalk(this.formData, this.currentGroupTalk.uuid)
        .then((response) => {


          this.fetchAllGroupTalks()
          this.closeUpdateGroupTalkModal()

          this.formData.name = ''
          this.formData.team_id = ''



          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

    

    setSelectedMessageGoal(messageGoal) {
      this.post.postData.message_goal_id = messageGoal.id;
    },


    navigateToTalkCreationBasedOnMessageGoal() {


      if (this.post.postData.message_goal_id === 1) {
        this.goToTextEditorStep();
      }

      if (this.post.postData.message_goal_id === 2) {
        this.goToPDFStep();
      }

      if (this.post.postData.message_goal_id === 3) {
        this.goToVideoStep();
      }


    },

    goToTextEditorStep() {
      this.currentStep = "text_editor_step";
      this.incrementProgressPercentage();
    },

    goToPDFStep() {
      this.currentStep = "pdf_step";
      this.incrementProgressPercentage();
    },

    goToVideoStep() {
      this.currentStep = "video_step";
      this.incrementProgressPercentage();
    },

    goToScheduleMessageStep() {

      if (this.post.postData.message_goal_id === 1) {

        if (this.textEditorValidationCheck()) {

          this.currentStep = "message_details_step";
          this.incrementProgressPercentage();

        }

      }

      if (this.post.postData.message_goal_id === 2) {

        if (this.pdfCheck()) {

          this.currentStep = "message_details_step";
          this.incrementProgressPercentage();

        }

      }


      if (this.post.postData.message_goal_id === 3) {

        if (this.videoCheck()) {

          this.currentStep = "message_details_step";
          this.incrementProgressPercentage();

        }

      }

      // this.currentStep = "message_details_step";
      // this.incrementProgressPercentage();
    },

    incrementProgressPercentage() {
      this.progressPercentage = this.progressPercentage + 10;
    },

    decrementProgressPercentage(number) {
      this.progressPercentage = this.progressPercentage - number;
    },

    goToSaveOrPublishStep() {

      if (this.messageValidationCheck()) {

        this.currentStep = "save_or_publish_step";
        this.incrementProgressPercentage();

      }


      // if (this.post.postData.message_goal_id === 1) {

      //   if (this.textEditorValidationCheck()) {



      //     this.currentStep = "save_or_publish_step";
      //     this.incrementProgressPercentage();

      //   }

      // }

      // if (this.post.postData.message_goal_id === 2) {

      //   if (this.pdfCheck()) {

      //     this.currentStep = "save_or_publish_step";
      //     this.incrementProgressPercentage();

      //   }

      // }


      // if (this.post.postData.message_goal_id === 3) {

      //   if (this.videoCheck()) {

      //     this.currentStep = "save_or_publish_step";
      //     this.incrementProgressPercentage();

      //   }

      // }


    },

    messageValidationCheck() {


      if (this.post.postData.message === "") {
        alert("Message conetnt is required");

        return;
      }

      if (this.post.postData.send_date === "") {
        alert("A send date is required");

        return;
      }

      if (this.post.postData.send_time === "") {
        alert("Send time is required");

        return;
      }

      if (this.post.postData.supervisors.length > 0) {

        const anyChecked = this.post.postData.supervisors.some(sup => sup.checked === 1);

        if (!anyChecked) {
          alert("At least one supervisor must be selected");
          return;
        }
      }

    
  

      return true;
    },

    goingBackFromSaveAsDraftOrPublish() {
      this.currentStep = "message_details_step";
      this.decrementProgressPercentage();
    },

   
    
  

    wizardFinished() {


      if (this.isPublishedSelectedCheck()) {


        this.removeSupervisorsWithCheckZero()

        this.onComplete();

        this.progressPercentage = 100;



      }




    },

    isPublishedSelectedCheck() {
      if (!this.isPublishedSelected) {
        alert(`Please select "Publish"`);

        return;
      }

      return true;
    },

    goBackToMessageGoal() {
      this.currentStep = "message_goal_step";
      this.decrementProgressPercentage(10);
    },

    goBackToTalkCreationBasedOnMessageGoal() {
      if (this.post.postData.message_goal_id === 1) {
        this.currentStep = "text_editor_step";
        this.decrementProgressPercentage(10);
      }

      if (this.post.postData.message_goal_id === 2) {
        this.currentStep = "pdf_step";
        this.decrementProgressPercentage(10);
      }

      if (this.post.postData.message_goal_id === 3) {
        this.currentStep = "video_step";
        this.decrementProgressPercentage(10);
      }
    },


    handleImageUploadToServer(event) {
      return new Promise((resolve, reject) => {
        const file = event.target.files[0];

        if (file.size > 1024 * 1024) {
          // 1MB in bytes
          alert("File size should not exceed 1MB");
          reject("File size too large");
          return;
        }

        const formData = new FormData();
        formData.append("image", file);

        CampaignService.uploadTextEditorImage(formData)
          .then((response) => {
            resolve(response.data); // Resolve the promise with the image URL
          })
          .catch((error) => {
            console.error(error);
            reject(error); // Reject the promise on error
          });
      });
    },

    openTextTalksLibraryModal() {
      this.showTextTalksLibraryModal = true;
    },

    closeTextTalksLibraryModal() {
      this.showTextTalksLibraryModal = false;
    },

    fetchAllTextSafetyTalks() {
      this.isLoading = true;

      LibraryTalkService.getTextTalksOnly()
        .then((response) => {
          this.isLoading = false;

          this.allTextTalks = response.data;

          // console.log(response.data);
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },


    setCurrentSafetyTalkTemplate(talk) {
      this.currentSafetyTalkTemplate = talk;

    },

    openPreviewTalkLibraryModal() {
      this.showPreviewTalkFromLibraryModal = true;
    },

    closePreviewTalkLibraryModal() {
      this.showPreviewTalkFromLibraryModal = false;
    },

    setSelectedPDFfromLibrary() {
      this.source1 = this.currentSafetyTalkTemplate.pdf_url_path;
      this.post.postData.title = this.currentSafetyTalkTemplate.title;

      this.post.postData.pdf_url_path = this.currentSafetyTalkTemplate.file_name;

      this.isLoading = true;

      //deleay to give the pdf some time to load in view
      setTimeout(() => {
        this.closePreviewTalkLibraryModal();
        this.closePDFLibraryModal();
        this.isLoading = false;
      }, 1500);
    },


    populateTheSelectedVideo() {
      this.isLoading = true;

      this.renderVideoEmbedCode = this.currentSafetyTalkTemplate.video_embed_code;

      this.post.postData.video_embed_code = this.renderVideoEmbedCode;
      this.post.postData.title = this.currentSafetyTalkTemplate.title;

      //this is just to satisfy the 'pastedEmbedVideoCode' check in post validation.
      this.pastedEmbedVideoCode = this.currentSafetyTalkTemplate.video_embed_code;

      this.isLoading = false;

      this.closePreviewTalkLibraryModal();
      this.closeVideoLibraryModal();
    },

    openVideoLibraryModal() {
      this.showVideoLibraryModal = true;
    },

    closeVideoLibraryModal() {
      this.showVideoLibraryModal = false;
    },


    openVideoEmbedModal() {
      this.showEmbedVideoModal = true;
    },

    closeVideoEmbedModal() {
      this.showEmbedVideoModal = false;
    },


    fetchAllVideoSafetyTalks() {
      this.isLoading = true;

      CampaignService.getAllVideoTalksFromLibrary()
        .then((response) => {
          this.allVideoSafetyTalks = response.data;

          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    setPastedCodeToRender() {
      var pastedString = this.pastedEmbedVideoCode;

      const checkUrls =
        pastedString.includes("www.youtube.com") ||
        pastedString.includes("player.vimeo.com") ||
        pastedString.includes("media.publit.io") ||
        pastedString.includes("wistia");

      //make sure embed code is from approved domains only
      // Youtube, Vimeo, Pubilio
      //If checkUrls returns true
      if (checkUrls) {

        if (pastedString.includes("www.youtube.com")) {

          //replace width to 100%
          var start = "iframe";
          var ending = "src";
          var string = pastedString;
          var replacement = 'width="100%" height="100%"';
          var newIframeCode = this.myReplace(
            string,
            replacement,
            start,
            ending
          );

          this.renderVideoEmbedCode = newIframeCode;

          this.post.postData.video_embed_code = this.renderVideoEmbedCode;


          this.closeVideoEmbedModal();

        } else {
          //other video plaforms give the embed already ressponsive, so no need to modify it
          this.renderVideoEmbedCode = this.pastedEmbedVideoCode;

          this.post.postData.video_embed_code = this.renderVideoEmbedCode;

          this.closeVideoEmbedModal();
        }
      } else {
        alert("Must embed code from approved domains");
      }
    },

    myReplace(string, replacement, start, ending) {
      replacement = start + " " + replacement;

      // eslint-disable-next-line no-useless-escape
      var pattern = new RegExp(start + " .+(?=" + ending + ")", "g");

      return string.replace(pattern, replacement + " ");
    },


    openDeletePDFmodal() {
      this.showDeletePDFmodal = true;
    },

    closeDeletePDFmodal() {
      this.showDeletePDFmodal = false;
    },

    openPDFLibraryModal() {
      this.showPDFlibraryModal = true;
    },

    closePDFLibraryModal() {
      this.showPDFlibraryModal = false;
    },

    fetchPDFtalksFromLibrary() {

      this.isLoading = true;

      LibraryTalkService.getAllPDFtalks()
        .then((response) => {
          this.allPDFSafetyTalks = response.data.pdfTalks;

          this.isLoading = false;

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    savePDF() {
      this.isLoading = true;

      const formData = new FormData();

      formData.set("pdf", this.pdf);
      formData.set("pdf_name", this.pdf_name);

      //assign the pdf_name to pdf_url_path to store in DB
      this.post.postData.pdf_url_path = this.pdf_name;

      CampaignService.uploadThisPdf(formData)
        .then((response) => {
          this.isLoading = false;

          this.source1 = response.data;

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    saveUpdatedPDF() {
      this.loading = true;

      const formData = new FormData();
      formData.set("updated_pdf_title", this.updated_pdf_title);
      formData.set("updated_pdf_name", this.updated_pdf_name);
      formData.set("updated_pdf", this.updated_pdf);
      formData.set("old_pdf_name", this.old_pdf_name);
      formData.set("post_id", this.postDetails.id);

      CampaignService.updatePDF(formData)
        .then((response) => {
          this.loading = false;

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    deletePDF() {
      const formData = new FormData();

      formData.set("pdf", this.pdf);
      formData.set("pdf_name", this.pdf_name);

      CampaignService.deleteFileFromDOSpaces(formData)
        .then((response) => {
          this.source1 = "";

          this.pdf_name = "";

          this.closeDeletePDFmodal();

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },

    pdfOnChange(e) {
      let file = e.target.files[0];

      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        alert("File size should not exceed 2MB");

        // Reset the file input
        e.target.value = ""; // Clear the input value

        return;
      }

      //Proceed
      this.pdf = file;

      //genereate a uniqe name
      const randomString = Math.random().toString(36).substring(2, 7);
      this.pdf_name = randomString + file.name;

      // // eslint-disable-next-line no-console
      // console.log(this.pdf, this.pdf_name);
    },
 

    textEditorValidationCheck() {
      if (this.post.postData.title === "") {
        alert("Safety talk title is required");

        return;
      }

      if (this.post.postData.content === "") {
        alert("You have not added any content to the text editor");

        return;
      }

      return true;
    },

    pdfCheck() {
      if (this.post.postData.title === "") {
        alert("Safety talk title is required");

        return;
      }

      if (this.source1 === "") {
        alert("Please upload the PDF file");

        return;
      }

      return true;
    },

    videoCheck() {
      if (this.post.postData.title === "") {
        alert("Safety talk title is required");

        return;
      }

      if (this.post.postData.video_embed_code === "") {
        alert("A video embed is required.");

        return;
      }

      return true;
    },


    onComplete() { 

      this.isLoading = true;

      this.motherArray.push(this.post);

      // console.log(this.motherArray)

      GroupTalkService.saveNewGroupTalkPost(this.groupTalk.uuid, this.motherArray)
        .then((response) => {

          if (response.status == 200) {

            setTimeout(() => {

              //redirect to view group talk page
              this.router.push({
                name: "ViewAGroupTalk",
                params: { uuid: this.groupTalk.uuid },
              });

              // this.resetWizard();
              this.isLoading = false;

              this.resetWizard()

              //  console.log(response.data);


            }, 1000);
          }


          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });



    },


    resetWizard() {
      this.currentStep = "message_goal_step";

      this.post.postData.message_goal_id = 1;
      this.post.postData.title = "";
      this.post.postData.content = "";
      this.post.postData.pdf_url_path = "";
      this.post.postData.video_embed_code = "";
      this.post.postData.message = "",
      this.post.postData.send_date = ""
      this.post.postData.send_time = ""
      // this.post.postData.supervisors = []


      this.renderVideoEmbedCode = ""
      this.pastedEmbedVideoCode = ""

      this.isPublishedSelected = false


    },


    exitWizard() {
      if (
        confirm(
          "You're exiting the wizard without publishing. Changes will not be saved!"
        )
      ) {

        this.router.push({
          name: "ViewAGroupTalk",
          params: { uuid: this.groupTalk.uuid },
        });

        this.resetWizard()

        // console.log('exit editor')
      }
    },



    fetchPost(uuid) {


      this.employeesCheck = [] //reset

      GroupTalkService.getThePostContentForGroupTalk(uuid)
        .then((response) => {


          this.postDetails = response.data.groupTalkPost.post
          this.groupTalkPost = response.data.groupTalkPost
          this.employees = response.data.employees

          //map to employeesCheck array to uncheck those not present
          this.employees.forEach(obj => {
            this.employeesCheck.push({
              user_id: obj.id,
              name: obj.name,
              checked: 1
            });
          })


          if (this.postDetails.message_goal_id == 3) {

            //write a function to return if video_embed_code includes 'youtube'
            if (this.isThisVideoYoutube()) {

              this.thisVideoIsYoutube = true


            }

          }

          if (this.postDetails.message_goal_id == 2) {


            this.doesPDFNameExistInLibrary = response.data.doesExistInLibrary

            this.buildPDFurl2()


          }


          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });


    },


    isThisVideoYoutube() {

      // Check if videoCode includes 'youtube' and return true or false
      return this.postDetails.video_embed_code.includes('youtube');

    },

    buildPDFurl2() {

      let url = "";

      if (this.doesPDFNameExistInLibrary === 1) {

        url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/pdftalks/" + this.postDetails.pdf_url_path;

        this.source1 = url;

        return;
      }

      if (this.doesPDFNameExistInLibrary === 0) {

        //if master_account_admin_id is null it means it was created by the master manager account
        if (this.postDetails.master_account_admin_id == null) {

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.postDetails.manager_id + "/9j1ks/" + this.postDetails.pdf_url_path;

        }

        //this is mean it was created by a master account admin
        if (this.postDetails.master_account_admin_id !== null) {

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.postDetails.master_account_admin_id + "/9j1ks/" + this.postDetails.pdf_url_path;

        }

        this.source1 = url;

        return;

      }

      // if (this.doesPDFNameExistInLibrary === 0) {

      //   url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.postDetails.manager_id + "/9j1ks/" + this.postDetails.pdf_url_path;

      //   this.source1 = url;

      //   return;
      // }


    },


    openMarkTalkAsCompleteModal() {
      this.showMarkTalkAsCompleteModal = true
    },

    closeMarkTalkAsCompleteModal() {
      this.showMarkTalkAsCompleteModal = false
    },

    updatePresentStatus(employee) {

      //if the status id true/1 switch to false
      if (employee.checked) {

        employee.checked = 0

      } else {
        employee.checked = 1
      }


    },



    getCurrentTime() {

      return moment().format('HH:mm');

    },

    markGroupTalkAsComplete(uuid) {


      this.employeeData.employeesCheck = this.employeesCheck
      this.employeeData.time_acknowledged = this.getCurrentTime()

      // console.log(this.employeeData, uuid)

      GroupTalkService.markGroupTalkAcknowledged(uuid, this.employeeData)
        .then((response) => {



          this.groupTalkPost.is_acknowledged = 1

          this.closeMarkTalkAsCompleteModal()

          toast("Talk marked as complete!", {
            position: "bottom-right",
            autoClose: 2000,
          });
          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    fetchEmployeesAcknowledgementStatus(uuid) {

      GroupTalkService.getEmployeesWhoAcknowledgedAGroupTalkPost(uuid)
        .then((response) => {

          this.updateEmployeesCheck = response.data.employeesCheck

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

    updateAcknowledgementStatus(uuid) {

      this.employeeData.employeesCheck = this.updateEmployeesCheck

      GroupTalkService.updateGroupAcknowledgement(uuid, this.employeeData)
        .then((response) => {


          this.closeUpdateEmployeeAckStatusModal()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 2000,
          });

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

    openUpdateEmployeeAckStatusModal() {
      this.showUpdateEmployeeAckStatusModal = true
    },

    closeUpdateEmployeeAckStatusModal() {
      this.showUpdateEmployeeAckStatusModal = false
    },

    openDeleteGroupTalkPostModal() {
      this.showDeleteGroupTalkPostContentModal = true
    },

    closeDeleteGroupTalkPostModal() {
      this.showDeleteGroupTalkPostContentModal = false
    },


    setCurrentGroupTalkToDelete(talk) {

      this.currentGroupTalkPost = talk



    },

    deleteThisTalk() {

      GroupTalkService.deleteGroupTalkPost(this.currentGroupTalkPost.uuid)
        .then((response) => {


          this.fetchGroupTalk(this.currentGroupTalkUUID)
          this.closeDeleteGroupTalkPostModal()

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    goBackToViewAllGroupTalks() {

      this.router.push({
        name: "ManagerViewAllGroupTalks",
      });

    },

    fetchAcknowledgmentData(uuid) {


      // console.log(uuid)

      GroupTalkService.fetchViewEmployeesWhoAcknowledgedAGroupTalkPost(uuid)
        .then((response) => {

          this.groupTalkPost = response.data.groupTalkPost
          this.groupTalkAcknowledgements = response.data.groupTalkAcknowledgements

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

   



    exportDataAsExcel(uuid) {

      GroupTalkService.exportAllGroupTalkAttendees(uuid)
        .then(response => {

          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)

          // Open the URL on new Window
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', this.groupTalkPost.post.title + 'GroupTalkData.xlsx')
          document.body.appendChild(link)
          link.click()

          // console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    exportSupervisorsListForGroupTalkDataAsExcel(uuid) {

      GroupTalkService.exportSupervisorsForAGroupTalk(uuid)
        .then(response => {

          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)

          // Open the URL on new Window
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', this.groupTalkPost.post.title + 'GroupTalkData.xlsx')
          document.body.appendChild(link)
          link.click()

          // console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },


    openEditMessageModal(){
      this.showEditMessageModal = true
    },

    closeEditMessageModal(){
      this.showEditMessageModal = false
    },

    setCurrentGroupTalk(talk) {
      this.currentGroupTalkPost = talk

      // console.log(talk)
    },

    updateMessage(){

      if(this.currentGroupTalkPost.message == "" || 
        this.currentGroupTalkPost.send_date == "" || 
        this.currentGroupTalkPost.send_time == ""){

          alert("Please add a send date, time and message");

          return;

      }

      const formData = {
        group_talk_post: this.currentGroupTalkPost
      }


      GroupTalkService.updateGroupTalkPostMessage(formData)
        .then((response) => {

          this.closeEditMessageModal()

          toast("Updated!", {
            position: "bottom-right",
            autoClose: 2000,
          });

          // console.log(response) 
          
        }).catch((err) => {

          console.log(err)
          
        });

      // console.log(this.currentGroupTalkPost)

    },

    limitText(body) {
      if (body.length > 30) {
        return body.substring(0, 30) + "...";
      }

      return body;
    },



    downloadImageFile(ack) { 


      let downloadURL = ack.signature_img_url;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = ack.user.name ; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },

    downloadImageFileInViewAttendees() { 


      let downloadURL = this.groupTalkPost.sign_in_sheet_img_url;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = 'signin_sheet' ; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },


    setCurrentCampaignToDeleteThenOpenModal(groupTalk){


      this.currentGroupTalkCampaign = groupTalk

      this.openDeleteGroupTalkModal()


      // console.log(groupTalk)
    },

    deleteThisGroupTalkCampaign(){

      GroupTalkService.deleteAGroupTalkCampaign(this.currentGroupTalkCampaign.uuid)
          .then((response) => {

            this.fetchAllGroupTalks()
            this.closeDeleteGroupTalkModal()

            this.formData.name = ''
            this.formData.team_id = ''

            // // eslint-disable-next-line no-console
            // console.log(response.data)
            
          }).catch((err) => {
            
          });


    },

    deleteGroupTalk() {

      // GroupTalkService.deleteGroupParentTalk(this.currentGroupTalk.uuid)
      //   .then((response) => {


      //     this.fetchAllGroupTalks()
      //     this.closeDeleteGroupTalkModal()

      //     this.formData.name = ''
      //     this.formData.team_id = ''

      //     // // eslint-disable-next-line no-console
      //     // console.log(response.data)

      //   }).catch((err) => {

      //     // eslint-disable-next-line no-console
      //     console.log(err)

      //   });

    },


    openSelectSupervisorsModal(){
      this.showSelectSupervisorsModal = true
    },

    closeSelectSupervisorsModal(){
      this.showSelectSupervisorsModal = false
    },


    updateSupervisorSelection(supervisor) {

      //if the status id true/1 switch to false
      if (supervisor.checked) {

        supervisor.checked = 0

      } else {
        supervisor.checked = 1
      }


    },


    removeSupervisorsWithCheckZero(){

      const updatedArray = []


      this.post.postData.supervisors.forEach((sup) => {

        if(sup.checked == 1){

          updatedArray.push(sup)

        }

      })


      this.post.postData.supervisors = updatedArray;


    },




 
    fetchAllGroupTalkAcknowledgementsForReport(){


      this.showExportButton = false

      GroupTalkService.getAllGroupCampaignsAckData()
        .then((response) => {
          

          this.talksCount = response.data.talksCount
          this.acknowledgementsCompletedCount = response.data.acknowledgementsCompletedCount
          this.acknowledgementsNotCompletedCount = response.data.acknowledgementsNotCompletedCount
          this.ack_records = response.data.sup_to_group_talk_records
          this.allSupervisors = response.data.supervisors

          // this.searchFormData.supervisor_id = this.allSupervisors[0].id

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },


    openSearchOptionsModal(){
      this.showSearchOptionsModal = true
    },

    closeSearchOptionsModal(){
      this.showSearchOptionsModal = false
    },


    searchACKData(){   

      this.isLoading = true;

      // console.log('searchFormData', this.searchFormData)

      GroupTalkService.searchGroupTalkAcknowledgmentsWithDates(this.searchFormData)
        .then((response) => { 


          setTimeout(() => {

            this.isLoading = false;

            this.talksCount = response.data.talksCount
            this.acknowledgementsCompletedCount = response.data.acknowledgementsCompletedCount
            this.acknowledgementsNotCompletedCount = response.data.acknowledgementsNotCompletedCount
            this.ack_records = response.data.sup_to_group_talk_records

            this.closeSearchOptionsModal()
            this.showExportButton = true
 
            // console.log(response.data)


          }, 2000);

      
          
        }).catch((err) => {

          this.isLoading = false;

          console.log(err)
          
        });
  

    },



    exportSearchFormData(){

      // console.log(this.searchFormData)


      GroupTalkService.exportGroupTalkReportSearchFormData(this.searchFormData)
        .then((response) => {

          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)

          // Open the URL on new Window
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', 'GroupTalksData.xlsx')
          document.body.appendChild(link)
          link.click()


          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });



    },


    resetWizardSearchBoxState(){ 

      this.searchFormData.supervisor_id = null
      this.searchFormData.start_date = null
      this.searchFormData.end_date = null
      this.searchFormData.completion_type = 'all'
     

    },


    openAIpromptModal() {
      this.showAIpromptModal = true;
    },

    closeAIpromptModal() {
      this.showAIpromptModal = false;
    },

    openAIresultsModal() {
      this.showAIresultsModal = true;
    },

    closeAIresultsModal() {
      this.showAIresultsModal = false;
    },


    generateAIcontent() {

      //check if user entered prompt
      if (this.promptContent.prompt == "") {
        alert(
          "Please describe what kind of safety talk should be should be created"
        );

        return;
      }

      const formData = {
        promptContent: this.promptContent,
      };

      this.isLoading = true;

      CampaignService.generateTextTalkWithAI(formData)
        .then((response) => {
          // console.log(response.data);

          this.theAIGenerateTalk = response.data.contentArray.talk;

          this.openAIresultsModal();

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resetAIFormPromptBox(){
      this.promptContent.language = "English"
      this.promptContent.prompt = ""
    }


















  },










});
