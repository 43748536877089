import apiClient from "./MyClient";


export default {



  getAllGroupTalksForASupervisor() {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/sup/allgrouptalks')

  },


  getAGroupTalkForASupervisor(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/sup/grouptalk/' + uuid)

  },


  getEmployeesForATeamForCheckoffOnly(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/sup/employees-for-checkoff/' + uuid)

  }, 


  markCheckoffTalkAcknowledgement(uuid, formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/sup/mark-checkoff-complete/' + uuid, formData)

  }, 

  getCheckoffEmployeesWhoAcknowledgedAGroupTalkPost(uuid) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/sup/employees/checkoff/list/' + uuid)

  }, 

  updateCheckoffGroupAcknowledgement(uuid, formData) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/sup/employees/checkoff/list/update/' + uuid, formData)

  }, 


  getEmployeesForATeamForSignaturesOnly(uuid) {  

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/sup/employees/signatures/list/' + uuid)

  }, 
  
  markSignaturesTalkAcknowledgement(uuid, formData) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post('/api/sup/employees/mark/complete/signatures/' + uuid, formData, config) 

  },  


  getAllEmployeesWhoAlreadyHadSignaturesDoneForATalk(uuid) {  

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/sup/all-employees/with-signatures/' + uuid)

  }, 

  uploadRedoSignatureImage(formData) {  

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };


    return apiClient.post('/api/sup/ack/signature/redo/', formData, config)

  }, 
  

  markUploadSignInSheetAsComplete(formData, uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post('/api/sup/signin-sheet/mark-complete/' + uuid, formData, config) 

  },








}