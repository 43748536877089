<template>
    <div class="mainWrapper">


        <EmployeeMainSideBarVue v-if="windowWidth > mobileWidthThreshold" />

        <div v-else>

            <EmployeeMobileTopNav />

            <EmployeeMobileBottomNavVue />

        </div>





        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 40px;' : ''">


            <div class="topNav">
                <TopBarVue v-if="windowWidth > mobileWidthThreshold" />
            </div>



            <div class="innerContainer">

                <div class="theHeaderWrapper">

                    <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                        class="bi bi-arrow-left"></i>

                    <div style="margin-left: 20px;">
                        <!-- {{ props.random_string }}  - {{ employeeDashStore.post }} -->

                    </div>

                </div>






                <div class="contentWrapper">



                    <div v-if="employeeDashStore.post.message_goal_id == 1" class="talkBox">


                        <div class="textBox">


                            <div class="headerBox">

                                {{ employeeDashStore.post.title }}

                            </div>

                            <div class="textContent">

                                <div class="htmlHtml" v-html="employeeDashStore.post.body"></div>


                            </div>




                        </div>



                    </div>


                    <div class="talkBox" v-if="employeeDashStore.post.message_goal_id == 2">


                        <div class="pdfBox">



                            <div class="headerBox">

                                <!-- {{ employeeDashStore.post.title }} -->

                            </div>


                            <div class="pdfContent">


                                <vue-pdf-embed :source="employeeDashStore.source1" />

                            </div>



                        </div>



                    </div>

                    <div class="talkBox" v-if="employeeDashStore.post.message_goal_id == 3">



                        <div class="videoBox">


                            <div class="headerBox">

                                {{ employeeDashStore.post.title }}

                            </div>

                            <div class="videoContent">


                                <div v-if="employeeDashStore.thisVideoIsYoutube">

                                    <div class="yTvideoWrapper" v-html="employeeDashStore.post.video_embed_code"></div>

                                </div>

                                <div v-else>



                                    <div class="normalVideoWrapper" v-html="employeeDashStore.post.video_embed_code"></div>

                                </div>






                            </div>




                        </div>


                    </div>

                </div>




            </div>






        </div>





    </div>
</template>

<script setup>

import { ref, onMounted, onUnmounted, defineProps } from 'vue';
import EmployeeMainSideBarVue from '@/components/EmployeeMainSideBar.vue';
import TopBarVue from "@/components/TopBar.vue";
import EmployeeMobileBottomNavVue from '@/components/EmployeeMobileBottomNav.vue';
import EmployeeMobileTopNav from '@/components/EmployeeMobileTopNav.vue';
import { useEmployeeDashboardStore } from '@/stores/EmployeeDashboardStore';
import VuePdfEmbed from 'vue-pdf-embed'


const employeeDashStore = useEmployeeDashboardStore();

const props = defineProps({
    random_string: String 
})

const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed

const onResize = () => {
    windowWidth.value = window.innerWidth;
};

onMounted(() => {
    window.addEventListener('resize', onResize);


    employeeDashStore.fetchGroupTalkPost(props.random_string)  


});

onUnmounted(() => {
    window.removeEventListener('resize', onResize);



});



</script>

<style scoped >
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.contentWrapper {
    display: flex;

    justify-content: center;
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}


.talkBox {
    width: 900px;

    /* background-color: antiquewhite; */
}


.textBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.videoBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.pdfBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.headerBox {
    width: 100%;
    background-color: #e9faf3;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    text-align: left;
    font-size: 19px;
    font-weight: bold;
}

.theInput {

    width: 100%;

}

.formBox {
    padding: 10px;
    margin-bottom: 30px;
}

.uploadBox {

    padding: 30px;
    margin-bottom: 30px;

}

.thePic {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.textContent {
    padding: 20px;
    text-align: left;
    font-size: 17px;

}

.videoContent {
    padding: 10px;
    text-align: left;
    font-size: 17px;

}




.pdfContent {
    text-align: left;
    font-size: 17px;
    width: 100%;
    height: auto;
    overflow-y: auto;
}

/* Adjust the height based on the viewport width */
@media (max-width: 1230px) {
    .pdfContent {
        height: 400px;
        /* Example height for smaller screens */
    }
}

/* Larger screens, can have a larger fixed height or remain auto */
@media (min-width: 1231px) {
    .pdfContent {
        height: 700px;
        /* Example height for larger screens */
    }
}

::v-deep .htmlHtml img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 15px;
    margin-top: 15px;
}

/* Media query for larger screens */
@media (min-width: 1231px) {
    ::v-deep .htmlHtml img {
        max-width: 500px;
    }
}


.normalVideoWrapper {
    width: 100%;
    height: 100%;
}

.yTvideoWrapper {
    width: 100%;
    height: 500px;
}


/* When the view port min width is 500 change height to 500px */
@media (max-width: 500px) {

    .yTvideoWrapper {
        height: 300px;

    }

}


.achnowledgeWrapper {
    display: flex;
    justify-content: center;
    /* background-color: #6ddaad; */
    width: 100%;
}

.achnowledgeBox {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #f8f9fa;
}

.achnowledgeBoxSuccess {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    font-weight: bold;
    font-size: 16px;
    background-color: #f8f9fa;
}



.quizBox {

    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 16px;
    padding: 10px;
    background-color: #f9f9f9;

}

.questionBox {
    max-width: 900px;
    padding: 20px;
    font-weight: bolder;
    font-size: 17px;
    /* background-color: #6b9fd2; */
    text-align: left;
}

.answerItem {
    padding: 10px;
    font-size: 16px;
    text-align: left;

}


.form-check {
    display: flex;
    flex-direction: row;
}


.form-check-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid black;
    cursor: pointer;
    margin-right: 10px;
}

.form-check-input:checked {
    background-color: green;
}


.quizNav {

    display: flex;
    justify-content: space-between;
    /* background-color: #e9faf3; */
    width: 100%;
    padding: 10px;
    margin-top: 10px;

}


.quizResults {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 900px;

    font-size: 16px;
    padding: 10px;
}
</style>