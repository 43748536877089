<template>
    <div class="loginWrapper">

 

        <div class="errorWrapper">

            <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>

            <div v-if="authStore.errorArray.length > 0" class="alert alert-danger" role="alert" style="max-width: 400px; margin-top: 20px;">
                <ul>
                    <li v-for="(error, index) in authStore.errorArray" :key="index">
                    {{ error }}
                    </li>
                </ul>
            </div>

           

        </div>
            


        <div class="formWrapper">

            
            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />


            <form class="theFormBox" @submit.prevent="authStore.registerEmployee">


                <div class="mb-3">
                    <label class="form-label" style="float: left;">First Name</label>
                    <input v-model="authStore.employeeRegisterFormData.fname" type="text" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Last Name</label>
                    <input v-model="authStore.employeeRegisterFormData.lname" type="text" class="form-control" required>
                </div>


                <div class="mb-3">
                    <label class="form-label" style="float: left;">Work Email</label>
                    <input v-model="authStore.employeeRegisterFormData.email" type="email" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Password</label>
                    <input v-model="authStore.employeeRegisterFormData.password" type="password" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Confirm Password</label>
                    <input v-model="authStore.employeeRegisterFormData.password_confirmation" type="password" class="form-control" required> 
                </div>



                <div v-if="authStore.isLoading">

                    <button type="button" class="btn btn-primary loginBTN" disabled>Loading...</button>

                </div>

                <div v-else>

                    <button type="submit" class="btn btn-primary loginBTN">SIGN UP</button>

                </div>

            </form>

      
 




        </div>

    </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from "vue";
import { useRoute } from 'vue-router';



const authStore = useAuthStore();
const route = useRoute();







onMounted(() => {


    authStore.employeeRegisterFormData.company_id = parseInt(route.query.j6wsywtmcYdssni, 10);
    authStore.employeeRegisterFormData.team_id = parseInt(route.query.u9bdnfidsnwwn, 10);
    authStore.employeeRegisterFormData.app = route.query.app

    // console.log(authStore.employeeRegisterFormData.company_id, authStore.employeeRegisterFormData.team_id, authStore.employeeRegisterFormData.app)


})


</script>

<style scoped>
.imgLogo {
    /* float: left; */
    width: 100px;
    margin-bottom: 20px;

}

.loginWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* background-color: rgb(235, 226, 226); */
}

.topNav {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
    width: 100%;
    height: 60px;
    margin-top: 30px;
    /* top: 0px; */
  
}

.formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(210, 124, 10); */
    width: 100%;
    height: 100%;
}


.theFormBox{
    max-width: 500px;
    padding: 20px;
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}


.form-control {
    background-color: rgb(243, 246, 246);
}

.errorWrapper{
    display: flex;
    justify-content: center;
}

</style>