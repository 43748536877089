<template>
    <div class="mainWrapper">



        <SOPMainSideBarVue v-if="windowWidth > mobileWidthThreshold" />

        <div v-else>

            <SOPMobileTopNav />

        </div>



        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 50px;' : ''">


            <div class="topNav">
                <TopBarVue v-if="windowWidth > mobileWidthThreshold" />
            </div>


            <div class="innerContainer">

                <div class="theHeaderWrapper">
                    <div>My Documents</div>


                    <div class="dropdown">

                        <button @click="sopAppStore.openNewDocumentModal" class="button-6" type="button">
                            New PDF +
                        </button>

                        <!-- <button class="button-6 dropdown-toggle" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Add New
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="width: 200px;">

                            <li @click="sopAppStore.openNewDocumentModal">
                                <a class="dropdown-item" href="#">Upload SOP / Document</a>
                            </li>

                            <li @click="sopAppStore.createNewTextContent">
                                <a class="dropdown-item" href="#">Create New SOP With AI</a>
                            </li>

                            <li @click="sopAppStore.openNewFolderModal">
                                <a class="dropdown-item" href="#">New Folder</a>
                            </li>

                        </ul> -->

                    </div>



                </div>





                <div v-if="sopAppStore.sopFiles.length > 0">

                    <div class="searchBarWrapper">
                        <input v-model="searchQuery" type="text" class="form-control theBar" placeholder="Search..." />
                    </div>





                    <div class="tableWrapper">

                        <div class="table-responsive">

                            <table class="table table-hover my-custom-table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="colTitle">Title</th>
                                        <th scope="col" class="colTitle">Type</th>
                                        <th scope="col" class="colTitle">Created By</th>
                                        <th scope="col" class="colTitle">Date Created</th>
                                        <th scope="col" class="colTitle">Last Updated</th>
                                        <th scope="col" class="colTitle">Teams</th>
                                        <th scope="col" class="colTitle">Chat Ready</th>
                                        <!-- <th scope="col" class="colTitle">Editors</th>
                                        <th scope="col" class="colTitle">Viewers</th> -->
                                        <th scope="col" class="colTitle"></th>
                                    </tr>
                                </thead>

                                <tbody>



                                    <tr class="rowItem" v-for="(file, index) in filteredTeams" :key="index">

                                        <td @click="sopAppStore.goToDocument(file)">

                                            <div>
                                                {{ sopAppStore.limitText(file.file_name) }}
                                            </div>

                                        </td>

                                        <td @click="sopAppStore.goToDocument(file)">

                                            <div v-if="file.file_type === 'image'">
                                                <i class="bi bi-image" style="font-size: 21px;"></i>
                                            </div>
                                            <div v-if="file.file_type === 'pdf'">
                                                <i class="bi bi-file-earmark-pdf" style="font-size: 21px;"></i>
                                            </div>
                                            <div v-if="file.file_type === 'video'">
                                                <i class="bi bi-play-btn-fill" style="font-size: 21px;"></i>
                                            </div>

                                            <div v-if="file.file_type === 'text'">
                                                <i class="bi bi-card-checklist" style="font-size: 21px;"></i>
                                            </div>


                                        </td>

                                        <td @click="sopAppStore.goToDocument(file)">
                                            <div v-if="file.user">
                                                {{ file.user.name }}
                                            </div>
                                        </td>

                                        <td @click="sopAppStore.goToDocument(file)">
                                            {{ sopAppStore.formatDate(file.created_at) }}
                                        </td>

                                        <td @click="sopAppStore.goToDocument(file)">
                                            {{ sopAppStore.formatDate(file.updated_at) }}
                                        </td>

                                        <td>

                                            <button 
                                            @click="() => { sopAppStore.fetchAllFileTeamsSelectedOrNot(file.uuid); sopAppStore.setCurrentFile(file) ;sopAppStore.openShowEditTeamAccessModal(); }"
                                   
                                                class="btn btn-dark btn-sm viewTeamsBtn">
                                                View
                                            </button>
                                            <!-- <div v-if="file && file.sop_file_team && file.sop_file_team.length > 0">

                                                <div v-for="(sopFileTeam, index) in file.sop_file_team" :key="index">
                                                    <div v-if="sopFileTeam.sop_team">{{ sopFileTeam.sop_team.name }}</div>
                                                </div>
                                            </div> -->

                                        </td>

                                        <td @click="sopAppStore.goToDocument(file)">

                                            <div v-if="file.is_vector_processed === 'no'">
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div> Processing...
                                            </div>
                                            <div v-else>
                                                Yes
                                            </div>


                                        </td>

                                        <!-- <td>sad</td>
                                        <td>sad</td> -->

                                        <td>
                                            <div class="iconsRow" v-if="file.is_vector_processed === 'yes'">


                                                <router-link :to="{
                                                    name: 'ViewADocumentLive',
                                                    params: { uuid: file.uuid },
                                                }">
                                                    <i class="bi bi-eye theActionIcon"></i>
                                                </router-link>



                                                <i @click="() => { sopAppStore.setCurrentFile(file); sopAppStore.openEditFileNameModal(); }"
                                                    class="bi bi-pencil theActionIcon"></i>

                                                <i @click="() => { sopAppStore.setCurrentFile(file); sopAppStore.openDeleteFileModal(); }"
                                                    class="bi bi-trash theActionIcon"></i>

                                            </div>
                                        </td>
                                    </tr>



                                </tbody>




                            </table>


                            <button v-if="displayedFilesCount < sopAppStore.sopFiles.length"
                                class="btn btn-dark loadMoreBtn" @click="loadMoreFiles">Load more</button>

                        </div>




                    </div>

                </div>



                <div class="noTeamBox" v-else>

                    No SOP files uploaed yet.

                </div>




            </div>





        </div>


        <div class="modal-overlay" v-if="sopAppStore.showNewDocumentModal">

            <div class="modal-box" style="max-width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Upload File</b>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closeNewDocumentModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


                    <form @submit.prevent="sopAppStore.uploadThisFile" enctype="multipart/form-data">

                        <div class="theUploadBox">


                            <div class="mb-3">

                                <input @change="sopAppStore.setFiletoUpload" class="form-control titleInput" type="file"
                                    accept="application/pdf" required />

                            </div>


                            <div class="mb-3" style="display: flex; flex-direction: column; padding-top: 20px;">

                                <label class="form-label" style="text-align: left;"><b>Select teams that can access this
                                        file:</b></label>

                                <div style="max-height: 350px; overflow-y: auto;">



                                    <table class="table">
                                        <thead>
                                            <tr>

                                                <th scope="col">Team</th>
                                                <th scope="col">Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(team, index) in sopAppStore.teams" :key="index">

                                                <td>{{ team.name }}</td>

                                                <td>

                                                    <div
                                                        @click="() => { sopAppStore.toggleSelection(team); sopAppStore.addrOrRemoveTeam(team); }">

                                                        <input class="form-check-input" type="checkbox"
                                                            :value="team.is_selected" v-model="team.is_selected"
                                                            :id="`flexCheck-${team.id}`" style="border-color: black;">

                                                    </div>




                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>




                            </div>

                            <!-- <div class="mb-3">
                                <label class="form-label" style="float: left"><b>Select Team To Access This File</b></label>

                                <select v-model="sopAppStore.newFileFormData.team_id" class="form-select custom-select"
                                    required>
                                    <option value="" disabled>Choose a team</option>

                                    <option v-for="(team, index) in sopAppStore.teams" :key="index" :value="team.id">
                                        {{ team.name }}
                                    </option>

                                </select>


                            </div> -->


                            <div class="mb-3">

                                <div v-if="sopAppStore.isLoading" class="spinner-grow text-success" role="status"
                                    style="margin-top: 20px;">
                                    <span class="visually-hidden">Uploading...</span>
                                </div>

                                <button v-else type="submit" class="btn btn-dark createBTN">
                                    <b>Upload</b>
                                </button>

                            </div>


                        </div>

                    </form>



                </div>
            </div>
        </div>

        <div class="modal-overlay" v-if="sopAppStore.showNewFolderModal">

            <div class="modal-box" style="max-width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Create New Folder</b>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closeNewFolderModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">

                    <form>

                        <label class="form-label" style="float: left"><b>Name</b></label>
                        <input type="text" class="form-control titleInput" placeholder="Type folder name..." required />



                        <div>
                            <button type="submit" class="btn btn-dark createBTN">
                                <b>Create</b>
                            </button>
                        </div>
                    </form>




                </div>
            </div>
        </div>

        <div class="modal-overlay" v-if="sopAppStore.showEditFileNameModal">

            <div class="modal-box" style="max-width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Rename File</b>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closeEditFileNameModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">



                    <form @submit.prevent="sopAppStore.renameFile">

                        <input v-model="sopAppStore.currentFile.file_name" type="text" class="form-control titleInput"
                            placeholder="Type folder name..." required />


                        <div>
                            <button type="submit" class="btn btn-dark createBTN">
                                <b>Update</b>
                            </button>
                        </div>
                    </form>




                </div>
            </div>
        </div>

        <div class="modal-overlay" v-if="sopAppStore.showDeleteFileModal">

            <div class="modal-box" style="max-width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Are you sure want to delete this file?</b>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closeDeleteFileModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


                    <p style="text-align: left;">After deleting file <b>{{ sopAppStore.currentFile.file_name }}</b> it's
                        unrecoverable. </p>



                    <div>
                        <div v-if="sopAppStore.isLoading" class="spinner-grow text-success" role="status"
                            style="margin-top: 20px;">
                            <span class="visually-hidden">Deleting...</span>
                        </div>

                        <button v-else type="button" @click="sopAppStore.deleteThisFile" class="btn btn-danger createBTN">
                            <b>Yes, Delete!</b>
                        </button>
                    </div>


                </div>
            </div>
        </div>


        <div class="modal-overlay" v-if="sopAppStore.showEditTeamAccessModal">

            <div class="modal-box" style="max-width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Edit Team Access</b>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closShowEditTeamAccessModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


               

                        <div class="theUploadBox">


                            <div class="mb-3" style="display: flex; flex-direction: column; padding-top: 20px;">


                                <div style="max-height: 350px; overflow-y: auto;">



                                    <table class="table">
                                        <thead>
                                            <tr>

                                                <th scope="col">Team</th>
                                                <th scope="col">Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(team, index) in sopAppStore.teams" :key="index">

                                                <td>{{ team.name }}</td>

                                                <td>

                                                    <div @click="sopAppStore.updateATeamAccessToFile(team)">

                                                        <input class="form-check-input" type="checkbox"
                                                            :value="team.is_selected" v-model="team.is_selected"
                                                            :id="`flexCheck-${team.id}`" style="border-color: black;">

                                                    </div>




                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>




                            </div>



                     


                        </div>

         



                </div>
            </div>
        </div>


    </div>
</template>

<script setup>

import SOPMainSideBarVue from '@/components/SOP/SOPMainSideBar.vue';
import SOPMobileTopNav from '@/components/SOP/SOPMobileTopNav.vue';
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { useSOPAppStore } from "@/stores/SOPAppStore";
import { useAuthStore } from "@/stores/AuthStore";



const sopAppStore = useSOPAppStore();
const authStore = useAuthStore();

const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed


const onResize = () => {
    windowWidth.value = window.innerWidth;
};



const searchQuery = ref('');
const displayedFilesCount = ref(30); // Start with displaying 5 teams
const incrementStep = 25; //


const filteredTeams = computed(() => {

    return sopAppStore.sopFiles
        .filter(file =>
            file.file_name &&
            typeof file.file_name === 'string' &&
            file.file_name.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        .slice(0, displayedFilesCount.value);
});


// const filteredTeams = computed(() => {

//     return sopAppStore.sopFiles
//         .filter(file => file.file_name.toLowerCase().includes(searchQuery.value.toLowerCase()))
//         .slice(0, displayedFilesCount.value);

// });

const loadMoreFiles = () => {
    displayedFilesCount.value += incrementStep;
};


onMounted(() => {

    sopAppStore.fetchAllSOPFiles()
    authStore.getAManagerAppAccessInfo('sop')

    window.addEventListener('resize', onResize);

});

onUnmounted(() => {
    window.removeEventListener('resize', onResize);
});




</script>

<style  scoped>
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    color: initial;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}


.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
    margin-right: 10px;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.dropBox {
    background-color: azure;
    max-width: 200px;
}

.dropdown-item {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-item:hover {
    background-color: rgb(222, 222, 222);
    color: black;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.colTitle {
    font-size: 14px;
}

.noLinkStyle {
    text-decoration: none;
    color: inherit;
}

.rowItem:hover {

    cursor: pointer;
}


.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.viewTeamsBtn {
    border-radius: 5px;
    font-size: 11px;
    background-color: white;
    color: black;
}
</style>