<template>
    <div class="mainWrapper">



        <MainSideBarVue />



        <div class="rightContentWrapper">


            <div class="topNav">
                <TopBarVue />
            </div>


            <div class="innerContainer">


                <div class="backBtnBox">


                    <div>

                        <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                            class="bi bi-arrow-left"></i>



                    </div>



                    <div class="theHeaderWrapper">

                        <div>
                            <!-- {{ groupTalkStore.postDetails.title }} -->
                        </div>



                    </div>


                </div>



                <div class="editContentBox">




                    <div class="theContent">


                        <div v-if="groupTalkStore.postDetails.message_goal_id == 1" class="talkBox">


                            <div class="textBox">


                                <div class="headerBox">

                                    <div>
                                        {{ groupTalkStore.postDetails.title }}
                                    </div>


                             

                                  

                                    <div>

                                        <div class="talkDone" v-if="groupTalkStore.groupTalkPost.is_acknowledged == 1">

                                            <i class="bi bi-people"
                                                @click="() => { groupTalkStore.fetchEmployeesAcknowledgementStatus(props.uuid); groupTalkStore.openUpdateEmployeeAckStatusModal(); }"
                                                style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>

                                            <span class="badge rounded-pill bg-success">
                                                Talk Completed
                                            </span>


                                        </div>
                                        <div v-else>

                                            <!-- <button @click="groupTalkStore.openMarkTalkAsCompleteModal" class="button-13">
                                                <b>Mark Talk As Complete</b>
                                            </button> -->

                                        </div>

                                    </div>

                                </div>

                                <div class="textContent">

                                    <div class="htmlHtml" v-html="groupTalkStore.postDetails.body"></div>


                                </div>




                            </div>



                        </div>


                        <div class="talkBox" v-if="groupTalkStore.postDetails.message_goal_id == 2">


                            <div class="pdfBox">



                                <div class="headerBox">

                                    <div>
                                        {{ groupTalkStore.postDetails.title }}
                                    </div>

                                    <div>

                                        <div class="talkDone" v-if="groupTalkStore.groupTalkPost.is_acknowledged == 1">

                                            <i class="bi bi-people"
                                                @click="() => { groupTalkStore.fetchEmployeesAcknowledgementStatus(props.uuid); groupTalkStore.openUpdateEmployeeAckStatusModal(); }"
                                                style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>

                                            <span class="badge rounded-pill bg-success">
                                                Talk Completed
                                            </span>


                                        </div>
                                        <div v-else>
                                            <!-- 
                                            <button @click="groupTalkStore.openMarkTalkAsCompleteModal" class="button-13">
                                                <b>Mark Talk As Complete</b>
                                            </button> -->

                                        </div>

                                    </div>

                                </div>


                                <div class="pdfContent">


                                    <vue-pdf-embed :source="groupTalkStore.source1" />

                                </div>



                            </div>



                        </div>

                        <div class="talkBox" v-if="groupTalkStore.postDetails.message_goal_id == 3">



                            <div class="videoBox">


                                <div class="headerBox">

                                    <div>
                                        {{ groupTalkStore.postDetails.title }}
                                    </div>

                                    <div>

                                        <div class="talkDone" v-if="groupTalkStore.groupTalkPost.is_acknowledged == 1">

                                            <i class="bi bi-people"
                                                @click="() => { groupTalkStore.fetchEmployeesAcknowledgementStatus(props.uuid); groupTalkStore.openUpdateEmployeeAckStatusModal(); }"
                                                style="margin-right: 15px; font-size: 24px; cursor: pointer;"></i>

                                            <span class="badge rounded-pill bg-success">
                                                Talk Completed
                                            </span>


                                        </div>
                                        <div v-else>

                                            <!-- <button @click="groupTalkStore.openMarkTalkAsCompleteModal" class="button-13">
                                                <b>Mark Talk As Complete</b>
                                            </button> -->

                                        </div>

                                    </div>

                                </div>

                                <div class="videoContent">


                                    <div v-if="groupTalkStore.thisVideoIsYoutube">

                                        <div class="yTvideoWrapper"
                                            v-html="groupTalkStore.postDetails.video_embed_code">
                                        </div>

                                    </div>

                                    <div v-else>



                                        <div class="normalVideoWrapper"
                                            v-html="groupTalkStore.postDetails.video_embed_code">
                                        </div>

                                    </div>






                                </div>




                            </div>


                        </div>




                    </div>





                </div>









            </div>






        </div>




        <div v-if="groupTalkStore.showMarkTalkAsCompleteModal" class="modal-overlay">

            <div class="modal-box" style="width: 700px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Team Members</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closeMarkTalkAsCompleteModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


                    <div class="teamList">


                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Present</th>

                                </tr>
                            </thead>


                            <tbody>

                                <tr v-for="(employee, index) in groupTalkStore.employeesCheck" :key="index">

                                    <td>
                                        {{ employee.name }}
                                    </td>


                                    <td @click="groupTalkStore.updatePresentStatus(employee)"
                                        style="display: flex; justify-content: center;">


                                        <div v-if="employee.checked" class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                checked>
                                        </div>

                                        <div v-else class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        </div>


                                    </td>

                                </tr>

                            </tbody>




                        </table>



                    </div>


                    <button @click="groupTalkStore.markGroupTalkAsComplete(props.uuid)" type="button"
                        class="btn btn-dark" style="width: 100%;">
                        <b>Mark Talk As Complete</b>
                    </button>



                </div>
            </div>
        </div>


        <div v-if="groupTalkStore.showUpdateEmployeeAckStatusModal" class="modal-overlay">

            <div class="modal-box" style="width: 700px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Team Members</b>
                    </div>

                    <div>
                        <i @click="groupTalkStore.closeUpdateEmployeeAckStatusModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">


                    <div class="teamList">


                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Present</th>

                                </tr>
                            </thead>


                            <tbody>

                                <tr v-for="(employee, index) in groupTalkStore.updateEmployeesCheck" :key="index">

                                    <td>
                                        {{ employee.name }}
                                    </td>


                                    <td @click="groupTalkStore.updatePresentStatus(employee)"
                                        style="display: flex; justify-content: center;">


                                        <div v-if="employee.checked" class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                checked>
                                        </div>

                                        <div v-else class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        </div>


                                    </td>

                                </tr>

                            </tbody>




                        </table>



                    </div>


                    <button @click="groupTalkStore.updateAcknowledgementStatus(props.uuid)" type="button"
                        class="btn btn-dark" style="width: 100%;">
                        <b>Update</b>
                    </button>



                </div>
            </div>
        </div>







    </div>
</template>

<script setup>

import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { defineProps, onMounted } from 'vue';
import { useGroupTalkStore } from '@/stores/GroupTalkStore';
import VuePdfEmbed from 'vue-pdf-embed'

const groupTalkStore = useGroupTalkStore();




const props = defineProps({
    uuid: String
})






onMounted(() => {

    groupTalkStore.fetchPost(props.uuid)

})








</script>



<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.rightContentWrapper {

    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
}


.theHeaderWrapper {
    display: flex;
    margin-left: 20px;
    align-items: center;
    width: 100%;
    /* text-align: left; */
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}


.backBtnBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* background-color: aquamarine; */

}


.editContentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.theContent {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 60px;
    /* background-color: antiquewhite; */
}


.textEditorBox {
    width: 850px;
    /* height: 700px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}


.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}


.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
}

.aiBtn {
    margin-right: 10px;
    /* float: right; */
}

.textEditorWrapper {
    width: 100%;

    /* width: 100%; */
    /* background-color: red; */
}


.btnBox {
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(79, 76, 76);
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    border-bottom: 2px solid rgb(79, 76, 76);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76);
    outline: none;
    min-height: 400px;
    text-align: left;
    max-height: 600px;
    overflow-y: auto;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}



.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}



.pdfOuterWrapper {
    display: flex;
    justify-content: center;
}

.pdfBox {
    min-width: 700px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* background-color: #d9e7ff; */
}

.pdfTitleWrapper {
    padding: 10px;
    width: 100%;
    /* background-color: #adbbd4; */
}

.pdfActionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pdfTopWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    padding: 10px;
}

.theUploadBox {
    display: flex;
}

.pdfContent {
    text-align: left;
    font-size: 17px;
    width: 100%;
    height: auto;
    overflow-y: auto;
}

.videoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
}

.videoOuterBox {
    display: flex;
    justify-content: center;
}



.videoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.videoContent {
    width: 100%;
    /* background-color: #d9e7ff; */
    /* height: 400px; */
}

.videoTitleWrapper {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    /* background-color: aqua; */
}

.videoRenderedIframe {
    height: 395px;
    overflow: hidden;
    position: relative;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;

}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}


.embedBox {
    display: flex;
    flex-direction: column;
}


.talkBox {
    width: 900px;

    /* background-color: antiquewhite; */
}


.textBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.videoBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.textContent {
    padding: 20px;
    text-align: left;
    font-size: 17px;

}

.headerBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #e9faf3;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
    text-align: left;
    font-size: 19px;
    font-weight: bold;
}




/* Adjust the height based on the viewport width */
@media (max-width: 1230px) {
    .pdfContent {
        height: 400px;
        /* Example height for smaller screens */
    }
}

/* Larger screens, can have a larger fixed height or remain auto */
@media (min-width: 1231px) {
    .pdfContent {
        height: 700px;
        /* Example height for larger screens */
    }
}

::v-deep .htmlHtml img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 15px;
    margin-top: 15px;
}

/* Media query for larger screens */
@media (min-width: 1231px) {
    ::v-deep .htmlHtml img {
        max-width: 500px;
    }
}


.normalVideoWrapper {
    width: 100%;
    height: 100%;
}

.yTvideoWrapper {
    width: 100%;
    height: 500px;
}


/* When the view port min width is 500 change height to 500px */
@media (max-width: 500px) {

    .yTvideoWrapper {
        height: 300px;

    }

}

.talkDone {
    display: flex;
    align-items: center;
    /* background-color: greenyellow; */

}


.teamList {
    height: 500px;
    background-color: rgb(247, 247, 247);
    overflow-y: auto;
    padding: 5px;
    border-radius: 5px;
}


.form-check-input:checked {
    cursor: pointer;
    background-color: #198754;
    /* Bootstrap's green color */
    border-color: #198754;
    /* Bootstrap's green color */
}

.form-check-input:focus {
    cursor: pointer;
    border-color: #198754;
    /* Bootstrap's green color */
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
    /* A lighter green shadow */
}
</style>