/* eslint-disable */
import AuthService from "@/services/AuthService";
import SOPAppService from "@/services/SOPAppService";
import moment from "moment";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";


export const useSOPAppStore = defineStore("SOPAppStore", {

  state: () => ({



    currentUser: {},

    sopFiles: [],

    rawFile: null,

    showNewDocumentModal: false,
    showNewFolderModal: false,
    showPrintContentModal: false,
    showEditFileNameModal: false,
    showDeleteFileModal: false,
    showAIPromptModal: false,

    showNewSOPTeamModal: false,
    showUpdateSOPTeamModal: false,
    showDeleteSOPTeamModal: false,

    sopFormData: {
      title: '',
      content: '<p>Type content here...</p>'
    },

    isLoading: false,

    currentFile: {},
    fileDetails: {},

    chatMessages: [],

    newMessageFormData: {
      sender: 'user',
      message: ''
    },

    newAIResponseFormData: {
      sender: 'ai',
      message: ''
    },

    pageNumbers: [],

    newTeamFormData: {
      name: '',
    },

    sopTeams: [],
    currentSOPTeam: {},

    showNewTeamModalWithOptions: false,

    newTeamOptions: [
      {
        id: 1, name: 'Copy An Existing Team', type: 'copy_team'
      },
      {
        id: 2, name: 'Invite New Users', type: 'invite_new_users'
      }
    ],

    currentNewTeamOption: 'copy_team',

    allTeams: [],

    copyTeamFormData: {
      team_id: null,
      name: null,
      app: null
    },

    showPreviewTeamModal: false,

    currentUserSOPTeam: {},

    currentTeamList: [],

    teams: [],

    newFileFormData: {
      team_id: null
    },

    showPreviewPDFModal: false,

    selectedTeams: [],

    showEditTeamAccessModal: false,


  }),


  getters: {

    buildInviteLink: (state) => (team_id) => {

      // var url = "http://localhost:3000/employee/register?" + 'j6wsywtmcYdssni=' + state.currentUser.id + '&' + 'u9bdnfidsnwwn=' + team_id + '&' + 'app=' + 'sop';

      var url = "https://mysafelyio.app/employee/register?" + 'j6wsywtmcYdssni=' + state.currentUser.id + '&' + 'u9bdnfidsnwwn=' + team_id + '&' + 'app=' + 'sop';

      return url;
    }

  },


  actions: {



    fetchAllSOPFiles() {

      SOPAppService.getAllSOPFiles()
        .then((response) => {

          // console.log(response.data)

          this.sopFiles = response.data.files

          //add is_selected to each object
          let teamsArray = response.data.teams

          teamsArray.forEach((team) => {
            // Add is_selected property set to false for each employee object
            team.is_selected = false;
          });

          this.teams = teamsArray

          // this.teams = response.data.teams 

        }).catch((err) => {

          console.log(err)

        });

    },


    // getFileDetails(uuid){ 

    //   this.fileDetails = {}

    //   SOPAppService.getThisFileDetails(uuid)
    //     .then((response) => {

    //       console.log(response.data)
    //       this.fileDetails = response.data.file

    //     }).catch((err) => {
    //       console.log(err)
    //     });

    // },


    openNewDocumentModal() {
      this.showNewDocumentModal = true
    },

    closeNewDocumentModal() {
      this.showNewDocumentModal = false
    },

    openNewFolderModal() {
      this.showNewFolderModal = true
    },

    closeNewFolderModal() {
      this.showNewFolderModal = false
    },



    fileOnChange(event) {

      // console.log(event)

    },

    goToDocument(file) {

      if (file.is_vector_processed === 'no') {
        alert('File is still processing')

        return
      }

      this.router.push({ name: 'ViewADocumentLive', params: { uuid: file.uuid } });

    },

    goToViewAllDocuments() {

      this.router.push({ name: 'ViewAllSOPFiles' });

    },


    openPrintContentRenderedModal() {
      this.showPrintContentModal = true
    },

    closePrintContentRenderedModal() {
      this.showPrintContentModal = false
    },


    downloadThisPDF(htmlContent) {
      const formData = {
        content: htmlContent
      };

      // console.log(formData);

      SOPAppService.generatePDF(formData)
        .then((response) => {
          // Create a new Blob object using the response data of type 'application/pdf'
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Create a URL for the blob
          const downloadUrl = window.URL.createObjectURL(blob);

          // Create a temporary link element and trigger the download
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "SOP.pdf"; // The filename for download
          document.body.appendChild(a); // Append the link to the document
          a.click(); // Programmatically click the link to trigger the download

          // Clean up by removing the link element and revoking the Blob URL
          document.body.removeChild(a);
          window.URL.revokeObjectURL(downloadUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    },



    handleImageUploadToServer(event) {

      return new Promise((resolve, reject) => {
        const file = event.target.files[0];

        if (file.size > 1024 * 1024) {
          // 1MB in bytes
          alert("File size should not exceed 1MB");
          reject("File size too large");
          return;
        }

        const formData = new FormData();
        formData.append("image", file);

        SOPAppService.uploadTextEditorImage(formData)
          .then((response) => {
            resolve(response.data); // Resolve the promise with the image URL
          })
          .catch((error) => {
            console.error(error);
            reject(error); // Reject the promise on error
          });
      });
    },


    exitEditor() {
      if (
        confirm(
          "You're exiting the editor, please make sure to save your changes before leaving!"
        )
      ) {

        this.router.push({
          name: "ViewAllSOPFiles",
        });



        // console.log('exit editor')
      }
    },


    formatDate(date) {

      return moment(date).format("MMM Do YYYY");

    },


    setFiletoUpload(event) {

      this.rawFile = event.target.files[0];


    },

    uploadThisFile() {

      this.isLoading = true

      // Must select at least 1 team
      if (this.selectedTeams.length === 0) {
        alert("Must select at least 1 team");
        this.isLoading = false
        return;
      }

      const formData = {
        file: this.rawFile,
        teams: this.selectedTeams
      }

      // console.log(formData)

      SOPAppService.uploadSOPFile(formData) 
        .then((response) => {

          this.fetchAllSOPFiles()

          this.isLoading = false
          this.closeNewDocumentModal()

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });





    },


    async testLargeIploadThisFile() {

      const formData = {
        file: this.rawFile
      }

      // Example chunk size: 1MB. Adjust based on your needs.
      const CHUNK_SIZE = 1024 * 1024 * 2;
      const fileSize = this.rawFile.size;
      const numChunks = Math.ceil(fileSize / CHUNK_SIZE);


      for (let index = 0; index < numChunks; index++) {

        const begin = index * CHUNK_SIZE;
        const end = Math.min(begin + CHUNK_SIZE, fileSize);
        const chunk = this.rawFile.slice(begin, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkNumber', index + 1);
        formData.append('totalChunks', numChunks);
        formData.append('fileName', this.rawFile.name);


        try {

          //make API call to upload chunk to digital ocean spaces on my Laravel backend api
          console.log(`Chunk ${index + 1} of ${numChunks} uploaded.`);

        } catch (error) {

          console.error(`Error uploading chunk ${index + 1}:`, error);
          // Handle retry logic here if necessary
          break; // Break or retry based on your error handling policy

        }


      }










    },

    testHandleFileSelect(event) {

      this.rawFile = event.target.files[0];

    },

    limitText(body) {
      if (body.length > 70) {
        return body.substring(0, 70) + "...";
      }

      return body;
    },

    limitText2(body) {
      if (body.length > 15) {
        return body.substring(0, 15) + "...";
      }

      return body;
    },



    openEditFileNameModal() {
      this.showEditFileNameModal = true
    },

    closeEditFileNameModal() {
      this.showEditFileNameModal = false
    },

    setCurrentFile(file) {
      this.currentFile = file
    },


    renameFile() {

      const formData = {
        file_name: this.currentFile.file_name
      }

      SOPAppService.updateFileName(formData, this.currentFile.random_string)
        .then((response) => {

          // console.log(response.data)

          this.fetchAllSOPFiles()
          this.closeEditFileNameModal()

        }).catch((err) => {

          console.log(err)

        });

    },


    deleteThisFile() {

      this.isLoading = true

      SOPAppService.deleteFile(this.currentFile.random_string)
        .then((response) => {

          // console.log(response.data)


          this.closeDeleteFileModal()
          this.fetchAllSOPFiles()

          this.isLoading = false


        }).catch((err) => {

          console.log(err)

        });


    },


    openDeleteFileModal() {
      this.showDeleteFileModal = true
    },

    closeDeleteFileModal() {
      this.showDeleteFileModal = false
    },



    createNewTextContent() {

      SOPAppService.createNewTextContentFile()
        .then((response) => {

          let newFile = response.data.file

          this.router.push({ name: 'ViewADocument', params: { uuid: newFile.uuid } });

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    updateTextContentChanges() {

      SOPAppService.updateTextEditorContent(this.fileDetails.uuid, this.fileDetails)
        .then((response) => {


          toast("Changes saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // console.log(response.data, 'YES')

        }).catch((err) => {

          console.log(err)

        });

    },


    togglePublishStatus(value) {


      this.fileDetails.is_published = value

      const formData = {
        is_published: this.fileDetails.is_published
      }

      SOPAppService.togglePublish(this.fileDetails.uuid, formData)
        .then((response) => {

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });



    },



    openAIPromptModal() {
      this.showAIPromptModal = true
    },

    closeAIPromptModal() {
      this.showAIPromptModal = false
    },


    userSendMessageAndGetAIResponse() {

      this.isLoading = true

      let newMessage = {
        sender: 'user',
        message: this.newMessageFormData.message
      }

      let newAIResponseMessage = {
        sender: 'ai',
        message: null
      }


      this.chatMessages.push(newMessage)
      this.chatMessages.push(newAIResponseMessage)


      SOPAppService.aiGenerateResponse(newMessage, this.fileDetails.uuid)
        .then((response) => {


          const lastIndex = this.chatMessages.length - 1;

          if (lastIndex >= 0) {

            this.chatMessages[lastIndex].message = response.data.ai_response

          }


          this.isLoading = false;
          this.newMessageFormData.message = ""

          // console.log(response.data.ai_response)

        }).catch((err) => {

          console.log(err)

        });



    },


    deleteThisChatHistory() {


      if (
        confirm(
          "Are you sure you want to delete this chat history?"
        )
      ) {


        SOPAppService.deleteChatHistory(this.fileDetails.uuid)
          .then((response) => {

            this.chatMessages = []

            // console.log(response.data)



          }).catch((err) => {

            console.log(err)

          });


      }




    },



    userSendDocChatMessageAndGetAIResponse() {

      this.isLoading = true

      let newMessage = {
        sender: 'user',
        message: this.newMessageFormData.message,

      }

      let newAIResponseMessage = {
        sender: 'ai',
        message: null,
        source_page_numbers: []
      }



      this.chatMessages.push(newMessage)
      this.chatMessages.push(newAIResponseMessage)


      SOPAppService.aiGenerateResponseForDoc(newMessage, this.fileDetails.uuid)
        .then((response) => {


          const lastIndex = this.chatMessages.length - 1;

          if (lastIndex >= 0) {

            this.chatMessages[lastIndex].message = response.data.ai_response
            this.chatMessages[lastIndex].source_page_numbers = response.data.pageNumbers

          }


          this.isLoading = false;
          this.newMessageFormData.message = ""

          // console.log(response.data.ai_response)

        }).catch((err) => {

          console.log(err)

        });



    },


    exitChatWindow() {
      if (
        confirm(
          "Are you sure you want exit this chat window?"
        )
      ) {

        this.router.push({
          name: "ViewAllSOPFiles",
        });


      }
    },


    employeeExitChatWindow() {
      if (
        confirm(
          "Are you sure you want exit this chat window?"
        )
      ) {

        this.router.push({
          name: "EmployeeViewAllSOPFiles",
        });


      }
    },


    opensNewSOPTeamModal() {
      this.showNewSOPTeamModal = true
    },

    closeNewSOPTeamModal() {
      this.showNewSOPTeamModal = false
    },

    openUpdateSOPTeamNameModal() {
      this.showUpdateSOPTeamModal = true
    },

    closeUpdateSOPTeamNameModal() {
      this.showUpdateSOPTeamModal = false
    },

    openDeleteSOPTeamModal() {
      this.showDeleteSOPTeamModal = true
    },

    closeDeleteSOPTeamModal() {
      this.showDeleteSOPTeamModal = false
    },


    storeNewTeam() {


      SOPAppService.storeNewSOPTeam(this.newTeamFormData)
        .then((response) => {

          this.fetchAllSOPTeams()
          this.closeShowNewTeamModalWithOptions()
          this.newTeamFormData.name = ""

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });



    },

    updateTeamName() {

      const formData = {
        name: this.currentSOPTeam.name
      }


      SOPAppService.updateSOPTeamName(formData, this.currentSOPTeam.uuid)
        .then((response) => {

          this.fetchAllSOPTeams()
          this.closeUpdateSOPTeamNameModal()
          this.currentSOPTeam = {}

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });



    },


    fetchAllSOPTeams() {

      SOPAppService.getAllSOPTeams()
        .then((response) => {

          this.sopTeams = response.data.teams
          // console.log(this.sopTeams)

        }).catch((err) => {

          console.log(err)

        });

    },

    setCurrentTeam(team) {
      this.currentSOPTeam = team
    },

    deleteThisSOPTeam() {

      this.isLoading = true

      SOPAppService.deleteSOPTeam(this.currentSOPTeam.uuid)
        .then((response) => {

          this.fetchAllSOPTeams()
          this.closeDeleteSOPTeamModal()

          this.isLoading = false

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    copyLink(index) {

      const linkInputField = document.getElementById('inviteLinkInputField' + index);

      linkInputField.select();

      document.execCommand('copy');

      alert('Link copied!');

    },

    fetchUser() {

      AuthService.getUser()
        .then((response) => {

          this.currentUser = response.data;


        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    openShowNewTeamModalWithOptions() {
      this.showNewTeamModalWithOptions = true
    },

    closeShowNewTeamModalWithOptions() {
      this.showNewTeamModalWithOptions = false
    },

    fetchAllTeamsFromAllApps() {
      SOPAppService.getAllTeamsFromAllApps()
        .then((response) => {

          this.allTeams = response.data.allTeams

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });
    },


    copyThisTeam() {

      this.isLoading = true

      //set app type for backend to copy from correct table
      this.allTeams.forEach((team) => {
        if (team.id === this.copyTeamFormData.team_id) {
          this.copyTeamFormData.app = team.app
        }
      })

      // console.log(this.copyTeamFormData)

      SOPAppService.copyTeamFromOtherAppsToSOPApp(this.copyTeamFormData)
        .then((response) => {

          this.isLoading = false

          this.fetchAllSOPTeams()

          this.closeShowNewTeamModalWithOptions()

          toast("Team copied successfully!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          this.copyTeamFormData.app = null
          this.copyTeamFormData.name = null
          this.copyTeamFormData.team_id = null

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },



    openShowPreviewTeamModal() {
      this.showPreviewTeamModal = true
    },

    closeShowPreviewTeamModal() {
      this.showPreviewTeamModal = false
    },

    setCurrentUserSOPTeam(team) {

      if (team.user_sop_teams) {

        // console.log(team.user_sop_teams)

      }




    },

    fetchATeam(uuid) {

      this.currentSOPTeam = {}
      this.currentTeamList = []

      SOPAppService.showSOPTeam(uuid)
        .then((response) => {

          this.currentSOPTeam = response.data.team
          let userTeamList = response.data.team.user_sop_teams


          userTeamList.forEach((userTeam) => {


            if (userTeam.user) {

              let user = userTeam.user

              this.currentTeamList.push(user)
            }
          })

          // console.log(this.currentTeamList)

        }).catch((err) => {

          console.log(err)

        });

    },


    openShowPreviewPDFModal() {
      this.showPreviewPDFModal = true
    },

    closeShowPreviewPDFModal() {
      this.showPreviewPDFModal = false
    },



    toggleSelection(team) {
      team.is_selected = !team.is_selected;
    },

    addrOrRemoveTeam(team) {


      //add to array else remove from array
      if (team.is_selected) {

        this.selectedTeams.push(team)

      } else {


        let teamIndexToRemove = this.selectedTeams.findIndex(
          (tm) => tm.id === team.id
        );


        if (teamIndexToRemove !== -1) {
          this.selectedTeams.splice(teamIndexToRemove, 1);
        }

      }



    }, 



    openShowEditTeamAccessModal(){
      this.showEditTeamAccessModal = true
    }, 

    closShowEditTeamAccessModal(){
      this.showEditTeamAccessModal = false
      this.teams = []
    },


    fetchAllFileTeamsSelectedOrNot(uuid){ 

      this.teams = []
      
      SOPAppService.getTeamAccessListForFile(uuid)
        .then((response) => {
          

          this.teams = response.data.allTeams

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });
    },


    updateATeamAccessToFile(team){

      this.toggleSelection(team)

      const formData = {
        team_id: team.id,
        sop_file_id: this.currentFile.id,
        is_selected: team.is_selected
      }

      // console.log(formData)

      SOPAppService.saveUpdatedTeamAccessList(formData)
        .then((response) => {

          toast("Changes saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });


    }


















  },


});
