<template>
    <div class="navBarWrapper">

        <div class="leftSide">

            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />

        </div>

        <div class="rightSide">

            <div class="usernameBox">

                <span>Hi, {{ authStore.currentUser.fname }}</span>

                <span  @click="authStore.logoutUser" class="logoutText">Logout</span>

            </div>


            <div class="profilePic">

                <img class="roundProfilePic" v-if="authStore.currentUser.profile_pic"
                    :src="authStore.currentUser.profile_pic" />

                <i v-else class="bi bi-person-circle" style="font-size: 30px; color: #959795;"></i>

                
                

            </div>


           



        </div>





    </div>
</template>

<script setup>

import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from "vue";

const authStore = useAuthStore();



onMounted(() => {

    authStore.getUser()

})

</script>

<style scoped>
.navBarWrapper {
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 27px;
    display: flex;
    /* Enables Flexbox */
    justify-content: space-between;
    /* Space between items */
    align-items: center;
    /* Align items vertically */
    border-bottom: 1px solid #D9D9D9;
    height: 90px;
}

.imgLogo {
    width: 110px;
}

.rightSide {
    display: flex;
    /* Enable Flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
}

.usernameBox {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 10px;
    /* background-color: grey; */

}
.profilePic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    flex-direction: column;

}

/* Additional specific styles for each class if needed */


.profilePic {
    font-size: 30px;
    color: #959795;
}


.roundProfilePic {
    border-radius: 60%; 
    border: 1.5px solid #c3d4cb; 
    object-fit: cover; /* Ensures the image covers the area without stretching */
    width: 35px; /* Set the width as required */
    height: 35px; /* Set the height as required */
}

.logoutText{
    cursor: pointer;
    font-size: 12px;
}

.logoutText:hover{ 
    color: green;
}



</style>