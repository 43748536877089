<template>
  <div>

    <div class="mainWrapper">
      <MainSideBarVue />

      <div class="rightContentWrapper">
        <div class="topNav">
          <TopBarVue />
        </div>

        <div class="innerContainer">

          <div class="theHeaderWrapper">

            <div class="titleArea">

              <div style="margin-right: 20px;">
                Group Talk Campaigns
              </div>

              <router-link class="button-13" role="button" :to="{
                        name: 'GroupTalkReports',
                      }">
                Reports
              </router-link> 

        
              
            </div>

            <button class="button-6" role="button" @click="groupTalkStore.openCreateGroupTalkModal">
              New Campaign +
            </button>

          </div>

          <div class="searchBarWrapper">
            <input v-if="groupTalkStore.groupTalks.length > 0" type="text" class="form-control theBar"
              placeholder="Search..." />
          </div>


          <div v-if="groupTalkStore.groupTalks.length > 0">

            <div class="tableWrapper">


              <table class="table table-hover my-custom-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <!-- <th scope="col">Team</th> -->
                    <th scope="col">Created</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
 


                <tbody>

                  <tr v-for="(groupTalk, index) in groupTalkStore.groupTalks" :key="index">

                    <td>
                      <router-link class="campName" :to="{
                        name: 'ViewAGroupTalk',
                        params: { uuid: groupTalk.uuid },
                      }">

                        <b>{{ groupTalk.name }}</b>
                      </router-link>
                      
                    </td>

                    <!-- <td>
                      {{ groupTalk.team ? groupTalk.team.name : 'N/A' }}
                    </td> -->

                    <td>

                      {{ groupTalkStore.formatDate(groupTalk.created_at) }}

                    </td>

                    <td>
                      <div class="iconsRow">


                        <router-link :to="{
                          name: 'ViewAGroupTalk',
                          params: { uuid: groupTalk.uuid },
                        }">

                          <i class="bi bi-chat-left-dots theActionIcon"></i>

                        </router-link>



                        <i @click="() => { groupTalkStore.setCurrentGroupTalk(groupTalk); groupTalkStore.openUpdateGroupTalkModal(); }"
                          class="bi bi-pencil theActionIcon"></i>

                        <i @click="groupTalkStore.setCurrentCampaignToDeleteThenOpenModal(groupTalk)"
                          class="bi bi-trash theActionIcon"></i>

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

          <div v-else class="emptypBox">

            No group talks created yet.

          </div>



        </div>
      </div>
    </div>



    <div v-if="groupTalkStore.showCreateNewGroupTalkModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">
        <div class="model-box-header">
          <div style="font-size: 17px">
            <b>New Group Talk Campaign</b>
          </div>

          <div>
            <i @click="groupTalkStore.closeCreateGroupTalkModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content">

          <form @submit.prevent="groupTalkStore.saveNewGroupTalk"> 

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Name</b></label>
              <input v-model="groupTalkStore.formData.name" type="text" class="form-control inputStyle"
                placeholder="Q1 Safety Group Talks" required />
            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Timezone</b></label>

              <select v-model="groupTalkStore.formData.timezone_id" class="form-select custom-select" required>
                <option value="" disabled>Choose a timezone</option>

                <option v-for="(timezone, index) in groupTalkStore.timezones" :key="index" :value="timezone.id">
                  {{ timezone.name }}
                </option>

              </select>


            </div>



            <div>
              <button type="submit" class="btn btn-dark createBTN">
                <b>Create</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div v-if="groupTalkStore.showUpdateGroupTalkModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">
        <div class="model-box-header">
          <div style="font-size: 17px">
            <b>Rename</b>
          </div>

          <div>
            <i @click="groupTalkStore.closeUpdateGroupTalkModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content">

          <form @submit.prevent="groupTalkStore.updateGroupTalk">

            <!-- <span class="badge bg-dark" style="font-size: 13px; float: left; margin-bottom: 10px;">
              {{ groupTalkStore.currentGroupTalk.team ? groupTalkStore.currentGroupTalk.team.name : '' }}</span> -->

        

            <div class="mb-3">
              <input v-model="groupTalkStore.formData.name" type="text" class="form-control inputStyle"
                placeholder="Q1 Safety Talks" required />
            </div>




            <div>
              <button type="submit" class="btn btn-dark createBTN">
                <b>Save</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div v-if="groupTalkStore.showDeleteGroupTalkModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">
        <div class="model-box-header">
          <div style="font-size: 17px">
            <b>Are you sure you want to delete this Group Talk?</b>
          </div>

          <div>
            <i @click="groupTalkStore.closeDeleteGroupTalkModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content">


          <!-- {{ groupTalkStore.currentGroupTalkCampaign }} -->


          <p style="font-size: 17px; text-align: left;">
            <b style="color: red;">NOTE:</b> Deleting <b>{{ groupTalkStore.currentGroupTalkCampaign.name }}</b> also deletes any
            safety talks related to it. You will not be able to recover this group talk campaign data .
          </p>

          <button @click="groupTalkStore.deleteThisGroupTalkCampaign" type="button" class="btn btn-danger" style="width: 100%;">
            <b>Yes, Delete!</b>
          </button>


        </div>
      </div>
    </div>



  </div>
</template>
  
<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted } from 'vue';
import { useGroupTalkStore } from "@/stores/GroupTalkStore";

const groupTalkStore = useGroupTalkStore();



onMounted(() => {

  groupTalkStore.fetchAllGroupTalks()

})




</script>
  
<style scoped>
.mainWrapper {
  /* background-color: aliceblue; */
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rightContentWrapper {
  /* overflow-y: auto; */
  width: 83%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  /* overflow-y: auto; */
  background-color: rgb(208, 28, 55);
  /* margin: 30px; */
  margin-top: 100px;
}

.rightColumns {
  /* display: flex;
    flex-direction: column; */
}

.topNav {
  background-color: blue;
}

.innerContainer {
  /* margin-top: 200px; */
  /* background-color: blueviolet; */
  overflow-y: auto;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.theHeaderWrapper {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  /* background-color: aqua; */
}

.searchBarWrapper {
  margin-top: 15px;
  width: 100%;
  padding-bottom: 20px;
  /* background-color: rgb(22, 166, 37); */
}

.theBar {
  width: 350px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.button-6 {
  align-items: center;
  /* background-color: #FFFFFF; */
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.tableWrapper {
  margin-top: 20px;
  /* background-color: aqua; */
  width: 100%;
}

.my-custom-table tbody tr td {
  padding-top: 20px;
  /* Adjust the value as needed */
  padding-bottom: 20px;
  /* Adjust the value as needed */
}

.my-custom-table th {
  padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
  text-align: left;
  /* Aligns text to the left */
  padding-left: 20px;
  padding-right: 20px;
}

.campName {
  text-decoration: none;
  color: inherit;
}

.campName:hover {
  color: rgb(10, 159, 92);
  cursor: pointer;
  text-decoration: none;
}

.iconsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.theActionIcon {
  font-weight: bolder;
  font-size: 18px;
  color: rgb(5, 2, 2);
  cursor: pointer;
}

.theActionIcon:hover {
  cursor: pointer;
  color: rgb(30, 158, 30);
  scale: 1.3;
  transition: 1s;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  z-index: 999;
  /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
  background-color: white;
  width: 1100px;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
  text-align: center;
}

.model-box-header {
  display: flex;
  justify-content: space-between;
  /* background-color: antiquewhite; */
  height: 40px;
  margin-bottom: 15px;
}

.form-control {
  background-color: rgb(243, 246, 246);
}

.createBTN {
  width: 100%;
  margin-top: 20px;
}

.emptypBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}


.campName {
  text-decoration: none;
  color: inherit;
}

.campName:hover {
  color: rgb(10, 159, 92);
  cursor: pointer;
  text-decoration: none;
}

.titleArea{
  display: flex;
  align-items: center;
  /* background-color: gray; */

}

.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
}

.button-13:hover {
  background-color: #f7fafa;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
}

</style>
  