import apiClient from "./MyClient";


export default{ 


    
    getAllSubManagers(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/all/manager/subadmins')  

    },

    archiveThisSubManager(userId){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/delete/subadmin/' + userId)

    },

    managerSendEmailInvite(formData){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/email/invite/subadmin', formData) 

    },





  
}