<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">




                    <div class="theHeaderWrapper">



                        <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                            class="bi bi-arrow-left"></i>

                        <div style="margin-left: 20px;">
                            Employees
                        </div>




                    </div>

                    <div class="searchBarWrapper">

                        <input v-model="searchQuery" type="text" class="form-control theBar"
                            placeholder="Search by employee name..." />

                        <div>

                        </div>


                    </div>




                    <div class="tableWrapper">

                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Teams</th>
                                    <th scope="col">Safety Talks</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr v-for="(employee, index) in filteredEmployees" :key="index">


                                    <td>
                                        {{ employee.name }}
                                        
                                        <div v-if="employee.role_id == 4">
                                            <span class="badge rounded-pill bg-success">
                                                Sub Manager
                                            </span>
                                        </div>

                                        <div v-if="employee.role_id == 5">

                        
                                            <span class="badge rounded-pill bg-light" style="color: black;">
                                                Master Account Admin
                                            </span>

                                        </div>

                                        <div v-if="employee.role_id == 6">

                        
                                            <span class="badge rounded-pill bg-light" style="color: black;">
                                                Supervisor 
                                            </span>

                                        </div>
                                    </td>

                                    <td>

                                       

                                        <div v-if="employee.role_id == 3">

                                            <span v-if="employee.user_teams" class="badge rounded-pill bg-success"
                                                style="font-size: 12px; cursor: pointer;"
                                                @click="() => { campaignStore.setCurrentUserTeams(employee.user_teams); campaignStore.openShowViewUserTeamsModal(); }">


                                                View

                                            </span>

                                        </div>




                                    </td>

                                    <td>

                                        <router-link v-if="employee.role_id == 3" :to="{
                            name: 'ViewTalksForAEmployee',
                            params: { uuid: employee.uuid },
                        }">

                                            <span v-if="employee.acknowledgements" class="badge rounded-pill bg-dark"
                                                style="font-size: 14px;">
                                                {{ employee.acknowledgements.length }}
                                            </span>

                                        </router-link>

                                    </td>

                                    <td>


                                        <div>

                                            <i @click="() => { teamStore.setCurrentEmployee(employee); teamStore.openShowDeleteEmployeeModal(); }"
                                                style="cursor: pointer;" class="bi bi-trash"></i>

                                        </div>



                                    </td>



                                </tr>

                            </tbody>




                        </table>






                    </div>



                    <button v-if="displayedEmployeesCount < campaignStore.employees.length"
                        class="btn btn-dark loadMoreBtn" @click="loadMoreEmp">Load more</button>


                </div>
            </div>
        </div>



        <div v-if="teamStore.showDeleteEmployeeModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to delete this employee?</b>
                    </div>

                    <div>
                        <i @click="teamStore.closeShowDeleteEmployeeModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">




                    <p><b style="color: red;">Very Important Note:</b>
                        It's highly recommeded to download all safety talks data related to <b>{{
                            teamStore.currentEmployee.name
                        }}</b> before deleting. Once deleted this user will removed from all teams its associated
                        with
                        and lose access to <b>all apps</b> on the Safelyio platform. This data is unrecoverable.
                    </p>




                    <div>
                        <button @click="softDeleteThisEmployeeCompletely()" type="button"
                            class="btn btn-danger createBTN">
                            <b>Yes, Delete!</b>
                        </button>
                    </div>



                </div>
            </div>
        </div>

        <div v-if="campaignStore.showViewUserTeamsModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Teams</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeShowViewUserTeamsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <div v-if="campaignStore.currentUserTeams"
                        style="width: 100%; max-height: 500px; overflow-y: auto;">

                        <table class="table">

                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Team Creation Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(team, index) in campaignStore.currentUserTeams" :key="index">

                                    <td>{{ team.name }}</td>
                                    <td>{{ campaignStore.formatDate(team.created_at) }}</td>
                                </tr>

                            </tbody>
                        </table>


                    </div>




                </div>
            </div>
        </div>


    </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import TeamService from "@/services/TeamService";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useTeamStore } from "@/stores/TeamStore";
import { onMounted, ref, computed } from 'vue';


const campaignStore = useCampaignStore();
const teamStore = useTeamStore();

const searchQuery = ref('');

const displayedEmployeesCount = ref(25); // Start with displaying 5 teams
const incrementStep = 15; //


const filteredEmployees = computed(() => {

    return campaignStore.employees
        .filter(emp => emp.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
        .slice(0, displayedEmployeesCount.value);

});

const loadMoreEmp = () => {
    displayedEmployeesCount.value += incrementStep;
};


const softDeleteThisEmployeeCompletely = () => {

    const formData = {
        user_id: teamStore.currentEmployee.id
    }

    TeamService.deleteEmployeeCompletelyAcrossAllApps(formData)
        .then((response) => {

            campaignStore.fetchAllEmployees()
            teamStore.closeShowDeleteEmployeeModal()
            console.log(response.data)

        }).catch((err) => {

            console.log(err)

        });

    console.log('completely')


}


onMounted(() => {

    campaignStore.fetchAllEmployees()

})



</script>

<style scoped>
 .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     align-items: center;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
 }

 .searchBarWrapper {
     display: flex;
     justify-content: space-between;
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 350px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }

 .button-6 {
     align-items: center;
     /* background-color: #FFFFFF; */
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     border: 1px solid rgba(0, 0, 0, 0.1);
     border-radius: 0.25rem;
     box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
     box-sizing: border-box;
     color: rgba(0, 0, 0, 0.85);
     cursor: pointer;
     display: inline-flex;
     font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
         Arial, sans-serif;
     font-size: 16px;
     font-weight: 600;
     justify-content: center;
     line-height: 1.25;
     margin: 0;
     min-height: 3rem;
     padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
     position: relative;
     text-decoration: none;
     transition: all 250ms;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: baseline;
     width: auto;
 }

 .button-6:hover,
 .button-6:focus {
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
     color: rgba(0, 0, 0, 0.65);
 }

 .button-6:hover {
     transform: translateY(-1px);
 }

 .button-6:active {
     background-color: #f0f0f1;
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
     color: rgba(0, 0, 0, 0.65);
     transform: translateY(0);
 }

 .tableWrapper {
     margin-top: 20px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
 }

 .button-13 {
     background-color: #fff;
     border: 1px solid #d5d9d9;
     border-radius: 8px;
     box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
     box-sizing: border-box;
     color: #0f1111;
     cursor: pointer;
     display: inline-block;
     font-family: "Amazon Ember", sans-serif;
     font-size: 13px;
     line-height: 29px;
     padding: 0 10px 0 11px;
     position: relative;
     text-align: center;
     text-decoration: none;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: middle;
     min-width: 100px;
 }

 .button-13:hover {
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
 }
</style>