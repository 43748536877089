<template>
    <div>
        <div class="mainWrapper">
            <MainSideBar />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>


                <div class="theHeaderWrapper">

                    <div class="backWrapper">

                        <i @click="templateStore.goBackToViewAllAssets" class="bi bi-arrow-left"
                            style="font-size: 20px; cursor: pointer;"></i>

                        <div style="margin-left: 15px;">Asset Name</div>

                    </div>



                    <button class="button-6" role="button">
                        Start Inspection +
                    </button>
                </div>


                <div class="innerContainer">



                    <div class="editBtnWrapper">

                 
                        <button v-if="!templateStore.showEditAssetForm" class="btn btn-dark" @click="templateStore.openEditAssetForm">
                            <i style="font-size: 12px; margin-right: 6px;" class="bi bi-pencil"></i>
                            Edit
                        </button>

                    </div>





                    <div class="formbox" v-if="templateStore.showEditAssetForm">

                        <form style="text-align: left;" @submit.prevent="templateStore.saveAssetFormChanges">


                           
                            <div class="formBody">




                                <div class="mb-3">
                                    <label class="form-label"><b>Asset Name</b></label>
                                    <input v-model="templateStore.asset.name" type="text"
                                        class="form-control theInputStyle" required>
                                </div>


                                <div class="mb-4">

                                    <label class="form-label"><b>Site/Location</b></label>


                                    <select v-model="templateStore.asset.location_id" class="form-select theInputStyle"
                                        required>


                                        <option v-for="(site, index) in templateStore.sites" :key="index"
                                            :value="site.id">
                                            {{ site.name }}
                                        </option>

                                    </select>

                                </div>


                                <div class="mb-3">
                                    <label class="form-label"><b>Description</b></label>
                                    <input v-model="templateStore.asset.desc" type="text"
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Model</b></label>
                                    <input v-model="templateStore.asset.model" type="text"
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Serial Number</b></label>
                                    <input type="text" v-model="templateStore.asset.serial_number"
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Purchased Date</b></label>
                                    <input type="date" v-model="templateStore.asset.purchased_date"
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Files</b></label>

                                    <div v-if="templateStore.isAssetLoading" style="display: flex; justify-content: center; margin-bottom: 14px;">
                                        
                                        <div class="spinner-grow" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>


                                    <div v-else class="assetFilesInputBox" @click="templateStore.editFormtriggerInput">

                                        Add files (Images, Videos or PDFs)

                                    </div>


                                    <div class="filesWrapper" v-if="templateStore.asset.asset_files.length > 0">


                                        <div class="fileItem" v-for="(file, index) in templateStore.asset.asset_files"
                                            :key="index">

                                            <div class="imgBox">


                                                <i v-if="file.type === 'image'" class="bi bi-image"></i>

                                                <i v-if="file.type === 'pdf'"
                                                    class="bi bi-file-earmark-pdf-fill"></i>


                                                <i v-if="file.type === 'video'" class="bi bi-play-btn"></i>

                                            </div>

                                            <div class="fileNameBox">
                                                {{ templateStore.trimLongString(file.file_name) }}
                                            </div>


                                            <div class="deleteFileBox">

                                                <i class="bi bi-trash" style="font-size: 15px; cursor: pointer;"
                                                    @click="templateStore.editFormDeleteAssetFromArray(index)"></i>

                                            </div>



                                        </div> 

                                    </div>


                                  

                                    <button style="width: 100%; margin-top: 22px;" class="btn btn-success"
                                    type="submit">

                                        <b>Save</b> 
                                        
                                    </button>


                                </div>








                            </div>



                        </form>




                    </div>

                    <div v-else class="formbox">

                        <form style="text-align: left;">



                            <div class="formBody">




                                <div class="mb-3">
                                    <label class="form-label"><b>Asset Name</b></label>
                                    <input v-model="templateStore.asset.name" type="text"
                                        class="form-control theInputStyle" readonly required>
                                </div>


                                <div class="mb-4">

                                    <label class="form-label"><b>Site/Location</b></label>


                                    <select v-model="templateStore.asset.location_id" class="form-select theInputStyle"
                                        disabled required>


                                        <option v-for="(site, index) in templateStore.sites" :key="index"
                                            :value="site.id">
                                            {{ site.name }}
                                        </option>

                                    </select>

                                </div>


                                <div v-if="templateStore.imgsArray.length > 0" style="margin-bottom: 20px;">

                                    <label class="form-label"><b>Images</b></label>

                                    <div class="imgFileBox">


                                        <div class="theImgBox" v-for="(imgFile, index) in templateStore.imgsArray"
                                            :key="index"
                                            @click="() => { templateStore.setCurrentAssetMedialFile(imgFile); templateStore.openImagePreviewModal(); }">

                                            <img class="theImage" :src="imgFile.file_url">

                                        </div>


                                    </div>


                                </div>

                                <div v-if="templateStore.videosArray.length > 0" style="margin-bottom: 20px;">

                                    <label class="form-label"><b>Videos</b></label>

                                    <div class="theVideosWrapper">


                                        <div class="theVideoItem"
                                            v-for="(videoFile, index) in templateStore.videosArray" :key="index"
                                            @click="templateStore.downloadVideoFile(videoFile)">

                                            <div>
                                                <i class="bi bi-play-btn"></i>
                                            </div>

                                            <div class="videoName">
                                                <span>{{ templateStore.trimFileNameString(videoFile.file_name) }}</span>
                                            </div>


                                        </div>


                                    </div>


                                </div>

                                <div v-if="templateStore.pdfsArray.length > 0" style="margin-bottom: 20px;">
                                    <label class="form-label"><b>PDFs</b></label>


                                    <div class="thePDFsWrapper">

                                        <div class="thePDFItem" v-for="(pdfFile, index) in templateStore.pdfsArray"
                                            :key="index" @click="templateStore.attachmentDownloadPDFfile(pdfFile)">



                                            <div>
                                                <i class="bi bi-file-earmark-pdf-fill" style="font-size: 18px;"></i>
                                            </div>

                                            <div class="pdfName">
                                                {{ templateStore.trimFileNameString(pdfFile.file_name) }}
                                                
                                            </div>
                                        </div>

                                    </div>



                                </div>




                                <div class="mb-3">
                                    <label class="form-label"><b>Description</b></label>
                                    <input v-model="templateStore.asset.desc" readonly type="text"
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Model</b></label>
                                    <input v-model="templateStore.asset.model" readonly type="text"
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Serial Number</b></label>
                                    <input type="text" v-model="templateStore.asset.serial_number" readonly
                                        class="form-control theInputStyle">
                                </div>

                                <div class="mb-3">
                                    <label class="form-label"><b>Purchased Date</b></label>
                                    <input type="date" v-model="templateStore.asset.purchased_date" readonly
                                        class="form-control theInputStyle">
                                </div>









                            </div>



                        </form>




                    </div>




                </div>
            </div>
        </div>




        <div v-if="templateStore.showImagePreviewModal" class="modal-overlay">





            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">

                    <div v-if="templateStore.currentAssetMediaFile" style="font-size: 17px">
                        <b>{{ templateStore.trimLongString(templateStore.currentAssetMediaFile.file_name ) }}</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeImagePreviewModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">




                    <div v-if="templateStore.currentAssetMediaFile">

                        <img :src="templateStore.currentAssetMediaFile.file_url" style="width: 100%;">

                    </div>


                </div>
            </div>




        </div>







    </div>
</template>

<script setup>
import MainSideBar from "@/components/Inspections/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, defineProps } from 'vue';
import { useTemplateStore } from "@/stores/TemplateStore";

const templateStore = useTemplateStore();


const props = defineProps({
    uuid: String
})


onMounted(() => {

    templateStore.fetchAAsset(props.uuid)


})



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #f0eeee;
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
}

.inviteInput {
    width: 500px;
    font-size: 13px;
    cursor: pointer;
}

.tabsWrapper {
    display: flex;
    width: 100%;
    /* background-color: #a9baba; */
}


.tabItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}

.tabItem:hover {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 30px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
}



.iconDrop {
    cursor: pointer;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.emptyTableBox {
    margin-top: 100px;
}

.formBody {
    width: 100%;
    /* background-color: rgb(224, 200, 200); */
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.formFooter {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    /* background-color: green; */
}


.assetFilesInputBox {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 13px;
    border-radius: 10px;
    border: 2px dotted rgb(66, 65, 65);
    cursor: pointer;
}


.filesWrapper {
    display: flex;
    width: 100%;
    /* background-color: gray; */
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
}


.fileItem {
    display: flex;
    align-items: center;
    flex-basis: calc(50% - 10px);
    border-radius: 5px;
    height: 50px;
    /* background-color: red; */
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 5px;
}

.imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    /* background-color: green; */
}

.fileNameBox {
    width: 70%;
    text-align: left;
    font-size: 12px;
    padding-left: 5px;
    /* background-color: purple; */
}

.deleteFileBox {
    width: 10%;
    text-align: left;
    font-size: 12px;
    padding-left: 5px;
    /* background-color: rgb(219, 169, 219); */
}

.theAssetImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;

}


.box1 {
    width: 100%;
    /* background-color: rgb(222, 231, 222); */
    margin-top: 20px;
    margin-bottom: 10px;


}

.formbox {
    max-width: 700px;
}

.imgFileBox,
.videoFileBox,
.pdfFileBox {
    width: 100%;
    /* background-color: rgb(182, 223, 182); */
    margin-bottom: 20px;

}


.imgFileBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.theImgBox {

    background-color: black;
    position: relative;
    width: 25%;
    padding-top: 25%;
    box-sizing: border-box;
    border: 1px solid white;
    cursor: pointer;


}


.theImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Media queries for responsiveness */
@media (max-width: 1200px) {
    .theImgBox {
        width: 33.33%;
        /* 3 per row on medium-large screens */
    }
}

@media (max-width: 992px) {
    .theImgBox {
        width: 50%;
        /* 2 per row on medium screens */
    }
}

@media (max-width: 768px) {
    .theImgBox {
        width: 100%;
        /* 1 per row on small screens */
    }
}


.theVideosWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.theVideoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(220, 226, 224);
    height: 100%;
    width: 25%;
    box-sizing: border-box;
    border: 1px solid white;
    padding: 20px;
    cursor: pointer;
}

.theVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.thePDFsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.thePDFItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(220, 226, 224);
    height: 100%;
    width: 25%;
    box-sizing: border-box;
    border: 1px solid white;
    padding: 20px;
    cursor: pointer;
}

.backWrapper {
    display: flex;
}

.pdfName {
    text-align: center;
    font-size: 12px;
    word-break: break-all;
}

.videoName {
    text-align: center;
    font-size: 12px;
    word-break: break-all;
}

.editBtnWrapper {
    display: flex;
    justify-content: flex-end;
    /* background-color: red; */
    /* margin-bottom: 20px; */
}
</style>