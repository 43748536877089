<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">
                        <div>Account</div>


                    </div>



                    <div class="accountWrapper">




                        <div class="accountBox">


                            <div class="myDetailsBox">


                                <div class="headerBox">

                                    Avatar


                                </div>


                                <form class="uploadBox" @submit.prevent="authStore.uploadProfileImage">


                                    <div class="thePic">



                                        <div class="mb-3">

                                            <input @change="authStore.previewImage" accept="image/*" class="form-control"
                                                type="file">
                                        </div>


                                        <div class="image-preview" v-if="authStore.imageData.length > 0">
                                            <img class="preview" :src="authStore.imageData" style="width: 100px;">
                                        </div>


                                        <button type="submit" v-if="authStore.imageData"
                                            class="btn btn-success mt-3"><b>Update Image</b></button>



                                    </div>







                                </form>


                            </div>


                            <div class="myDetailsBox">




                                <div class="headerBox">

                                    My Details


                                </div>


                                <form class="formBox" @submit.prevent="authStore.updateMyDetails">


                                    <div class="mb-3">
                                        <label class="form-label" style="float: left;">First Name</label>
                                        <input v-model="authStore.currentUser.fname" type="text"
                                            class="form-control theInput" required>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" style="float: left;">Last Name</label>
                                        <input v-model="authStore.currentUser.lname" type="text"
                                            class="form-control theInput" required>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" style="float: left;">Email</label>
                                        <input v-model="authStore.currentUser.email" type="email"
                                            class="form-control theInput" required>
                                    </div>




                                    <button type="submit" class="btn btn-dark"
                                        style="width: 100%; margin-top: 10px;"><b>Save
                                            Changes</b></button>



                                </form>


                            </div>


                            <div class="myDetailsBox">

                                <div v-if="authStore.errorMsg" role="alert" aria-live="polite" aria-atomic="true"
                                    class="alert alert-dismissible alert-danger">
                                    {{ authStore.errorMsg }}
                                </div>


                                <div class="headerBox">

                                    Update Password



                                </div>


                                <form class="formBox" @submit.prevent="authStore.updateMyPassword">


                                    <div class="mb-3">
                                        <label class="form-label" style="float: left;">Current Password</label>
                                        <input v-model="authStore.passwordData.current_password" type="password"
                                            class="form-control theInput" required>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" style="float: left;">New Password</label>
                                        <input v-model="authStore.passwordData.new_password" type="password"
                                            class="form-control theInput" required>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" style="float: left;">Confirm Password</label>
                                        <input v-model="authStore.passwordData.confirm_new_password" type="password"
                                            class="form-control theInput" required>
                                    </div>




                                    <button type="submit" class="btn btn-dark"
                                        style="width: 100%; margin-top: 10px;"><b>Change
                                            Password</b></button>



                                </form>


                            </div>

                        </div>




                    </div>








                </div>
            </div>
        </div>





    </div>
</template>

<script setup>

import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";

import { useAuthStore } from '@/stores/AuthStore';
import { onMounted } from "vue";


const authStore = useAuthStore();



onMounted(() => {

    authStore.getUser();

});



</script>

<style scoped> .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     justify-content: space-between;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
 }

 .searchBarWrapper {
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 350px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }

 .button-6 {
     align-items: center;
     /* background-color: #FFFFFF; */
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     border: 1px solid rgba(0, 0, 0, 0.1);
     border-radius: 0.25rem;
     box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
     box-sizing: border-box;
     color: rgba(0, 0, 0, 0.85);
     cursor: pointer;
     display: inline-flex;
     font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
         Arial, sans-serif;
     font-size: 16px;
     font-weight: 600;
     justify-content: center;
     line-height: 1.25;
     margin: 0;
     min-height: 3rem;
     padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
     position: relative;
     text-decoration: none;
     transition: all 250ms;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: baseline;
     width: auto;
 }

 .button-6:hover,
 .button-6:focus {
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
     color: rgba(0, 0, 0, 0.65);
 }

 .button-6:hover {
     transform: translateY(-1px);
 }

 .button-6:active {
     background-color: #f0f0f1;
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
     color: rgba(0, 0, 0, 0.65);
     transform: translateY(0);
 }

 .tableWrapper {
     margin-top: 20px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
 }

 .inviteInput {
     width: 500px;
     font-size: 13px;
     cursor: pointer;
 }
 
 
 

 
.accountWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.accountBox {
    width: 500px;

    /* background-color: antiquewhite; */
}

.myDetailsBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.headerBox {
    width: 100%;
    background-color: #e9faf3;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: left;
}

.theInput {

    width: 100%;

}

.formBox {
    padding: 10px;
    margin-bottom: 30px;
}

.uploadBox {

    padding: 30px;
    margin-bottom: 30px;

}

.thePic {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 
 
 </style>