import apiClient from "./MyClient";


export default{ 

    
    //manager register
    registerThisManager(formData){
        return apiClient.post('api/business/register', formData);
    },

     //employee register
    registerNewEmployee(formData){
        return apiClient.post('/api/employee/register', formData);
    },


    //sub admin register
    registerSubAdmin(formData){
        return apiClient.post('/api/subadmin/register', formData);
    },

    


    verifyThisAuthCode(formData){
        return apiClient.post('api/auth/code/verify', formData)
    },


    loginUser(formData){
        return apiClient.post('/api/auth/login', formData);
    },

    userForgotPasssword(formData){

        return apiClient.post('/api/forgot-password', formData)

    }, 


    userResetPassword(formData){

        return apiClient.post('/api/reset-password', formData)

    },


    getUser(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/user');
    },


    updateMyAccountDetails(data){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/account/update/user', data);

    },

   


    logout(){

        //this is the only endping that need to token
        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token
  
        return apiClient.post('api/auth/logout');
    }, 



    updatePassword(data){

        //this is the only endping that need to token
        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/account/update/password', data)

    },


    updateProfileAvatar(data){

          //this is the only endping that need to token
        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        //for file uploads IMPORTANT
        const config = {
            headers: {
            "content-type": "multipart/form-data",
            },
        };

        return apiClient.post('/api/account/update/profile/avatar', data, config)

    },


    //admin register
    registerSuperAdminUser(formData){
        return apiClient.post('/api/sakd0923p', formData);
    },



    
    getAllAppsForManagerAppsPage(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/managers/apps');
    },


    getAManagerAppsAccessStatus(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/manager/apps/status');
    },

    getASupervisorAppsAccessStatus(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/supervisor/apps/status');
    },

    getAEmployeeAppsAccessStatus(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/employee/apps/status');
    },

    checkAEmployeeAppsAccessStatus(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/employee/app/access/check');
    },

  
}