<template>
    <div class="mainWrapper">



        <div class="editorHeader">

            <div class="theRight">


                <div v-if="sopAppStore.fileDetails">


                    <i v-if="sopAppStore.fileDetails.file_type === 'text'" @click="sopAppStore.exitEditor"
                        class="bi bi-x-lg" style="font-size: 20px; cursor: pointer;"></i>

                </div>


                <div v-if="sopAppStore.fileDetails">

                    <div v-if="sopAppStore.fileDetails.file_type === 'image'">

                        <i @click="sopAppStore.goToViewAllDocuments" class="bi bi-arrow-left"
                            style="font-size: 21px; font-weight: bolder; cursor: pointer;"></i>

                    </div>

                    <div v-if="sopAppStore.fileDetails.file_type === 'pdf'">

                        <i @click="sopAppStore.goToViewAllDocuments" class="bi bi-arrow-left"
                            style="font-size: 21px; font-weight: bolder; cursor: pointer;"></i>

                    </div>

                    <div v-if="sopAppStore.fileDetails.file_type === 'video'">

                        <i @click="sopAppStore.goToViewAllDocuments" class="bi bi-arrow-left"
                            style="font-size: 21px; font-weight: bolder; cursor: pointer;"></i>

                    </div>

                </div>





            </div>

            <div class="theLeft">

                <div v-if="sopAppStore.fileDetails.file_type === 'text'">



                    <button v-if="sopAppStore.fileDetails.is_published === 1" @click="sopAppStore.togglePublishStatus(0)"
                        class="button-13" style="margin-right: 10px;">Unpublish</button>

                    <button v-if="sopAppStore.fileDetails.is_published === 0" @click="sopAppStore.togglePublishStatus(1)"
                        class="button-13" style="margin-right: 10px;">Publish Live</button>



                </div>


                <button class="button-13">Share</button>

            </div>


        </div>




        <div class="rightContentWrapper">


            <div v-if="sopAppStore.fileDetails.file_type === 'text'" class="aiTextWrapper">

                <div class="textWrapper">

                    <div class="textEditorBox">


                        <div class="textEditorHeader">
                            <div style="width: 50%">
                                <b style="float: left">Editor</b>
                            </div>

                            <div class="editorBtns">


                                <button role="button" class="button-13" @click="sopAppStore.updateTextContentChanges">Save
                                    Changes</button>

                            </div>
                        </div>

                        <div class="titleBox">
                            <input v-model="sopAppStore.fileDetails.title" type="text" class="form-control titleInput"
                                placeholder="SOP title..." />
                        </div>

                        <div class="contentInputBody">

                            <div class="textEditorWrapper">



                                <div v-if="editor">

                                    <div class="btnBox">


                                        <div class="btnItem" @click="editor.chain().focus().toggleBold().run()"
                                            :disabled="!editor.can().chain().focus().toggleBold().run()"
                                            :class="{ 'btnItemSelected': editor.isActive('bold') }">

                                            <i class="bi bi-type-bold"></i>

                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().toggleItalic().run()"
                                            :disabled="!editor.can().chain().focus().toggleItalic().run()"
                                            :class="{ 'btnItemSelected': editor.isActive('italic') }">

                                            <i class="bi bi-type-italic"></i>

                                        </div>


                                        <div class="btnItem" @click="editor.chain().focus().toggleUnderline().run()"
                                            :class="{ 'btnItemSelected': editor.isActive('underline') }">

                                            <i class="bi bi-type-underline"></i>

                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().setParagraph().run()"
                                            :class="{ 'btnItemSelected': editor.isActive('paragraph') }">

                                            <i class="bi bi-paragraph"></i>

                                        </div>



                                        <div class="btnItem"
                                            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                                            :class="{ 'btnItemSelected': editor.isActive('heading', { level: 3 }) }">

                                            <i class="bi bi-type-h3"></i>

                                        </div>

                                        <div class="btnItem"
                                            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                            :class="{ 'btnItemSelected': editor.isActive('heading', { level: 4 }) }">

                                            <i class="bi bi-type-h4"></i>

                                        </div>

                                        <div class="btnItem"
                                            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                                            :class="{ 'btnItemSelected': editor.isActive('heading', { level: 5 }) }">

                                            <i class="bi bi-type-h5"></i>

                                        </div>

                                        <div class="btnItem"
                                            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                                            :class="{ 'btnItemSelected': editor.isActive('heading', { level: 6 }) }">

                                            <i class="bi bi-type-h6"></i>

                                        </div>


                                        <div class="btnItem">

                                            <!-- <input class="colorWheel" type="color"
                                                @input="editor.chain().focus().setColor($event.target.value).run()"
                                                :value="editor.getAttributes('textStyle').color"
                                                > -->
                                            <input class="colorWheel" type="color"
                                                @input="editor.chain().focus().setColor($event.target.value).run()"
                                                :value="currentColor">


                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().setTextAlign('left').run()"
                                            :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'left' }) }">

                                            <i class="bi bi-text-left"></i>

                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().setTextAlign('center').run()"
                                            :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'center' }) }">

                                            <i class="bi bi-text-center"></i>

                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().setTextAlign('right').run()"
                                            :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'right' }) }">

                                            <i class="bi bi-text-right"></i>

                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().toggleBulletList().run()"
                                            :class="{ 'btnItemSelected': editor.isActive('bulletList') }">

                                            <i class="bi bi-list-task" style="font-size: 20px;"></i>

                                        </div>

                                        <div class="btnItem" @click="editor.chain().focus().toggleOrderedList().run()"
                                            :class="{ 'is-active': editor.isActive('orderedList') }">
                                            <i class="bi bi-list-ol" style="font-size: 20px;"></i>
                                        </div>


                                        <div class="btnItem" @click="editor.chain().focus().undo().run()">

                                            <i class="bi bi-arrow-counterclockwise" style="font-size: 20px;"></i>

                                        </div>


                                        <div class="btnItem" @click="editor.chain().focus().redo().run()">

                                            <i class="bi bi-arrow-clockwise" style="font-size: 20px;"></i>

                                        </div>



                                        <div class="btnItem" @click="triggerFileInput">

                                            <i class="bi bi-card-image"></i>

                                            <input type="file" id="fileInput" hidden @change="handleFileChange"
                                                accept="image/*">



                                        </div>

                                        <div class="btnItem" @click="downloadPDF">

                                            <i class="bi bi-file-earmark-pdf" style="font-size: 20px;"></i>

                                        </div>






                                    </div>

                                </div>



                                <EditorContent :editor="editor" />

                            </div>


                        </div>


                    </div>

                </div>


                <div class="chatArea">

                    <div class="chatHeader">

                        <div style="font-weight: bold; font-size: 15px;">
                            SOP AI Assistant
                        </div>

                    </div>


                    <div class="chatBubbles">

                        <div v-if="sopAppStore.chatMessages.length > 0">

                            <div v-for="(mess, index) in sopAppStore.chatMessages" :key="index">



                                <div class="askItem">

                                    <div class="askBox">

                                        <div class="avatarArea">


                                            <div v-if="mess.sender === 'user'">
                                                <i class="bi bi-person-circle" style="margin-right: 4px;"></i> You
                                            </div>

                                            <div v-else>
                                                <i class="bi bi-person-circle" style="margin-right: 4px;"></i> AI Assistant
                                            </div>

                                        </div>



                                    </div>

                                    <div>

                                        <div class="aiAnswerBox" v-if="mess.sender === 'user'">

                                            <div v-html="mess.message"></div>

                                        </div>

                                        <div class="aiAnswerBox" v-else>

                                            <div v-if="mess.message == null" class="loadingBox">

                                                <div style="width: 15px; height: 15px; margin-right: 10px;" class="spinner-grow" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>Typing...
                                            </div>

                                            <div v-else v-html="mess.message"></div>

                                        </div>

                                    </div>



                                    <div class="btnArea" v-if="mess.sender === 'ai'">

                                        <button @click="addAIResponseToEditor(mess)" class="addBtn">+ Add to
                                            document</button>

                                        <!-- <button class="addBtn" style="width: 70px; margin-left: 10px;">
                                            <i class="bi bi-copy"></i>
                                            Copy
                                        </button> -->
                                    </div>


                                </div>



                            </div>

                        </div>


                        <div v-else class="outer">

                            <div class="noMessages">

                                <i class="bi bi-chat-left-dots"></i>
                                Let the SOP AI Assistant help you write!

                            </div>



                        </div>


                    </div>


                    <div class="promptBox">

                        <form @submit.prevent="sopAppStore.userSendMessageAndGetAIResponse">

                            <div class="theHead">
                                <div>
                                    Ask AI
                                </div>
                                <div>
                                    <button type="submit" class="sendBtn">Send</button>
                                </div>
                            </div>

                            <div class="theBody">

                                <textarea required v-model="sopAppStore.newMessageFormData.message"
                                    placeholder="Type here..." class="form-control askArea" rows="3"></textarea>

                            </div>

                        </form>


                        <div class="clearChatBox" @click="sopAppStore.deleteThisChatHistory">

                            <span style="cursor: pointer;">Clear chat</span>

                        </div>


                    </div>



                </div>


            </div>



            <div v-if="sopAppStore.fileDetails.file_type === 'image'">

                <div class="imageBox">

                    <img class="theImg" :src="sopAppStore.fileDetails.img_url" width="100%">

                </div>



            </div>

            <div v-if="sopAppStore.fileDetails.file_type === 'pdf'">





                <div class="pdfContent">

                    <vue-pdf-embed :source="sopAppStore.fileDetails.pdf_url" />


                </div>





            </div>

            <div v-if="sopAppStore.fileDetails.file_type === 'video'">






                <video class="theVideo" controls>
                    <source :src="sopAppStore.fileDetails.video_url" type="video/mp4">
                    Your browser does not support HTML video.
                </video>




            </div>






        </div>


        <div class="modal-overlay" v-if="sopAppStore.showPrintContentModal">

            <div class="modal-box">


                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <button class="button-13" @click="downloadPDF">Save as PDF</button>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closePrintContentRenderedModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="width: 100%;">

                    <div class="content-to-download" style="overflow-y: scroll; height: 600px;">

                        <div v-html="sopAppStore.sopFormData.content"></div>

                    </div>







                </div>
            </div>
        </div>

        <!-- 
        <div class="modal-overlay" v-if="sopAppStore.showAIPromptModal">

            <div class="modal-box" style="width: 700px;">


                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>SOP AI Assistant</b>
                    </div>

                    <div>
                        <i class="bi bi-x-lg" @click="sopAppStore.closeAIPromptModal"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="width: 100%;">

                    <form>

                        <textarea
                            placeholder="Example: Write an SOP for safe handling of chemicals. Cover details about the proper procedures for transporting, using, and storing chemicals safely."
                            class="form-control titleInput" rows="3"></textarea>



                        <button type="submit" class="btn btn-dark createBTN">
                            <b>Generate</b>
                        </button>


                        <div class="aiResultsBox">

                            <div v-for="index in 100" :key="index">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus vero accusantium
                                voluptates cum quisquam ipsa pariatur adipisci quibusdam iure eaque est consequuntur,
                                deserunt aut aspernatur amet perferendis enim impedit quos.
                            </div>

                        </div>

                    </form>




                </div>
            </div>
        </div> -->



    </div>
</template>

<script setup>


import { onMounted, ref, nextTick, defineProps, computed } from 'vue';
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import { useSOPAppStore } from "@/stores/SOPAppStore";
import VuePdfEmbed from 'vue-pdf-embed'
import SOPAppService from '@/services/SOPAppService';


const sopAppStore = useSOPAppStore();

const props = defineProps({
    uuid: String
})


const editor = ref(
    useEditor({

        content: "dd",
        extensions: [
            StarterKit,
            Underline,
            TextStyle,
            Color,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                defaultAlignment: 'left',
                alignments: ['left', 'center', 'right'],

            }),
            Image.configure({
                allowBase64: true,
            })
        ],
        // eslint-disable-next-line 
        onUpdate: ({ editor }) => {
            updateContent();
        }

    })
);


const updateContent = () => {

    sopAppStore.fileDetails.body = editor.value.getHTML();

    // console.log(sopAppStore.fileDetails.body)

};





const triggerFileInput = () => {
    document.getElementById('fileInput').click();
};


const handleFileChange = async (event) => {
    try {
        // Wait for the image URL to be returned
        let imageUrl = await sopAppStore.handleImageUploadToServer(event);

        // Add the image into the editor
        if (imageUrl) {
            editor.value.chain().focus().setImage({ src: imageUrl }).run();
        } else {
            alert('Failed to upload image');
        }
    } catch (error) {
        console.error(error);
        alert('Failed to upload image');
    }
};



const downloadPDF = async () => {
    // Get the current HTML content from the editor
    let content = editor.value.getHTML();

    // Create a temporary element to parse the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Find all image elements in the content
    const images = tempDiv.querySelectorAll('img');

    // Use a loop to process all images
    for (let img of images) {

        // Check if the image src is a URL (and not already a base64 string)
        if (img.src.startsWith('http') && !img.src.startsWith('data:image')) {

            // Convert image src to base64
            const base64String = await convertImageToBase64(img.src);
            img.src = base64String;
        }

        // Set a maximum width for the image to fit the PDF page
        img.style.maxWidth = '100%';
        img.style.height = 'auto';
    }

    // Update the content with the modified HTML which includes base64 images
    content = tempDiv.innerHTML;

    // Wait for the Vue DOM to update
    await nextTick();

    // Now send this updated content to the backend for PDF generation
    sopAppStore.downloadThisPDF(content);
};

// Utility function to convert image URL to base64
function convertImageToBase64(url) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
}


const currentColor = computed(() => {
    // Get the color attribute from the editor, provide a default color if undefined
    const color = editor.value.getAttributes('textStyle').color;
    return color || '#000000'; // Default to black if no color is set
});


const getFileDetails = () => {

    sopAppStore.fileDetails = {}

    SOPAppService.getThisFileDetails(props.uuid)
        .then((response) => {


            sopAppStore.fileDetails = response.data.file


            if (sopAppStore.fileDetails.file_type === 'text') {

                editor.value.commands.setContent(sopAppStore.fileDetails.body);
                sopAppStore.chatMessages = response.data.chatMessages



            }

            console.log(response.data)


        }).catch((err) => {
            console.log(err)
        });

}


const addAIResponseToEditor = (mess) => {

    // Ensure editor instance is available and mess.message is not undefined
    if (editor.value && mess.message) {
        // Move the cursor to the end of the content
        editor.value.chain().focus().setTextSelection(editor.value.state.doc.content.size).insertContent(mess.message).run();

        console.log(sopAppStore.fileDetails)
    }


}





onMounted(() => {

    getFileDetails()



});



</script>

<style  scoped>
@media print {
    body * {
        visibility: hidden;
    }

    .mainWrapper,
    .mainWrapper * {
        visibility: visible;
    }

    .mainWrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        overflow: visible !important;
    }

    .textEditorBox,
    .contentInputBody,
    .ProseMirror {
        max-height: none !important;
        overflow: visible !important;
    }
}


.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding-top: 30px; */
    /* background-color: rgb(242, 239, 236); */
    height: 100vh;

}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    /* color: initial; */
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}


.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
    margin-right: 10px;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.dropBox {
    background-color: azure;
    max-width: 200px;
}

.dropdown-item {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-item:hover {
    background-color: rgb(222, 222, 222);
    color: black;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.colTitle {
    font-size: 14px;
}

.editorHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: antiquewhite; */
    height: 70px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.theRight {
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    width: 50%;
    /* background-color: rgb(123, 105, 105); */

}

.theLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
    /* background-color: rgb(190, 163, 163); */
    padding: 20px;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}


.theContent {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: antiquewhite; */
}



.textEditorBox {
    width: 100%;
    height: 100vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* border-radius: 10px; */
    padding: 20px;


}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}


.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}


.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
    /* height: 100%; */

}




.textEditorWrapper {
    width: 100%;

    /* width: 100%; */
    /* background-color: red; */
}

.btnBox {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e4e4e4;
    /* border: 1px solid rgb(239, 236, 236); */
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}

.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    /* border-bottom: 2px solid rgb(239, 239, 19);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76); */
    outline: none;
    min-height: 400px;
    text-align: left;
    max-height: 600px;
    overflow-y: auto;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}

/* .colorWheel{
    width: 30px;
    height: 30px;
   
} */

.colorWheel {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* Make the input circular */
    border: none;
    /* Remove the border */
    cursor: pointer;
    /* Change cursor to pointer to indicate it's clickable */
    -webkit-appearance: none;
    /* Try to remove default styling on WebKit browsers */
    padding: 0;
    /* Remove padding */
    outline: none;
    /* Remove focus outline */
}

/* Hide the color input's default square color preview on WebKit browsers */
.colorWheel::-webkit-color-swatch-wrapper {
    padding: 0;
}

.colorWheel::-webkit-color-swatch {
    border: none;
}

.theImg {
    padding-left: 10px;
    padding-right: 10px;
}

.theVideo {
    width: 800px;
    height: auto;
    background-color: #d5d9d9;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;

}

@media (max-width: 800px) {
    .theVideo {
        width: 100%;
    }
}


.pdfWrapperBox {
    width: 900px;
}

.pdfBox {
    width: 800px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.pdfContent {
    text-align: left;
    font-size: 17px;
    width: 800px;
    height: 600px;
    overflow-y: auto;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
}

@media (max-width: 800px) {
    .pdfContent {
        width: 100%;
    }
}


.aiResultsBox {
    height: 400px;
    background-color: rgb(232, 224, 224);
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    overflow-y: auto;
}


.aiTextWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: rgb(144, 190, 144); */
}


.textWrapper {
    width: 70%;
    /* background-color: rgb(144, 160, 219); */
}


.chatArea {
    display: flex;
    flex-direction: column;
    /* background-color: #f7f7f7; */
    width: 30%;
}


.chatHeader {
    display: flex;
    align-items: center;
    height: 50px;
    /* background-color: #d9e7ff; */
    /* padding-left: 15px; */
    padding: 20px;
    border-bottom: 1px solid #e4e4e4;
}

.chatBubbles {
    height: 100%;
    /* background-color: red; */
    overflow-y: auto;
    border-bottom: 1px solid #e4e4e4;
    padding: 20px;
}

.promptBox {
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
    height: 450px;
    padding: 20px;
    width: 100%;
}

.clearChatBox {
    text-align: left;
    font-size: 13px;

}


.theHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
    /* background-color: green; */
}

.theBody {
    /* background-color: purple; */

}


.askArea {
    resize: none;
    height: 130px;
}


.askItem {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
    /* background-color: gray; */
}

.askBox {
    display: flex;
    flex-direction: row;
    /* background-color: antiquewhite; */
}

.aiAnswerBox {
    /* background-color: yellow; */
    font-size: 15px;
    text-align: left;
    margin-bottom: 10px;
}


.avatarArea {
    font-size: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.addBtn {
    width: 120px;
    font-size: 11px;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 500;
}

.addBtn:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.btnArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.sendBtn {
    width: 80px;
    font-size: 11px;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #ffffff;
    background-color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;
}

.noMessages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* background-color: rgb(230, 195, 195); */
    height: 500px;
    font-size: 13px;
}

.outer {
    display: flex;
    justify-content: center;
    align-content: center;

}

.loadingBox{
    display: flex;
    flex-direction: row;
}


.imageBox{
    margin-top: 30px;
}

</style>