<template>
    <div>


        <!-- <PublicMainNavBar /> -->

        <div class="contentContainer">



            <div class="buttonOptionsWrapper" v-show="publicViewStore.showTopicsResult">


                <div class="theButtonBox">


                    <button @click="publicViewStore.tryTopicsAgain" class="btn btn-light" style="font-size: 13px;">
                        <i class="bi bi-arrow-clockwise"></i> <b>Try Again</b>
                    </button>

                    <button class="btn btn-dark" style="font-size: 13px;" @click="copyText">
                        <i class="bi bi-clipboard"></i> <b style="margin-left: 3px;">Copy All</b>
                    </button>



                </div>




            </div>

            <div class="buttonOptionsWrapper" v-show="publicViewStore.showTopicsResult">


                <div class="theButtonBox" style="text-align: left;">



                    <a href="https://safelyio.com/ai-toolbox-talk-generator/" target="_blank" style="font-size: 18px;"><b>NEXT STEP: Now That You Have A List Use Our 100% Free Toolbox Talk Writer To Generate These
                        Talks!</b></a>



                </div>




            </div>

            <div class="resultsBox" v-show="publicViewStore.showTopicsResult">



                <div class="theListWrapper">

                    <ul class="list-group list-group-flush" ref="textToCopy">

                        <li v-for="(theTopic, index) in publicViewStore.talkTopics" :key="index"
                            class="list-group-item">{{ theTopic.topic }}</li>

                    </ul>


                </div>


            </div>

            <div class="resultsBox" v-show="publicViewStore.showTopicsResult">

                <div class="theAdWrapper">

                    <div class="theAdHeader">

                        <h5><b>Great! Now That You Have A List Use Our 100% Free Toolbox Talk Writer To Generate These
                                Talks!</b></h5>

                    </div>



                    <a class="btn btn-success theBtnCTA" href="https://safelyio.com/ai-toolbox-talk-generator/"
                        target="_blank">Try For Free</a>


                </div>


            </div>

            <div class="formWrapper" v-show="!publicViewStore.showTopicsResult">

                <form @submit.prevent="publicViewStore.generateSafetyTopics" class="theFormBox">



                    <div class="mb-3">

                        <label for="exampleInputEmail1" class="form-label"><b>Briefly describe the job tasks and specify
                                the industry</b></label>

                        <textarea class="form-control theInput" v-model="publicViewStore.topicsFormData.prompt" rows="4"
                            cols="50" style="resize: none; height: 200px;" required
                            placeholder="Example: Give me a list of safety topics for an electrical contracting company that specializes in installation, maintenance, and repair of electrical systems in construction industry."></textarea>

                    </div>


                    <div class="mb-4">
                        <label for="exampleInputEmail1" class="form-label"><b>Select season</b></label>

                        <select class="form-select theInput" v-model="publicViewStore.topicsFormData.season"
                            aria-label="Default select example">

                            <option v-for="(season, index) in publicViewStore.seasonsArray" :key="index"
                                :value="season.name">{{ season.name }}</option>

                        </select>

                    </div>


                    <div class="mb-4">
                        <label for="exampleInputEmail1" class="form-label"><b>How many topics to generate?</b></label>

                        <select class="form-select theInput" v-model="publicViewStore.topicsFormData.amt_of_topics"
                            aria-label="Default select example">

                            <option v-for="(theNum, index) in publicViewStore.topicAmountArray" :key="index"
                                :value="theNum.count">{{ theNum.count }}</option>

                        </select>


                    </div>

                    <div class="loadingDiv" v-if="publicViewStore.isLoadingTopics">

                        <div class="typingDiv">

                            <b style="font-size: 12px;">Generating safety topic ideas...</b>

                            <div class="spinner-grow" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>


                        </div>


                    </div>


                    <button v-else type="submit" class="btn btn-dark theSubmitBtn"><b>Generate Safety Topic
                            Ideas</b></button>

                </form>



            </div>



        </div>



    </div>
</template>

<script setup>

// import PublicMainNavBar from "@/components/PublicViews/MainNavBar";
import { usePublicViewStore } from "@/stores/PublicViewStore";
import { ref } from 'vue'


/* eslint-disable */

const publicViewStore = usePublicViewStore();

const textToCopy = ref(null)

const copyText = () => {
    if (textToCopy.value) {

        const text = textToCopy.value.innerText
        const textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.select()
        try {
            document.execCommand('copy')
            publicViewStore.notifyAdminForTopics()
            alert('Copied!')


        } catch (err) {
            console.error('Failed to copy text:', err)
        }
        document.body.removeChild(textArea)
    }
}




</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.contentContainer {
    width: 100%;
    /* background-color: rgb(202, 217, 202); */
}

.formWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: rgb(200, 200, 193); */
    padding: 20px;
    margin-top: 20px;
}


.theFormBox {
    /* max-width: 800px; */
    text-align: left;
    width: 800px;
}


.theSubmitBtn {
    width: 100%;
}


.theHeader {
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
}

.theInput {
    border: 1.5px solid rgb(103, 103, 103);
}

.resultsBox {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;

    /* background-color: rgb(137, 158, 177); */
}

.theTalkWrapper {
    display: flex;
    flex-direction: column;
    width: 800px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    /* background-color: yellow; */
}


.titleBox {
    /* background-color: antiquewhite; */
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}

.talkBody {
    /* background-color: grey; */
    font-size: 17px;
    text-align: left;
}


.buttonOptionsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    margin-top: 15px;
    padding: 20px;
}

.theButtonBox {
    display: flex;
    justify-content: space-between;
    /* background-color: purple; */
    width: 500px;
}

.theAdBox {
    width: 100%;
    background-color: green;
}

.theAdWrapper {
    width: 800px;
    background-color: beige;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}


.theAdHeader {
    text-align: center;
    line-height: 2px;

}

.theAdBody {
    text-align: left;
    font-size: 17px;
}

.theBtnCTA {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-weight: bold;
}


.loadingDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: rgb(115, 104, 104); */
}

.typingDiv {
    display: flex;
    /* background-color: red; */
    width: 300px;
}

.theListWrapper {
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* max-height: 600px;
    overflow-y: auto; */


}
</style>