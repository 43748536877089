<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">




                    <div class="theHeaderWrapper">



                        <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                            class="bi bi-arrow-left"></i>

                        <div style="margin-left: 20px;">
                            Acknowledgments 

                            <span v-if="campaignStore.scheduledMessage" style="font-size: 13px;"
                            class="badge rounded-pill bg-dark badgy">{{ campaignStore.scheduledMessage.name }}</span>
                        </div>




                    </div>

                    <div class="searchBarWrapper">

                        <input v-model="searchQuery" type="text" class="form-control theBar"
                            placeholder="Search by employee name..." />

                        <div class="theRight">

                            <button @click="campaignStore.exportDataAsExcel(props.schedule_message_id)"
                                class="button-13" style="margin-right: 16px;">Export as Excel</button>

                                <i @click="campaignStore.openMassResendModal" class="bi bi-gear" style="cursor: pointer;"></i> 
                        </div>


                    </div>




                    <div class="tableWrapper">

                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Acknowledged</th>
                                    <th scope="col">Score</th>
                                    <th scope="col">Date</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr v-for="(ack, index) in filteredAck" :key="index">


                                    <td>

                                        <div v-if="ack.user">

                                            {{ ack.user.name }}

                                        </div>

                                    </td>

                                    <td>
                                        <div v-if="ack.is_acknowledged">

                                            <span class="badge rounded-pill bg-success">YES</span>

                                        </div>

                                        <div v-else>

                                            <span class="badge rounded-pill bg-danger">NO</span>

                                        </div>

                                    </td>

                                    <td>

                                        <div v-if="ack.pass_score">
                                            {{ ack.pass_score }}%
                                        </div>
                                        <div v-else>
                                            No quiz required
                                        </div>

                                    </td>

                                    <td>

                                        <div v-if="ack.is_acknowledged">

                                            {{ ack.updated_at }}


                                        </div>

                                        <div v-else>

                                            <span>Not signed yet</span>

                                        </div>

                                    </td>

                                    <td>
                                        <i @click="() => { campaignStore.setCurrrentEmployeeToResendEmail(ack); campaignStore.openResendTalkEmailToEmployeeModal(); }"
                                        style="cursor: pointer;" class="bi bi-envelope"></i>
                                    </td>



                                </tr>

                            </tbody>




                        </table>


                        <button v-if="displayedAckCount < campaignStore.allAcknowledgments.length"
                            class="btn btn-dark loadMoreBtn" @click="loadMoreAck">Load more</button>



                    </div>




                </div>
            </div>
        </div>



        <div v-if="campaignStore.showResendTalkEmailToEmployeeModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to resend this email?</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeResendTalkEmailToEmployeeModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <p v-if="campaignStore.currentEmployee"><b>
                        {{ campaignStore.currentEmployee.name }}
                    </b> will be resent this safety talk.</p>
                  

                    

                    <button v-if="campaignStore.isLoading"
                    type="button"  class="btn btn-dark" style="width: 100%;">
                            <b>Sending...</b>
                    </button>

                    <button v-else @click="campaignStore.resendTalkToThisEmployee"
                    type="button"  class="btn btn-dark" style="width: 100%;">
                            <b>Resend</b>
                    </button>


                </div>


            </div>


        </div>


         <div v-if="campaignStore.showMassResendModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">

                    <div style="font-size: 17px; text-align: left;">
                        <b>Mass resend this safety talk only to those who did not acknowledge</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeMassResendModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>

                </div>

                <div class="modal-content" style="text-align: left; margin-top: 30px;">



                    <button @click="campaignStore.massResendToThisWhoDidNotAck" type="button" class="btn btn-dark" style="width: 100%;">
                            <b>Resend</b>
                    </button>
                    

                    

                </div>


            </div>


        </div>





    </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, defineProps, ref, computed } from 'vue';
import { useCampaignStore } from "@/stores/CampaignStore";

const campaignStore = useCampaignStore();


const searchQuery = ref('');
const displayedAckCount = ref(25); // Start with displaying 5 teams
const incrementStep = 15; //


const filteredAck = computed(() => {

    return campaignStore.allAcknowledgments
        .filter(ack => ack.user.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
        .slice(0, displayedAckCount.value);

});

const loadMoreAck = () => {
    displayedAckCount.value += incrementStep;
};



const props = defineProps({
    schedule_message_id: Number
})



onMounted(() => {

    campaignStore.fetchAcknowledgements(props.schedule_message_id)


})



</script>

<style scoped> .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     align-items: center;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
 }

 .searchBarWrapper {
     display: flex;
     justify-content: space-between;
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 350px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }

 .button-6 {
     align-items: center;
     /* background-color: #FFFFFF; */
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     border: 1px solid rgba(0, 0, 0, 0.1);
     border-radius: 0.25rem;
     box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
     box-sizing: border-box;
     color: rgba(0, 0, 0, 0.85);
     cursor: pointer;
     display: inline-flex;
     font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
         Arial, sans-serif;
     font-size: 16px;
     font-weight: 600;
     justify-content: center;
     line-height: 1.25;
     margin: 0;
     min-height: 3rem;
     padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
     position: relative;
     text-decoration: none;
     transition: all 250ms;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: baseline;
     width: auto;
 }

 .button-6:hover,
 .button-6:focus {
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
     color: rgba(0, 0, 0, 0.65);
 }

 .button-6:hover {
     transform: translateY(-1px);
 }

 .button-6:active {
     background-color: #f0f0f1;
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
     color: rgba(0, 0, 0, 0.65);
     transform: translateY(0);
 }

 .tableWrapper {
     margin-top: 20px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
 }

 .button-13 {
     background-color: #fff;
     border: 1px solid #d5d9d9;
     border-radius: 8px;
     box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
     box-sizing: border-box;
     color: #0f1111;
     cursor: pointer;
     display: inline-block;
     font-family: "Amazon Ember", sans-serif;
     font-size: 13px;
     line-height: 29px;
     padding: 0 10px 0 11px;
     position: relative;
     text-align: center;
     text-decoration: none;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: middle;
     min-width: 100px;
 }

 .button-13:hover {
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
 }

.theRight{
    display: flex;
    align-items: center;
  
}

</style>