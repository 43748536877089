/* eslint-disable */

import { defineStore } from "pinia";
import PublicViewsService from "@/services/PublicViewsService";


export const usePublicViewStore = defineStore("publicViewStore", {

  state: () => ({



    toolboxTalkFormData: {
      language: 'English',
      prompt: null,
    },

    languagesArray: [
      {id: 1, name: 'English', type: 'english'}, 
      {id: 2, name: 'Spanish', type: 'spanish'}, 
    ],

    isLoading: false, 

    theAIGenerateTalk: {},

    showResult: false,

    notifyAdminFormData: {
      prompt: null,
      title: null,
      body: null,
    },


    seasonsArray: [
      {id: 1, name: 'Summer', type: 'summer'}, 
      {id: 2, name: 'Winter', type: 'winter'}, 
      {id: 3, name: 'Fall', type: 'fall'}, 
      {id: 4, name: 'Spring', type: 'spring'}, 
    ],

    topicAmountArray: [
      {id: 1, count: 10}, 
      {id: 2, count: 15}, 
      {id: 3, count: 25}, 
    ],

    topicsFormData: {
      prompt: null,
      season: 'Summer',
      amt_of_topics: 10,
    },

    showTopicsResult: false,

    talkTopics: [],

    isLoadingTopics: false,

    notifyAdminForTopicsFormData: {
      prompt: null,
      season: null,
      amt_of_topics: null,
      the_list: null
    },

 
  }),


  actions: {



    generateTalk(){ 


      this.isLoading = true;

      if(this.toolboxTalkFormData.language == null || this.toolboxTalkFormData.prompt == null){

        alert('Please select a language and make sure to describe what of takl should be created')

        this.isLoading = false;

        return;

      }

      const formData = {
        promptContent: this.toolboxTalkFormData
      }

      PublicViewsService.generateThisTalk(formData)
        .then((response) => {


          this.theAIGenerateTalk = response.data.contentArray.talk;

          // console.log(response.data)

          this.isLoading = false;
          this.showResult = true
          
        }).catch((err) => {

          console.log(err)

          this.isLoading = false;
          
        });
      

      // console.log(this.toolboxTalkFormData)

    },


    tryAgain(){
      this.showResult = false
    },

    notifyAdmin(){

      this.notifyAdminFormData.prompt = this.toolboxTalkFormData.prompt
      this.notifyAdminFormData.title = this.theAIGenerateTalk.title
      this.notifyAdminFormData.body = this.theAIGenerateTalk.body

      const formData = {
        allData: this.notifyAdminFormData
      }

      PublicViewsService.notifyAdminOfThisTalk(formData)
        .then((response) => {

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },


    generateSafetyTopics(){ 


      this.isLoadingTopics = true

      if(this.topicsFormData.prompt == null){

        alert('Please describe the industry and job tasks!')

        this.isLoadingTopics = false

        

        return;

      }


      const formData = {
        userPrompt: this.topicsFormData.prompt,
        season: this.topicsFormData.season,
        amt_of_topics: this.topicsFormData.amt_of_topics
      }

    

      PublicViewsService.publicAIGenerateSafetyTalkTopics(formData)
        .then((response) => {


          this.talkTopics = response.data.contentArray.talk_topics;

          this.isLoadingTopics = false

          this.showTopicsResult = true

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },

    tryTopicsAgain(){
      this.showTopicsResult = false
    },


    notifyAdminForTopics(){ 

      this.notifyAdminForTopicsFormData.prompt = this.topicsFormData.prompt
      this.notifyAdminForTopicsFormData.season = this.topicsFormData.season
      this.notifyAdminForTopicsFormData.amt_of_topics = this.topicsFormData.amt_of_topics
      this.notifyAdminForTopicsFormData.the_list = this.talkTopics

      const formData = {
        allData: this.notifyAdminForTopicsFormData
      }

      // console.log(formData)

      PublicViewsService.notifyAdminOfTopics(formData)
        .then((response) => {

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },

  

  },

});
