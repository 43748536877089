import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap.js' //must be added for dropdowns to work
import 'bootstrap';
import '@popperjs/core'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'vue3-toastify/dist/index.css';

import VueSignaturePad from 'vue-signature-pad';




//make router aviable in all stores
const pinia = createPinia()

pinia
.use(({ store }) => {
    store.router = markRaw(router)
})
.use(piniaPluginPersistedstate)

createApp(App)
    .use(pinia)
    .use(router)
    .use(VueSignaturePad)
    .mount('#app')
