<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">
                        <div>Safety Toolbox Talk Library</div>



                    </div>

                    <div class="searchBarWrapper">
                        <input v-model="searchQuery" type="text" class="form-control theBar"
                            placeholder="Search library..." />
                    </div>



                    <div class="tableWrapper">

                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Content Type</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>

                            <tbody v-if="filteredTalks.length > 0">

                                <tr v-for="(talk, index) in filteredTalks" :key="index">


                                    <td>{{ talk.title }}</td> 

                                    <td>
                                        <span class="badge rounded-pill bg-dark" style="font-size: 14px;">
                                            {{ talk.content_type }}
                                        </span>
                                    </td>

                                    <td>

                                        <button class="button-13" role="button"
                                            @click="() => { toolboxLibraryStore.setCurrentTalk(talk); toolboxLibraryStore.openShowPreviewTalkModal(); }">
                                            <b>Preview</b>
                                        </button>

                                    </td>



                                </tr>
                            </tbody>




                        </table>



                        <button v-if="displayedTalksCount < toolboxLibraryStore.talks.length"
                            class="btn btn-dark loadMoreBtn" @click="loadMoreTalks">Load more</button>




                    </div>







                </div>
            </div>
        </div>


        <div v-if="toolboxLibraryStore.showPreviewTalkModal" class="modal-overlay">

            <div class="modal-box">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>
                            {{ toolboxLibraryStore.currentTalk.title }}
                        </b>
                    </div>

                    <div>
                        <i @click="toolboxLibraryStore.closeShowPreviewTalkModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content thePreviewContent" style="text-align: left;">



                    <div v-if="toolboxLibraryStore.currentTalk.content_type == 'text'">

                        <div v-html="toolboxLibraryStore.currentTalk.body"></div>

                    </div>

                    <div v-if="toolboxLibraryStore.currentTalk.content_type == 'video'">

                        <div v-html="toolboxLibraryStore.currentTalk.video_embed_code"></div>

                        

                    </div>


                    <div v-if="toolboxLibraryStore.currentTalk.content_type == 'pdf'">

                        

                        <vue-pdf-embed :source="toolboxLibraryStore.currentTalk.pdf_url_path" />

              
                    </div>




                </div>
            </div>
        </div>






    </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, ref, computed } from 'vue';
import { useToolboxLibraryStore } from "@/stores/ToolboxLibraryStore";
import VuePdfEmbed from 'vue-pdf-embed'


const toolboxLibraryStore = useToolboxLibraryStore();

const searchQuery = ref('');

const displayedTalksCount = ref(40); // Start with displaying 5 teams
const incrementStep = 25; //


const filteredTalks = computed(() => {

    return toolboxLibraryStore.talks
        .filter(talk => talk.title.toLowerCase().includes(searchQuery.value.toLowerCase()))
        .slice(0, displayedTalksCount.value);

});

const loadMoreTalks = () => {
    displayedTalksCount.value += incrementStep;
};



onMounted(() => {

    toolboxLibraryStore.fetchAllLibraryTalks()


})






</script>

<style scoped> .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     justify-content: space-between;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
 }

 .searchBarWrapper {
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 350px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }


 .tableWrapper {
     margin-top: 20px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
     margin-top: 20px;
 }

 .button-13 {
     background-color: #fff;
     border: 1px solid #d5d9d9;
     border-radius: 8px;
     box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
     box-sizing: border-box;
     color: #0f1111;
     cursor: pointer;
     display: inline-block;
     font-family: "Amazon Ember", sans-serif;
     font-size: 13px;
     line-height: 29px;
     padding: 0 10px 0 11px;
     position: relative;
     text-align: center;
     text-decoration: none;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: middle;
     width: 100px;
 }

 .button-13:hover {
     background-color: #f7fafa;
 }

 .button-13:focus {
     border-color: #008296;
     box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
     outline: 0;
 }


.thePreviewContent{
    height: 600px;
    overflow-y: auto;

}

</style>