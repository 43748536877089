<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper"> 
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">




                    <div class="theHeaderWrapper">

                        <div style="margin-right: 10px;">Reports</div>  


                        <div class="settingsBox" @click="reportStore.openSearchOptionsModal">
                            <i class="bi bi-gear-wide-connected"></i> Settings
                        </div>

                    </div>


                    <div class="visualBoxesWrapper">


                        <div class="dashBox">

                            <div class="numberHeader">
                                {{reportStore.talksCreatedCount}}
                            </div>

                            <div class="labelbottom">
                                Talks
                            </div>

                        </div>

                        <div class="dashBox"
                        >

                            <div class="numberHeader">
                                {{reportStore.acknowledgementsCompletedCount}}
                            </div>

                            <div class="labelbottom">
                                Talks Completed
                            </div>

                        </div>

                        <div class="dashBox"
                        >

                            <div class="numberHeader">
                                {{reportStore.acknowledgementsNotCompletedCount}}
                            </div>

                            <div class="labelbottom">
                                Talks Not Completed
                            </div>

                        </div>

                        <!-- <div class="dashBox">

                            <div class="numberHeader">
                                2
                            </div>

                            <div class="labelbottom">
                                Teams
                            </div>

                        </div> -->




                    </div>

                    <div class="searchBarWrapper">
                        <input v-model="searchQuery" type="text" class="form-control theBar" placeholder="Search by employee name..." />


                        <button v-if="reportStore.showExportButton"
                         class="btn btn-dark btn-sm exportBtn" @click="reportStore.exportSearchFormData">
                            <i class="bi bi-file-earmark-spreadsheet" style="margin-right: 7px;"></i>
                            <b>Export</b></button>
                    </div>



                    <div class="tableWrapper">

                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Campaign</th>
                                    <th scope="col">Team</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Talk Title</th>
                                    <th scope="col">Acknowledged</th>
                                    <th scope="col">Pass Score</th>
                                    <th scope="col">Date Signed</th>

                                </tr>
                            </thead>

                            <tbody>

                                <tr v-for="(ack, index) in filteredAck" :key="index">


                                    <td>
                                        
                                        <div v-if="ack.campaign">

                                            {{ reportStore.limitText(ack.campaign.name) }}

                                        </div>

                                    </td>

                                    <td>
                                        {{ ack.team?.name ?? 'N/A' }}
                                    </td>

                                    <td>
                                        
                                        <div v-if="ack.user">

                                            {{ ack.user.name }}

                                        </div>

                                    </td>

                                   

                                    <td>

                                        <div v-if="ack.scheduled_message">
                                            {{ ack.scheduled_message.post?.title }}
                                        </div>

                                    </td>

                                    <td>

                                        <div v-if="ack.is_acknowledged">

                                            <span style="font-size: 10px;" class="badge rounded-pill bg-success">YES</span>

                                        </div>

                                        <div v-else>

                                            <span style="font-size: 10px;" class="badge rounded-pill bg-danger">NO</span>

                                        </div>

                                    </td>

                                    <td>

                                        <div v-if="ack.pass_score">
                                            {{ ack.pass_score }}%
                                        </div>
                                        <div v-else>
                                            No quiz required
                                        </div>

                                    </td>

                                    <td>

                                        <div v-if="ack.is_acknowledged">

                                            {{ ack.updated_at }}


                                        </div>

                                        <div v-else>

                                            <span>Not signed yet</span>

                                        </div>

                                    </td>



                                </tr>
                            </tbody>




                        </table>




                        <button v-if="displayedAckCount < reportStore.acknowledgements.length"
                            class="btn btn-dark loadMoreBtn" @click="loadMoreAck">Load more</button>





                    </div>







                </div>
            </div>
        </div>


        <div v-if="reportStore.showSearchOptionsModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Search</b>
                    </div>

                    <div>
                        <i @click="reportStore.closeSearchOptionsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content">

                    <form @submit.prevent="reportStore.searchACKData">
                     

                        <div class="mb-3" style="margin-bottom: 10px;">
                            <label class="form-label" style="float: left"><b>Account</b></label>
                            <select v-model="reportStore.searchFormData.manager_id" class="form-select custom-select titleInput" required> 

                                <option v-for="(manager, index) in reportStore.allManagers" :key="index" :value="manager.id">
                                    {{ manager.name }}
                                </option>

                            </select>
                        </div>



                        <!-- <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Campaigns</b></label>
                            
                            <select v-model="reportStore.searchFormData.campaign_id" class="form-select custom-select titleInput">
                                    <option value="all_campaigns">All campaigns</option>
                                
                                    <option v-for="(campaign, index) in reportStore.allCampaignsInAckTableForThisManager" :key="index" :value="campaign.id">
                                        {{ campaign.name }}
                                </option>
                                    
                            </select>
                        </div> -->

                        <div class="row" style="margin-top: 20px; margin-bottom: 20px;">

                            <div class="col">
                                <label class="form-label" style="float: left"><b>Start Date</b></label>
                                <input v-model="reportStore.searchFormData.start_date" type="date" class="form-control" placeholder="First name" aria-label="First name"
                                required
                                >
                            </div>
                            <div class="col">
                                <label class="form-label" style="float: left"><b>End Date</b></label>
                                <input v-model="reportStore.searchFormData.end_date" type="date" class="form-control" placeholder="Last name" aria-label="Last name"
                                required
                                >
                            </div>
                        </div>


                        


                        <div class="mb-3">
                            <label class="form-label" style="float: left"><b>Completion</b></label>
                            <select v-model="reportStore.searchFormData.completion_type" class="form-select custom-select titleInput" required>
                                    <option value="all">All</option>
                                    <option value="did_not_acknowledged">Employees who didn't complete talks</option>
                                    <option value="acknowledged">Employees who completed talks</option>
                            </select>
                        </div>


                        

                        <div>
                            <div v-if="reportStore.isLoading" class="spinner-grow" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <button v-else type="submit" class="btn btn-dark createBTN">
                                <b>Search</b>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>






    </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { useReportStore } from "@/stores/ReportsStore";
import { onMounted, ref, computed } from "vue";

const reportStore = useReportStore();


const searchQuery = ref('');
const displayedAckCount = ref(45); // Start with displaying 5 teams
const incrementStep = 25; //


const filteredAck = computed(() => {
  return reportStore.acknowledgements
    .filter(ack => 
      ack.user && ack.user.name && 
      ack.user.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    .slice(0, displayedAckCount.value);
});


// const filteredAck = computed(() => {

//     return reportStore.acknowledgements
//         .filter(ack => ack.user.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
//         .slice(0, displayedAckCount.value);

// });

const loadMoreAck = () => {
    displayedAckCount.value += incrementStep;
};


onMounted(() => {

  
    reportStore.fetchAllAcknowledgments()

})




</script>

<style scoped> .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     align-items: center;
     justify-content: space-between;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
     margin-bottom: 20px;
 }

 .searchBarWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 330px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }


 .tableWrapper {
     margin-top: 8px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
     margin-top: 20px;
 }

 .button-13 {
     background-color: #fff;
     border: 1px solid #d5d9d9;
     border-radius: 8px;
     box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
     box-sizing: border-box;
     color: #0f1111;
     cursor: pointer;
     display: inline-block;
     font-family: "Amazon Ember", sans-serif;
     font-size: 13px;
     line-height: 29px;
     padding: 0 10px 0 11px;
     position: relative;
     text-align: center;
     text-decoration: none;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: middle;
     width: 100px;
 }

 .button-13:hover {
     background-color: #f7fafa;
 }

 .button-13:focus {
     border-color: #008296;
     box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
     outline: 0;
 }


 .thePreviewContent {
     height: 600px;
     overflow-y: auto;

 }

 .visualBoxesWrapper {
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     width: 100%;
     /* background-color: #d5d9d9; */
     height: 100px;
     margin-bottom: 40px;
     margin-top: 15px;
 }


 .dashBox {

     /* background: linear-gradient(90deg,#ddefbb,#fee); */
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 100%;
     margin-right: 10px;
     /* margin: 5px; */
     border-radius: 15px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     padding: 20px;

 }

 .dashBox2 {

    /* background: linear-gradient(90deg,#ddefbb,#fee); */
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(237,3,32,0.87) 20.8%, rgba(242,121,1,0.84) 74.4% );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    /* margin: 5px; */
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;

}

 

 .dashBox:hover {
     scale: 1.02;
     transition: 1s;
 }


 .numberHeader {
     text-align: left;
     font-weight: bold;
     font-size: 24px;
 }

 .labelbottom {
     font-size: 15px;
 }

 .numberHeader {
     text-align: left;
     /* background-color: gray; */
     width: 100%;
 }

 .labelbottom {
     text-align: left;
     /* background-color: rgb(227, 179, 179); */
     width: 100%;
 }

 .formOptionsbox {
     width: 100%;
     background-color: gray;
     height: 100px;
 }

 .settingsBox {
     cursor: pointer;
     font-size: 13px;
     margin-right: 10px;
     background-color: #f8f8f8;
     border-radius: 10px;
     padding: 9px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 }

 .settingsBox:hover {
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
 }

 .titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.exportBtn{
    width: 100px;
    font-size: 12px;
    height: 30px;
    border-radius: 7px;
    margin-right: 10px;
}

</style>