/* eslint-disable */
// import AuthService from "@/services/AuthService";
import ReportsService from "@/services/ReportsService";
import { defineStore } from "pinia";

export const useReportStore = defineStore("ReportStore", {

  state: () => ({ 


    acknowledgements: [],

    talksCreatedCount: null,
    acknowledgementsCompletedCount: null,
    acknowledgementsNotCompletedCount: null,
    subManagers: null,
    allCampaignsInAckTableForThisManager: [],

    currentUser: {}, 

    allManagers: [],
  
    showSearchOptionsModal: false,

    searchFormData: {
      manager_id: null,
      // campaign_id: null,
      start_date: null,
      end_date: null,
      completion_type: null
    },

    isLoading: false,

    showExportButton: false,

    

  }),



  actions: {  



    fetchAllAcknowledgments(){ 

      ReportsService.getAllTalksForACampaign()
        .then((response) => {
          
          this.showExportButton = false

          this.currentUser = response.data.currentUser
          this.talksCreatedCount = response.data.talksCreatedCount
          this.acknowledgementsCompletedCount = response.data.acknowledgementsCompletedCount
          this.acknowledgementsNotCompletedCount = response.data.acknowledgementsNotCompletedCount
          this.acknowledgements = response.data.acknowledgements
          this.allManagers = response.data.allManagers
          this.allCampaignsInAckTableForThisManager = response.data.allCampaignsInAckTableForThisManager


          this.setSearchBoxState()

          // console.log(response.data)

          
        }).catch((err) => {

          console.log(err)
          
        });

    },

    setSearchBoxState(){

      this.searchFormData.manager_id = this.currentUser.id
      // this.searchFormData.campaign_id = 'all_campaigns'
      this.searchFormData.start_date = null
      this.searchFormData.end_date = null
      this.searchFormData.completion_type = 'all'
     

    },


    openSearchOptionsModal(){
      // this.populateManagersArray()
      this.showSearchOptionsModal = true
    },

    closeSearchOptionsModal(){
      this.showSearchOptionsModal = false
    },

    limitText(body) {
      if (body.length > 40) {
        return body.substring(0, 40) + "...";
      }

      return body;
    },


    searchACKData(){ 

      this.showExportButton = true
      this.isLoading = true
      
      // console.log('searchFormData', this.searchFormData)

      ReportsService.searchAcknowledgments(this.searchFormData)
        .then((response) => {

          setTimeout(() => {

            this.isLoading = false;

            this.talksCreatedCount = response.data.talksCreatedCount
            this.acknowledgementsCompletedCount = response.data.acknowledgementsCompletedCount
            this.acknowledgementsNotCompletedCount = response.data.acknowledgementsNotCompletedCount
            this.acknowledgements = response.data.acknowledgements

            this.closeSearchOptionsModal()


            // console.log('response', response.data)
            // console.log('ack araay', this.acknowledgements)

          }, 1000);

      
          
          
        }).catch((err) => {

          console.log(err)
          
        });


  

    },


    exportSearchFormData(){

      ReportsService.exportAckDataFromSearch(this.searchFormData)
        .then((response) => {


          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)

          // Open the URL on new Window
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', 'TalksData.xlsx')
          document.body.appendChild(link)
          link.click()

          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    }


    



    

  },


});
