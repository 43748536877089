<template>
    <div class="mainWrapper">



        <MainSideBarVue />



        <div class="rightContentWrapper">


            <div class="topNav">
                <TopBarVue />
            </div>


            <div class="innerContainer">


                <div class="backBtnBox">


                    <div>

                        <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                            class="bi bi-arrow-left"></i>

                    </div>



                    <div class="theHeaderWrapper">

                        <div>
                            Edit: {{ campaignStore.quizDetails.name }}
                        </div>



                    </div>


                </div> 




                    <div class="outerQuizWrapper">

                        <div class="quizBuilderBox">

                            <div class="quizBuilderHeader">

                                <div class="quizTitle">

                                    <div style="margin-bottom: 10px;"></div>

                                    <div>
                                        <!-- <input type="text" class="form-control" style="width: 200px;"
                                            placeholder="Give this quiz a name..."> -->
                                    </div>

                                </div>

                                <div class="rightSide">
                                    <button @click="campaignStore.updateQuiz"
                                    class="button-13" role="button">
                                       Save
                                    </button>
                                </div>

                            </div>

                            <div class="quizContent">


                          


                                <div class="questionItem" v-for="(quest, index) in campaignStore.questions.questionsData" :key="index">

                                    <div class="questionText">

                                        <div style="width: 100%;">
                                            
                                            
                                            <input v-model="quest.question" type="text" class="form-control titleInput" style="font-weight: bold;" />

                                        </div>

                                

                                    </div>

                                    <div v-for="(ans, index) in quest.answers" :key="index" class="answerText">

                                        
                                        <div style="width: 80%;">
                                            <input v-model="ans.answer"  type="text" class="form-control titleInput" />
                                        </div>

                                        <div style="width: 20%; display: flex; justify-content: center; align-items: center;"
                                        @click="campaignStore.selectCorrectAnswer(ans)"
                                        >

                                            <button type="button" 
                                            class="btn btn-success btn-sm correctBtn" 
                                           
                                            v-if="ans.is_correct">
                                            Correct</button>
                                            
                                            <button v-else type="button" class="btn btn-danger btn-sm grayBtn" style="border-radius: 5px; font-size: 11px;">Select</button>

                                            

                                        </div>

                                    </div>


                                    <!-- <div v-for="index in 4" :key="index" class="answerText">
                                        
                                        
                                        <div style="width: 80%;">
                                            <input type="text" class="form-control titleInput" />
                                        </div>

                                        <div style="width: 20%; display: flex; justify-content: center; align-items: center;">
                                            
                                            <button type="button" class="btn btn-danger btn-sm grayBtn" style="border-radius: 5px; font-size: 11px;">Select</button>

                                            

                                        </div>


                                    </div> -->

                                </div>







                            </div>
                        </div>
                    </div>














            </div>






        </div>







    </div>
</template> 

<script setup>

import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { defineProps, onMounted } from 'vue';
import { useCampaignStore } from "@/stores/CampaignStore";



const campaignStore = useCampaignStore();


const props = defineProps({
    random_string: String
})






onMounted(() => {

    campaignStore.fetchQuizDetails(props.random_string)

})







</script>



<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.rightContentWrapper {

    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
}


.theHeaderWrapper {
    /* text-align: left; */
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}


.backBtnBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* background-color: aquamarine; */

}


/* .editContentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aquamarine;
    width: 100%;
} */


.outerQuizWrapper {
    display: flex;
    justify-content: center;
    /* background-color: #d9e7ff; */
    padding-top: 20px;
    padding-bottom: 50px;
}

.quizBuilderBox {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 700px;
    height: 700px;
    /* background-color: #d5d9d9; */
    border-radius: 10px;
}

.quizBuilderHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    /* height: 50px; */
    background-color: rgb(245, 243, 243);
}


.quizContent {
    padding: 20px;
    /* height: 550px; */
    /* background-color: #d8a1a1; */
    overflow-y: auto;
}

.quizTitle {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.questionItem {
    width: 100%;
    /* background-color: #f4ec13; */
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 25px;
}

.questionText {
    display: flex;
    flex-direction: row;
    font-size: 18p;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    /* background-color: aqua; */
}

.answerText {
    /* background-color: green; */
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 15px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    border-radius: 5px;
    font-size: 15px;

}

.deleteQuestRight {
    width: 10%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: right;
}

.noQuestionsBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(189, 149, 149); */
    height: 100%;
}


.textEditorBox {
    width: 850px;
    /* height: 700px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}


.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}


.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
}

.aiBtn {
    margin-right: 10px;
    /* float: right; */
}

.textEditorWrapper {
    width: 100%;

    /* width: 100%; */
    /* background-color: red; */
}


.btnBox {
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(79, 76, 76);
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    border-bottom: 2px solid rgb(79, 76, 76);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76);
    outline: none;
    min-height: 400px;
    text-align: left;
    max-height: 600px;
    overflow-y: auto;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}



.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}



.pdfOuterWrapper {
    display: flex;
    justify-content: center;
}

.pdfBox {
    min-width: 700px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* background-color: #d9e7ff; */
}

.pdfTitleWrapper {
    padding: 10px;
    width: 100%;
    /* background-color: #adbbd4; */
}

.pdfActionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pdfTopWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    padding: 10px;
}

.theUploadBox {
    display: flex;
}

.pdfContent {
    width: 100%;
    height: 600px;
    overflow-y: auto;
    background-color: #e0eded;
}



.videoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
}

.videoOuterBox {
    display: flex;
    justify-content: center;
}

.videoBox {
    width: 700px;
    /* background-color: #d5d9d9; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.videoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.videoContent {
    width: 100%;
    /* background-color: #d9e7ff; */
    /* height: 400px; */
}

.videoTitleWrapper {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    /* background-color: aqua; */
}

.videoRenderedIframe {
    height: 395px;
    overflow: hidden;
    position: relative;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content { 
    text-align: center;

}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}


.embedBox {
    display: flex;
    flex-direction: column;
}

.grayBtn{
    border: none;
    outline: none;
    background-color: #8b9191;
}

.grayBtn:hover{
    background-color: green;
}

.correctBtn{
    border: none;
    outline: none;
    background-color: #58c35f;
    border-radius: 5px; 
    font-size: 11px;
    font-weight: bold;
}

</style>