<template>
    <div>


        Redirecting...

    </div>
</template>

<script setup>

</script>

<style scoped>

</style>