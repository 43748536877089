<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <WizardProgressBar />

                <div class="innerContainer">

                    <div class="theHeaderWrapper">

                        <div>{{ campaignStore.currentCampaign.name }}</div>

                        <div>
                            <i @click="campaignStore.exitWizard" class="bi bi-x-lg" style="cursor: pointer;"></i>
                        </div>
                    </div>



                                        
                    <!-- {{ campaignStore.post.postData }} | {{ 
                        campaignStore.message.messageData }}  | {{ campaignStore.quiz }} -->

        

                    <!-- {{ campaignStore.quiz }}  | {{ campaignStore.newQuestionObject }} | {{ campaignStore.questions }} -->

                    <!-- {{ campaignStore.message }} -->

                    <div class="wizardWrapper">






                        <div v-for="(wizardStep, index) in campaignStore.wizardSteps" :key="index">

                            <div v-if="wizardStep.step === campaignStore.currentStep">
                                <div v-if="wizardStep.step === 'message_goal_step'" class="messageGoalBox">
                                    <div class="wizardQuestion">Choose your goal</div>

                                    <div class="wizardOptionsWrapper">
                                        <div class="goalItem" v-for="(messageGoal, index) in campaignStore.messageGoals"
                                            :key="index" @click="campaignStore.setSelectedMessageGoal(messageGoal)" :class="{
                                                goalItemSelected:
                                                    campaignStore.post.postData.message_goal_id ===
                                                    messageGoal.id,
                                            }">
                                            <div>
                                                <i :class="messageGoal.icon"></i>
                                            </div>

                                            <div class="optionHeader">
                                                {{ messageGoal.name }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <div></div>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.goToQuizSelectionStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'quiz_selection_step'" class="quizSelectionBox">
                                    <div class="wizardQuestion">
                                        Will This Safety Talk Have A Quiz?
                                    </div>

                                    <div class="wizardOptionsWrapper">
                                        <div class="goalItem" v-for="(willIt, index) in campaignStore.willItHaveQuiz"
                                            :key="index" @click="campaignStore.setWillHaveQuizAnswer(willIt)" :class="{
                                                goalItemSelected:
                                                    campaignStore.post.postData.has_quiz ===
                                                    willIt.answer,
                                            }">
                                            <div class="optionHeader" style="font-size: 20px">
                                                {{ willIt.answer }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px"
                                            @click="campaignStore.goBackToMessageGoal">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px" @click="
                                            campaignStore.navigateToTalkCreationBasedOnMessageGoal
                                            ">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'text_editor_step'" class="textEditorBoxWrapper">

                                    <div class="outerWrapper">

                                        <div class="textEditorBox">

                                            <div class="textEditorHeader">
                                                <div style="width: 50%">
                                                    <b style="float: left">Text Editor</b>
                                                </div>

                                                <div class="editorBtns">

                                                    <button class="button-13 aiBtn" role="button"
                                                        @click="campaignStore.openAIpromptModal">
                                                        Create with AI
                                                    </button>

                                                    <button class="button-13 tempBtn" role="button"
                                                        @click="() => { campaignStore.openTextTalksLibraryModal(); campaignStore.fetchAllTextSafetyTalks(); }">
                                                        Templates
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="titleBox">
                                                <input v-model="campaignStore.post.postData.title" type="text"
                                                    class="form-control titleInput" placeholder="Safety talk title..." />
                                            </div>

                                            <div class="contentInputBody">

                                                <div class="textEditorWrapper">



                                                    <div v-if="editor">

                                                        <div class="btnBox">


                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleBold().run()"
                                                                :disabled="!editor.can().chain().focus().toggleBold().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('bold') }">

                                                                <i class="bi bi-type-bold"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleItalic().run()"
                                                                :disabled="!editor.can().chain().focus().toggleItalic().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('italic') }">

                                                                <i class="bi bi-type-italic"></i>

                                                            </div>


                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleUnderline().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('underline') }">

                                                                <i class="bi bi-type-underline"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setParagraph().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('paragraph') }">

                                                                <i class="bi bi-paragraph"></i>

                                                            </div>




                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 3 }) }">

                                                                <i class="bi bi-type-h3"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 4 }) }">

                                                                <i class="bi bi-type-h4"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 5 }) }">

                                                                <i class="bi bi-type-h5"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('heading', { level: 6 }) }">

                                                                <i class="bi bi-type-h6"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setTextAlign('left').run()"
                                                                :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'left' }) }">

                                                                <i class="bi bi-text-left"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setTextAlign('center').run()"
                                                                :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'center' }) }">

                                                                <i class="bi bi-text-center"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().setTextAlign('right').run()"
                                                                :class="{ 'btnItemSelected': editor.isActive({ textAlign: 'right' }) }">

                                                                <i class="bi bi-text-right"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleBulletList().run()"
                                                                :class="{ 'btnItemSelected': editor.isActive('bulletList') }">

                                                                <i class="bi bi-list-task" style="font-size: 20px;"></i>

                                                            </div>

                                                            <div class="btnItem"
                                                                @click="editor.chain().focus().toggleOrderedList().run()"
                                                                :class="{ 'is-active': editor.isActive('orderedList') }">
                                                                <i class="bi bi-list-ol" style="font-size: 20px;"></i>
                                                            </div>



                                                            <div class="btnItem" @click="triggerFileInput">

                                                                <i class="bi bi-card-image"></i>

                                                                <input type="file" id="fileInput" hidden
                                                                    @change="handleFileChange" accept="image/*">




                                                            </div>




                                                        </div>

                                                    </div>



                                                    <EditorContent :editor="editor" />

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px"
                                            @click="campaignStore.goBackToQuizSelection">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.gotoQuizOrNotAfterTextEditor">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'video_step'" class="videoWrapper">
                                    <div class="videoOuterBox">
                                        <div class="videoBox">
                                            <div class="videoHeader">
                                                <div><b>Video</b></div>

                                                <div>

                                                    <button @click="campaignStore.openVideoEmbedModal" class="button-13"
                                                        style="margin-right: 10px">
                                                        Embed
                                                    </button>

                                                    <button
                                                        @click="() => { campaignStore.fetchAllVideoSafetyTalks(); campaignStore.openVideoLibraryModal(); }"
                                                        class="button-13">Library</button>
                                                </div>
                                            </div>

                                            <div class="videoTitleWrapper">
                                                <input v-model="campaignStore.post.postData.title" type="text"
                                                    class="form-control titleInput" placeholder="Safety talk title..." />
                                            </div>


                                            <div class="videoContent">


                                                <div v-if="campaignStore.renderVideoEmbedCode === ''"
                                                    style="padding-bottom: 30px;">

                                                    No video added yet.

                                                </div>

                                                <div v-else>

                                                    <div v-html="campaignStore.renderVideoEmbedCode"
                                                        class="videoRenderedIframe"></div>

                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px"
                                            @click="campaignStore.goBackToQuizSelection">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.gotoQuizOrNotAfterVideoStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'pdf_step'" class="pdfWrapper">

                                    <div class="pdfOuterWrapper">

                                        <div class="pdfBox">

                                            <div class="pdfTopWrapper">

                                                <div class="pdfActionsWrapper">

                                                    <button
                                                        @click="() => { campaignStore.openPDFLibraryModal(); campaignStore.fetchPDFtalksFromLibrary(); }"
                                                        class="button-13">Library</button>

                                                    <form enctype="multipart/form-data"
                                                        @submit.prevent="campaignStore.savePDF">

                                                        <div class="theUploadBox">


                                                            <input class="form-control" type="file"
                                                                @change="campaignStore.pdfOnChange" accept=".pdf"
                                                                required />

                                                            <button type="submit" class="button-13"
                                                                style="margin-left: 5px">
                                                                Upload
                                                            </button>


                                                        </div>

                                                    </form>


                                                </div>
                                            </div>

                                            <div class="pdfTitleWrapper">
                                                <input v-model="campaignStore.post.postData.title" type="text"
                                                    class="form-control titleInput" placeholder="Safety talk title..." />
                                            </div>


                                            <div v-if="campaignStore.isLoading">

                                                <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>

                                            </div>

                                            <div v-if="campaignStore.source1">

                                                <div class="deletePDFbox">

                                                    <div>
                                                        <b>Preview</b>
                                                    </div>

                                                    <div>
                                                        <i class="bi bi-trash" @click="campaignStore.openDeletePDFmodal()"
                                                            style="font-size: 17px; font-weight: bold; color: red; cursor: pointer;"></i>
                                                    </div>

                                                </div>

                                                <div class="pdfContent">


                                                    <vue-pdf-embed :source="campaignStore.source1" />

                                                </div>

                                            </div>





                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px"
                                            @click="campaignStore.goBackToQuizSelection">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.gotoQuizOrNotAfterPDFStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'quiz_builder_step'" class="quizBuilderWrapper">

                                    <div class="outerQuizWrapper">

                                        <div class="quizBuilderBox">

                                            <div class="quizBuilderHeader">

                                                <div class="quizTitle">

                                                    <div style="margin-bottom: 10px;">Quiz Builder</div>

                                                    <div>
                                                        <input v-model="campaignStore.quiz.quizData.name" readonly
                                                            @click="campaignStore.openQuizDetailsModal" type="text"
                                                            class="form-control" style="width: 200px;"
                                                            placeholder="Give this quiz a name...">
                                                    </div>

                                                </div>

                                                <div class="rightSide">

                                                    <button v-if="campaignStore.post.postData.message_goal_id !== 3"
                                                    @click="campaignStore.openGenQuizWithAIModal"
                                                        class="button-13 aiBtn" role="button">
                                                        Create with AI
                                                    </button>

                                                    <button @click="campaignStore.openAddQuestionModal" class="button-13"
                                                        role="button">
                                                        Add Question +
                                                    </button>
                                                </div>

                                            </div>

                                            <div class="quizContent">


                                                <div v-if="campaignStore.questions.questionsData.length > 0">

                                                    <div class="questionItem"
                                                        v-for="(quest, index) in campaignStore.questions.questionsData"
                                                        :key="index">

                                                        <div class="questionText">

                                                            <!-- {{ quest }} -->

                                                            <div style="width: 90%;">
                                                                {{ quest.question }}
                                                            </div>

                                                            <div class="deleteQuestRight">


                                                                <i @click="campaignStore.openShowEditQuestiosModal"
                                                                style="margin-right: 16px; cursor: pointer;"
                                                                 class="bi bi-pencil"></i>

                                                                <i class="bi bi-trash"
                                                                    @click="() => { campaignStore.setCurrentQuestionIndex(index); campaignStore.openDeleteQuestionModal(); }"
                                                                    style="font-size: 17px; font-weight: bold; cursor: pointer;"></i>
                                                            </div>

                                                        </div>

                                                        <div v-for="(ans, ansIndex) in quest.answersData" :key="ansIndex"
                                                            class="answerText">

                                                            <div style="width: 90%; margin-right: 15px;">
                                                                {{ ans.answer }}
                                                            </div>


                                                            <div style="width: 10%; padding-right: 10px;">

                                                                <span v-if="quest.correctAnswerIndex == ansIndex"
                                                                    class="badge bg-success">Correct
                                                                </span>

                                                            </div>




                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="noQuestionsBox" v-else>

                                                    No questions added yet.


                                                </div>





                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px" @click="
                                            campaignStore.goBackToTalkCreationBasedOnMessageGoal
                                            ">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.gotMessageDetailsStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'message_details_step'" class="messageDetailsBox">
                                    <div class="messageBoxOuterWrapper">
                                        <div class="messageBox">
                                            <div class="mb-3">
                                                <label class="form-label theLabel">Message Name</label>
                                                <input v-model="campaignStore.message.messageData.name" type="text"
                                                    placeholder="Example: Ladder safety message" class="form-control" />
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label theLabel">Message</label>

                                                <textarea v-model="campaignStore.message.messageData.messageContent"
                                                    placeholder="Example: Good morning team, in an effort to prevent fall injuries, please review this ladder safety talk to ensure proper ladder usage on the construction site."
                                                    class="form-control" id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label theLabel">Send Date</label>
                                                <input v-model="campaignStore.message.messageData.send_date" type="date"
                                                    class="form-control" />
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label theLabel">Send Time</label>
                                                <input v-model="campaignStore.message.messageData.send_time" type="time"
                                                    class="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">
                                        <button class="button-31" role="button" style="margin-left: 100px"
                                            @click="campaignStore.goingBackFromMessagDetails">
                                            Previous
                                        </button>

                                        <button class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.goToSaveOrPublishStep">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                <div v-if="wizardStep.step === 'save_or_publish_step'" class="saveOrPublishBox">
                                    <div class="wizardQuestion">
                                        Save as Draft or Publish Now?
                                    </div>

                                    <div class="wizardOptionsWrapper">
                                        <div v-for="(status, index) in campaignStore.statusArray" :key="index"
                                            class="goalItem" @click="campaignStore.setMessageStatus(status)" :class="{
                                                goalItemSelected:
                                                    campaignStore.message.messageData.statusId ===
                                                    status.id,
                                            }">
                                            <div class="optionHeader" style="font-size: 20px">
                                                {{ status.label }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="navBtnsWrapper">

                                        <button :disabled="campaignStore.isLoading" class="button-31" role="button"
                                            style="margin-left: 100px"
                                            @click="campaignStore.goingBackFromSaveAsDraftOrPublish">
                                            Previous
                                        </button>




                                        <button v-if="campaignStore.isLoading" class="button-31"
                                            style="margin-right: 100px">
                                            Saving...
                                        </button>
                                        <button v-else class="button-31" style="margin-right: 100px"
                                            @click="campaignStore.wizardFinished">
                                            Finish
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="campaignStore.showTextTalksLibraryModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Safety Talk Template Library</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeTextTalksLibraryModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">


                    <div class="innerBox">



                        <div v-if="campaignStore.isLoading">
                            <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>
                        </div>


                        <div v-else>

                            <div class="talkItem" v-for="(textTalk, index) in filteredTextTalks" :key="index">

                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ textTalk.title }}
                                    </div>


                                </div>

                                <div class="talkRight">

                                    <button
                                        @click="() => { campaignStore.setCurrentSafetyTalkTemplate(textTalk); campaignStore.openPreviewTalkLibraryModal(); }"
                                        class="button-13"><b>Preview</b></button>

                                </div>


                            </div>

                        </div>






                    </div>




                </div>
            </div>
        </div>

        <div v-if="campaignStore.showPreviewTalkFromLibraryModal" class="preview-modal-overlay">

            <div class="preview-modal-box" style="width: 900px">

                <div class="preview-model-box-header">

                    <div style="font-size: 17px; max-width: 500px; text-align: left;">
                        <b>{{ campaignStore.currentSafetyTalkTemplate.title }} </b>
                    </div>


                    <div>

                        <div v-if="campaignStore.currentSafetyTalkTemplate.content_type === 'text'">

                            <button @click="populateSelectedContentInEditor()" class="btn btn-dark"
                                style="margin-right: 35px;">Use This Template</button>

                            <i @click="campaignStore.closePreviewTalkLibraryModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer; "></i>

                        </div>

                        <div v-if="campaignStore.currentSafetyTalkTemplate.content_type === 'pdf'">

                            <button v-if="campaignStore.isLoading" class="btn btn-dark"
                                style="margin-right: 35px;"><b>Assigning PDF...</b></button>

                            <button v-else @click="campaignStore.setSelectedPDFfromLibrary" class="btn btn-dark"
                                style="margin-right: 35px;">Use This Template</button>

                            <i @click="campaignStore.closePreviewTalkLibraryModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer; "></i>

                        </div>

                        <div v-if="campaignStore.currentSafetyTalkTemplate.content_type === 'video'">

                            <button v-if="campaignStore.isLoading" class="btn btn-dark"
                                style="margin-right: 35px;"><b>Assigning Video...</b></button>

                            <button v-else class="btn btn-dark" @click="campaignStore.populateTheSelectedVideo"
                                style="margin-right: 35px;">Use This Video</button>

                            <i @click="campaignStore.closePreviewTalkLibraryModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer; "></i>

                        </div>



                    </div>
                </div>



                <div class="preview-modal-content">

                    <div class="previewModalContentBody"
                        :class="{ previewModalContentBodyForVideo: campaignStore.currentSafetyTalkTemplate.content_type === 'video' }">


                        <div v-if="campaignStore.currentSafetyTalkTemplate.content_type === 'text'">

                            <div v-html="campaignStore.currentSafetyTalkTemplate.body"></div>

                        </div>

                        <div v-if="campaignStore.currentSafetyTalkTemplate.content_type === 'pdf'">

                            <vue-pdf-embed :source="campaignStore.currentSafetyTalkTemplate.pdf_url_path" />

                        </div>

                        <div v-if="campaignStore.currentSafetyTalkTemplate.content_type === 'video'">



                            <div v-html="campaignStore.currentSafetyTalkTemplate.video_embed_code"></div>

                        </div>




                    </div>



                </div>
            </div>
        </div>


        <div v-if="campaignStore.showAIpromptModal" class="ai-prompt-modal-overlay">

            <div class="ai-prompt-modal-box" style="width: 550px">

                <div class="ai-prompt-model-box-header">

                    <div style="font-size: 18px; max-width: 500px; text-align: left;">
                        <b>AI Toolbox Talk Creator</b>
                    </div>


                    <div>

                        <i @click="campaignStore.closeAIpromptModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer; "></i>
                    </div>
                </div>



                <div class="ai-prompt-modal-content">


                    <form @submit.prevent="campaignStore.generateAIcontent">


                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>Select Language</b></label>

                            <select class="form-select" v-model="campaignStore.promptContent.language">


                                <option v-for="(language, index) in campaignStore.languages" :key="index"
                                    :value="language.type">
                                    {{ language.type }}
                                </option>

                            </select>

                        </div>


                        <div class="mb-3" style="margin-top: 23px;">

                            <label class="form-label" style="float: left;"><b>What kind of toolbox talk should the AI
                                    create?</b></label>

                            <textarea type="text" class="form-control titleInput"
                                v-model="campaignStore.promptContent.prompt" style="height: 200px;"
                                placeholder="Example: Write a safety toolbox talk about ladder safety for a construction company that specializes in roofing."></textarea>

                        </div>

                        <div>



                          

                            <div v-if="campaignStore.isLoading" class="spinner-grow" role="status">
                                <span class="visually-hidden">Creating talk...</span>
                            </div>

                            <button v-else type="submit" class="btn btn-dark createBTN">
                                <b>Generate</b>
                            </button>

                            <p style="font-size: 12px; margin-top: 10px;">
                                DISCLAIMER: The content generated by the AI system may occasionally contain errors or inaccuracies. <b>We strongly recommend reviewing and verifying the AI-generated content before use.</b> Our service is provided "as is," and we do not accept responsibility for any inaccuracies, errors, or liabilities that may arise from the use of this content. 
                            </p>

                        </div>



                    </form>





                </div>
            </div>
        </div>



        <div v-if="campaignStore.showAIresultsModal" class="ai-results-modal-overlay">

            <div class="ai-results-modal-box" style="width: 900px">

                <div class="ai-results-model-box-header">

                    <div style="font-size: 17px">

                        <button @click="campaignStore.closeAIresultsModal" class="button-13">Regenerate</button>
                    </div>



                    <div>

                        <button @click="useTheAItalkGenerated" class="btn btn-dark"><b>Use This Talk</b></button>

                    </div>

                </div>



                <div class="ai-results-modal-content">


                    <div class="aiResultsbox">


                        <div class="aiTitle">


                            {{ campaignStore.theAIGenerateTalk.title }}

                        </div>


                        <div class="aiContent">

                            <div v-html="campaignStore.theAIGenerateTalk.body"></div>



                        </div>








                    </div>




                </div>
            </div>
        </div>

        <div v-if="campaignStore.showQuizDetailsModal" class="modal-overlay">

            <div class="modal-box" style="width: 400px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Quiz Details</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeQuizDetailsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>



                <div class="modal-content">

                    <div class="mb-3">

                        <label class="form-label" style="float: left;"><b>Name</b></label>
                        <input v-model="campaignStore.quiz.quizData.name" type="text" class="form-control titleInput"
                            placeholder="Example: Ladder safety quiz" />

                    </div>

 
                    <div class="mb-3">

                        <label class="form-label" style="float: left;"><b>Pass Score</b></label>

                        <select class="form-select" v-model="campaignStore.quiz.quizData.min_pass_score">

                            <option v-for="(passScore, index) in campaignStore.passScores" :key="index"
                                :value="passScore.score">
                                {{ passScore.score }}%
                            </option>


                        </select>



                    </div>







                </div>
            </div>
        </div>


        <div v-if="campaignStore.showAddQuestionModal" class="modal-overlay">

            <div class="modal-box" style="width: 700px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Add New Question</b>
                    </div>



                    <div>
                        <i @click="campaignStore.closeAddQuestionModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>



                <div style="width: 100%;">
                    <hr style="margin-top: -10px;">


                    <button @click="campaignStore.addToQuestionsDataArray" class="button-13" style="float: left;">Add
                        Question +</button>

                    <button @click="campaignStore.addNewAnswer" class="button-13" style="float: right;">Add Answer
                        +</button>
                </div>



                <div class="modal-content" style="padding-top: 30px; height: 600px; overflow-y: auto;">


                    <div class="mb-3" style="padding-left: 10px; padding-right: 10px;">

                        <label class="form-label" style="float: left;"><b>Question</b></label>

                        <input v-model="campaignStore.newQuestionObject.question" type="text"
                            class="form-control titleInput" placeholder="Paste question here..." />

                    </div>


                    <div class="answerBox" v-for="(ans, index) in campaignStore.newQuestionObject.answersData" :key="index">

                        <div class="answerLeft">

                            <div>
                                <b>Option</b>
                            </div>

                            <div style="margin-top: 6px; margin-bottom: 6px;">

                                <input v-model="ans.answer" type="text" class="form-control titleInput"
                                    placeholder="Paste answer here..." />

                            </div>

                            <div>

                                <input @click="campaignStore.setCorrectAnswerIndex(index)" class="form-check-input"
                                    type="radio" name="flexRadioDefault" required />

                            </div>


                        </div>

                        <div class="answerRight">

                            <i class="bi bi-trash" @click="campaignStore.deleteAnswer(index)"
                                style="font-size: 17px; font-weight: bold; color: red; cursor: pointer;"></i>

                        </div>



                    </div>








                </div>
            </div>
        </div>

        <div v-if="campaignStore.showDeleteQuestionModal" class="modal-overlay">

            <div class="modal-box" style="width: 400px">

                <div class="model-box-header">
                    <div style="font-size: 17px; text-align: left;">
                        <b>Are you sure you want to delete this question and it's answers?</b>
                    </div>



                    <div>
                        <i @click="campaignStore.closeDeleteQuestionModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>




                <div class="modal-content" style="padding-top: 20px;">



                    <button @click="campaignStore.deleteQuestionFromArray" class="btn btn-danger"
                        style="width: 100%;"><b>Yes, Delete!</b></button>



                </div>
            </div>
        </div>

        <div v-if="campaignStore.showDeletePDFmodal" class="modal-overlay">

            <div class="modal-box" style="width: 400px">

                <div class="model-box-header">
                    <div style="font-size: 17px; text-align: left;">
                        <b>Are you sure you want to delete this PDF?</b>
                    </div>



                    <div>
                        <i @click="campaignStore.closeDeletePDFmodal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>




                <div class="modal-content" style="padding-top: 20px;">



                    <button @click="campaignStore.deletePDF" class="btn btn-danger" style="width: 100%;"><b>Yes,
                            Delete!</b></button>



                </div>
            </div>
        </div>


        <div v-if="campaignStore.showPDFlibraryModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Safety Talk Template Library</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closePDFLibraryModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">


                    <div class="innerBox">



                        <div v-if="campaignStore.isLoading">
                            <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>
                        </div>


                        <div v-else>


                            <div class="talkItem" v-for="(talk, index) in filteredPDFTalks" :key="index">

                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ talk.title }}
                                    </div>


                                </div>
                                <div class="talkRight">



                                    <button
                                        @click="() => { campaignStore.setCurrentSafetyTalkTemplate(talk); campaignStore.openPreviewTalkLibraryModal(); }"
                                        class="button-13"><b>Preview</b></button>

                                </div>


                            </div>

                        </div>






                    </div>




                </div>
            </div>
        </div>


        <div v-if="campaignStore.showEmbedVideoModal" class="modal-overlay">

            <div class="modal-box" style="width: 600px">

                <div class="model-box-header">
                    <div style="font-size: 17px; text-align: left;">
                        <b>Embed video</b>
                    </div>



                    <div>
                        <i @click="campaignStore.closeVideoEmbedModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>




                <div class="modal-content" style="padding-top: 10px;">


                    <div class="embedBox">

                        <textarea class="form-control" style="resize: none;" placeholder="Paste video embed code here..."
                            v-model="campaignStore.pastedEmbedVideoCode" cols="30" rows="10" required></textarea>

                        <button @click="campaignStore.setPastedCodeToRender" class="btn btn-dark"
                            style="width: 100%; margin-top: 15px;"><b>Add</b></button>


                    </div>




                </div>
            </div>
        </div>


        <div v-if="campaignStore.showVideoLibraryModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Safety Talk Template Library</b>
                    </div>

                    <div>
                        <i @click="campaignStore.closeVideoLibraryModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-search-box" style="padding: 12px;">

                    <input v-model="searchQuery" type="text" class="form-control titleInput" placeholder="Search..." />

                </div>

                <div class="modal-content">


                    <div class="innerBox">



                        <div v-if="campaignStore.isLoading">
                            <span aria-hidden="true" class="m-2 spinner-grow text-success"></span>
                        </div>


                        <div v-else>


                            <div class="talkItem" v-for="(talk, index) in filteredVideoTalks" :key="index">

                                <div class="talkLeft">

                                    <div class="talkTitle">
                                        {{ talk.title }}
                                    </div>


                                </div>
                                <div class="talkRight">



                                    <button
                                        @click="() => { campaignStore.setCurrentSafetyTalkTemplate(talk); campaignStore.openPreviewTalkLibraryModal(); }"
                                        class="button-13"><b>Preview</b></button>

                                </div>


                            </div>

                        </div>






                    </div>




                </div>
            </div>
        </div>


        <div v-if="campaignStore.showGenQuizWithAIModal" class="ai-prompt-modal-overlay">

            <div class="ai-prompt-modal-box" style="width: 550px">

                <div class="ai-prompt-model-box-header">

                    <div style="font-size: 18px; max-width: 500px; text-align: left;">
                        <b>AI Quiz Creator</b>
                    </div>


                    <div>

                        <i @click="campaignStore.closeGenQuizWithAIModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer; "></i>
                    </div>
                </div>




                <div class="ai-prompt-modal-content"> 



                    <form v-if="campaignStore.post.postData.message_goal_id === 1"
                    @submit.prevent="campaignStore.generateQuizText">

                    


                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>Select Language</b></label>

                            <select class="form-select" v-model="campaignStore.quizTextCreatorFormData.language" required>


                                <option v-for="(language, index) in campaignStore.languages" :key="index"
                                    :value="language.type">
                                    {{ language.type }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>How many questions?</b></label>


                            <input v-model="campaignStore.quizTextCreatorFormData.amt_of_questions" type="number" min="2"
                                max="7" class="form-control titleInput" required />

                        </div>


                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>How many answers per question?</b></label>


                            <input v-model="campaignStore.quizTextCreatorFormData.amt_of_answers_per_question" type="number"
                                min="2" max="5" class="form-control titleInput" required />

                        </div>





                        <div>



                            <div v-if="campaignStore.isLoading" class="spinner-grow" role="status">
                                <span class="visually-hidden">Creating quiz...</span>
                            </div>

                            <button v-else type="submit" class="btn btn-dark createBTN">
                                <b>Generate</b>
                            </button>

                        </div>



                    </form>

                    <form v-if="campaignStore.post.postData.message_goal_id === 2"
                    @submit.prevent="campaignStore.generateQuizFromPDF">

             

                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>Select Language</b></label>

                            <select class="form-select" v-model="campaignStore.quizPDFCreatorFormData.language" required>


                                <option v-for="(language, index) in campaignStore.languages" :key="index"
                                    :value="language.type">
                                    {{ language.type }}
                                </option>

                            </select>

                        </div>

                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>How many questions?</b></label>


                            <input v-model="campaignStore.quizPDFCreatorFormData.amt_of_questions" type="number" min="2"
                                max="7" class="form-control titleInput" required />

                        </div>


                        <div class="mb-3">

                            <label class="form-label" style="float: left;"><b>How many answers per question?</b></label>


                            <input v-model="campaignStore.quizPDFCreatorFormData.amt_of_answers_per_question" type="number"
                                min="2" max="5" class="form-control titleInput" required />

                        </div>





                        <div>



                            <div v-if="campaignStore.isLoading" class="spinner-grow" role="status">
                                <span class="visually-hidden">Creating quiz...</span>
                            </div>

                            <button v-else type="submit" class="btn btn-dark createBTN">
                                <b>Generate</b>
                            </button>

                        </div>



                    </form>


                    <p style="font-size: 12px; margin-top: 10px;">
                                DISCLAIMER: The content generated by the AI system may occasionally contain errors or inaccuracies. <b>We strongly recommend reviewing and verifying the AI-generated content before use.</b> Our service is provided "as is," and we do not accept responsibility for any inaccuracies, errors, or liabilities that may arise from the use of this content. 
                            </p>




                </div>
            </div>
        </div>


        <div v-if="campaignStore.showAIQuizResultsModal" class="ai-results-modal-overlay">

            <div class="ai-results-modal-box" style="width: 900px">

                <div class="ai-results-model-box-header">

                    <div style="font-size: 17px">

                        <button @click="campaignStore.closeAIQuizResultsModal" class="button-13">Regenerate</button>
                    </div>



                    <div>

                        <button @click="campaignStore.useThisQuizResults" class="btn btn-dark"><b>Use This Quiz</b></button>

                    </div>

                </div>



                <div class="ai-results-modal-content">


                    <div class="aiResultsbox" style="padding: 20px;">




                        <div v-for="(question, index) in campaignStore.questionsCreatedByAIArray" :key="index"
                            class="questionBox">

                            <div class="questionHeader">

                                {{ question.question_text }}

                            </div>


                            <div v-for="(answer, index) in question.answers" :key="index" class="answetItem">

                                <div class="answerBody" style="width: 90%">

                                    {{ answer.answer_text }}
                                </div>

                                <div class="correctBox" style="width: 10%">

                                    <span v-if="answer.is_correct" class="badge bg-success">Correct</span>

                                    <span v-else class="badge bg-danger">Incorrect</span>

                                </div>

                            </div>

                        </div>







                    </div>




                </div>
            </div>
        </div>

        <div v-if="campaignStore.showEditQuestiosModal" class="ai-results-modal-overlay">

            <div class="ai-results-modal-box" style="width: 900px">

                <div class="ai-results-model-box-header">

                    <div style="font-size: 17px">

                        <button @click="campaignStore.closeShowEditQuestiosModal" class="button-13">Close</button>
                    </div>



                    <div>

                        <!-- <button class="btn btn-dark"><b>Save Changes</b></button> -->

                    </div>

                </div>



                <div class="ai-results-modal-content">


                    <div class="aiResultsbox" style="padding: 20px;">




                        <div v-for="(quest, index) in campaignStore.questions.questionsData" :key="index"
                            class="questionBox">

                            <div class="questionHeader">

                                <input v-model="quest.question" type="text" style="font-weight: bolder;"
                                    class="form-control titleInput" placeholder="Paste question here..." />


                            </div>


                            <div class="answerBox" v-for="(ans, ansIndex) in quest.answersData" :key="ansIndex"
                            
                            >

                                <div class="answerLeft">



                                    <div style="margin-top: 6px; margin-bottom: 6px;">

                                        <input v-model="ans.answer" type="text" class="form-control titleInput"
                                            placeholder="Paste answer here..." />

                                    </div>

                                    <div>

                                        <input @click="campaignStore.setCorrectAnswerIndexInQuestionsData(index, ansIndex)"
                                        class="form-check-input" type="radio" 
                                        :name="'flexRadioDefault' + index" 
                                            :checked="quest.correctAnswerIndex === ansIndex" required />


                                    </div>


                                </div>

                                <div class="answerRight">

                                    <i class="bi bi-trash" @click="campaignStore.deleteAnswerInQuestionsArray(index, ansIndex)"
                                        style="font-size: 17px; font-weight: bold; color: red; cursor: pointer;"></i>

                                </div>



                            </div>

                         

                        </div>







                    </div>




                </div>
            </div>
        </div>




    </div>
</template>

<script setup>
import { defineProps, onMounted, ref, computed } from "vue";
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import WizardProgressBar from "@/components/WizardProgressBar.vue";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import VuePdfEmbed from 'vue-pdf-embed'

// eslint-disable-next-line
const props = defineProps({
    random_string: String,
});

const campaignStore = useCampaignStore();


const editor = ref(
    useEditor({

        content: "",
        extensions: [
            StarterKit,
            Underline,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                defaultAlignment: 'left',
                alignments: ['left', 'center', 'right'],

            }),
            Image.configure({
                allowBase64: true,
            })
        ],
        // eslint-disable-next-line 
        onUpdate: ({ editor }) => {
            updateContent();
        }

    })
);


const updateContent = () => {
    campaignStore.post.postData.content = editor.value.getHTML();
};



const triggerFileInput = () => {
    document.getElementById('fileInput').click();
};


const handleFileChange = async (event) => {
    try {
        // Wait for the image URL to be returned
        let imageUrl = await campaignStore.handleImageUploadToServer(event);

        // Add the image into the editor
        if (imageUrl) {
            editor.value.chain().focus().setImage({ src: imageUrl }).run();
        } else {
            alert('Failed to upload image');
        }
    } catch (error) {
        console.error(error);
        alert('Failed to upload image');
    }
};



const searchQuery = ref('');



const filteredTextTalks = computed(() => {

    return campaignStore.allTextTalks
        .filter(talk =>
            talk.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            talk.body.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
});

const filteredPDFTalks = computed(() => {

    return campaignStore.allPDFSafetyTalks
        .filter(talk =>
            talk.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
});

const filteredVideoTalks = computed(() => {

    return campaignStore.allVideoSafetyTalks
        .filter(talk =>
            talk.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
});



const populateSelectedContentInEditor = () => {


    campaignStore.post.postData.title = campaignStore.currentSafetyTalkTemplate.title;
    campaignStore.post.postData.content = campaignStore.currentSafetyTalkTemplate.body;

    if (editor.value) {

        let stringToRemove = "<div><br></div>"; //causes extra spaces
        let regex = new RegExp(stringToRemove, 'g');

        // Remove all instances of the string
        let updatedBody = campaignStore.currentSafetyTalkTemplate.body.replace(regex, '');

        // Set the content of the editor
        editor.value.commands.setContent(updatedBody);
    }


    campaignStore.closePreviewTalkLibraryModal()
    campaignStore.closeTextTalksLibraryModal()




}

const useTheAItalkGenerated = () => {

    campaignStore.post.postData.title = campaignStore.theAIGenerateTalk.title;
    campaignStore.post.postData.content = campaignStore.theAIGenerateTalk.body;

    if (editor.value) {

        // Set the content of the editor
        editor.value.commands.setContent(campaignStore.theAIGenerateTalk.body);

        campaignStore.closeAIresultsModal()
        campaignStore.closeAIpromptModal()

    }



}


onMounted(() => {
    campaignStore.fetchCampaignDetailsOnly(props.random_string);
});
</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(170, 156, 156); */
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 45px;
    height: 100%;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.wizardWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    margin-top: 30px;
    /* height: 100%; */
}

.wizardOptionsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.goalItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: 250px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: white;
    font-weight: bold;
    cursor: pointer;
}

.goalItem:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 250px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.goalItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 250px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.wizardQuestion {
    font-size: 25px;
}

.optionHeader {
    margin-top: 15px;
}

.theOptionIcon {
    font-size: 30px;
    font-weight: bold;
}

.textEditorBoxWrapper {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    width: 100%;
    /* background-color: #10cc2c; */
}

.textEditorBox {
    width: 850px;
    /* height: 700px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.outerWrapper {
    display: flex;
    justify-content: center;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

/* .button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
} */

.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}

.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
}

.aiBtn {
    margin-right: 10px;
    /* float: right; */
}

.tempBtn {
    float: right;
}

.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}

.quizBuilderWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* background-color: aquamarine; */
    width: 100%;
    margin-top: 20px;
}

.outerQuizWrapper {
    display: flex;
    justify-content: center;
}

.quizBuilderBox {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 700px;
    height: auto;
    /* background-color: #d5d9d9; */
    border-radius: 10px;
}

.quizBuilderHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    /* height: 50px; */
    background-color: rgb(245, 243, 243);
}

.quizContent {
    padding: 20px;
    height: 550px;
    /* background-color: #d8a1a1; */
    overflow-y: auto;
}

.quizTitle {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.messageDetailsBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* background-color: #d5d9d9; */
    margin-top: 20px;
}

.messageBox {
    /* background-color: #0f1111; */
    width: 550px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.theLabel {
    float: left;
    font-weight: bold;
}

.saveOrPublishBox {
    margin-top: 30px;
}

.videoWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
}

.videoOuterBox {
    display: flex;
    justify-content: center;
}

.videoBox {
    width: 700px;
    /* background-color: #d5d9d9; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.videoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.videoContent {
    width: 100%;
    /* background-color: #d9e7ff; */
    /* height: 400px; */
}

.videoTitleWrapper {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    /* background-color: aqua; */
}

.pdfWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pdfBox {
    min-width: 700px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* background-color: #d9e7ff; */
}

.pdfTitleWrapper {
    padding: 10px;
    width: 100%;
    /* background-color: #adbbd4; */
}

.pdfActionsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pdfTopWrapper {
    width: 100%;
    /* background-color: antiquewhite; */
    padding: 10px;
}

.theUploadBox {
    display: flex;
}

.pdfContent {
    width: 100%;
    height: 600px;
    overflow-y: auto;
    background-color: #e0eded;
}

.navBtnsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;
    /* background-color: #679191; */
}

.button-31 {
    background-color: #222;
    border-radius: 5px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    height: 35px;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 120px;
}

.button-31:hover,
.button-31:focus {
    opacity: 0.75;
}

.messageBoxOuterWrapper {
    display: flex;
    justify-content: center;
}

.pdfOuterWrapper {
    display: flex;
    justify-content: center;
}

/* .ProseMirror {
    padding: 10px;
    border: 2px solid black;
    outline: none;
    border-radius: 5px;

} */


.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    border-bottom: 2px solid rgb(79, 76, 76);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76);
    outline: none;
    min-height: 400px;
    text-align: left;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}


.textEditorWrapper {
    width: 100%;
    /* width: 100%; */
    /* background-color: red; */
}


.btnBox {
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(79, 76, 76);
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.is-active {
    background-color: #d5d9d9;
    padding: 5px;
}

.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;

}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.modal-search-box {
    /* background-color: red; */
    margin-bottom: 15px;
}

.innerBox {
    /* background-color: red; */
    overflow-y: auto;
    width: 100%;
    height: 600px;
    padding: 12px;
}

.talkItem {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.talkItem:hover {
    background-color: rgb(249, 253, 252);
}

.talkLeft {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 80%;
    /* background-color: #aadada; */
}

.talkRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    /* padding: 10px; */
    /* background-color: #3ddbdb; */
}


.talkTitle {
    /* background-color: #d9e7ff; */
    text-align: left;
    font-weight: bold;
    padding: 6px;
}

.talkBody {
    /* background-color: #3f84fa; */
    text-align: left;
    padding-left: 5px;
    padding-bottom: 5px;
    /* margin-top: 7px; */
    /* padding: 6px; */

}



.preview-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 60, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 9999;
    /* Ensure the modal is on top */
}


/* Styles for the modal box */
.preview-modal-box {
    background-color: white;
    width: 900px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.preview-model-box-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 35px;
}

.preview-modal-content {
    text-align: center;
}

.previewModalContentBody {
    text-align: left;
    height: 700px;
    width: 100%;
    height: 700px;
    /* background-color: red; */
    overflow-y: auto;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 6px;
}

.previewModalContentBodyForVideo {
    text-align: left;
    height: 700px;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    overflow-y: auto;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 6px;
}


.ai-prompt-modal-overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 60, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;

}

.ai-prompt-modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ai-prompt-model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.ai-prompt-modal-content {
    text-align: center;
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}



.ai-results-modal-overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 9999;
}

.ai-results-modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.ai-results-model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}


.ai-results-modal-content {
    text-align: center;
}

.aiResultsbox {
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    height: 600px;
    /* background-color: red; */
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;

}

.aiTitle {
    background-color: #f2f4f3;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 15px;
}

.aiContent {
    /* background-color: rgb(176, 156, 156); */
    width: 100%;
    padding: 15px;
    text-align: left;
}

.answerBox {
    width: 100%;
    /* background-color: #90c1a9; */
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    padding-left: 10px;

}

.answerLeft {
    display: flex;
    flex-direction: column;
    width: 90%;
    /* background-color: #f3bfbf; */
    text-align: left;
}

.answerRight {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 10%;
    margin-top: 10px;
    /* background-color: #c19797; */
}


.questionItem {
    width: 100%;
    /* background-color: #f4ec13; */
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    /* cursor: pointer; */
}

/* .questionItem:hover {
    background-color: #f5f6f5;
} */

.questionText {
    display: flex;
    flex-direction: row;
    font-size: 18p;
    font-weight: bold;
    text-align: left;
    /* background-color: aqua; */
}

.answerText {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: green; */
    text-align: left;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-size: 15px;

}

.deleteQuestRight {
    width: 10%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: right;
}

.noQuestionsBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(189, 149, 149); */
    height: 100%;
}

.deletePDFbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: antiquewhite;
    width: 100%;
    padding: 10px;
}

.embedBox {
    display: flex;
    flex-direction: column;
}

.videoRenderedIframe {
    height: 395px;
    overflow: hidden;
    position: relative;
}

.questionBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    background-color: #f8f8f8;
    padding: 10px;

}


.questionHeader {
    text-align: left;
    margin-bottom: 10px;
    font-weight: bold;
}

.answetItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 10px;
    padding: 7px;
    margin-bottom: 10px;



}
</style>
