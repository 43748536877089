import apiClient from "./MyClient";

export default { 



  getAllSOPFiles(){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/sop/files",);

  },

  getThisFileDetails(uuid) { 

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.get("/api/sop/file/" + uuid);

  },

  uploadSOPFile(formData) { 

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/sop/file/upload", formData, config);

  },


  generatePDF(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/sop/generate/pdf", formData, { responseType: 'blob' });

  },

  uploadTextEditorImage(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    //for file uploads IMPORTANT
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return apiClient.post("/api/sop/texteditor/image/upload", formData, config);

  },


  updateFileName(formData, randomString) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/rename/" + randomString, formData);

  },

  deleteFile(randomString) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/file/delete/" + randomString);

  },



  createNewTextContentFile() {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.get("/api/sop/new/textcontent");

  },


  updateTextEditorContent(uuid, formData) { 

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/update/textcontent/" + uuid, formData);

  },

  togglePublish(uuid, formData) {  

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/toggle/publish/" + uuid, formData);

  },


  aiGenerateResponse(formData, uuid) {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/new/chatmessage/" + uuid, formData);

  },


  deleteChatHistory(uuid) {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/file/chathistory/delete/" + uuid);

  },


  getLiveFileDetails(uuid) {  

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.get("/api/sop/file/live/" + uuid);

  },


  aiGenerateResponseForDoc(formData, uuid) {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/new/doc/chatmessage/" + uuid, formData);

  },


  getAllSOPTeams() {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.get("/api/sop/teams");

  },

  showSOPTeam(uuid) {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.get("/api/sop/team/" + uuid);

  },

  storeNewSOPTeam(formData) {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/team/store/", formData);

  },

  updateSOPTeamName(formData, uuid) {   

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/team/update/" + uuid, formData);

  },

  deleteSOPTeam(uuid){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/team/delete/" + uuid);


  },

  deleteEmployeeFromSOPApp(uuid){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.post("/api/sop/team/delete/employee/" + uuid);


  },


  getAllTeamsFromAllApps(){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;


    return apiClient.get("/api/all/apps/teams/");


  },



  getAllSOPFilesForEmployee(){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/employee/all/sop-files",);

  },

  copyTeamFromOtherAppsToSOPApp(formData){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/copy/team/to/sop", formData);

  },


  getTeamAccessListForFile(uuid) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/file/sop/teams/selectedornot/" + uuid);

  },

  saveUpdatedTeamAccessList(formData) {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/file/sop/teams/update/selected/", formData);

  },





  
  


  


  


  







};
