<template>
    <div class="loginWrapper">

        <div class="topNav">

            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />

        </div>


        <div class="errorWrapper">

            <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>

            <div v-if="authStore.errorArray.length > 0" class="alert alert-danger" role="alert" style="max-width: 400px; margin-top: 20px;">
                <ul>
                    <li v-for="(error, index) in authStore.errorArray" :key="index">
                    {{ error }}
                    </li>
                </ul>
            </div>


           

        </div>
            


        <div class="formWrapper">

            


            <form class="theFormBox" @submit.prevent="authStore.registerManager">

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Company Name</label>
                    <input v-model="authStore.managerRegisterFormData.company_name" type="text" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">First Name</label>
                    <input v-model="authStore.managerRegisterFormData.fname" type="text" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Last Name</label>
                    <input v-model="authStore.managerRegisterFormData.lname" type="text" class="form-control" required>
                </div>

                <div class="mb-3">

                    <label class="form-label" style="float: left;">Country</label>
                    
                    <select class="form-select"
                    v-model="authStore.managerRegisterFormData.country_id"
                    >

                        <option v-for="(country, index) in authStore.countries" :key="index" :value="country.id">
                          {{ country.name }}
                        </option>
                     
                    </select>

                </div>


                <div class="mb-3">
                    <label class="form-label" style="float: left;">Contact Number</label>
                    <input v-model="authStore.managerRegisterFormData.phone_number" type="text" class="form-control" required>
                </div>


                <div class="mb-3">
                    <label class="form-label" style="float: left;">Work Email</label>
                    <input v-model="authStore.managerRegisterFormData.email" type="email" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Password</label>
                    <input v-model="authStore.managerRegisterFormData.password" type="password" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Confirm Password</label>
                    <input v-model="authStore.managerRegisterFormData.password_confirmation" type="password" class="form-control" required>
                </div>



                <div v-if="authStore.isLoading">

                    <button type="button" class="btn btn-primary loginBTN" disabled>Loading...</button>

                </div>

                <div v-else>

                    <button type="submit" class="btn btn-primary loginBTN">SIGN UP</button>

                </div>

            </form>

      
 




        </div>

    </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();


</script>

<style scoped>
.imgLogo {
    /* float: left; */
    width: 80px;

}

.loginWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;

    /* background-color: red; */
}

.topNav {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
    width: 100%;
    height: 60px;
    margin-top: 30px;
    /* top: 0px; */
  
}

.formWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(210, 124, 10); */
    width: 100%;
    height: 100%;
}


.theFormBox{
    width: 500px;
    padding: 20px;
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}


.form-control {
    background-color: rgb(243, 246, 246);
}

.errorWrapper{
    display: flex;
    justify-content: center;
}

</style>