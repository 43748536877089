import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'


import AppsHome from '../views/Apps/AppsHome'
import ToolboxTalkHome from '../views/ToolboxTalks/ToolboxHome'
import ViewACampaign from '../views/Campaigns/ViewACampaign'
import CreateAMessage from '../views/Campaigns/CreateAMessage'
import EditPostContent from '../views/Campaigns/EditPostContent'
import EditQuiz from '../views/Campaigns/EditQuiz'

import ViewEmployeesAckStatus from '../views/Campaigns/ViewEmployeesAckStatus'
import ViewAllEmployees from '../views/ManagerEmployees/ViewAllEmployees'
import ViewTalksForAEmployee from '../views/ManagerEmployees/ViewTalksForAEmployee'


import AdminRegister from '../views/SuperAdmin/AdminRegister'

import ManagerRegister from '../views/Auth/Manager/ManagerRegister'
import LoginUser from '../views/Auth/LoginUser'
import ForgotPassword from '../views/Auth/ForgotPassword'
import ResetPassword from '../views/Auth/ResetPassword'
import VerifyAuthCode from '../views/Auth/VerifyAuthCode'

import EmployeeRegister from '../views/Auth/Employee/EmployeeRegister'

import ViewAllTeams from '../views/Teams/ViewAllTeams'
import ViewATeam from '../views/Teams/ViewATeam'
import ViewAllLibraryTalks from '../views/Library/ViewAllLibraryTalks.vue'


import HomeDefault from '../views/HomeDefault'

import EmployeeAppsPage from '../views/Employee/EmployeeAppsPage'
import ViewAllTalks from '../views/Employee/ViewAllTalks'
import EmployeeViewGroupTalkPost from '../views/Employee/ViewGroupTalk'
import ViewTalk from '../views/Employee/ViewTalk'
import ViewAllGroupTalks from '../views/Employee/ViewAllGroupTalks'
import EmployeeAccount from '../views/Employee/EmployeeAccount'
import EmployeeViewAllSOPFiles from '../views/Employee/EmployeeViewAllSOPFiles'
import EmployeeViewADocumentLive from '../views/Employee/EmployeeViewADocumentLive'



import ManagerViewAllGroupTalks from '../views/GroupTalks/ManagerViewAllGroupTalks' 
import ViewAGroupTalk from '../views/GroupTalks/ViewAGroupTalk' 
import CreateAGroupTalkWizard from '../views/GroupTalks/CreateAGroupTalkWizard'
import ViewAGroupTalkPostContent from '../views/GroupTalks/ViewAGroupTalkPostContent'
import ViewSupervisorsGroupTalks from '@/views/GroupTalks/ViewSupervisorsGroupTalks.vue'
import ViewGroupTalkAttendees from '../views/GroupTalks/ViewGroupTalkAttendees'
import GroupTalkReports from '@/views/GroupTalks/GroupTalkReports.vue' 

import SubAdminRegister from '../views/Auth/SubAdmin/SubAdminRegister'

import ViewAllSubManagers from '../views/SubManagers/ViewAllSubManagers'
import MainReport from '../views/Reports/MainReport'

import UserAccount from '../views/Account/UserAccount'

import ViewAllSOPFiles from '../views/SOP/ViewAllSOPFiles'
import ViewADocument from '../views/SOP/ViewADocument'
import ViewADocumentLive from '../views/SOP/ViewADocumentLive'
// import ViewAllSOPTeams from '../views/SOP/ViewAllSOPTeams'
import ViewAllTeamsInSOP from '../views/SOP/ViewAllTeamsInSOP'
import ViewATeamInSOP from '../views/SOP/ViewATeamInSOP'

import SuperAdminViewAllTalks from '../views/SuperAdmin/SuperAdminViewAllTalks'

import CreateATalk from '../views/SuperAdmin/CreateATalk'
import EditATalk from '../views/SuperAdmin/EditATalk'

import ViewAllManagers from '../views/SuperAdmin/ViewAllManagers'
import ViewAManager from '../views/SuperAdmin/ViewAManager'


import ViewAllTemplates from '../views/Inpsections/ViewAllTemplates'
import TemplateEditor from '../views/Inpsections/TemplateEditor'
import ViewLiveTemplate from '../views/Inpsections/ViewLiveTemplate'


import ViewAllSites from '../views/Inpsections/ViewAllSites'
import ViewAllAssets from '../views/Inpsections/ViewAllAssets'
import ViewAnAsset from '../views/Inpsections/ViewAnAsset'

import ViewAllActions from '../views/Inpsections/ViewAllActions'
import ViewActionSettings from '../views/Inpsections/ViewActionSettings'
import ViewAllUsers from '@/views/Inpsections/ViewAllUsers.vue'


import SupAppsPage from '../views/Supervisor/SupAppsPage'
import SupViewAllGroupTalks from '../views/Supervisor/SupViewAllGroupTalks'
import SupViewGroupTalk from '../views/Supervisor/SupViewGroupTalk' 
import SupAccount from '../views/Supervisor/SupAccount'

import GenerateTalkView from '@/views/PublicViews/GenerateTalkView.vue'
import GenerateSafetyTopicsView from '@/views/PublicViews/GenerateSafetyTopicsView.vue'







const routes = [



   {
    path: '/', 
    name: 'HomeDefault',
    component: HomeDefault,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()  

      if(authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {

        return next({name: 'AppsHome'});

      } else if(authStore.user.rid == 3) {

        return next({name: 'EmployeeAppsPage'}); 

      }else if(authStore.user.rid == 6) {

        return next({name: 'SupAppsPage'}); 

      }else if(authStore.user.rid == 1){

        return next({name: 'SuperAdminViewAllTalks'});
        
      }

      return authStore.token ? next() : next('/auth/login')

    }
  },

  {
    path: '/apps',
    name: 'AppsHome',
    component: AppsHome,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }
  },

  {
    path: '/98ue9ldms',
    name: 'AdminRegister',
    component: AdminRegister,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },


  {
    path: '/business/register',
    name: 'ManagerRegister',
    component: ManagerRegister,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },

  {
    path: '/employee/register',
    name: 'EmployeeRegister',
    component: EmployeeRegister,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },

  {
    path: '/subadmin/register',
    name: 'SubAdminRegister',
    component: SubAdminRegister,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },

  {
    path: '/auth/login',
    name: 'Login',
    component: LoginUser,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },

  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },

  },

  {
    path: '/verify',
    name: 'VerifyAuthCode',
    component: VerifyAuthCode,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },

  },



 

  
  
  

  {
    path: '/toolbox-talks',
    name: 'ToolboxHome',
    component: ToolboxTalkHome,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/campaign/:random_string',
    name: 'ViewACampaign',
    component: ViewACampaign,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }




    }
  },

  {
    path: '/campaign/message/:random_string',
    name: 'CreateAMessage',
    component: CreateAMessage,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }



    }
  },

  {
    path: '/campaign/safetytalk/message/:random_string',
    name: 'EditPostContent',
    component: EditPostContent,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/edit/quiz/:random_string',
    name: 'EditQuiz',
    component: EditQuiz,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


      

    }
  },


  {
    path: '/teams',
    name: 'ViewAllTeams',
    component: ViewAllTeams,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }


  },

  {
    path: '/team/:random_string',
    name: 'ViewATeam',
    component: ViewATeam,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },


  {
    path: '/library/talks',
    name: 'ViewAllLibraryTalks',
    component: ViewAllLibraryTalks,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }



    }
  },

  {
    path: '/employees/acknowledgement/:schedule_message_id',
    name: 'ViewEmployeesAckStatus',
    props: true,
    component: ViewEmployeesAckStatus,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }



    }
  },

  {
    path: '/employees', 
    name: 'ViewAllEmployees',
    component: ViewAllEmployees,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }



    }
  },

  {
    path: '/talks/employee/:uuid', 
    name: 'ViewTalksForAEmployee',
    props: true,
    component: ViewTalksForAEmployee,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }



    }
  },



  {
    path: '/team/apps',
    name: 'EmployeeAppsPage',
    component: EmployeeAppsPage,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 3 || authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/sup/apps',
    name: 'SupAppsPage',
    component: SupAppsPage,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/sup/group-talks',
    name: 'SupViewAllGroupTalks',
    component: SupViewAllGroupTalks,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/sup/group-talk/:uuid',
    name: 'SupViewGroupTalk',
    component: SupViewGroupTalk,
    props: true,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/sup/group-talks',
    name: 'SupAccount',
    component: SupAccount,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },



  {
    path: '/employee/talks',
    name: 'ViewAllTalks',
    component: ViewAllTalks,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 3) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/talk/:schedule_message_random_string',
    name: 'ViewTalk',
    component: ViewTalk,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 3) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

      

    }
  },

  {
    path: '/employee/grouptalks',
    name: 'ViewAllGroupTalks',
    component: ViewAllGroupTalks,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 3 || authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      
      

    }
  },

  {
    path: '/employee/account',
    name: 'EmployeeAccount',
    component: EmployeeAccount,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 3 || authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      
  
      

    }
  },


  {
    path: '/grouptalk/campaigns',
    name: 'ManagerViewAllGroupTalks',
    component: ManagerViewAllGroupTalks,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },


  {
    path: '/grouptalk/campaign/:uuid',
    name: 'ViewAGroupTalk',
    component: ViewAGroupTalk,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },


  {
    path: '/grouptalk/:uuid/create',
    name: 'CreateAGroupTalkWizard',
    component: CreateAGroupTalkWizard,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },


  {
    path: '/grouptalk/content/:uuid',
    name: 'ViewAGroupTalkPostContent',
    component: ViewAGroupTalkPostContent,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },

  {
    path: '/grouptalk/post/:uuid',
    name: 'ViewSupervisorsGroupTalks',
    component: ViewSupervisorsGroupTalks,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },


  {
    path: '/grouptalk/attendees/:uuid',
    name: 'ViewGroupTalkAttendees',
    component: ViewGroupTalkAttendees,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },

  {
    path: '/grouptalk/:random_string',
    name: 'EmployeeViewGroupTalkPost',
    component: EmployeeViewGroupTalkPost,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 3 || authStore.user.rid == 6) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },

  {
    path: '/team/docs',
    name: 'EmployeeViewAllSOPFiles',
    component: EmployeeViewAllSOPFiles,
    beforeEnter: (to, from, next) => {



      const authStore = useAuthStore()

      if (authStore.user.rid == 3) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }


    }
  },

  {
    path: '/submanagers',
    name: 'ViewAllSubManagers',
    component: ViewAllSubManagers,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },
 

  {
    path: '/reports',
    name: 'MainReport',
    component: MainReport,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },

  {
    path: '/account',
    name: 'UserAccount',
    component: UserAccount,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },


  {
    path: '/documents',
    name: 'ViewAllSOPFiles',
    component: ViewAllSOPFiles,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      


    }

  },

  {
    path: '/document/:uuid',
    name: 'ViewADocument',
    component: ViewADocument,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      


    }

  },

  {
    path: '/view/document/:uuid',
    name: 'ViewADocumentLive',
    component: ViewADocumentLive,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      


    }

  },

  {
    path: '/sop/teams',
    name: 'ViewAllTeamsInSOP',
    component: ViewAllTeamsInSOP,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      


    }

  },

  {
    path: '/admin/talks',
    name: 'SuperAdminViewAllTalks',
    component: SuperAdminViewAllTalks,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 1) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      


    }

  },

  {
    path: '/admin/talk/create/:type',
    name: 'CreateATalk',
    component: CreateATalk,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 1) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      

    }

  },

  {
    path: '/admin/talk/edit/:id',
    name: 'EditATalk',
    component: EditATalk,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 1) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      

    }

  },

  {
    path: '/admin/managers',
    name: 'ViewAllManagers',
    component: ViewAllManagers,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 1) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      

    }

  },

  {
    path: '/manager/:uuid',
    name: 'ViewAManager',
    component: ViewAManager,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 1) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      

    }

  },

  //for master managers, master acc admins and sub manager
  {
    path: '/apps',
    name: 'AppsHome',
    component: AppsHome,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }
  },


  {
    path: '/team/view/document/:uuid',
    name: 'EmployeeViewADocumentLive',
    component: EmployeeViewADocumentLive,
    props: true,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 3) {
        return next();
      } else {
        return next({ name: "AppsHome" });
      }
      


    }

  },

  {
    path: '/sop/team/:random_string',
    name: 'ViewATeamInSOP',
    component: ViewATeamInSOP,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    path: '/templates',
    name: 'ViewAllTemplates',
    component: ViewAllTemplates,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },


  {
    path: '/template/editor/:random_string',
    name: 'TemplateEditor',
    component: TemplateEditor,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },


  {
    path: '/template/:random_string',
    name: 'ViewLiveTemplate',
    component: ViewLiveTemplate,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    path: '/sites',
    name: 'ViewAllSites',
    component: ViewAllSites,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    path: '/assets',
    name: 'ViewAllAssets',
    component: ViewAllAssets,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    path: '/asset/:uuid',
    name: 'ViewAnAsset',
    component: ViewAnAsset,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    path: '/actions',
    name: 'ViewAllActions',
    component: ViewAllActions,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    path: '/actions/settings',
    name: 'ViewActionSettings',
    component: ViewActionSettings,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },


  {
    path: '/inspection/users',
    name: 'ViewAllUsers',
    component: ViewAllUsers,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }

    }

    
  },

  {
    
    path: '/generate/talk',
    name: 'GenerateTalkView',
    component: GenerateTalkView,
   
  },

  {
    
    path: '/generate/safety-topics',
    name: 'GenerateSafetyTopicsView',
    component: GenerateSafetyTopicsView,
   
  },


  {
    path: '/grouptalks/reports',
    name: 'GroupTalkReports',
    component: GroupTalkReports,
    beforeEnter: (to, from, next) => {


      const authStore = useAuthStore()

      if (authStore.user.rid == 2 || authStore.user.rid == 4 || authStore.user.rid == 5) {
        return next();
      } else {
        return next({ name: "HomeDefault" });
      }
      


    }

  },




  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
