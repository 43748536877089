import apiClient from "./MyClient";

export default {




  getAllGroupTalks() {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/grouptalks')

  },


  getAGroupTalkDetails(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/grouptalk/show/' + uuid)
  },


  getAllSupervisorsForAGroupTalkPost(uuid) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/grouptalk/post/supervisors/' + uuid)
  },

  saveThisNewGroupTalk(formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/store', formData)

  },

  updateThisNewGroupTalk(formData, uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/update/' + uuid, formData)

  },

  updateGroupTalkPostMessage(formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/post/message/update', formData) 

  },

  deleteGroupParentTalk(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/delete/' + uuid)

  },

  saveNewGroupTalkPost(uuid, formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/post/save/' + uuid, formData)

  },

  getThePostContentForGroupTalk(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/grouptalk/postcontent/' + uuid)

  },

  markGroupTalkAcknowledged(uuid, formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/mark/post/acknowlegment/' + uuid, formData)

  },

  getEmployeesWhoAcknowledgedAGroupTalkPost(uuid) {

    return apiClient.get('/api/grouptalk/post/acknowledgements/' + uuid)

  },


  updateGroupAcknowledgement(uuid, formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/update/post/acknowlegment/' + uuid, formData)

  },

  fetchViewEmployeesWhoAcknowledgedAGroupTalkPost(uuid, formData) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/post/acknowledgements/view/' + uuid, formData)

  },

  exportAllGroupTalkAttendees(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token



    return apiClient.get('/api/export/grouptalkpost/attendees/' + uuid, { responseType: 'blob' })

  },


  exportSupervisorsForAGroupTalk(uuid) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token



    return apiClient.get('/api/export/supervisors/list/for/grouptalkpost/' + uuid, { responseType: 'blob' })

  },

  deleteGroupTalkPost(uuid) {

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk/post/delete/' + uuid)

  },


  deleteAGroupTalkCampaign(uuid) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/grouptalk-campaign/delete/' + uuid)

  },


  getAllGroupCampaignsAckData() { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.get('/api/all/grouptalks/campaigns/report/data')

  },


  searchGroupTalkAcknowledgmentsWithDates(formData) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    return apiClient.post('/api/search/grouptalks/with-dates/reports', formData) 

  },

  exportGroupTalkReportSearchFormData(formData) { 

    const authStore = localStorage.getItem('authStore')
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

    // return apiClient.post('/api/search-form/grouptalks/export/', formData) 

    return apiClient.post('/api/search-form/grouptalks/export/', formData, { responseType: 'blob' }) 

  },



  




};
