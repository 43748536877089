<template>
    <div class="theSideBar">



        <div class="logoWrapper">

            <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />

        </div>



        <div class="mainMenuWrapper">




            <router-link class="linkStyle" :to="{ name: 'AppsHome' }">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <i class="bi bi-grid"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Apps</span>

                    </div>

                </div>

            </router-link>





            <router-link class="linkStyle" :to="{ name: 'ViewAllTemplates' }">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <i class="bi bi-chat-right-dots"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Templates</span>


                    </div>


                </div>

            </router-link>






            <router-link class="linkStyle" :to="{ name: 'ViewAllSites' }">

                <div class="menuItemRow">


                    <div class="iconArea">


                        <i class="bi bi-geo-alt-fill"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Sites</span>


                    </div>


                </div>

            </router-link>



            <router-link class="linkStyle" :to="{ name: 'ViewAllAssets' }">

                <div class="menuItemRow">


                    <div class="iconArea">


                        <i class="bi bi-box-seam"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Assets</span>


                    </div>


                </div>

            </router-link>

            <router-link class="linkStyle" :to="{ name: 'ViewAllUsers' }">

                <div class="menuItemRow">


                    <div class="iconArea">


                        <i class="bi bi-people"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Users</span>


                    </div>


                </div>

            </router-link>


        



            <div class="menuItemRow" @click="authStore.logoutUser">


                <div class="iconArea">

                    <i class="bi bi-box-arrow-right"></i>

                </div>

                <div class="labelArea">

                    <span style="float: left; margin-left: 0px;">Logout</span>

                </div>

            </div>





        </div>





    </div>
</template>

<script setup>

import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from "vue";

// import { useRoute } from 'vue-router';


const authStore = useAuthStore();






onMounted(() => {


    authStore.getAManagerAppAccessInfo()


})


</script>

<style scoped>
.theSideBar {
    background-color: #f9f9f9;
    width: 17%;
    overflow-y: auto;

}

.logoWrapper {
    display: flex;
    /* Enable Flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    width: 100%;
    height: 50px;
    padding-top: 20px;

    /* background-color: aqua; */
}

.imgLogo {
    width: 110px;
}


.mainMenuWrapper {
    /* background-color: aqua; */
    width: 100%;
    margin-top: 20px;
    /* padding: 20px; */
}

.menuItemRow {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;

}


.iconArea {
    /* background-color: #b8ffd3; */
    width: 10%;
    display: flex;
    align-items: center;
}

.labelArea {

    /* background-color: #36e1e1; */
    width: 80%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}


.menuItemRow:hover {
    background-color: rgb(232, 243, 234);
}

.menuItemRowSelected {
    background-color: rgb(232, 243, 234);
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}



</style>