<template>
    <div>
        <div class="mainWrapper">
            <SOPMainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">

                    <div class="theHeaderWrapper">




                        <div style="display: flex; align-items: center;">

                            <i @click="$router.go(-1)" 
                                style="font-size: 25px; font-weight: bold; cursor: pointer; margin-right: 10px;"
                                class="bi bi-arrow-left"></i>


                            {{ teamStore.team.name }}

                        </div>


                        <!-- {{ teamStore.selectedEmployees }} -->



                    </div>

                    <div class="searchBarWrapper">
                        <input v-model="searchQuery" type="text" class="form-control theBar"
                            placeholder="Search by team name..." />
                    </div>


                    <div class="tableWrapper">

                        <div class="optionsWrapper" v-if="teamStore.selectedEmployees.length > 0">

                            <button @click="teamStore.openCopyEmployeeToAontherTeamModal" class="btn btn-dark btn-sm"
                                style="border-radius: 10px; margin-right: 10px;"><b>Add To
                                    Another Team</b></button>

                            <button @click="teamStore.opemShowGroupDeleteModal" class="btn btn-danger btn-sm"
                                style="border-radius: 10px;"><b>Delete</b></button>


                        </div>

                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Joined</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>


                            <tbody>

                                <tr v-for="(employee, index) in filteredEmployees" :key="index">




                                    <td style="width: 200px;">



                                        <div class="form-check"
                                            @click="() => { teamStore.toggleSelection(employee); teamStore.addrOrRemoveUser(employee); }">



                                            <input class="form-check-input" type="checkbox" :value="employee.is_selected"
                                                v-model="employee.is_selected" :id="`flexCheck-${employee.id}`"
                                                style="border-color: black;">
                                            <label :for="`flexCheck-${employee.id}`">{{ employee.name }}</label>
                                        </div>



                                    </td>



                                    <td style=" width: 300px;">

                                        {{ teamStore.formatDate(employee.created_at) }}

                                    </td>


                                    <td style="width: 150px;">
                                        <div class="iconsRow">




                                            <i @click="() => { teamStore.setCurrentEmployee(employee); teamStore.openShowDeleteEmployeeModal(); }"
                                                class="bi bi-trash theActionIcon"></i>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>






                        </table>


                        <button v-if="displayedEmployeesCount < teamStore.employees.length" class="btn btn-dark loadMoreBtn"
                            @click="loadMoreEmp">Load more</button>



                    </div>







                </div>
            </div>
        </div>



        <div v-if="teamStore.showDeleteEmployeeModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to delete this employee?</b>
                    </div>

                    <div>
                        <i @click="teamStore.closeShowDeleteEmployeeModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">




                    <p><b style="color: red;">Very Important Note:</b>
                        It's recommeded to download all data related to <b>{{ teamStore.currentEmployee.name
                        }}</b> before deleting. Once deleted this data is unrecoverable.
                    </p>




                    <div>
                        <button @click="teamStore.softDeleteThisEmployee(teamStore.employees)" type="button"
                            class="btn btn-danger createBTN">
                            <b>Yes, Delete!</b>
                        </button>
                    </div>



                </div>
            </div>
        </div>



        <div v-if="teamStore.showCopyEmployeeToAontherTeamModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Add Users To Another Team</b>
                    </div>

                    <div>
                        <i @click="teamStore.closeCopyEmployeeToAontherTeamModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">



                    <form @submit.prevent="teamStore.copyUsersToTeam">

                        <div class="mb-3" v-if="teamStore.team && teamStore.teams">

                            <label class="form-label" style="float: left">Select Team</label>

                            <select v-model="teamStore.copyUsersToTeamFormData.team_id" class="form-select custom-select"
                                required>

                                <option disabled value="" selected>Choose a team</option>


                                
                                
                                <option v-for="(team, index) in teamStore.getAllTeamsExceptCurrentTeam()" :key="index" :value="team.id">


                                    {{ team.name }}



                                </option>


                            </select>



                        </div>




                        <div>

                            <div v-if="teamStore.isLoading" class="spinner-grow text-success" role="status"
                                style="margin-top: 20px;">
                                <span class="visually-hidden">Processing...</span>
                            </div>

                            <button v-else type="submit" class="btn btn-dark createBTN">
                                <b>Add To This Team</b>
                            </button>
                        </div>
                    </form>




                </div>
            </div>
        </div>

        <div v-if="teamStore.showGroupDeleteModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Are you sure you want to delete these employees?</b>
                    </div>

                    <div>
                        <i @click="teamStore.closeShowGroupDeleteModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <form @submit.prevent="teamStore.deleteGroupOfUsers">

                        <p><b style="color: red;">Very Important Note:</b>
                            It's highly recommeded to download all talks data related to these employees before
                            deleting for record keeping. Once deleted this data is unrecoverable.
                        </p>


                        <div style="width: 100%; max-height: 300px; overflow-y: auto; padding: 10px;">

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>



                                    <tr v-for="(employee, index) in teamStore.selectedEmployees" :key="index">

                                        <td>{{ employee.name }}</td>

                                        <td style="display: flex; justify-content: center; padding: 10px;">

                                            <i @click="teamStore.removeEmployeeFromSelectedArray(employee)"
                                            class="bi bi-trash" style="cursor: pointer;"></i>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>



                        </div>


                        <div>
                            <button type="submit"
                                class="btn btn-danger createBTN">
                                <b>Delete These Employees</b>
                            </button>
                        </div>




                    </form>







                </div>
            </div>
        </div>


    </div>
</template>

<script setup>
import SOPMainSideBarVue from '@/components/SOP/SOPMainSideBar.vue';
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, defineProps, computed, ref } from 'vue';

import { useTeamStore } from "@/stores/TeamStore";


const teamStore = useTeamStore();

const props = defineProps({
    random_string: String
})


const searchQuery = ref('');

const displayedEmployeesCount = ref(25); // Start with displaying 5 teams
const incrementStep = 15; //


const filteredEmployees = computed(() => {

    return teamStore.employees
        .filter(emp => emp.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
        .slice(0, displayedEmployeesCount.value);

});

const loadMoreEmp = () => {
    displayedEmployeesCount.value += incrementStep;
};


onMounted(() => {

    teamStore.fetchTeam(props.random_string)
    teamStore.fetchAllTeams()


})



</script>

<style scoped> .mainWrapper {
     /* background-color: aliceblue; */
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
 }

 .rightContentWrapper {
     /* overflow-y: auto; */
     width: 83%;
     display: flex;
     flex-direction: column;
 }

 .contentContainer {
     /* overflow-y: auto; */
     background-color: rgb(208, 28, 55);
     /* margin: 30px; */
     margin-top: 100px;
 }

 .rightColumns {
     /* display: flex;
  flex-direction: column; */
 }

 .topNav {
     background-color: blue;
 }

 .innerContainer {
     /* margin-top: 200px; */
     /* background-color: blueviolet; */
     overflow-y: auto;
     padding-top: 30px;
     padding-left: 25px;
     padding-right: 25px;
     padding-bottom: 100px;
 }

 .theHeaderWrapper {
     display: flex;
     justify-content: space-between;
     align-content: center;
     text-align: left;
     font-size: 20px;
     font-weight: bold;
     /* background-color: aqua; */
 }

 .searchBarWrapper {
     margin-top: 15px;
     width: 100%;
     padding-bottom: 20px;
     /* background-color: rgb(22, 166, 37); */
 }

 .theBar {
     width: 350px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 10px;
 }

 .button-6 {
     align-items: center;
     /* background-color: #FFFFFF; */
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     border: 1px solid rgba(0, 0, 0, 0.1);
     border-radius: 0.25rem;
     box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
     box-sizing: border-box;
     color: rgba(0, 0, 0, 0.85);
     cursor: pointer;
     display: inline-flex;
     font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
         Arial, sans-serif;
     font-size: 16px;
     font-weight: 600;
     justify-content: center;
     line-height: 1.25;
     margin: 0;
     min-height: 3rem;
     padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
     position: relative;
     text-decoration: none;
     transition: all 250ms;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
     vertical-align: baseline;
     width: auto;
 }

 .button-6:hover,
 .button-6:focus {
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
     color: rgba(0, 0, 0, 0.65);
 }

 .button-6:hover {
     transform: translateY(-1px);
 }

 .button-6:active {
     background-color: #f0f0f1;
     border-color: rgba(0, 0, 0, 0.15);
     box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
     color: rgba(0, 0, 0, 0.65);
     transform: translateY(0);
 }

 .tableWrapper {
     margin-top: 20px;
     /* background-color: aqua; */
     width: 100%;
 }

 .my-custom-table tbody tr td {
     padding-top: 20px;
     /* Adjust the value as needed */
     padding-bottom: 20px;
     /* Adjust the value as needed */
 }

 .my-custom-table th {
     padding-bottom: 20px;
 }

 .my-custom-table th,
 .my-custom-table td {
     text-align: left;
     /* Aligns text to the left */
     padding-left: 20px;
     padding-right: 20px;
 }

 .campName {
     text-decoration: none;
     color: inherit;
 }

 .campName:hover {
     color: rgb(10, 159, 92);
     cursor: pointer;
     text-decoration: none;
 }

 .iconsRow {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }

 .theActionIcon {
     font-weight: bolder;
     font-size: 18px;
     color: rgb(5, 2, 2);
     cursor: pointer;
 }

 .theActionIcon:hover {
     cursor: pointer;
     color: rgb(30, 158, 30);
     scale: 1.3;
     transition: 1s;
 }

 .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.7);
     /* Semi-transparent black background */
     display: flex;
     justify-content: center;
     /* Center horizontally */
     align-items: center;
     /* Center vertically */
     z-index: 999;
     /* Ensure the modal is on top */
 }

 /* Styles for the modal box */
 .modal-box {
     background-color: white;
     width: 1100px;
     padding: 20px;
     border-radius: 2px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 }

 .modal-content {
     text-align: center;
 }

 .model-box-header {
     display: flex;
     justify-content: space-between;
     /* background-color: antiquewhite; */
     height: 40px;
     margin-bottom: 15px;
 }

 .form-control {
     background-color: rgb(243, 246, 246);
 }

 .createBTN {
     width: 100%;
     margin-top: 20px;
 }

 .noTeamBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

 .noMatchBox {
     background-color: red;
     width: 100%;
 }

 .loadMoreBtn {
     background-color: black;
     border-radius: 10px;
 }

 .inviteInput {
     width: 500px;
     font-size: 13px;
     cursor: pointer;
 }

 .optionsWrapper {
     display: flex;
     width: 100%;
     background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
     color: black;
     padding: 10px;
     border-radius: 10px;
 }
</style>