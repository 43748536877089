<template>
    <div>


        <div class="mainWrapper" v-if="windowWidth > mobileWidthThreshold">

            <div class="editorHeader">



                <div class="theRight">



                    <div v-if="sopAppStore.fileDetails">

                        <i @click="sopAppStore.goToViewAllDocuments" class="bi bi-arrow-left"
                            style="font-size: 21px; font-weight: bolder; cursor: pointer; margin-right: 15px;"></i>

                    </div>


                    <div v-if="sopAppStore.fileDetails && sopAppStore.fileDetails.file_type === 'pdf'">

                        <span style="font-size: 16px;">
                            <b>{{ sopAppStore.fileDetails.file_name }}</b>
                        </span>



                    </div>




                </div>

                <div class="theLeft">

<!-- 
                    <button class="button-13">Download</button> -->

                </div>


            </div>


            <div class="rightContentWrapper">



                <div v-if="sopAppStore.fileDetails.file_type === 'pdf'" class="pdfChatOuterWrapper">




                    <div class="pdfRenderWrapper">

                        <div class="pdfBtnsWrapper">

                            <div class="leftTop">

                                <div>
                                    <b>{{ page }} / {{ pagesCount }}</b>
                                </div>

                            </div>

                            <div class="rightTop">

                                <div class="navWrapper1" @click="prevPage">

                                    <i class="bi bi-chevron-left navIcon"></i>

                                </div>

                                <div class="navWrapper2" @click="nextPage">

                                    <i class="bi bi-chevron-right navIcon"></i>

                                </div>



                            </div>


                        </div>


                        <div class="thePdfBox">



                            <vue-pdf-embed :source="sopAppStore.fileDetails.pdf_url" :page="page" :text-layer="true"
                                @loaded="handleDocumentLoad" />

                        </div>



                    </div>



                    <div class="pdfChatWrapper">



                        <div class="headerBox">
                            <b style="text-align: left; font-size: 17px;">Chat</b>

                        </div>



                        <div class="theChatBubblesWrapper" ref="chatContainer">


                            <div v-if="sopAppStore.chatMessages.length > 0">

                                <div class="bubbleItem" v-for="(mess, index) in sopAppStore.chatMessages" :key="index">

                                    <div class="userBubble" v-if="mess.sender === 'user'">
                                        <div v-html="mess.message"></div>
                                    </div>

                                    <div class="aiBubble" v-if="mess.sender === 'ai'">


                                        <div v-if="mess.message == null" class="loadingBox">

                                            <div style="width: 15px; height: 15px; margin-right: 10px;" class="spinner-grow"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div> Typing...

                                        </div>

                                        <div v-else class="messBox">

                                            <div v-html="mess.message"></div>

                                            <div class="sourceBox">
                                                <div class="pageSourceItem"
                                                    v-for="(pageSource, index) in mess.source_page_numbers" :key="index"
                                                    @click="page = pageSource">
                                                    p. {{ pageSource }}
                                                </div>

                                            </div>


                                        </div>



                                    </div>


                                </div>

                            </div>

                            <div v-else class="outer">

                                <div class="noMessages">

                                    <i class="bi bi-chat-left-dots"></i>
                                    You haven't asked this document a question yet.

                                </div>



                            </div>




                        </div>


                        <div class="sendMessageBox">

                            <form @submit.prevent="sopAppStore.userSendDocChatMessageAndGetAIResponse">

                                <div class="sendBox">


                                    <textarea required placeholder="Ask this document a question..."
                                        v-model="sopAppStore.newMessageFormData.message" class="form-control askArea"
                                        rows="3"></textarea>

                                    <button class="sendBtn">
                                        <b>Send</b>
                                    </button>




                                </div>

                            </form>



                        </div>



                    </div>



                </div>


                <div v-if="sopAppStore.fileDetails.file_type === 'text'" class="aiTextWrapper">


                    <div class="viewTextHTMLBox">

                        <div class="bodyTitle">{{ sopAppStore.fileDetails.title }}</div>

                        <div class="theHTMLBody" v-html="sopAppStore.fileDetails.body"></div>



                    </div>


                </div>



                <div class="imageBox" v-if="sopAppStore.fileDetails.file_type === 'image'">

                    <img class="theImg" :src="sopAppStore.fileDetails.img_url" width="100%">

                </div>







                <video class="theVideo" controls v-if="sopAppStore.fileDetails.file_type === 'video'">
                    <source :src="sopAppStore.fileDetails.video_url" type="video/mp4">
                    Your browser does not support HTML video.
                </video>










            </div>

        </div>

        <div class="mainWrapper" v-else>

            <div class="mobileEditorHeader">

                <div class="mobileLeft">
                    <span style="font-size: 14px;" v-if="sopAppStore.fileDetails.file_name">
                        <b>Chat with {{ sopAppStore.limitText2(sopAppStore.fileDetails.file_name) }}</b>
                    </span>
                </div>

                <div class="mobileRight">

                    <i @click="sopAppStore.exitChatWindow" class="bi bi-x-lg"></i>
                </div>

            </div>

            <div class="pdfBtnsWrapper">

                <div class="rightTop">

                    <div class="navWrapper1" @click="prevPage">

                        <i class="bi bi-chevron-left navIcon"></i>

                    </div>

                    <div class="navWrapper2" @click="nextPage">

                        <i class="bi bi-chevron-right navIcon"></i>

                    </div>



                </div>

                <div class="leftTop">

                    <div>
                        <b>{{ page }} / {{ pagesCount }}</b>
                    </div>

                </div>




            </div>

            <div class="pdfHolderBox">

                <vue-pdf-embed :source="sopAppStore.fileDetails.pdf_url" :page="page" :text-layer="true"
                    @loaded="handleDocumentLoad" />


            </div>

            <div class="chatHolderBox">

                <div class="mobileChatBubblwWrapper">

                    <div class="chatList" ref="chatContainer">


                        <div v-if="sopAppStore.chatMessages.length > 0">

                            <div class="bubbleItem" v-for="(mess, index) in sopAppStore.chatMessages" :key="index">

                                <div class="userBubble" v-if="mess.sender === 'user'">
                                    <div v-html="mess.message"></div>
                                </div>

                                <div class="aiBubble" v-if="mess.sender === 'ai'">


                                    <div v-if="mess.message == null" class="loadingBox">

                                        <div style="width: 15px; height: 15px; margin-right: 10px;" class="spinner-grow"
                                            role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div> Typing...

                                    </div>

                                    <div v-else class="messBox">

                                        <div v-html="mess.message"></div>

                                        <div class="sourceBox">
                                            <div class="pageSourceItem"
                                                v-for="(pageSource, index) in mess.source_page_numbers" :key="index"
                                                @click="page = pageSource">
                                                p. {{ pageSource }}
                                            </div>

                                        </div>


                                    </div>



                                </div>


                            </div>

                        </div>

                        <div v-else class="outer">

                            <div class="noMessages">

                                <i class="bi bi-chat-left-dots"></i>
                                You haven't asked this document a question yet.

                            </div>



                        </div>


                    </div>

                    <div class="mobileTextArea">


                        <div class="userTypingBox">

                            <form @submit.prevent="sopAppStore.userSendDocChatMessageAndGetAIResponse">

                                <div class="sendBox">


                                    <textarea required placeholder="Ask this document a question..."
                                        v-model="sopAppStore.newMessageFormData.message" class="form-control askArea"
                                        rows="3"></textarea>

                                    <button class="sendBtn">
                                        <b>Send</b>
                                    </button>




                                </div>

                            </form>




                        </div>

                    </div>



                </div>


            </div>



        </div>








    </div>
</template>

<script setup>


import { onMounted, defineProps, ref, nextTick, watch, onUnmounted } from 'vue';
import { useSOPAppStore } from "@/stores/SOPAppStore";
import SOPAppService from '@/services/SOPAppService';
import VuePdfEmbed from 'vue-pdf-embed'



const sopAppStore = useSOPAppStore();


const props = defineProps({
    uuid: String
})

const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230;

const onResize = () => {
    windowWidth.value = window.innerWidth;
};




const getFileDetails = () => {

    sopAppStore.fileDetails = {}

    SOPAppService.getLiveFileDetails(props.uuid)
        .then((response) => {


            sopAppStore.fileDetails = response.data.file
            sopAppStore.chatMessages = response.data.chatMessages

            console.log(response.data)


        }).catch((err) => {
            console.log(err)
        });

}


const page = ref(1)
const pagesCount = ref(null)

const nextPage = () => {
    if (page.value < pagesCount.value) {
        page.value++;
    }
};

const prevPage = () => {
    if (page.value > 1) {
        page.value--;
    }
};

const handleDocumentLoad = (e) => {

    pagesCount.value = e.numPages
    console.log(e.numPages)
};



const chatContainer = ref(null);

watch(() => sopAppStore.chatMessages, () => {
    // Ensure the DOM updates before scrolling
    nextTick(() => {
        if (chatContainer.value) {
            chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
        }
    });
}, { deep: true });






onMounted(() => {

    window.addEventListener('resize', onResize);

    getFileDetails()

});


onUnmounted(() => {
    window.removeEventListener('resize', onResize);

});



</script>

<style  scoped>
.mainWrapper {
    display: flex;
    flex-direction: column;
    /* background-color: rgb(118, 183, 240); */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 100%;
    display: flex;

    justify-content: center;
    /* padding-top: 30px; */
    /* background-color: rgb(239, 127, 14); */
    height: 100vh;

}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    /* color: initial; */
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}


.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
    margin-right: 10px;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.dropBox {
    background-color: azure;
    max-width: 200px;
}

.dropdown-item {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-item:hover {
    background-color: rgb(222, 222, 222);
    color: black;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.colTitle {
    font-size: 14px;
}

.editorHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: rgb(182, 226, 5);
    height: 70px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.theRight {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 50%;

    /* background-color: rgb(123, 105, 105); */

}

.theLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
    /* background-color: rgb(190, 163, 163); */
    padding: 20px;

}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}


.theContent {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: antiquewhite; */
}



.textEditorBox {
    width: 100%;
    height: 100vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* border-radius: 10px; */
    padding: 20px;


}

.textEditorHeader {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.editorBtns {
    display: flex;
    justify-content: flex-end;
    /* background-color: rgb(205, 237, 237);  */
    width: 50%;
}


.titleBox {
    padding: 10px;
    width: 100%;
    /* background-color: #e3ffe7; */
}


.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.contentInputBody {
    text-align: left;
    margin: 10px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    /* padding: 10px; */
    /* background-color: #d5d9d9; */
    /* height: 100%; */

}




.textEditorWrapper {
    width: 100%;

    /* width: 100%; */
    /* background-color: red; */
}

.btnBox {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e4e4e4;
    /* border: 1px solid rgb(239, 236, 236); */
    width: 100%;
    /* background-color: purple; */
    height: 60px;
    padding: 4px;

}


.btnItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    margin: 4px;
    border-radius: 5px;

}

.btnItem:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}


.btnItemSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(236, 236, 234);
    width: 100%;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;

}

.mainWrapper ::v-deep .ProseMirror {
    /* Styles for the ProseMirror editor, which is inside the Tiptap EditorContent component */
    padding: 10px;
    /* border-bottom: 2px solid rgb(239, 239, 19);
    border-left: 2px solid rgb(79, 76, 76);
    border-right: 2px solid rgb(79, 76, 76); */
    outline: none;
    min-height: 400px;
    text-align: left;
    max-height: 600px;
    overflow-y: auto;
}

.mainWrapper ::v-deep .ProseMirror img {
    max-width: 50%;
    height: auto;

    /* center the imgage */
    display: block;
    /* Makes the image a block-level element */
    margin-left: auto;
    margin-right: auto;
}

/* .colorWheel{
    width: 30px;
    height: 30px;
   
} */

.colorWheel {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    /* Make the input circular */
    border: none;
    /* Remove the border */
    cursor: pointer;
    /* Change cursor to pointer to indicate it's clickable */
    -webkit-appearance: none;
    /* Try to remove default styling on WebKit browsers */
    padding: 0;
    /* Remove padding */
    outline: none;
    /* Remove focus outline */
}

/* Hide the color input's default square color preview on WebKit browsers */
.colorWheel::-webkit-color-swatch-wrapper {
    padding: 0;
}

.colorWheel::-webkit-color-swatch {
    border: none;
}

.theImg {
    padding-left: 10px;
    padding-right: 10px;
}

.theVideo {
    width: 800px;
    height: auto;
    background-color: #d5d9d9;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;

}

@media (max-width: 800px) {
    .theVideo {
        width: 100%;
    }
}


.pdfWrapperBox {
    width: 900px;
}

.pdfBox {
    width: 800px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



/* .pdfContent {
    text-align: left;
    font-size: 17px;
    width: 800px;
    height: 600px;
    overflow-y: auto;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
} */

@media (max-width: 800px) {
    .pdfContent {
        width: 100%;
    }
}


.aiResultsBox {
    height: 400px;
    background-color: rgb(232, 224, 224);
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    overflow-y: auto;
}


.aiTextWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: rgb(144, 190, 144); */
    padding: 10px;
}


.textWrapper {
    width: 70%;
    /* background-color: rgb(144, 160, 219); */
}


.chatArea {
    display: flex;
    flex-direction: column;
    /* background-color: #f7f7f7; */
    width: 30%;
}


.chatHeader {
    display: flex;
    align-items: center;
    height: 50px;
    /* background-color: #d9e7ff; */
    /* padding-left: 15px; */
    padding: 20px;
    border-bottom: 1px solid #e4e4e4;
}

.chatBubbles {
    height: 100%;
    /* background-color: red; */
    overflow-y: auto;
    border-bottom: 1px solid #e4e4e4;
    padding: 20px;
}

.promptBox {
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
    height: 450px;
    padding: 20px;
    width: 100%;
}

.clearChatBox {
    text-align: left;
    font-size: 13px;

}


.theHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
    /* background-color: green; */
}

.theBody {
    /* background-color: purple; */

}


.askArea {
    resize: none;
    height: 100%;
    border-radius: 0px;
}


.askItem {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
    /* background-color: gray; */
}

.askBox {
    display: flex;
    flex-direction: row;
    /* background-color: antiquewhite; */
}

.aiAnswerBox {
    /* background-color: yellow; */
    font-size: 15px;
    text-align: left;
    margin-bottom: 10px;
}


.avatarArea {
    font-size: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.addBtn {
    width: 120px;
    font-size: 11px;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 500;
}

.addBtn:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.btnArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}



.noMessages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* background-color: rgb(230, 195, 195); */
    height: 500px;
    font-size: 13px;
}

.outer {
    display: flex;
    justify-content: center;
    align-content: center;

}

.loadingBox {
    display: flex;
    align-items: center;
    flex-direction: row;
}


.imageBox {
    margin-top: 30px;
}

.viewTextHTMLBox {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    max-width: 700px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    max-height: 700px;
    overflow-y: auto;
    padding: 18px;
    /* background-color: #24292E; */
}

.theHTMLBody {
    text-align: left;
}

.bodyTitle {
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 15px;
}

.pdfChatOuterWrapper {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    /* background-color: rgb(113, 10, 217); */

}


.pdfRenderWrapper {
    width: 50%;
    padding: 15px;
    /* background-color: red; */
}

.pdfChatWrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e4e4e4;
    height: 100vh;
    /* background-color: teal; */

}


.thePdfBox {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 800px;
    overflow-y: auto;
}



.headerBox {
    display: flex;
    /* background-color: palegoldenrod; */
    widows: 100%;
    padding: 10px;
    border-bottom: 1px solid #e4e4e4;
    height: 5%;
}

.theChatBubblesWrapper {
    height: 70%;
    width: 100%;
    /* background-color: #d9e7ff; */
    overflow-y: auto;
    padding: 10px;
}


.sendMessageBox {
    width: 100%;
    height: 25%;
    padding: 20px;
    border-top: 1px solid #e4e4e4;
    /* background-color: gray; */

}

.sendBox {
    display: flex;
    flex-direction: row;
    /* background-color: #3be0e0; */
    height: 70px;

}

.bubbleItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: #e3ffe7; */
    align-items: flex-start;
    margin-bottom: 18px;
}

.userBubble {
    width: 60%;
    /* margin-bottom: 10px; */
    align-self: flex-start;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    text-align: left;
    background-color: #f6f6f6;
    color: black;

}

.aiBubble {
    width: 60%;
    background-color: #f4f9ff;
    color: rgb(4, 3, 3);
    align-self: flex-end;
    /* Aligns this bubble to the end of the main axis (right) */
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    text-align: left;

}


.sendBtn {
    width: 80px;
    font-size: 13px;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: black;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;
}

.pageSourceItem {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    /* background-color: rgb(250, 242, 232); */
    border-radius: 4px;
    margin-right: 10px;
    padding: 2px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.pageSourceItem:hover{
    background-color: #acacac;
    color: white;
}

.pdfBtnsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}


.leftTop {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(208, 230, 174); */
    width: 50%;
    padding: 10px;
    border: 1px solid #eeefef;
}

.rightTop {
    display: flex;
    flex-direction: row;
    /* background-color: rgb(211, 212, 209); */
    width: 50%;
    /* padding: 5px; */
}

.navWrapper1 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #58d472; */
    width: 50%;
    height: 100%;
    cursor: pointer;
    border: 1px solid #eeefef;
}

.navWrapper2 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #f3f5ec; */
    width: 50%;
    height: 100%;
    cursor: pointer;
    border: 1px solid #eeefef;
}

.navWrapper1:hover {
    background-color: #d5eee1;
}

.navWrapper2:hover {
    background-color: #d5eee1;
}


.navIcon {
    font-size: 22px;
    font-weight: bold;
}

.messBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: yellow; */
}

.sourceBox {
    display: flex;
}



.outer {
    display: flex;
    justify-content: center;
    align-content: center;

}

.mobileEditorHeader {
    display: flex;
    flex-direction: row;
    height: 5%;
    width: 100%;
    background-color: rgb(182, 226, 5);
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.pdfHolderBox {
    width: 100%;
    /* background-color: green; */
    height: 35%;
    overflow-y: auto;

}

.chatHolderBox {
    /* background-color: rgb(223, 201, 4); */
    height: 60%;

}


/* .theChatBubblesWrapper {
    height: 70%;
    width: 100%;
    overflow-y: auto;
    padding: 10px;
} */

.mobileLeft {
    /* background-color: red; */
    width: 80%;
    padding: 10px;
    text-align: left;
}

.mobileRight {
    /* background-color: rgb(0, 255, 72); */
    width: 20%;
    padding: 10px;
    text-align: right;
    font-size: 18px;
    font-weight: bolder;
    cursor: pointer;
}

.mobileChatBubblwWrapper {
    height: 100%;
    /* background-color: purple; */
}


.chatList {
    /* background-color: orange; */
    height: 75%;
    overflow-y: auto;
    padding: 15px;
}

.mobileTextArea {
    /* background-color: rgb(13, 231, 97); */
    height: 25%;
    padding: 10px;
}

.userTypingBox {
    /* background-color: grey; */
}
</style>