<template>


    <div class="loginWrapper">


        <img class="imgLogo" :src="require(`@/assets/images/logo_black.png`)" />
    

        <div class="formWrapper">


            <div v-if="authStore.errorMessage"  class="dangerAlert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>

            <form @submit.prevent="authStore.login">

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Email address</label>
                    <input v-model="authStore.loginFormData.email" type="email" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Password</label>
                    <input v-model="authStore.loginFormData.password" type="password" class="form-control" required>
                </div>

                <div>
                    <button type="submit" class="btn btn-primary loginBTN">Login</button>
                </div>

            </form>

            <div style="margin-top: 25px;">


                <div id="emailHelp" class="form-text">Forgot password? <router-link
                        :to="{ name: 'ForgotPassword' }">
                        <span>Click here</span>
                    </router-link>
                </div>


            </div>





        </div>

    </div>
</template>

<script setup>

import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();




</script>

<style scoped>
.imgLogo {
    width: 120px;
    position: fixed; /* Fixed position */
    top: 0; /* Stick to the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for exact centering */
    padding-top: 100px;
}


.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: aliceblue; */
}



.formWrapper {
    /* Styling for form wrapper */
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}


.form-control {
    background-color: rgb(243, 246, 246);
}


.dangerAlert{
    background-color: rgb(255, 255, 255); 
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}
</style>