<template>
    <div>

        <!-- 
        <PublicMainNavBar/> -->



        <div class="contentContainer">

            <div class="buttonOptionsWrapper" v-show="publicViewStore.showResult">


                <div class="theButtonBox">

                    <!-- <button class="btn btn-dark" style="font-size: 13px;">
                        <i class="bi bi-file-pdf-fill"></i> <b>Download as PDF</b> 
                    </button> -->


                    <button @click="publicViewStore.tryAgain" class="btn btn-light" style="font-size: 13px;">
                        <i class="bi bi-arrow-clockwise"></i> <b>Try Again</b>
                    </button>

                    <button class="btn btn-dark" style="font-size: 13px;" @click="copyText">
                        <i class="bi bi-clipboard"></i> <b style="margin-left: 3px;">Copy Text</b>
                    </button>



                </div>



            </div>

            <div class="buttonOptionsWrapper" v-show="publicViewStore.showResult">


                <div class="theButtonBox" style="text-align: left;">

   

                    <a href="https://safelyio.com/" target="_blank" style="font-size: 18px;"><b>NEXT STEP: Let's Show You How Easy It Is To Schedule Talks
                        Like This To Be Sent Digitally To Your Teams >> </b></a>



                </div>


                

            </div>


            <div class="resultsBox" v-show="publicViewStore.showResult">


                <div class="theTalkWrapper">

                    <div class="titleBox">


                        Title: {{ publicViewStore.theAIGenerateTalk.title }}

                    </div>

                    <div class="talkBody" ref="textToCopy">

                        <div v-html="publicViewStore.theAIGenerateTalk.body"></div>

                    </div>


                </div>





            </div>

            <div class="resultsBox" v-show="publicViewStore.showResult">

                <div class="theAdWrapper">

                    <div class="theAdHeader">

                        <h5><b>Generate Industry-Specific Toolbox Talks Like This & Schedule Them For Your Teams, Weeks
                                and Months In Advance</b></h5>

                    </div>

                    <div class="theAdBody">

                        <li>
                            Make it easy for supervisors or employees to complete talks anywhere at anytime
                        </li>

                        <li>
                            Track each toolbox talk performance to see which teams are consistently completing talks.
                        </li>

                        <li>
                            Get access to our video and text toolbox talk library created and updated by our in-house
                            environmental health and safety team with decades of experience
                        </li>



                    </div>


                    <a class="btn btn-dark theBtnCTA" href="https://safelyio.com/" target="_blank">Request A Quick
                        Demo</a>


                </div>


            </div>


            <div class="formWrapper" v-show="!publicViewStore.showResult">

                <form @submit.prevent="publicViewStore.generateTalk" class="theFormBox">

                    <!-- 
                    <div class="mb-3 theHeader">

                       <h4>Safety Toolbox Talk Generator</h4>

                    </div> -->


                    <div class="mb-4">
                        <label for="exampleInputEmail1" class="form-label"><b>Select Language</b></label>

                        <select v-model="publicViewStore.toolboxTalkFormData.language" class="form-select theInput"
                            aria-label="Default select example">


                            <option v-for="(language, index) in publicViewStore.languagesArray" :key="index"
                                :value="language.name">{{ language.name }}</option>

                        </select>

                    </div>



                    <div class="mb-3">

                        <label for="exampleInputEmail1" class="form-label"><b>What kind of toolbox talk should be
                                created?</b></label>

                        <textarea v-model="publicViewStore.toolboxTalkFormData.prompt" class="form-control theInput"
                            rows="4" cols="50" style="resize: none; height: 200px;"
                            placeholder="Example: Write a safety toolbox talk about ladder safety for a construction company that specializes in roofing."></textarea>

                    </div>

                    <p style="font-size: 11px; margin-top: 10px;">
                        DISCLAIMER: The content generated by the AI system may occasionally contain errors or
                        inaccuracies. We strongly recommend reviewing and verifying the AI-generated content before use.
                        Our service is provided "as is," and we do not accept responsibility for any inaccuracies,
                        errors, or liabilities that may arise from the use of this content.
                    </p>


                    <div class="loadingDiv" v-if="publicViewStore.isLoading">

                        <div class="typingDiv">

                            <b>Creating talk...</b>

                            <div class="spinner-grow" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>


                        </div>


                    </div>



                    <button v-else type="submit" class="btn btn-dark theSubmitBtn"><b>Generate Toolbox Talk</b></button>

                </form>



            </div>



        </div>



    </div>
</template>

<script setup>

// import PublicMainNavBar from "@/components/PublicViews/MainNavBar";
import { usePublicViewStore } from "@/stores/PublicViewStore";
import { ref } from 'vue'



const publicViewStore = usePublicViewStore();

const textToCopy = ref(null)

// const copyText = () => {

//   if (textToCopy.value) {

//     navigator.clipboard.writeText(textToCopy.value.innerText)
//       .then(() => console.log('Text copied'))
//       .catch(err => console.error('Failed to copy text:', err))
//   }

// }

const copyText = () => {
    if (textToCopy.value) {
        const text = textToCopy.value.innerText
        const textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.select()
        try {
            document.execCommand('copy')
            alert('Copied!')

            publicViewStore.notifyAdmin()
            //   console.log('Text copied')
        } catch (err) {
            console.error('Failed to copy text:', err)
        }
        document.body.removeChild(textArea)
    }
}



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.contentContainer {
    width: 100%;
    /* background-color: rgb(202, 217, 202); */
}

.formWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: rgb(200, 200, 193); */
    padding: 20px;
    margin-top: 20px;
}


.theFormBox {
    /* max-width: 800px; */
    text-align: left;
    width: 800px;
}


.theSubmitBtn {
    width: 100%;
}


.theHeader {
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
}

.theInput {
    border: 1.5px solid rgb(103, 103, 103);
}

.resultsBox {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    /* background-color: rgb(137, 158, 177); */
}

.theTalkWrapper {
    display: flex;
    flex-direction: column;
    width: 800px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    /* background-color: yellow; */
}


.titleBox {
    /* background-color: antiquewhite; */
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}

.talkBody {
    /* background-color: grey; */
    font-size: 17px;
    text-align: left;
}


.buttonOptionsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    margin-top: 15px;
    padding: 20px;
}

.theButtonBox {
    display: flex;
    justify-content: space-between;
    /* background-color: purple; */
    width: 800px;
}

.theAdBox {
    width: 100%;
    background-color: green;
}

.theAdWrapper {
    width: 800px;
    background-color: beige;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}


.theAdHeader {
    text-align: left;
    line-height: 2px;

}

.theAdBody {
    text-align: left;
    font-size: 17px;
}

.theBtnCTA {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-weight: bold;
}


.loadingDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: rgb(115, 104, 104); */
}

.typingDiv {
    display: flex;
    /* background-color: red; */
    width: 200px;
}
</style>