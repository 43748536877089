
/* eslint-disable */
import SubManagerService from "@/services/SubManagerService";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";


export const useSubManagerStore = defineStore("SubManagerStore", {

  state: () => ({


    subManagers: [],

    showDeleteSubManagerModal: false,
    showSendInviteToSubManagerModal: false, 

    currentSubManager: {},

    inviteFormData: {
      email: ''
    },

    isLoading: false

    
  }),

  actions: { 

    
    fetchAllSubManagers() {

      SubManagerService.getAllSubManagers()  
        .then((response) => {

          this.subManagers = response.data.admins

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },


    openDeleteSubManagerModal(){ 
      this.showDeleteSubManagerModal = true
    },

    closeDeleteSubManagerModal(){
      this.showDeleteSubManagerModal = false 
    },

    openSendInviteToSubManagerModal(){ 
      this.showSendInviteToSubManagerModal = true
    },

    closeSendInviteToSubManagerModal(){
      this.showSendInviteToSubManagerModal = false 
    },

    setCurrentSubManager(user){
      this.currentSubManager = user
    },


    archiveSubManager() {

      SubManagerService.archiveThisSubManager(this.currentSubManager.id)
        .then((response) => {

          this.closeDeleteSubManagerModal()

          this.fetchAllSubManagers()

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });


    },


    sendEmailInvite() {

 
      this.isLoading = true


      SubManagerService.managerSendEmailInvite(this.inviteFormData)
        .then((response) => {

          this.isLoading = false

          this.closeSendInviteToSubManagerModal()
          this.inviteFormData.email = ''

          toast("Invite sent!", {
            position: "bottom-right",
            autoClose: 1000,
          });


          // // eslint-disable-next-line no-console
          // console.log(response)


        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });



    },







  },


});
