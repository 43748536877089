/* eslint-disable */


import EmployeeDashService from "@/services/EmployeeDashService";
import SOPAppService from "@/services/SOPAppService";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";

export const useEmployeeDashboardStore = defineStore("employeeDashboardStore", {

  state: () => ({



    acknowledgments: [],
    post: {},
    quiz: {},
    questions: [],
    checkIfUserAcknowledged: {},
    loading: false,
    questionIndex: 0,
    userResponses: [], 

    digitalOceanUrl: "https://safelyio.nyc3.cdn.digitaloceanspaces.com/9j1ks/",

    questionAnswer: {
      question_id: 0,
      answer_id: 0,
      quiz_id: "",
    },

    thisVideoIsYoutube: false,

    source1: "",
    doesPDFNameExistInLibrary: "",

    scoreData: {
      schedule_message_random_string: "",
      pass_score: "",
    },


    sopFiles: [], 






  }),


  actions: {


    fetchAllTalks() {

      this.acknowledgments = []

      EmployeeDashService.getAllTalks()
        .then((response) => {

          this.acknowledgments = response.data.myAcknowledgments

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((error) => {

          // eslint-disable-next-line no-console
          console.log(error)

        });

    },

    fetchPost(schedule_message_random_string) {


      this.post = {}
      this.thisVideoIsYoutube = false
      this.doesPDFNameExistInLibrary = ""
      this.source1 = ""
      this.checkIfUserAcknowledged = {}
      this.quiz = {}
      this.questions = []
      this.questionIndex = 0


      EmployeeDashService.getATalk(schedule_message_random_string)
        .then((response) => {

          this.post = response.data.scheduleMessage.post;
          this.checkIfUserAcknowledged = response.data.checkIfUserAcknowledged;
          this.quiz = response.data.quiz;

          if (this.quiz) {
            this.questions = response.data.questions;
            this.userResponses = Array(this.questions.length).fill(false);
          }


          if (this.post.message_goal_id == 3) {

            //write a function to return if video_embed_code includes 'youtube'
            if (this.isThisVideoYoutube()) {

              this.thisVideoIsYoutube = true


            }

          }

          if (this.post.message_goal_id == 2) {


            this.doesPDFNameExistInLibrary = response.data.doesExistInLibrary

            this.buildPDFurl2()


          }


          // console.log(response.data);

        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

    isThisVideoYoutube() {

      // Check if videoCode includes 'youtube' and return true or false
      return this.post.video_embed_code.includes('youtube');

    },


    buildPDFurl2() {

      let url = "";

      if (this.doesPDFNameExistInLibrary === 1) {

        url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/pdftalks/" + this.post.pdf_url_path;

        this.source1 = url;

        return;
      }

      if (this.doesPDFNameExistInLibrary === 0) {

         //if master_account_admin_id is null it means it was created by the master manager account
         if(this.post.master_account_admin_id == null){

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.post.manager_id + "/9j1ks/" + this.post.pdf_url_path;

        }

        //this is mean it was created by a master account admin
        if(this.post.master_account_admin_id !== null){

          url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.post.master_account_admin_id + "/9j1ks/" + this.post.pdf_url_path;

        }

        // url = "https://safelyio.nyc3.cdn.digitaloceanspaces.com/u" + this.post.manager_id + "/9j1ks/" + this.post.pdf_url_path;

        this.source1 = url;

        // console.log(url)
        // console.log(this.source1)

        return;
      }


    },


    acknowledge(schedule_message_random_string) {

      const formData = {
        schedule_message_random_string: schedule_message_random_string
      }


      EmployeeDashService.acknowledgeThisTalk(formData)
        .then(() => {


          this.checkIfUserAcknowledged.is_acknowledged = 1

          toast("You've successfully acknowledged this safety talk!", {
            position: "bottom-right",
            autoClose: 2000,
          });

          // // eslint-disable-next-line no-console
          // console.log(response.data);


        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },


    next() {
      this.questionIndex++;
    },

    prev() {
      this.questionIndex--;
    },


    choices(questionId, choiceId) {
      this.questionAnswer.question_id = questionId;
      this.questionAnswer.answer_id = choiceId;
      this.questionAnswer.quiz_id = this.quiz.id;
    },

    score() {
      return this.userResponses.filter((val) => {
        return val === true; //This count the number of 'true' responses
      }).length;
    },

    percentageScore() {
      var pScore = 0;

      let correctAnswers = parseInt(this.score()); //convert 'score' value to integer
      let totalQuestions = parseInt(this.questions.length); //convert value to integer

      pScore = Math.trunc((correctAnswers / totalQuestions) * 100);

      this.scoreData.pass_score = pScore;

      return pScore;
    },


    refreshFinalQuizPage() {
      this.questionIndex = 0;
    },


    storeUserChoice() {

      EmployeeDashService.storeQuizAnswerResult(this.questionAnswer)
        .then(() => {
          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });

      // // eslint-disable-next-line no-console
      // console.log(this.questionAnswer)
    },


    storePasscore(schedule_message_random_string) {

      this.scoreData.schedule_message_random_string = schedule_message_random_string


      EmployeeDashService.storeScore(this.scoreData)
        .then(() => {


          this.checkIfUserAcknowledged.is_acknowledged = 1

          toast("You've successfully acknowledged this safety talk!", {
            position: "bottom-right",
            autoClose: 2000,
          });


          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });

    },


    fetchAllGroupTalks() { 

      this.acknowledgments = []


      EmployeeDashService.getAllGroupTalks()
          .then((response) => {

              this.acknowledgments = response.data.groupTalkAcknowledgments

              // // eslint-disable-next-line no-console
              // console.log(response.data)

          }).catch((error) => {

              // eslint-disable-next-line no-console
              console.log(error)

          });

    },

    fetchGroupTalkPost(random_string) {

 
      this.post = {}
      this.thisVideoIsYoutube = false
      this.doesPDFNameExistInLibrary = ""
      this.source1 = ""
    


      EmployeeDashService.getAGroupTalk(random_string)
        .then((response) => {

          this.post = response.data.post;  
          this.checkIfUserAcknowledged = response.data.checkIfUserAcknowledged;


          if (this.post.message_goal_id == 3) {

            //write a function to return if video_embed_code includes 'youtube'
            if (this.isThisVideoYoutube()) {

              this.thisVideoIsYoutube = true


            }

          }

          if (this.post.message_goal_id == 2) {


            this.doesPDFNameExistInLibrary = response.data.doesExistInLibrary

            this.buildPDFurl2()


          }


          // console.log(response.data);

        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },



    fetchAllSOPFilesForEmployee(){ 

      SOPAppService.getAllSOPFilesForEmployee()
        .then((response) => {

          // console.log(response.data)

          this.sopFiles = response.data.files

        }).catch((err) => {

          console.log(err)

        });

    },

    limitText(body) {
      if (body.length > 40) {
        return body.substring(0, 40) + "...";
      }

      return body;
    },



  },


});
