
<template>
    <div>

        <div class="progressBarWrapper"> 

            <div class="wrapper">


                <div class="progressBar">

                    <div class="progressFill" :style="{ width: campaignStore.progressPercentage + '%' }">

                    </div>

                </div>



            </div>


        </div>


    </div>
</template>

<script setup>

import { useCampaignStore } from '@/stores/CampaignStore'

const campaignStore = useCampaignStore();

</script>

<style scoped>


.progressBarWrapper {
    position: sticky;
    top: 0;
    /* background-color: transparent; */
    /* Add a background color if needed */
    z-index: 1;
    /* Add a z-index to position the element above other content */
}

.progressBar {
    /* min-width: 5px; */
    width: 100%;
    height: 10px;
    /* background: rgb(225, 225, 57); */
    background-color: rgba(177, 192, 177, 0.5);
    overflow: hidden;
   
}

.wrapper {
    width: 100%;
    /* margin-top: 20px;
    margin-bottom: 20px; */

}

.progressFill {

    height: 100%;
    /* background: red; */
    /* background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); */
    background-color: #85FFBD;
background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);

    transition: width 0.8s ease;
}




</style>