<template>
    <div>
        <div class="mainWrapper">
            <MainSideBarVue />

            <div class="rightContentWrapper">
                <div class="topNav">
                    <TopBarVue />
                </div>

                <div class="innerContainer">




                    <div class="theHeaderWrapper">



                        <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                            class="bi bi-arrow-left"></i>

                        <div style="margin-left: 20px;"
                            v-if="groupTalkStore.groupTalkPost && groupTalkStore.groupTalkPost.post">
                            Supervisors - {{ groupTalkStore.groupTalkPost.post.title }}
                        </div>




                    </div>



                    <div class="searchBarWrapper">

                        <input v-model="searchQuery" type="text" class="form-control theBar"
                            placeholder="Search by name..." />


                        <div>

                            <button @click="groupTalkStore.exportSupervisorsListForGroupTalkDataAsExcel(props.uuid)" class="button-13">Export as
                                Excel</button> 

                        </div>




                    </div>






                    <div class="tableWrapper">

                        <table class="table table-hover my-custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Talk Completed</th>
                                    <th scope="col">Method</th>
                                    <th scope="col">Time & Date </th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>

                            <tbody>

                                <tr v-for="(supervisor, index) in filteredSupervisors" :key="index">


                                    <td>
                                        <div v-if="supervisor.user">
                                            {{ supervisor.user.name }}
                                        </div>
                                    </td>

                                    <td>
                                        <div v-if="supervisor.is_acknowledged">

                                            <span class="badge rounded-pill bg-success"
                                                style="font-size: 12px;">YES</span>

                                        </div>

                                        <div v-else>

                                            <span class="badge rounded-pill bg-danger"
                                                style="font-size: 12px;">NO</span>

                                        </div>
                                    </td>


                                    <td>

                                        <div v-if="supervisor.completion_type == null"  style="font-size: 14px;">
                                            Not completed yet
                                        </div>

                                        <div v-if="supervisor.completion_type === 'checkoff'"  style="font-size: 14px;">
                                            Checkoff
                                        </div>

                                        <div v-if="supervisor.completion_type === 'signature'"  style="font-size: 14px;">
                                            Signatures
                                        </div>

                                        <div v-if="supervisor.completion_type === 'signed_sheet'"  style="font-size: 14px;">
                                            Sign-in Sheet
                                        </div>

                                    </td>

                                    <td>


                                        <div v-if="supervisor.is_acknowledged" style="font-size: 14px;">

                                            {{ supervisor.time_acknowledged }} on {{
                                                groupTalkStore.formatDate(supervisor.date_acknowledged) }}

                                        </div>

                                        <div v-else style="font-size: 14px;">

                                            Not completed yet

                                        </div>


                                    </td>

                                    


                                    <td>

                                        <div v-if="supervisor.is_acknowledged">

                                            <router-link class="btn btn-dark viewAttBtn" :to="{
                                                name: 'ViewGroupTalkAttendees',
                                                params: { uuid: supervisor.uuid },
                                            }">
                                                View Atendees

                                            </router-link>


                                        </div>

                                        <div v-else style="font-size: 14px;">

                                            Not completed yet

                                        </div>



                                    </td>






                                </tr>

                            </tbody>




                        </table>



                        <button v-if="displayedCount < groupTalkStore.supervisorsWithGroupTalkPosts.length"
                            class="btn btn-dark loadMoreBtn" @click="loadMore">Load more</button>




                    </div>




                </div>
            </div>
        </div>





    </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { onMounted, defineProps, ref, computed } from 'vue';
import { useGroupTalkStore } from '@/stores/GroupTalkStore';


const groupTalkStore = useGroupTalkStore();

const props = defineProps({
    uuid: String
})

const searchQuery = ref('');

const filteredSupervisors = computed(() => {

    return groupTalkStore.supervisorsWithGroupTalkPosts
        .filter(sup => {

            if (sup.user && sup.user.name) {
                return sup.user.name.toLowerCase().includes(searchQuery.value.toLowerCase());
            }
            return false; // Exclude supervisors without a user or name
        })
        .slice(0, displayedCount.value);
});

const displayedCount = ref(45);
const incrementStep = 25;

const loadMore = () => {
    displayedCount.value += incrementStep;
};

onMounted(() => {

    groupTalkStore.fetchAllSupervisorsForThisGroupTalkPost(props.uuid) 


})



</script>

<style scoped>
.mainWrapper {
    /* background-color: aliceblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    /* overflow-y: auto; */
    width: 83%;
    display: flex;
    flex-direction: column;
}

.contentContainer {
    /* overflow-y: auto; */
    background-color: rgb(208, 28, 55);
    /* margin: 30px; */
    margin-top: 100px;
}

.rightColumns {
    /* display: flex;
  flex-direction: column; */
}

.topNav {
    background-color: blue;
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}

.theBar {
    width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.button-6 {
    align-items: center;
    /* background-color: #FFFFFF; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-6:hover,
.button-6:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
    transform: translateY(-1px);
}

.button-6:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}

.campName {
    text-decoration: none;
    color: inherit;
}

.campName:hover {
    color: rgb(10, 159, 92);
    cursor: pointer;
    text-decoration: none;
}

.iconsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.theActionIcon {
    font-weight: bolder;
    font-size: 18px;
    color: rgb(5, 2, 2);
    cursor: pointer;
}

.theActionIcon:hover {
    cursor: pointer;
    color: rgb(30, 158, 30);
    scale: 1.3;
    transition: 1s;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.form-control {
    background-color: rgb(243, 246, 246);
}

.createBTN {
    width: 100%;
    margin-top: 20px;
}

.noTeamBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.noMatchBox {
    background-color: red;
    width: 100%;
}

.loadMoreBtn {
    background-color: black;
    border-radius: 10px;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.methodType {
    font-size: 13px;
    font-weight: normal;
    margin-left: 12px;
}

.theImgWrapper {
    width: 70px;
    height: 50px;
    overflow: hidden;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 5px;
    cursor: pointer;
}

.imgSignature {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;

}

.theSheetImgBox {
    width: 100%;
    height: 600px;
    /* background-color: green; */
    overflow-y: auto;
    padding: 20px;
    background-color: rgb(235, 235, 235);
    border-radius: 10px;
}

.theSheetImg {
    width: 400px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.viewAttBtn {
    font-size: 13px;
}
</style>