/* eslint-disable */
import LibraryTalkService from "@/services/LibraryTalkService";
import { defineStore } from "pinia";

export const useToolboxLibraryStore = defineStore("ToolboxLibraryStore", {

  state: () => ({

    talks: [],
    currentTalk: {},
    searchTerm: "",

    showPreviewTalkModal: false,

  }),

  actions: { 

    fetchAllLibraryTalks() {

      LibraryTalkService.getAllLibraryTalk()
        .then((response) => {

          this.talks = response.data.libaryTalks;
          
          // console.log(response.data)

        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });

    },



    openShowPreviewTalkModal(){
      this.showPreviewTalkModal = true
    },

    closeShowPreviewTalkModal(){
      this.showPreviewTalkModal = false
    },

    setCurrentTalk(talk){

      this.currentTalk = talk

    }




  },


});
