<template>
    <div class="mainWrapper">


        <EmployeeMainSideBarVue v-if="windowWidth > mobileWidthThreshold" />

        <div v-else>

            <EmployeeMobileTopNav />

            <!-- <EmployeeMobileBottomNavVue /> -->

        </div>





        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 40px;' : ''">


            <div class="topNav">
                <TopBarVue v-if="windowWidth > mobileWidthThreshold" />
            </div>



            <div class="innerContainer">

                <div class="theHeaderWrapper">

                    <i @click="$router.go(-1)" style="font-size: 25px; font-weight: bold; cursor: pointer;"
                        class="bi bi-arrow-left"></i>

                    <div style="margin-left: 20px;">
                        Account

                    </div>

                </div>






                <div class="accountWrapper">




                    <div class="accountBox">


                        <div class="myDetailsBox">


                            <div class="headerBox">

                                Avatar


                            </div>


                            <form class="uploadBox" @submit.prevent="authStore.uploadProfileImage">


                                <div class="thePic">



                                    <div class="mb-3">

                                        <input @change="authStore.previewImage" accept="image/*" class="form-control"
                                            type="file">
                                    </div>


                                    <div class="image-preview" v-if="authStore.imageData.length > 0">
                                        <img class="preview" :src="authStore.imageData" style="width: 100px;">
                                    </div>


                                    <button type="submit" v-if="authStore.imageData" class="btn btn-success mt-3"
                                    ><b>Update Image</b></button>



                                </div>







                            </form>


                        </div>


                        <div class="myDetailsBox">




                            <div class="headerBox">

                                My Details


                            </div>


                            <form class="formBox" @submit.prevent="authStore.updateMyDetails">


                                <div class="mb-3">
                                    <label class="form-label" style="float: left;">First Name</label>
                                    <input v-model="authStore.currentUser.fname" type="text" class="form-control theInput"
                                        required>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" style="float: left;">Last Name</label>
                                    <input v-model="authStore.currentUser.lname" type="text" class="form-control theInput"
                                        required>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" style="float: left;">Email</label>
                                    <input v-model="authStore.currentUser.email" type="email" class="form-control theInput"
                                        required>
                                </div>




                                <button type="submit" class="btn btn-dark" style="width: 100%; margin-top: 10px;"><b>Save
                                        Changes</b></button>



                            </form>


                        </div>


                        <div class="myDetailsBox">

                            <div v-if="authStore.errorMsg" role="alert" aria-live="polite" aria-atomic="true"
                                class="alert alert-dismissible alert-danger">
                                {{ authStore.errorMsg }}
                            </div>


                            <div class="headerBox">

                                Update Password



                            </div>


                            <form class="formBox" @submit.prevent="authStore.updateMyPassword">


                                <div class="mb-3">
                                    <label class="form-label" style="float: left;">Current Password</label>
                                    <input v-model="authStore.passwordData.current_password" type="password"
                                        class="form-control theInput" required>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" style="float: left;">New Password</label>
                                    <input v-model="authStore.passwordData.new_password" type="password"
                                        class="form-control theInput" required>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" style="float: left;">Confirm Password</label>
                                    <input v-model="authStore.passwordData.confirm_new_password" type="password"
                                        class="form-control theInput" required>
                                </div>




                                <button type="submit" class="btn btn-dark" style="width: 100%; margin-top: 10px;"><b>Change
                                        Password</b></button>



                            </form>


                        </div>

                    </div>




                </div>



            </div>






        </div>





    </div>
</template>

<script setup>

import { ref, onMounted, onUnmounted } from 'vue';
import EmployeeMainSideBarVue from '@/components/EmployeeMainSideBar.vue';
import TopBarVue from "@/components/TopBar.vue";
// import EmployeeMobileBottomNavVue from '@/components/EmployeeMobileBottomNav.vue';
import EmployeeMobileTopNav from '@/components/EmployeeMobileTopNav.vue';
import { useAuthStore } from '@/stores/AuthStore';


const authStore = useAuthStore();

const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed

const onResize = () => {
    windowWidth.value = window.innerWidth;
};

onMounted(() => {


    authStore.getUser();

    window.addEventListener('resize', onResize);


});

onUnmounted(() => {
    window.removeEventListener('resize', onResize);



});



</script>

<style  scoped>
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: blueviolet; */
    overflow-y: auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
}

.theHeaderWrapper {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.accountWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}


.accountBox {
    width: 500px;

    /* background-color: antiquewhite; */
}


.myDetailsBox {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.headerBox {
    width: 100%;
    background-color: #e9faf3;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    text-align: left;
}

.theInput {

    width: 100%;

}

.formBox {
    padding: 10px;
    margin-bottom: 30px;
}

.uploadBox {

    padding: 30px;
    margin-bottom: 30px;

}

.thePic {
    display: flex;
    flex-direction: column;
    justify-content: center;
}</style>