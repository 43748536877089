/* eslint-disable */
import TemplateService from "@/services/TemplateService";
import moment from "moment";
import { defineStore } from "pinia";
import { computed } from "vue";
import { debounce } from 'lodash'
import { toast } from "vue3-toastify";

export const useTemplateStore = defineStore("TemplateStore", {

  state: () => ({


    templates: [],

    showNewTemplateModal: false,

    templateCreationOptions: [
      { id: 1, name: "Create from scratch", desc: 'Build your own template from scratch', type: "from_scratch", icon_name: 'bi bi-file-earmark-text' },
      { id: 2, name: "Create with AI", desc: 'Let AI build a template based on your instructions.', type: "create_with_ai", icon_name: 'bi bi-magic' },
      { id: 3, name: "Use a Public Template", desc: 'Pick a template from our library.', type: "use_template", icon_name: 'bi bi-file-earmark-check' }
    ],

    editorTabOptions: [
      { id: 1, name: "Builder", type: "builder" },
      { id: 2, name: "Report", type: "report" },
      { id: 3, name: "Access", type: "access" }
    ],

    currentEditorTab: 'builder',

    currentTab: 'from_scratch',

    template: {},

    showSectionElement: true,
    showPageElement: false,

    responseTypes: [
      { id: 1, name: 'Text Answer', type: 'text_answer', img_name: 'text' },
      { id: 2, name: 'Multiple Choice', type: 'multiple_choice', img_name: 'test' },
      { id: 3, name: 'Checkbox', type: 'checkbox', img_name: 'checkmark' },
      { id: 4, name: 'Number', type: 'number', img_name: 'number' },
      { id: 5, name: 'Date & Time', type: 'datetime', img_name: 'calendar' },
      { id: 6, name: 'Date Only', type: 'date', img_name: 'calendar' },
      { id: 7, name: 'Time Only', type: 'time', img_name: 'clock' },
      { id: 8, name: 'Media', type: 'media', img_name: 'camera' },
      // { id: 6, name: 'Photo', type: 'photo', img_name: 'camera' },
      // { id: 7, name: 'Video', type: 'video', img_name: 'play' },
      { id: 9, name: 'Slider', type: 'slider', img_name: 'slider' },
      { id: 10, name: 'Geo Stamp', type: 'geostamp', img_name: 'map' },
      { id: 11, name: 'Instruction', type: 'instruction', img_name: 'guidebook' },
      // { id: 12, name: 'Pass/Fail', type: 'pass_fail', img_name: 'pass_fail' },
      { id: 13, name: 'Dropdown', type: 'dropdown', img_name: 'drop1' },
      { id: 14, name: 'Signature', type: 'signature', img_name: 'sign2' },
    ],

    templatePages: [],

    currentPage: {},
    currentPageIndex: null,

    currentElement: {},
    currentElementIndex: null,

    currentSectionElement: {},
    currentSectionElementIndex: null,

    showReorderPagesModal: false,

    showPageBox: false,

    currentMobilePreviewPageIndex: 0,
    currentMobilePage: {},


    isLoading: false,

    showImageAndVideoGalleryModal: false,
    currentFileIndexInGallery: null,

    currentElementImageVideFilesArray: [],

    currentFile: {},
    galleryFiles: [],

    showRangeSelectorModal: false,

    colorArray: [
      { id: 1, hex_code: '#228626' },
      { id: 2, hex_code: '#e9a041' },
      { id: 3, hex_code: '#ff8f00' },
      { id: 4, hex_code: '#8d6e63' },
      { id: 5, hex_code: '#dc112f' },
      { id: 6, hex_code: '#29b6f6' },
      { id: 7, hex_code: '#5c6bc0' },
      { id: 8, hex_code: '#7e57c2' },
      { id: 9, hex_code: '#2f3fe4' },
      { id: 10, hex_code: '#ff73a6' },
      { id: 11, hex_code: '#9a9a9a' },
      { id: 12, hex_code: '#d6ca23' },
    ],

    showSavedOptionsModal: false,
    showOptionEditMode: false,
    allOptions: [],

    currentOption: {},


    showSignatureModal: false,
    currentSignatureType: 'text_sign',

    signOptions: [
      { id: 1, name: 'Text Signature', type: 'text_sign' },
      { id: 2, name: 'Digital Signature', type: 'digital_sign' },
    ],


    currentGeoPosition: {
      latitude: null,
      longitude: null
    },

    showAutomationsModal: false,
    showEditAutomationModal: false,

    logicConditions: [
      { id: 1, name: 'is equal to', type: 'is_equal_to' },
      { id: 2, name: 'is not equal to', type: 'is_not_equal_to' },
    ],

    checkboxLogicConditions: [
      { id: 1, name: 'is equal to', type: 'is_equal_to' },
    ],


    singleChoiceLogicConditions: [
      { id: 1, name: 'is selected', type: 'is_selected' },
      { id: 2, name: 'is not selected', type: 'is_not_selected' },
    ],


    multipleChoiceLogicConditions: [
      { id: 3, name: 'is one of selected', type: 'is_one_of_selected' },
      { id: 4, name: 'is not one of selected', type: 'is_not_one_of_selected' },
    ],



    numberLogicConditions: [
      { id: 1, name: 'is equal to', type: 'is_equal_to' },
      { id: 2, name: 'is not equal to', type: 'is_not_equal_to' },
      { id: 3, name: 'is less than', type: 'is_less_than' },
      { id: 4, name: 'is greater than', type: 'is_greater_than' },
    ],


    triggerOptions: [
      { id: 1, name: 'Require Action', type: 'require_action', icon_name: 'bi bi-check2-square' },
      { id: 2, name: 'Require Media', type: 'require_media', icon_name: 'bi bi-image' },
      { id: 3, name: 'Notify', type: 'notify', icon_name: 'bi bi-bell' },
      { id: 4, name: 'Ask Follow Up Question', type: 'ask_follow_up_question', icon_name: 'bi bi-chat-left-dots' },
    ],

    checkboxOptions: [
      { id: 1, name: 'Checked', type: 'checked' },
      { id: 2, name: 'Not Checked', type: 'not_checked' },
    ],

    showTriggerBox: false,

    newAutomation: {
      trigger_type: null,
      string_value: null,
      number_value: null,
      selected_choice_id: null,
      template_dropdown_option_id: null,
      logic_condition: 'is_equal_to',
    },

    editAutomationObject: {},


    showColorBoxModal: false,
    currentChoice: null,

    newSite: {
      id: null,
      name: null,
      desc: null,
    },

    showNewSiteModal: false,
    showEditSiteModal: false,

    sites: [],
    assets: [],

    showNewAssetModal: false,

    newAsset: {
      name: null,
      desc: null,
      site_id: null,
      model: null,
      serial_number: null,
      purchased_date: null,
      files: []
    },

    isAssetLoading: false,

    asset: {},
    imgsArray: [],
    videosArray: [],
    pdfsArray: [],

    showImagePreviewModal: false,
    currentAssetMediaFile: {},

    showEditAssetForm: false, 

    showAddNewLabelForm: false,
    showAddEditLabelForm: false,

    labels: [],

    theLabel: {
      name: null
    },

    showNewActionModal: false,

    priorityLevels: [
      {id: 1, name: 'Low Priority', type: 'low_priority' },
      {id: 2, name: 'Medium Priority', type: 'medium_priority' },
      {id: 3, name: 'High Priority', type: 'high_priority' }
    ],

    newAction: {
      unique_template_id: null,
      title: null,
      desc: null,
      priority: null,
      due_date: null,
      label_id: null,
      site_id: null,
    },

    actions: [],

    showInspectionAddUsersModal: false,

    allEmployees: [],
    employeesUsingInspectionApp: [],

    usersToAddToInspectionAppArray: []
    



  }),


  getters: {



    getImageAndVideoFilesOnly: () => {
      return (element) => {
        if (!element.media_files || element.media_files.length === 0) {
          return []; // Return an empty array if media_files does not exist or is empty
        }
        return element.media_files.filter(file => file.type === 'image' || file.type === 'video');
      };
    },



    getPdfFiles: (state) => {
      return (element) => {
        // Check if media_files exist and is not empty before filtering
        if (!element.media_files || element.media_files.length === 0) {
          return []; // Return an empty array if media_files does not exist or is empty
        }
        return element.media_files.filter(file => file.type === 'pdf');
      };
    },


  },

  actions: {



    fetchAllTemplates() {

      TemplateService.getAllTemplates()
        .then((response) => {

          this.templates = response.data.templates
          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    fetchaATemplatePagesForEditor(random_string) {

      TemplateService.getTemplateForEditor(random_string)
        .then((response) => {

          this.template = response.data.template
          this.templatePages = response.data.templatePages

          //set first page as current page
          this.currentPage = this.templatePages[0]
          this.currentPageIndex = 0

          //set mobile preview page too
          this.setCurrentMobilePreviewPageIndex(0)


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    newTemplateRouter(type) {

      if (type === 'from_scratch') {


        TemplateService.createNewTemplateFromScratch()
          .then((response) => {

            this.template = response.data.template

            if (this.template) {


              this.router.push({
                name: "TemplateEditor",
                params: { random_string: this.template.random_string },
              });


            }



          }).catch((err) => {

            console.log(err)

          });



      }

      if (type === 'create_with_ai') {
        alert('create_with_ai')
      }

      if (type === 'use_template') {
        alert('use_template')
      }

    },


    openNewTemplateModal() {
      this.showNewTemplateModal = true
    },

    closeNewTemplateModal() {
      this.showNewTemplateModal = false
    },


    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    formatTime(time) {
      var formatedTime = moment(time, "HH:mm").format("h:mma");

      return formatedTime;
    },

    funckingTest() {

      alert("skdjbfjknsdf")

    },

    togglePage(page) {

      if (page.show_page === 1) {

        page.show_page = 0

      } else {

        page.show_page = 1

      }



    },

    toggleSection(section) {

      if (section.show_section === 1) {

        section.show_section = 0

      } else {

        section.show_section = 1

      }

    },


    toggleEditPageTitle(page) {

      if (page.is_editing_title === 1) {

        page.is_editing_title = 0

      } else {

        page.is_editing_title = 1

      }

    },

    toggleEditSectionName(section) {

      if (section.is_editing_name === 1) {

        section.is_editing_name = 0

      } else {

        section.is_editing_name = 1

      }

    },




    addANewQuestion(pageId) {

      const formData = {
        template_page_id: pageId
      }

      console.log(formData)

      TemplateService.addNewQuestion(formData)
        .then((response) => {


          let newElement = response.data.newElement


          const pageIndex = this.templatePages.findIndex(
            (page) => page.id === formData.template_page_id
          );

          this.templatePages[pageIndex].elements.push(newElement)


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    addANewSection(page) {

      const formData = {
        template_page_id: page.id,
      }

      // console.log(page)


      TemplateService.addNewSection(formData)
        .then((response) => {

          let newSectionElement = response.data.newSectionElement

          page.elements.push(newSectionElement)


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });




    },

    setCurrentPage(page) {
      this.currentPage = page
    },

    setCurrentElement(element, elementIndex) {

      this.currentElement = {} //reset

      this.currentElement = element
      this.currentElementIndex = elementIndex

      this.galleryFiles = this.getImageAndVideoFilesOnly(this.currentElement)


    },



    addQuestionToASection(element) {



      const formData = {
        template_page_layout_id: element.id,
        template_section_id: element.template_section_id,
      }

      // // console.log(formData)
      // console.log(formData)
      // console.log(pageIndex)



      TemplateService.addNewQuestionToASection(formData)
        .then((response) => {


          let newPageSectionElement = response.data.newPageSectionElement

          element.section_layouts.push(newPageSectionElement)

          // this.insertNewSectionQuestionInLastPosition(pageIndex, formData.template_section_id, newSectionQuestionElement)


          // const pageIndex = this.templatePages.findIndex(
          //   (page) => page.id === this.templatePages[pageIndex].id
          // );

          // this.templatePages[pageIndex].elements.push(newQuestionElement)

          // let newQuestion = response.data.newQuestion

          // element.questions.push(newQuestion)


          // this.templatePages[pageIndex].elements.forEach(element => {

          //   if(element.id === formData.template_section_id){

          //     // this.templatePages[pageIndex].elements.push(newElement)



          //   }

          // });

          // const pageIndex = this.templatePages.findIndex(
          //   (page) => page.id === formData.template_page_id
          // );

          // this.templatePages[pageIndex].elements.push(newElement)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    insertNewSectionQuestionInLastPosition(pageIndex, sectionId, newObject) {

      let currentPage = this.templatePages[pageIndex]

      const filteredSectionQuestions = computed(() => {
        return currentPage.elements.filter(ele => ele.section_id === sectionId);
      });


      const lastSectionQuestiontIndex = filteredSectionQuestions.length - 1;


      if (lastSectionQuestiontIndex !== -1) {
        // Insert newObject after the target object
        // The first parameter of splice is the index at which to start changing the array.
        // Since we want to insert after the target, we add 1 to the targetIndex.
        // The second parameter specifies the number of elements to remove, which is 0 in this case.
        // The third parameter is the new item to be added to the array.
        this.templatePages[pageIndex].elements.splice(lastSectionQuestiontIndex + 1, 0, newObject);

      }


    },



    deleteThisQuestion(templatePageLayoutId, pageIndex) {

      const formData = {
        template_page_layout_id: templatePageLayoutId
      }


      TemplateService.deleteAQuestion(formData)
        .then((response) => {


          const pageElementToDelete = this.templatePages[pageIndex].elements.findIndex(
            (ele) => ele.id === templatePageLayoutId
          );

          if (pageElementToDelete !== -1) {
            this.templatePages[pageIndex].elements.splice(pageElementToDelete, 1);
          }


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });



    },



    deleteASectionQuestion(element, sectionElement) {


      const formData = {
        template_page_layout_id: element.id,
        template_page_section_layout_id: sectionElement.id
      }



      TemplateService.deleteAQuestionFromASection(formData)
        .then((response) => {

          const sectionQuestionElementToDelete = element.section_layouts.findIndex(
            (ele) => ele.id === formData.template_page_section_layout_id
          );


          if (sectionQuestionElementToDelete !== -1) {
            element.section_layouts.splice(sectionQuestionElementToDelete, 1);
          }

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    deleteASection(element, pageIndex) {

      if (confirm("Deleting this section also delete all the questions under it. Are you sure you want to delete this Section?")
      ) {



        const formData = {
          template_page_layout_id: element.id,
        }



        TemplateService.deleteASection(formData)
          .then((response) => {

            const elementToDelete = this.templatePages[pageIndex].elements.findIndex(
              (ele) => ele.id === element.id
            );


            if (elementToDelete !== -1) {
              this.templatePages[pageIndex].elements.splice(elementToDelete, 1);
            }

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });




      }


    },



    addNewPage() {

      const formData = {
        template_id: this.template.id
      }


      TemplateService.addANewPage(formData)
        .then((response) => {

          let newPage = response.data.newPage;

          this.templatePages.push(newPage)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });




    },



    deleteAPage(page) {

      const formData = {
        template_page_id: page.id
      }


      if (confirm("Deleting this page also delete all the questions and sections under it. Are you sure you want to delete this page?")
      ) {

        TemplateService.deleteThisPage(formData)
          .then((response) => {

            const pageToDelete = this.templatePages.findIndex(
              (pg) => pg.id === page.id
            );

            if (pageToDelete !== -1) {
              this.templatePages.splice(pageToDelete, 1);
            }


            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });

      }





    },



    openShowReorderPagesModal() {
      this.showReorderPagesModal = true
    },

    closeShowReorderPagesModal() {
      this.showReorderPagesModal = false
    },


    setSelectedResponseType(type) {

      this.currentElement.response_type = type

      //delete an associated automations
      if (this.currentElement.automations.length > 0) {

        const formData = {
          the_element: this.currentElement
        }


        TemplateService.deleteWhenElementChangesDeleteAutomations(formData)
          .then((response) => {

            this.currentElement.automations = []
            console.log(response.data)

          }).catch((err) => {

          });

      }




    },


    checkMove(event) {


      // Check if the item being dragged is a 'section_header'
      if (event.draggedContext.element.element_type === 'section_header') {
        // Check if the destination list has the class 'section-layouts-list'
        if (event.to.classList.contains('section-layouts-list')) {
          // Prevent the move
          return false;
        }
      }
      // Allow the move for other items or to other lists
      return true;

    },

    checkDropdownItemMove(event) {



      // Check if the element being dragged is theItemWrapper div
      if (event.dragged.classList.contains('theItemWrapper')) {

        if (event.to.classList.contains('dropdown_list')) {
          // Prevent the move from going into elements-list and section-layouts-list
          return true;
        }

      }

      return false


    },



    // onAddToPageElementsArray(event){

    //   let element = event.item._underlying_vm_
    //   element.element_type = 'question'
    //   element.template_section_id = null


    //   //update on the backend


    //     // // Log the ID of the item
    //     // console.log(event.item._underlying_vm_);

    // },

    // onAddToSection(event){

    //   console.log(event);

    //   // console.log(event.item._underlying_vm_);

    // },


    togglePageBox() {
      this.showPageBox = !this.showPageBox
    },

    setCurrentMobilePreviewPageIndex(index) {
      this.currentMobilePreviewPageIndex = index
      this.currentMobilePage = this.templatePages[index]
    },




    triggerInput() {

      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = 'image/*,video/*,application/pdf';


      input.onchange = () => {
        let files = Array.from(input.files);
        this.uploadPreviewFiles(files)
        // console.log(files);
      };

      input.click();

    },

    uploadPreviewFiles(files) {


      //show loading state
      this.currentElement.is_file_uploading = 'true'

      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      TemplateService.uploadPreviewFiles(formData)
        .then((response) => {

          let filesArray = response.data.mediaFiles

          filesArray.forEach((file) => {

            // Create a deep copy of the file object
            let fileCopy = JSON.parse(JSON.stringify(file));

            // Modify fileCopy as needed

            // Push the copy into the array
            this.currentElement.media_files.push(fileCopy);





          });

          this.currentElement.is_file_uploading = 'false'

          console.log(response.data);

        }).catch((err) => {
          console.log(err);
        });
    },


    openShowImageAndVideoGalleryModal() {


      // //set the current gallery array
      // if(this.galleryFiles.length > 0){

      //   this.currentElement.media_files

      // }


      this.showImageAndVideoGalleryModal = true
    },

    closeShowImageAndVideoGalleryModal() {
      this.showImageAndVideoGalleryModal = false

      //reset gallery array
      this.galleryFiles = []

    },


    setCurrentFileIndex(theFile) {

      //set the array
      this.galleryFiles = []
      this.galleryFiles = this.getImageAndVideoFilesOnly(this.currentElement)

      //find the index of the matching file id
      const fileIndex = this.galleryFiles.findIndex(
        (file) => file.name === theFile.name
      );

      this.currentFileIndexInGallery = fileIndex


      this.openShowImageAndVideoGalleryModal()

      console.log(this.currentFileIndexInGallery)
      console.log(theFile)




    },

    nextFile() {
      if (this.currentFileIndexInGallery < this.galleryFiles.length - 1) {
        this.currentFileIndexInGallery++;
      }

    },

    prevFile() {
      if (this.currentFileIndexInGallery > 0) {
        this.currentFileIndexInGallery--;
      }
    },



    deleteFileFromGallery() {

      if (this.currentFileIndexInGallery !== -1) {

        let theFile = this.galleryFiles[this.currentFileIndexInGallery]

        //delete file from currentElement media_file by name
        const theFileInMediaFilesToDeleteIndex = this.currentElement.media_files.findIndex(
          (file) => file.name === theFile.name
        );

        if (theFileInMediaFilesToDeleteIndex !== -1) {
          this.currentElement.media_files.splice(theFileInMediaFilesToDeleteIndex, 1);
        }



        //delete from gallery array too
        this.galleryFiles.splice(this.currentFileIndexInGallery, 1);

        //set to last file in array
        let lastIndex = this.galleryFiles.length - 1;
        this.currentFileIndexInGallery = lastIndex

        //rerun the function that filters the img and video
        this.getImageAndVideoFilesOnly(this.currentElement)


        // if no more files left in array close the modal
        if (this.galleryFiles.length === 0) {

          this.closeShowImageAndVideoGalleryModal()

        }

      }
    },


    deletePdfFile(pdfFile) {


      //find pdf file by name in media_files and delete
      const pdfToDelete = this.currentElement.media_files.findIndex(
        (pdf) => pdf.name === pdfFile.name
      );

      if (pdfToDelete !== -1) {
        this.currentElement.media_files.splice(pdfToDelete, 1);
      }

      // //then rerun pdf files filter getter func
      // this.getPDfFiles(this.currentElement)



    },



    downloadFileFromGallery() {

      let theFile = this.galleryFiles[this.currentFileIndexInGallery]


      if (theFile.type === 'image') {

        let downloadURL = theFile.url;

        fetch(downloadURL)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = theFile.name; // Assuming theFile has a name property
            link.click();
          })
          .catch(console.error);



      }

      if (theFile.type === 'video') {


        let downloadURL = theFile.url;

        fetch(downloadURL)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = theFile.name; // Assuming theFile has a name property
            link.click();
          })
          .catch(console.error);


      }


    },

    downloadPDFfile(pdfFile) {

      console.log(pdfFile)

      // let downloadURL = pdfFile.url;

      // fetch(downloadURL)
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok");
      //     }
      //     return response.blob();
      //   })
      //   .then(blob => {
      //     const url = window.URL.createObjectURL(blob);
      //     const a = document.createElement("a");
      //     a.href = url;
      //     a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
      //     document.body.appendChild(a);
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      //     document.body.removeChild(a);
      //   })
      //   .catch(error => {
      //     console.error("There has been a problem with your fetch operation:", error);
      //   });



    },

    attachmentDownloadPDFfile(pdfFile) {

      let downloadURL = pdfFile.file_url;

      fetch(downloadURL)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error("There has been a problem with your fetch operation:", error);
        });



    },

    toggleOnEditMote(element) {

      element.show_note = 'yes'
    },

    toggleOffEditMote(element) {

      element.show_note = 'no'
    },


    openShowRangeSelectorModal() {
      this.showRangeSelectorModal = true
    },

    closeShowRangeSelectorModal() {
      this.showRangeSelectorModal = false
    },


    triggerInstructionAttachmentInput() {

      let input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*,application/pdf';

      input.onchange = (e) => {

        let file = e.target.files[0];

        if (file) {
          this.uploadAttachmentFiles(file);

          // Log the file name for debugging purposes
          console.log(file.name);

        }
      };

      // Trigger the file input dialog
      input.click();
    },



    uploadAttachmentFiles(file) {


      if (this.currentElement.is_a_section_element === 'no') {

        //add template_page_layout_id in reqpest 

        const formData = new FormData();
        const theTemplatePageLayoutId = this.currentElement.id;


        formData.append('is_a_section_element', 'no');
        formData.append('the_file', file);
        formData.append('template_page_layout_id', theTemplatePageLayoutId);



        TemplateService.uploadInstructionAttachmentFile(formData)
          .then((response) => {

            let theMediaFile = response.data.the_media_file

            this.currentElement.instruction_files.push(theMediaFile)

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });




      } else {

        //add template_page_section_layout_id in reqpest 

        const formData = new FormData();
        const theTemplatePageSectionLayoutId = this.currentElement.id;


        formData.append('is_a_section_element', 'yes');
        formData.append('the_file', file);
        formData.append('template_page_section_layout_id', theTemplatePageSectionLayoutId);



        TemplateService.uploadInstructionAttachmentFile(formData)
          .then((response) => {

            let theMediaFile = response.data.the_media_file

            this.currentElement.instruction_files.push(theMediaFile)

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });



      }





    },


    triggerInstructionAttachmentInputReplacement() {

      let input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*,application/pdf';

      input.onchange = (e) => {

        let file = e.target.files[0];

        if (file) {
          this.uploadReplacementAttachmentFiles(file);



        }
      };

      // Trigger the file input dialog
      input.click();
    },

    uploadReplacementAttachmentFiles(file) {

      const formData = new FormData();
      const theMediaFileId = this.currentElement.instruction_files[0].id


      formData.append('the_file', file);
      formData.append('the_media_file_id', theMediaFileId);



      TemplateService.uploadReplacementInstructionAttachmentFile(formData)
        .then((response) => {

          let theMediaFile = response.data.the_new_media_file

          //reset before new one pushed in
          this.currentElement.instruction_files = []

          this.currentElement.instruction_files.push(theMediaFile)


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });




    },



    deleteAttachmentFile() {


      if (confirm("Are you sure you want to delete this file?")
      ) {

        const formData = {
          the_media_file_id: this.currentElement.instruction_files[0].id
        }


        TemplateService.deleteAttachmentFile(formData)
          .then((response) => {

            this.currentElement.instruction_files = [] //reset 

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });
      }



    },


    addNewDropownOption() {

      const formData = {
        template_page_layout_id: this.currentElement.id
      }

      TemplateService.addANewOptionToDropdownToElement(formData)
        .then((response) => {

          let newOption = response.data.newOption

          this.currentElement.dropdown_options.push(newOption)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },


    deleteThisDropdownOption(option) {



      const formData = {
        dropdown_option_id: option.id
      }


      TemplateService.deleteADropdownOption(formData)
        .then((response) => {

          const optionToDelete = this.currentElement.dropdown_options.findIndex(
            (ele) => ele.id === option.id
          );


          this.currentElement.dropdown_options.splice(optionToDelete, 1);

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });






      console.log(option)


    },


    toggleIsFlagged(dropdownOption) {

      if (dropdownOption.is_flagged === 1) {
        dropdownOption.is_flagged = 0
      } else {
        dropdownOption.is_flagged = 1
      }

    },


    toggleDropdown(element) {

      if (element.show_dropdown === 1) {

        element.show_dropdown = 0

      } else {
        element.show_dropdown = 1
      }



    },

    setSelectedDropdownOption(element, dropItem) {

      element.dropdown_option_id = dropItem.id

      // console.log(element)

    },


    showSelectedOption(element) {

      let theOption = {
        option_text: null,
        color_code: null
      }

      element.dropdown_options.forEach((drop) => {
        if (drop.id === element.dropdown_option_id) {


          theOption.option_text = drop.option_text
          theOption.color_code = drop.color_code



          // Check if the string exceeds 30 characters
          if (theOption.option_text.length > 20) {
            theOption.option_text = theOption.option_text.slice(0, 20) + '...';
          }

        }
      });



      return theOption;

    },



    addNewChoiceToElement() {

      const formData = {
        template_page_layout_id: this.currentElement.id
      }


      console.log(formData, 'addNewChoiceToElement')

      TemplateService.addANewChoice(formData)
        .then((response) => {

          let newChoice = response.data.newChoice

          this.currentElement.multiple_choice_options.push(newChoice)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    choiceToggleIsFlagged(choice) {

      if (choice.is_flagged === 1) {
        choice.is_flagged = 0
      } else {
        choice.is_flagged = 1
      }

    },

    toggleIsMultipleSelect(element) {

      if (element.is_multiple_answer_selection === 'yes') {

        element.is_multiple_answer_selection = 'no'

      } else {

        element.is_multiple_answer_selection = 'yes'

      }



    },

    deleteChoice(choice) {


      const formData = {
        choice_id: choice.id
      }


      TemplateService.deleteAChoice(formData)
        .then((response) => {

          const choiceToDelete = this.currentElement.dropdown_options.findIndex(
            (ele) => ele.id === option.id
          );


          this.currentElement.multiple_choice_options.splice(choiceToDelete, 1);

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });




    },

    toggleDropdownColorBox(dropdownOption) {

      this.currentChoice = dropdownOption

      this.openColorBoxModal()


      // console.log(dropdownOption)

      // if (dropdownOption.show_color_popup === 1) {
      //   dropdownOption.show_color_popup = 0
      // } else {
      //   dropdownOption.show_color_popup = 1
      // }

    },




    setDropdowColor(dropdownOption, color) {

      dropdownOption.color_code = color.hex_code

      dropdownOption.show_color_popup = 0


    },


    toggleChoiceColorPopup(choice) {

      this.currentChoice = choice

      this.openColorBoxModal()

      // console.log(dropdownOption)

      // if (choice.show_color_popup === 1) {
      //   choice.show_color_popup = 0
      // } else {
      //   choice.show_color_popup = 1
      // }

    },

    setChoiceColor(color) {

      this.currentChoice.color_code = color.hex_code

      // choice.color_code = color.hex_code

      // choice.show_color_popup = 0
      this.closeColorBoxModal()


    },


    openShowSavedOptionsModal() {
      this.showSavedOptionsModal = true

      this.fetchAllSavedOptions();
    },

    closeShowSavedOptionsModal() {
      this.showSavedOptionsModal = false
    },

    showEditOptionArea() {

      this.showOptionEditMode = true

    },

    hideEditOptionArea() {

      this.showOptionEditMode = false

    },

    setCurrentOptionElement(option) {

      this.currentOption = option

      console.log(option)

    },

    bookmarkThisDropdownOptions() {

      const formData = {
        the_options: this.currentElement.dropdown_options
      }

      TemplateService.saveChoiceOrDropdownOption(formData)
        .then((response) => {

          console.log(response.data)

          toast("Options saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

        }).catch((err) => {

          console.log(err)

        });

      // console.log(this.currentElement.dropdown_options)

    },

    fetchAllSavedOptions() {


      TemplateService.getAllSavedOptions()
        .then((response) => {

          this.allOptions = response.data.allOptions

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    saveTheSavedOptionsChanges() {



      const formData = {
        template_saved_option_id: this.currentOption.id,
        the_options: this.currentOption.template_saved_option_elements
      }

      TemplateService.updateSavedOptions(formData)
        .then((response) => {

          toast("Saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    addNewSaveOption() {

      const formData = {
        template_saved_option_id: this.currentOption.id
      }

      TemplateService.addNewSavedOptionElement(formData)
        .then((response) => {


          let newOption = response.data.newOption

          this.currentOption.template_saved_option_elements.push(newOption)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },


    deleteASavedOptionElement(index) {


      const formData = {
        template_saved_option_element_id: this.currentOption.template_saved_option_elements[index].id
      }



      TemplateService.deleteThisSavedOptionElement(formData)
        .then((response) => {

          this.currentOption.template_saved_option_elements.splice(index, 1);

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });




    },

    deleteThisSavedOption(option, optionIndex) {


      if (confirm("Are you sure you want to delete these options?")
      ) {


        const formData = {
          template_saved_option_id: option.id
        }

        TemplateService.deleteSavedOption(formData)
          .then((response) => {


            this.allOptions.splice(optionIndex, 1);

            console.log(response.data)



          }).catch((err) => {

            console.log(err)

          });




      }




    },

    replaceSavedOption(option) {



      if (confirm("Using this saved option will replace the current ones in this dropdown. Are you sure?")
      ) {


        const formData = {
          template_page_layout_id: this.currentElement.id,
          template_saved_option_id: option.id
        }


        TemplateService.replaceWithSavedOptionElement(formData)
          .then((response) => {

            let newDropdownOptions = response.data.newDropdownOptions

            //replace with newDropdownOptions
            this.replaceWithNewDropdownOptions(newDropdownOptions)


            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });






      }




    },


    bookmarkTheseChoices() {

      const formData = {
        the_options: this.currentElement.multiple_choice_options,

      }


      TemplateService.saveChoiceOrDropdownOption(formData)
        .then((response) => {

          console.log(response.data)

          toast("Options saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

        }).catch((err) => {

          console.log(err)

        });


    },

    replaceWithSavedChoices(option) {



      if (confirm("Using these saved choices will replace the current ones. Are you sure?")
      ) {

        const formData = {
          template_page_layout_id: this.currentElement.id,
          template_saved_option_id: option.id
        }


        TemplateService.replaceWithSavedChoices(formData)
          .then((response) => {

            let newChoices = response.data.newChoices

            //replace with newDropdownOptions
            this.currentElement.multiple_choice_options = [] //reset      

            //then replace with new
            newChoices.forEach((choice) => {

              this.currentElement.multiple_choice_options.push(choice)

            });


            this.closeShowSavedOptionsModal()


            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });




      }




    },


    sectionReplaceWithSavedChoices(option) {



      if (confirm("Using these saved choices will replace the current ones. Are you sure?")
      ) {

        const formData = {
          template_page_section_layout_id: this.currentElement.id,
          template_saved_option_id: option.id
        }


        TemplateService.sectionReplaceWithSavedChoices(formData)
          .then((response) => {

            let newChoices = response.data.newChoices

            //replace with newDropdownOptions
            this.currentElement.multiple_choice_options = [] //reset      

            //then replace with new
            newChoices.forEach((choice) => {

              this.currentElement.multiple_choice_options.push(choice)

            });


            this.closeShowSavedOptionsModal()


            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });




      }




    },


    replaceWithNewDropdownOptions(newDropdownOptions) {


      this.currentElement.dropdown_options = [] //reset      

      //then replace with new
      newDropdownOptions.forEach((option) => {

        this.currentElement.dropdown_options.push(option)

      });


      this.closeShowSavedOptionsModal()



    },


    toggleScoringEnabled() {

      if (this.template.is_scoring_enabled === 'yes') {

        this.template.is_scoring_enabled = 'no'

      } else {
        this.template.is_scoring_enabled = 'yes'
      }


    },


    openShowSignatureModal() {
      this.showSignatureModal = true;
    },

    closeShowSignatureModal() {
      this.showSignatureModal = false;
    },


    setCurentSignOption(signOption) {
      this.currentSignatureType = signOption.type
    },




    getSignatureCurrentTime() {

      const formattedTime = moment().format('h:mm A');
      return formattedTime;

    },

    getSignatureCurrentDate() {

      const formattedDate = moment().format('Do MMM YYYY');
      return formattedDate;

    },

    setSignatureImg(base64Image) {


      this.currentElement.signature_img_url = base64Image
      this.currentElement.signature_time = this.getSignatureCurrentTime()
      this.currentElement.signature_date = this.getSignatureCurrentDate()



      this.closeShowSignatureModal()

      console.log(this.currentElement.signature_img_url)

    },

    deleteSignatureImmage() {
      this.currentElement.signature_img_url = null
      this.currentElement.signature_time = null
      this.currentElement.signature_date = null
    },


    getGeoCoordinates() {


      if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition((position) => {



          this.currentGeoPosition.latitude = position.coords.latitude,
            this.currentGeoPosition.longitude = position.coords.longitude

          console.log(this.currentGeoPosition)



        },
          (err) => {
            alert(err.message)
          })

      } else {

        console.log('Geolocation is not supported by your browser')

      }



    },


    toggleShowMap(element) {

      if (element.show_map === 1) {

        element.show_map = 0

      } else {

        element.show_map = 1

      }

    },


    sectionAddNewDropownOption() {

      const formData = {
        template_page_section_layout_id: this.currentElement.id
      }

      TemplateService.sectionElementAddNewOptionToDropdown(formData)
        .then((response) => {

          let newOption = response.data.newOption

          this.currentElement.dropdown_options.push(newOption)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },


    sectionReplaceSavedOption(option) {


      if (confirm("Using this saved option will replace the current ones in this dropdown. Are you sure?")
      ) {

        const formData = {
          template_page_section_layout_id: this.currentElement.id,
          template_saved_option_id: option.id
        }


        TemplateService.sectionReplaceWithSavedOptionElement(formData)
          .then((response) => {

            let newDropdownOptions = response.data.newDropdownOptions

            //replace with newDropdownOptions
            this.replaceWithNewDropdownOptions(newDropdownOptions)


            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });



      }




    },



    sectionAddNewChoiceToElement() {

      const formData = {
        template_page_section_layout_id: this.currentElement.id
      }

      console.log(formData, 'sectionAddNewChoiceToElement')

      TemplateService.sectionAddNewChoice(formData)
        .then((response) => {

          let newChoice = response.data.newChoice

          this.currentElement.multiple_choice_options.push(newChoice)

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },




    toggleShowSection(element) {

      if (element.show_section === 1) {

        element.show_section = 0

      } else {

        element.show_section = 1

      }

    },



    openAutomationsModal() {

      //reset
      this.newAutomation.string_value = null
      this.newAutomation.number_value = null
      this.newAutomation.selected_choice_id = null

      this.showAutomationsModal = true
    },

    closeAutomationsModal() {
      this.showAutomationsModal = false
    },


    openTriggerBox() {
      this.showTriggerBox = true
    },

    closeTriggerBox() {
      this.showTriggerBox = false
    },

    saveAutomationOption() {


      // const formData = {
      //   template_page_layout_id: ,

      // }

      console.log('sdf')

      this.closeTriggerBox()


    },


    startAUniqueTemplateForThisUser(template_id) {


      const formData = {
        original_template_id: template_id
      }

      TemplateService.startANewInspection(formData)
        .then((response) => {

          let uniqueTemplate = response.data.uniqueTemplate

          this.router.push({
            name: "ViewLiveTemplate",
            params: { random_string: uniqueTemplate.random_string },
          });

          // console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    selectChoice(choice) {


      if (choice.is_selected === 0) {

        choice.is_selected = 1

      } else {

        choice.is_selected = 0

      }




    },


    setTriggerType(triggerItem) {

      this.newAutomation.trigger_type = triggerItem.type
      this.closeTriggerBox()

    },


    saveNewAutomation() {


      let errorObject = this.validateTheFields();

      if (errorObject.hasError) {

        alert(errorObject.message)

        return

      }


      const formData = {
        template_page_layout_id: this.currentElement.id,
        trigger_type: this.newAutomation.trigger_type,
        logic_condition: this.newAutomation.logic_condition,
        string_value: this.newAutomation.string_value,
        number_value: this.newAutomation.number_value,
        selected_choice_id: this.newAutomation.selected_choice_id,
        template_dropdown_option_id: this.newAutomation.template_dropdown_option_id
      }


      // console.log(formData)


      TemplateService.saveAutomation(formData)
        .then((response) => {



          //populate the element.automations array
          let newAuto = response.data.automation
          this.currentElement.automations.push(newAuto)

          this.closeAutomationsModal()
          this.newAutomation.trigger_type = null
          this.newAutomation.string_value = null
          this.newAutomation.number_value = null


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },

    pageSectionSaveNewAutomation() {


      let errorObject = this.validateTheFields();

      if (errorObject.hasError) {

        alert(errorObject.message)

        return

      }


      const formData = {
        template_page_section_layout_id: this.currentElement.id,
        trigger_type: this.newAutomation.trigger_type,
        logic_condition: this.newAutomation.logic_condition,
        string_value: this.newAutomation.string_value,
        number_value: this.newAutomation.number_value,
        selected_choice_id: this.newAutomation.selected_choice_id,
        template_dropdown_option_id: this.newAutomation.template_dropdown_option_id
      }


      // console.log(formData)


      TemplateService.saveAutomationForPageSection(formData)
        .then((response) => {



          //populate the element.automations array
          let newAuto = response.data.automation
          this.currentElement.automations.push(newAuto)

          this.closeAutomationsModal()
          this.newAutomation.trigger_type = null
          this.newAutomation.string_value = null
          this.newAutomation.number_value = null


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });


    },


    validateTheFields() {


      let hasError = false
      let message = null

      if (this.currentElement.response_type === 'text_answer') {

        if (this.newAutomation.string_value === null || this.newAutomation.trigger_type === null) {

          message = "Please make sure you entered a value and selected a trigger type"
          hasError = true

        }

      }

      if (this.currentElement.response_type === 'number') {

        if (this.newAutomation.number_value === null || this.newAutomation.trigger_type === null) {

          message = "Please make sure you entered a number and selected a trigger type"
          hasError = true

        }

      }

      if (this.currentElement.response_type === 'checkbox') {

        if (this.newAutomation.string_value === null || this.newAutomation.trigger_type === null) {

          message = "Please make sure you entered a number and selected a trigger type"
          hasError = true

        }

      }

      if (this.currentElement.response_type === 'multiple_choice') {

        if (this.newAutomation.selected_choice_id === null || this.newAutomation.logic_condition === null || this.newAutomation.trigger_type === null) {

          message = "Please make sure you selected a choice, condition and a trigger type"
          hasError = true

        }

      }

      if (this.currentElement.response_type === 'dropdown') {

        if (this.newAutomation.template_dropdown_option_id === null || this.newAutomation.logic_condition === null || this.newAutomation.trigger_type === null) {

          message = "Please make sure you selected a dropdown option, condition and a trigger type"
          hasError = true

        }

      }

      if (this.currentElement.response_type === 'slider') {

        if (this.newAutomation.number_value === null || this.newAutomation.trigger_type === null) {

          message = "Please make sure you entered a number and selected a trigger type"
          hasError = true

        }

      }

      let errorObject = {
        hasError: hasError,
        message: message,
      }

      return errorObject

    },


    openEditAutomationModal() {
      this.showEditAutomationModal = true
    },

    closeEditAutomationModal() {
      this.showEditAutomationModal = false
    },

    setCurrentAutomation(automation) {
      this.editAutomationObject = automation
    },

    onChangeUpdateChoice() {

      //find the new choice in the choices array and update the current automation.choice 
      const choiceIndex = this.currentElement.multiple_choice_options.findIndex(
        (cho) => cho.id === this.editAutomationObject.selected_choice_id
      );

      let newChoice = this.currentElement.multiple_choice_options[choiceIndex]

      this.editAutomationObject.choice = newChoice


    },

    saveUpdatedAutomation() {

      const formData = {
        the_automation: this.editAutomationObject
      }

      // console.log(formData)

      TemplateService.updateAutomation(formData)
        .then((response) => {

          //update the automation update from backend
          let updatedAutomation = response.data.automation
          this.hydrateTheAutomation(updatedAutomation)

          this.closeEditAutomationModal()
          this.editAutomationObject = {}

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });



    },

    hydrateTheAutomation(updatedAutomation) {

      const automationIndex = this.currentElement.automations.findIndex(
        (auto) => auto.id === this.editAutomationObject.id
      );

      this.currentElement.automations[automationIndex] = updatedAutomation


    },

    deleteAutomation(automation) {



      if (confirm("Are you sure you want to delete this automation?")
      ) {


        const formData = {
          automation_id: automation.id
        }

        TemplateService.deleteAutomation(formData)
          .then((response) => {


            const automationToDelete = this.currentElement.automations.findIndex(
              (auto) => auto.id === automation.id
            );


            if (automationToDelete !== -1) {
              this.currentElement.automations.splice(automationToDelete, 1);
            }


            this.closeEditAutomationModal()



            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });


      }


    },



    checkType(responseType) {

      //compare and make that element.response_type doesn match any in theList

      const theList = [
        'instruction', 'datetime', 'time', 'date', 'geostamp', 'media', 'signature'
      ]

      return theList.includes(responseType);



    },



    deleteAutomationsOnChange() {

      console.log(this.currentElement)

    },


    openColorBoxModal() {
      this.showColorBoxModal = true
    },

    closeColorBoxModal() {
      this.showColorBoxModal = false
    },

    openNewSiteModal() {
      this.showNewSiteModal = true
    },

    closeNewSiteModal() {
      this.showNewSiteModal = false
    },

    openEditSiteModal() {
      this.showEditSiteModal = true
    },

    closeEditSiteModal() {
      this.showEditSiteModal = false
    },

    openNewAssetModal() {
      this.showNewAssetModal = true
    },

    closeNewAssetModal() {
      this.showNewAssetModal = false
    },

    setCurrentSite(site) {
      this.newSite = site
    },

    trimLongString(string) {

      // Check if the string is longer than 10 characters
      if (string.length > 20) {
        // Trim the string to the first 10 characters
        return string.substring(0, 20) + '...';;
      }

      return string

    },

    trimFileNameString(string) {

      // Check if the string is longer than 10 characters
      if (string.length > 15) {
        // Trim the string to the first 10 characters
        return string.substring(0, 15) + '...';;
      }

      return string

    },



    fetchAllSites() {

      TemplateService.getAllSites()
        .then((response) => {

          this.sites = response.data.sites

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    createSite() {


      TemplateService.saveNewSite(this.newSite)
        .then((response) => {


          this.closeNewSiteModal()
          this.newSite.name = null
          this.newSite.desc = null

          this.fetchAllSites()


          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    updateSite() {


      const formData = {
        site: this.newSite
      }

      TemplateService.updateSite(formData)
        .then((response) => {

          this.fetchAllSites()
          this.closeEditSiteModal()

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });





    },

    deleteThisSite(id) {

      if (confirm("Are you sure you want to delete this site? Deleting a site can’t be undone.")
      ) {

        const formData = {
          site_id: id
        }

        TemplateService.deleteSite(formData)
          .then((response) => {

            console.log(response.data)

            this.fetchAllSites()

          }).catch((err) => {

            console.log(err)

          });

        console.log(formData)

      }



    },


    triggerAssetFileInput() {

      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = 'image/*,video/*,application/pdf';


      input.onchange = () => {

        let files = Array.from(input.files);

        // Filter out files that are larger than 2MB
        let validFiles = files.filter(file => file.size <= 2 * 1024 * 1024);

        if (validFiles.length !== files.length) {
          alert('Some files were not added because they exceed the 2MB size limit.');
        }

        this.newAsset.files.push(...validFiles);

        // this.newAsset.files.push(...files);
      };

      input.click();

    },

    deleteAssetFromArray(indexToDelete) {

      // delete indexToDelete from this.newAsset.files
      this.newAsset.files.splice(indexToDelete, 1);


    },

   

    saveNewAsset() {

      this.isAssetLoading = true

      if (this.newAsset.site_id === null) {

        alert('Please select a location')

        return
      }


      let formData = new FormData();
      formData.append('name', this.newAsset.name);
      formData.append('desc', this.newAsset.desc);
      formData.append('site_id', this.newAsset.site_id);
      formData.append('model', this.newAsset.model);
      formData.append('serial_number', this.newAsset.serial_number);
      formData.append('purchased_date', this.newAsset.purchased_date);

      this.newAsset.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });


      TemplateService.addNewAsset(formData)
        .then((response) => {

          console.log(response.data)

          this.isAssetLoading = false

          this.closeNewAssetModal()
          this.newAsset.name = null
          this.newAsset.desc = null
          this.newAsset.site_id = null
          this.newAsset.model = null
          this.newAsset.serial_number = null
          this.newAsset.purchased_date = null
          this.newAsset.files = []

          this.fetchAllAssets();


        }).catch((err) => {

          console.log(err)

          this.isAssetLoading = false

        });



    },



    fetchAllAssets() {

      TemplateService.getAllAssets()
        .then((response) => {

          this.assets = response.data.assets
          this.sites = response.data.sites

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    fetchAAsset(uuid) {

      TemplateService.getAsset(uuid)
        .then((response) => {

          this.asset = response.data.asset
          this.imgsArray = response.data.imgsArray
          this.videosArray = response.data.videosArray
          this.pdfsArray = response.data.pdfsArray

          this.sites = response.data.sites

          console.log(response.data)

        }).catch((err) => {

          console.log(err)

        });

    },

    goToAssetDetails(asset) {

      this.router.push({
        name: "ViewAnAsset",
        params: { uuid: asset.uuid },
      });

    },

    goBackToViewAllAssets() {

      this.router.push({
        name: "ViewAllAssets",

      });

    },

    goToActionSettings() {

      this.router.push({
        name: "ViewActionSettings",
      });

    },

    goToViewAllActions() {

      this.router.push({
        name: "ViewAllActions",
      });

    },


    openImagePreviewModal() {
      this.showImagePreviewModal = true
    },

    closeImagePreviewModal() {
      this.showImagePreviewModal = false
    },

    setCurrentAssetMedialFile(file) {

      this.currentAssetMediaFile = file

    },

    deleteThisAsset(asset) {


      if (confirm("Are you sure you want to delete this? Once deleted it cannot be recovered.")
      ) {

        const formData = {
          asset_id: asset.id
        }

        TemplateService.deleteAsset(formData)
          .then((response) => {

            this.fetchAllAssets()

            console.log(response.data)

          }).catch((err) => {

            console.log(err)

          });


      }



    },

    attachmentDownloadPDFfile(pdfFile) {

      let downloadURL = pdfFile.file_url;

      fetch(downloadURL)
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = pdfFile.name || "download.pdf"; // Use the file name if available, else default to "download.pdf"
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error("There has been a problem with your fetch operation:", error);
        });



    },

    downloadImageFile(theFile) {


      let downloadURL = theFile.file_url;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = theFile.file_name; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },

    downloadVideoFile(theFile) {


      let downloadURL = theFile.file_url;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = theFile.file_name; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },

    openEditAssetForm(){
      this.showEditAssetForm = true
    },

    closeEditAssetForm(){
      this.showEditAssetForm = false
    },


    editFormtriggerInput() {

      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = 'image/*,video/*,application/pdf';


      input.onchange = () => { 
        let files = Array.from(input.files);

        // Filter out files that are larger than 2MB
        let validFiles = files.filter(file => file.size <= 2 * 1024 * 1024);

        if (validFiles.length !== files.length) {
          alert('Some files were not added because they exceed the 2MB size limit.');

          return;
        }


        this.uploadTheseAssetFiles(validFiles)

      };

      input.click();

    },

    uploadTheseAssetFiles(files) {


      this.isAssetLoading = true


      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });


      formData.append('asset_id', this.asset.id);

      TemplateService.uploadAssetFilesToDO(formData)
        .then((response) => {

          let filesArray = response.data.newAssets

          filesArray.forEach((file) => {

            // Create a deep copy of the file object
            let fileCopy = JSON.parse(JSON.stringify(file));

            // Push the copy into the array
            this.asset.asset_files.push(fileCopy);


          });

          this.isAssetLoading = false

          

          console.log(response.data);

        }).catch((err) => {
          console.log(err);

          this.isAssetLoading = false
        });

    },

    
 
    editFormDeleteAssetFromArray(indexToDelete) {

      if (confirm("Are you sure you want to delete this file? Once deleted it cannot be recovered.")
      ) {

        let fileToDelete = this.asset.asset_files[indexToDelete]

        const formData = {
          file_id: fileToDelete.id
        }
  
        TemplateService.deleteAssetMediaFile(formData)
          .then((response) => {
  
  
            this.asset.asset_files.splice(indexToDelete, 1);

            
            console.log(response.data)
            
          }).catch((err) => {
  
            console.log(err)
            
          });
    
      }



    },

    saveAssetFormChanges(){

      const formData = {
        asset: this.asset
      }

      // console.log(formData)

      TemplateService.updateAsset(formData)
        .then((response) => {

          this.closeEditAssetForm()

          //refresh data
          this.fetchAAsset(this.asset.uuid)

          toast("Saved!", {
            position: "bottom-right",
            autoClose: 1000,
          });

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });
      

    },


    showAddLabelForm(){
      this.theLabel.name = null
      this.showAddNewLabelForm = true
    },

    hideAddLabelForm(){
      this.showAddNewLabelForm = false
    },

    showEditLabelForm(){
      this.showAddEditLabelForm = true
    },

    hideEditLabelForm(){
      this.showAddEditLabelForm = false
    },

    fetchAllLabels(){


      TemplateService.getAllLabels()
        .then((response) => {

          this.labels = response.data.labels

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },

    addNewLabel(){
      
      const formData = {
        name: this.theLabel.name
      }

      TemplateService.addNewLabel(formData)
        .then((response) => {

          let newLabel = response.data.newLabel

          this.labels.push(newLabel)

          this.hideAddLabelForm()
          this.theLabel.name = null
          
        }).catch((err) => {
          
        });
      

    },

    setCurrentLabel(label){
      this.theLabel = label
      this.showEditLabelForm()
    },

    updateLabel(){
      
      const formData = {
        label: this.theLabel
      }

      // console.log(formData)

      TemplateService.updateLabel(formData)
        .then((response) => {

          this.hideEditLabelForm()
          this.fetchAllLabels()
          console.log(response.data)
          
        }).catch((err) => {
          
        });
      

    },

    deleteLabel(label){

      if (confirm("This label will be removed from actions that have selected this particular label. The actions themselves won't be deleted. You cannot undo this deletion.")
      ) {

        const formData = {
          label_id: label.id
        }
  
        TemplateService.deleteLabel(formData)
          .then((response) => {
  
            this.fetchAllLabels()
  
            console.log(response.data)
  
            
          }).catch((err) => {
  
            console.log(err)
            
          });
    
    
      }

    

    },


    openNewActionModal(){
      this.showNewActionModal = true
    },

    closeNewActionModal(){
      this.showNewActionModal = false
    },


    fetchAllActions(){



      TemplateService.getAllActions()
        .then((response) => {

          this.actions = response.data.actions
          this.sites = response.data.sites
          this.assets = response.data.assets
          this.labels = response.data.labels

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },



    openInspectionAddUsersModal(){ 

      this.fetchAllEmployeesForInspectionImport()


      this.showInspectionAddUsersModal = true
    },

    closeInspectionAddUsersModal(){
      this.showInspectionAddUsersModal = false
      this.usersToAddToInspectionAppArray = []
    },



    fetchAllEmployeesForInspectionImport(){

      TemplateService.getAllEmployeesForImport()
        .then((response) => {

          this.allEmployees = response.data.employees

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },



    addThisUser(theUser) {
      // Find the index of the user in the array
      const userIndex = this.usersToAddToInspectionAppArray.findIndex(user => user.id === theUser.id);
  
      if (userIndex !== -1) {
        // User exists, remove them from the array
        this.usersToAddToInspectionAppArray.splice(userIndex, 1);
        console.log('User already exists so remove from array');
      } else {
        // User does not exist, add them to the array
        this.usersToAddToInspectionAppArray.push(theUser);
        console.log('Add user to array as the user does not exist in it');
      }
    },

    importTheseUsersToInspectionApp(){

      if(this.usersToAddToInspectionAppArray.length === 0){

        alert('Please select the users!')

        return
      }


      if (confirm("Are sure you want import these users to the inpsection app?")
      ) {

        const formData = {
          users: this.usersToAddToInspectionAppArray
        }
  
  
        TemplateService.importUsersToInspectionNormalUsersTable(formData)
          .then((response) => {
  
            this.closeInspectionAddUsersModal()
  
            toast("Users imported!", {
              position: "bottom-right",
              autoClose: 2000,
            });
  
            console.log(response.data)
  
  
            
          }).catch((err) => {
  
            console.log(err)
            
          });
      
      }

      


    },
    

    fetchAllEmployeesForInsectionApp(){


      TemplateService.getAllEmployeesForTheInspectionApp()
        .then((response) => {

          this.employeesUsingInspectionApp = response.data.employees

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });
      

    },

    saveChanges() {


      const formData = {
        template: this.template,
        templatePages: this.templatePages
      }

      TemplateService.saveWholeTemplateProject(formData)
        .then((response) => {


          console.log(response.data)
          console.log('formData', formData)

        }).catch((err) => {

          console.log(err)

        });


    },

    // This code will be executed after a 5000ms debounce delay
    // Add your logic here, such as making an API call or updating the data
    debouncedSaveChanges: debounce(function () {

      this.saveChanges()
      console.log('changes saved')

    }, 4000),







  },


});
