import apiClient from "./MyClient";


export default{ 

 
    
    getAllTalksByAdmin(){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.get('/api/admin/talks')
    },


    saveTalkToDB(formData){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/admin/save/talk', formData)

    },


    getThisTalk(id){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.get('/api/admin/talk/' + id)

    },


    updateTalk(id, formData){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/admin/talk/update/' + id, formData) 

    },


    deleteThisTalk(id){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/admin/talk/delete/' + id)

    },


    getAllManagerAccounts(){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.get('/api/admin/managers')

    },


    getAManagerAccountDetails(uuid){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.get('/api/admin/manager/' + uuid)

    },

    addAppAccess(formData){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/add/new/manaager/access/', formData)

    },

    toggleAppAccess(formData, uuid){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/toggle/manager/access/' + uuid, formData)

    },


    updateAppAccess(formData, uuid){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/update/app/access/' + uuid, formData) 

    },

    processExcelFile(formData){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        //for file uploads IMPORTANT
        const config = {
            headers: {
            "content-type": "multipart/form-data", 
            },
        };

        return apiClient.post('/api/manager/upload/users-file/', formData, config) 

    },

    deleteAppAccess(formData){

        const authStore = localStorage.getItem("authStore"); 
        apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.post('/api/manager/delete/app-access/', formData) 

    },


  
}