import apiClient from "./MyClient";

export default { 
  


  generateThisTalk(formData) {

    return apiClient.post("/api/public/generate/talk", formData);
  },

  publicAIGenerateSafetyTalkTopics(formData) {

    return apiClient.post("/api/public/generate/topics", formData);
  },


  notifyAdminOfThisTalk(formData) {

    return apiClient.post("/api/public/generate/notify", formData);
  },

  notifyAdminOfTopics(formData) {

    return apiClient.post("/api/public/generate/topics/notify", formData);
  },
 

};
