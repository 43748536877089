/* eslint-disable */
import AuthService from "@/services/AuthService";
import TeamService from "@/services/TeamService";
import moment from "moment";
import { defineStore } from "pinia";

export const useTeamStore = defineStore("TeamStore", {

  state: () => ({

    formData: {
      name: ""
    },

    teams: [],
    currentTeam: {},

    showCreateTeamModal: false,
    showDeleteTeamModal: false,
    showEditTeamNameModal: false,

    showDeleteEmployeeModal: false,

    currentUser: {},

    currentEmployee: {},

    team: {},
    employees: [],
    showCopyEmployeeToAontherTeamModal: false,
    
    showTableOptions: false,
    selectedEmployees: [],

    copyUsersToTeamFormData: {
      team_id: null,
      employees: null
    },

    isLoading: false,

    showGroupDeleteModal: false,
    teamCount: null


  }),

  getters: {

    buildInviteLink: (state) => (team_id) => {
      // var url = "http://localhost:3000/employee/register?" + 'j6wsywtmcYdssni=' + state.currentUser.id + '&' + 'u9bdnfidsnwwn=' + team_id;

      // var url = "http://localhost:3000/employee/register?" + 'j6wsywtmcYdssni=' + state.currentUser.id + '&' + 'u9bdnfidsnwwn=' + team_id + '&' + 'app=' + 'talks';

      var url = "http://mysafelyio.app/employee/register?" + 'j6wsywtmcYdssni=' + state.currentUser.id + '&' + 'u9bdnfidsnwwn=' + team_id + '&' + 'app=' + 'talks';

      return url;
    }

  },


  actions: {


    fetchAllTeams() {

      TeamService.getAllTeams().then((response) => {

        this.teams = response.data.teams;


        // // eslint-disable-next-line no-console
        // console.log(response.data);
        
      });
    },

    fetchUser() {

      AuthService.getUser()
        .then((response) => {

          this.currentUser = response.data;

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });

    },

    storeNewTeam() {

      TeamService.saveNewTeam(this.formData)
        .then(() => {

          this.fetchAllTeams()

          this.closeCreateTeamModal()

          this.formData.name = ''

          // console.log(response.data);

        }).catch((error) => {

          // eslint-disable-next-line no-console
          console.log(error);

        });

    },

    updateThisTeam() {

      // console.log(this.formData)

      TeamService.updateTeam(this.currentTeam.random_string, this.formData)
        .then((response) => {

          this.fetchAllTeams();

          this.closeEditTeamModal()

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });

    },

    deleteThisTeam() {
      TeamService.deleteTeam(this.currentTeam.random_string)
        .then((response) => {

          this.fetchAllTeams();

          this.closeDeleteTeamModal()

          // // eslint-disable-next-line no-console
          // console.log(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },


    setCurrentTeam(team) {
      this.currentTeam = team;
      this.formData.name = this.currentTeam.name;
      // console.log(team)
    },



    openCreateTeamModal() {
      this.showCreateTeamModal = true
    },

    closeCreateTeamModal() {
      this.showCreateTeamModal = false
    },

    openDeleteTeamModal() {
      this.showDeleteTeamModal = true
    },

    closeDeleteTeamModal() {
      this.showDeleteTeamModal = false
    },

    openEditTeamModal() {
      this.showEditTeamNameModal = true
    },

    closeEditTeamModal() {
      this.showEditTeamNameModal = false
      this.formData.name = ''
    },

    openShowDeleteEmployeeModal() {
      this.showDeleteEmployeeModal = true
    },

    closeShowDeleteEmployeeModal() {
      this.showDeleteEmployeeModal = false
    },


    copyLink(index) { 

      const linkInputField = document.getElementById('inviteLinkInputField' + index);

      linkInputField.select();

      document.execCommand('copy');

      alert('Link copied!');

    },


    setCurrentEmployee(employee) {
      this.currentEmployee = employee
    },


    softDeleteThisEmployee() {  

      const formData = {
        user_id: this.currentEmployee.id,
        team_id: this.team.id
      }


      TeamService.deleteAEmployeeFromTeam(formData)
        .then((response) => {


          let employeeIndexToDelete = this.employees.findIndex(
            (emp) => emp.id === this.currentEmployee.id
          );


          if (employeeIndexToDelete !== -1) {
            this.employees.splice(employeeIndexToDelete, 1);
          }

 

          this.closeShowDeleteEmployeeModal()

          // console.log(response.data)

         

        }).catch((err) => {

          console.log(err)

        });

    },


    softDeleteThisEmployeeCompletely(){

      const formData = {
        user_id: this.currentEmployee.id
      }

      TeamService.deleteEmployeeCompletelyAcrossAllApps(formData)
        .then((response) => {

          this.closeShowDeleteEmployeeModal()
          // console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

      console.log('completely')


    },

    goBackToViewAllTeams() {
      this.router.push({
        name: "ViewAllTeams"
      });
    },


    fetchTeam(random_string) {

      this.selectedEmployees = []

      TeamService.getATeam(random_string)
        .then((response) => {

          this.team = response.data.team
          this.teamCount = response.data.count

          //add is_selected to each object
          let employeeArray = response.data.employees

          employeeArray.forEach((emp) => {
            // Add is_selected property set to false for each employee object
            emp.is_selected = false;
          });

          this.employees = employeeArray
          

          

          // // eslint-disable-next-line no-console
          // console.log(response.data)

        }).catch((err) => {

          // eslint-disable-next-line no-console
          console.log(err)

        });
    },

    formatDate(date){

      return moment(date).format("MMMM Do YYYY"); 

    },


    openCopyEmployeeToAontherTeamModal(){
      this.showCopyEmployeeToAontherTeamModal = true 
    },

    closeCopyEmployeeToAontherTeamModal(){
      this.showCopyEmployeeToAontherTeamModal = false
    },

    openTableOptions(){
      this.showTableOptions = true
    },

    closeTableOptions(){
      this.showTableOptions = false
    },


    toggleSelection(employee){
      employee.is_selected = !employee.is_selected;
    },

    addrOrRemoveUser(employee){


      //add to array else remove from array
      if(employee.is_selected){

        this.selectedEmployees.push(employee)

      }else{


        let userIndexToRemove = this.selectedEmployees.findIndex(
          (emp) => emp.id === employee.id
        );


        if (userIndexToRemove !== -1) {
          this.selectedEmployees.splice(userIndexToRemove, 1);
        }

      }

      
      
    },


    opemShowGroupDeleteModal(){
      this.showGroupDeleteModal = true
    },

    closeShowGroupDeleteModal(){
      this.showGroupDeleteModal = false
    },

    removeEmployeeFromSelectedArray(employee){

      let userIndexToRemove = this.selectedEmployees.findIndex(
        (emp) => emp.id === employee.id
      );


      if (userIndexToRemove !== -1) {
        this.selectedEmployees.splice(userIndexToRemove, 1);
      }

      employee.is_selected = false

    },

    copyUsersToTeam(){

      this.isLoading = true 

      this.copyUsersToTeamFormData.employees = this.selectedEmployees

      TeamService.copyEmployeesToThisTeam(this.copyUsersToTeamFormData)
          .then((response) => {

            this.isLoading = false
            this.closeCopyEmployeeToAontherTeamModal()

            this.selectedEmployees = []
            this.fetchTeam(this.team.random_string)

            // console.log(response.data)


            
          }).catch((err) => {

            console.log(err)
            
          });
      

    },

    deleteGroupOfUsers(){    


      if(this.selectedEmployees.length > 0){

        const formData = {
          selectedEmployees: this.selectedEmployees,
          team_id: this.team.id
        }

        TeamService.deleteAListOfEmployees(formData)
          .then((response) => {


            this.selectedEmployees.forEach((empToDelete) => {

              let employeeIndexToDelete = this.employees.findIndex(
                (emp) => emp.id === empToDelete.id
              );
    
    
              if (employeeIndexToDelete !== -1) {
                this.employees.splice(employeeIndexToDelete, 1);
              }
              
            });

            this.closeShowGroupDeleteModal()
            this.selectedEmployees = []
            
            // console.log(response.data)
            
          }).catch((err) => {

            console.log(err)
            
          });

       

  

      }else{
        alert("No users in selection list")

        this.closeShowGroupDeleteModal()
      }

      
    },

    getAllTeamsExceptCurrentTeam() {
      return this.teams.filter(team => team.id !== this.team.id);
    }
    

    // getAllTeamsExceptCurrentTeam(){

    //   let filteredTeams = [];

    //   this.teams.forEach((team) => {
    //     if(team.id !== this.team.id){
    //       filteredTeams.push(team)
    //     }
    //   })

    //   return filteredTeams;


    // }

    









  },
});
