import apiClient from "./MyClient";


export default{ 


    
    getAllTalksForACampaign(){ 

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/reports/all/acknowledgements') 

    },


    searchAcknowledgments(formData){ 

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/search/reports/acknowledgements', formData) 

    },


    exportAckDataFromSearch(formData) { 
        
        const authStore = localStorage.getItem("authStore");
        apiClient.defaults.headers.common["Authorization"] =
          "Bearer " + JSON.parse(authStore).token;
    
        return apiClient.post("/api/export/ack/search/data", formData, { responseType: 'blob' });
    
      }, 



  
}