<template>
  <div>
    <MainNavBarVue />

    <div class="container">

      <div class="appsListWrapper">


        <div class="appItemWrapper" v-for="(appAccess, index) in authStore.appsAccess" :key="index">


          <router-link v-if="appAccess.has_access === 'yes'" class="linkStyle" :to="{ name: appAccess.app.route_name }">

            <div class="appItem" v-if="appAccess.app">
              <i :class="appAccess.app.icon_name + ' myicon'"></i>
            </div>

            <div v-if="appAccess.app" class="appName">{{ appAccess.app.name }}</div>

          </router-link>

          <div v-if="appAccess.has_access === 'no'" class="linkStyle" :to="{ name: appAccess.app.route_name }"
            @click="authStore.showAppMarketingModal(appAccess.app)">

            <div class="appItem" v-if="appAccess.app">
              <i :class="appAccess.app.icon_name + ' myicon'"></i>
            </div>

            <div v-if="appAccess.app" class="appName">{{ appAccess.app.name }}</div>

          </div>


        </div>


        <!-- <div class="appItemWrapper">

          <router-link class="linkStyle" :to="{ name: 'ToolboxHome' }">

            <div class="appItem">
              <i class="bi bi-clipboard2-check-fill myicon"></i>
            </div>

            <div class="appName">Toolbox Talks</div>

          </router-link>



        </div> -->


        <!-- <div class="appItemWrapper" @click="authStore.openInpectionsObersvationsModal">

          <div class="appItem">
            <i class="bi bi-zoom-in myicon"></i>
          </div>

          <div class="appName">Inspections & Obeservations</div>
        </div> -->



        <!-- <div class="appItemWrapper" @click="authStore.openSafetyDocsPolicyModal">

          <div class="appItem">

            <i class="bi bi-stack myicon"></i>
          </div>

          <div class="appName">Safety Docs & Policies</div>
        </div> -->



      </div>
    </div>



    <div v-if="authStore.showInpectionsObersvationsModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">

        <div class="model-box-header">
          <div style="font-size: 19px">
            <b>Inspections & Checklist Tool</b> (Coming soon)
          </div>

          <div>
            <i @click="authStore.closeInpectionsObersvationsModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content" style="text-align: left;">

          <p> Now you can conduct inspections, safety audits, flag issues and fix problems faster.
            Create industry-specific inspection checklists, perform detailed inspections, and easily produce digestible
            inspection reports.
          </p>


          <div class="ctaBox">
            <b>Contact your account manager to learn more!</b>
          </div>





        </div>
      </div>
    </div>

    <div v-if="authStore.showSafetyDocsPolicyModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">

        <div class="model-box-header">
          <div style="font-size: 19px">
            <b>Chat With Safety Docs</b>
          </div>

          <div>
            <i @click="authStore.closeSafetyDocsPolicyModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content" style="text-align: left;">

          <p>We're working on a simple yet powerful tool that makes understanding safety documents easier.</p>


          <li style="margin-bottom: 10px;"><b>Chat with Your Documents: </b>Just ask questions and get answers directly
            from your safety documents.</li>

          <li style="margin-bottom: 10px;"><b>AI-Powered: </b>Uses smart technology to find and explain the information
            you need.</li>

          <li style="margin-bottom: 10px;"><b>Easy-to-Use: </b>Designed for simplicity, so you don't need to be a tech
            expert.</li>



          <div class="ctaBox">
            <b>Contact your account manager to learn more!</b>
          </div>


        </div>
      </div>
    </div>

    <div v-if="authStore.showToolboxTalkModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">

        <div class="model-box-header">
          <div style="font-size: 19px">
            <b>Safety Toolbox Talks</b>
          </div>

          <div>
            <i @click="authStore.closeToolboxTalkModal" class="bi bi-x-lg" style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content" style="text-align: left;">

          <div>
            <div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;">
              <figure
                style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%; margin-block-end: 0; margin-block-start: 0; margin-inline-start: 0; margin-inline-end: 0;">
                <iframe id="pv_B3m5xrop" src="https://media.publit.io/file/safelyio-toolbox-talk-app-video.html"
                  scrolling="no"
                  style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute; overflow:hidden;"
                  allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
              </figure>
            </div>
          </div>


          <div class="ctaBox">
            <b>Contact your account manager to try it out!</b>
          </div>

        </div>
      </div>
    </div>

    <div v-if="authStore.showSafetyDateSheetsModal" class="modal-overlay">

      <div class="modal-box" style="width: 550px">

        <div class="model-box-header">
          <div style="font-size: 16px">
            <b>Safety Data Sheets Management Made Easy</b> (Coming Soon)
          </div>

          <div>
            <i @click="authStore.openCloseSafetyDataSheetskModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content" style="text-align: left;">


          <li style="margin-bottom: 10px;">Create and manage a central repository for all your safety data sheets so
            employees can find what they need with minimal effort.</li>

          <li style="margin-bottom: 10px;">Quickly upload SDS's and manage your database by document, title,
            manufacturer, SDS, and CAS numbers.</li>


          <div class="ctaBox">
            <b>Contact your account manager to learn more!</b>
          </div>

        </div>
      </div>
    </div>




    <MainFooterVue />
  </div>
</template>

<script setup>
import MainFooterVue from "@/components/MainFooter.vue";
import MainNavBarVue from "@/components/MainNavBar.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from "vue";



const authStore = useAuthStore();



onMounted(() => {

  authStore.fetchAllAppsForTilePage()

});


</script>

<style scoped>
.container {
  /* background-color: rgb(200, 211, 211); */
  padding-top: 20px;
  padding-bottom: 20px;
  height: calc(100vh - 90px - 50px);
  /* Subtract the combined height of top nav and bottom footer */
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
}

.appsWrapper {
  background-color: rgb(0, 255, 38);
}

.appsListWrapper {
  display: flex;
  justify-content: space-between;
  /* This will space out the items evenly */
  align-items: center;
  width: 800px;
  /* background-color: #d9e7ff; */
}

.appItemWrapper {
  /* background-color: bisque; */
  cursor: pointer;
  padding: 10px;
}

.appItemWrapper:hover {
  scale: 1.1;
  transition: 1s;
}

.appItem {
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  width: 200px;
  height: 200px;
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.appName {
  padding-top: 16px;
  font-size: 15px;
  font-weight: bold;
}


.myicon {
  font-size: 70px;
  color: black;
}

.linkStyle {
  text-decoration: none;
  color: inherit;
}




.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  z-index: 999;
  /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
  background-color: white;
  width: 1100px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
  text-align: center;
  padding: 20px;
}

.model-box-header {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  /* height: 40px; */
  padding: 20px;
}

.ctaBox {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: red; */
  padding: 10px;
}
</style>
