import apiClient from "./MyClient";

export default {
  //this only return text-based talks
  getTextTalksOnly() {

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/safetytalks"); 
  },

   //this only return video-based talks
    getAllVideoTalksFromLibrary(){

      const authStore = localStorage.getItem("authStore");
      apiClient.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(authStore).token;

        return apiClient.get('/api/videotalks');

    },

  getAllLibraryTalk() {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/all/safetytalks");
  },

  getASingleLibraryTalk(id) {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/library/safetytalk/" + id);
  },

  getAllPDFtalks() {
    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/pdftalks");
  },
};
