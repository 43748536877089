import apiClient from "./MyClient";


export default{ 

    
 

    getAllTalks(){ 

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/employee/talks/');
    },

    getAllGroupTalks(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/employee/grouptalks');
    },


    getATalk(schedule_message_random_string){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/talk/' + schedule_message_random_string);
    },

    getAGroupTalk(random_string){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/grouptalk/' + random_string)

    }, 


    acknowledgeThisTalk(formData){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/acknowledgment/talk/', formData);

    },

    storeQuizAnswerResult(data){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/store/quiz/result/', data);

    },

    storeScore(data){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/store/quiz/passcore', data);
    },


    getUser(){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.get('/api/user');
    },


    updateMyAccountDetails(data){

        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('/api/account/update/user', data);

    },

   




  
}