<template>
    <div class="outer">






        <div class="topNavBar">



            <div style="width: 20%;  text-align: left; display: flex; align-items: center; font-weight: bolder;">

                <i class="bi bi-arrow-left" style="margin-right: 10px; font-size: 20px;"></i> Back

            </div>

            <div style="display: flex; justify-content: center; width: 80%;">


                <div class="tabsWrapper">


                    <div class="tabItem" v-for="(tab, index) in templateStore.editorTabOptions" :key="index"
                        :class="{ tabItemSelected: tab.type === templateStore.currentEditorTab }"
                        @click="templateStore.currentEditorTab = tab.type">

                        <b>{{ tab.name }}</b>

                    </div>


                </div>


            </div>


            <div style="width: 20%; display: flex; align-items: center; justify-content: flex-end;">

                <div style="display: flex; font-size: 15px;">

                    Weighted Scoring

                    <!-- <div class="form-check form-switch" style="margin-left: 10px;">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                    </div> -->

                    <div @click="templateStore.toggleScoringEnabled">

                        <input v-if="templateStore.template.is_scoring_enabled === 'yes'" class="myCheckBox"
                            type="checkbox" checked>

                        <input v-else class="myCheckBox" type="checkbox">

                    </div>


                </div>

                <i class="bi bi-gear-fill" style="font-size: 18px; margin-left: 15px;"></i>

            </div>



        </div>












        <!-- {{ templateStore.galleryFiles }} - <h2>{{ templateStore.currentFileIndexInGallery }}</h2> -->


        <!-- <p style="font-size: 10px;">{{ templateStore.currentElement }}</p> -->


        <!-- <button @click="templateStore.openColorBoxModal">open color box</button> -->



        <div v-show="templateStore.currentEditorTab === 'builder'" style="height: 100%;">

            <!-- <button @click="templateStore.openShowImageAndVideoGalleryModal">Test mobile modal</button> -->



            <div class="controlsNavBar">


                <div style="width: 20%; font-size: 13px; display: flex; align-items: center;">




                    <div class="boxItem">


                        <span style="font-weight: bold; color: black; cursor: pointer;"
                            @click="templateStore.addNewPage">
                            + Add Page
                        </span>

                    </div>

                </div>

                <div
                    style="width: 80%;  font-size: 20px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
                    {{ templateStore.template.name }} - <button @click="templateStore.saveChanges">save</button>
                </div>


                <div style="width: 20%;  display: flex; justify-content: flex-end; align-items: center;">

                    <div style="font-size: 13px; margin-right: 15px;">
                        Last saved at 08:29 AM
                    </div>

                    <button class="button-13">Publish</button>
                </div>


            </div>


            <div class="builderArea">





                <!-- <p style="font-size: 13px;">{{ templateStore.currentElement }}</p> -->




                <div class="formArea">





                    <!-- <button @click="loadMap">Load Map</button> 

<div ref="mapContainer" style="width: 200px; height: 200px; background-color: red;"></div> -->




                    <div class="pageWrapper" v-for="(page, pageIndex) in templateStore.templatePages" :key="pageIndex">


                        <div class="pageHeader">




                            <div class="headerLeft">



                                <i @click="templateStore.togglePage(page)" class="bi bi-chevron-down"
                                    style="margin-right: 15px; cursor: pointer;"></i>



                                <div v-if="page.is_editing_title === 0" class="thePageTitle">

                                    {{ page.title }} <i @click="templateStore.toggleEditPageTitle(page)"
                                        style="font-size: 13px; margin-left: 14px;" class="bi bi-pencil-fill"></i>

                                </div>

                                <div v-else class="editTitleBox">

                                    <input v-model="page.title" type="text" class="form-control thePageTitle"
                                        style="width: 500px;">

                                    <button @click="page.is_editing_title = 0" class="btn btn-dark doneBtn"><b><i
                                                class="bi bi-check2"></i></b></button>

                                </div>



                            </div>

                            <div class="headerRight">



                                <button class="button-14" style="margin-right: 10px;"
                                    @click="templateStore.addANewQuestion(page.id)">+ Add Question</button>

                                <button class="button-14" style="margin-right: 16px;"
                                    @click="templateStore.addANewSection(page)">+ Add Section</button>



                                <div class="dropdown">

                                    <i class="bi bi-three-dots moreSection" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"></i>

                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">


                                        <li v-if="pageIndex !== 0" @click="templateStore.deleteAPage(page)">
                                            <div class="dropdown-item" href="#"
                                                style="display: flex; align-items: center;">
                                                <i class="bi bi-trash"
                                                    style="font-size: 15px; margin-right: 6px;"></i>Delete
                                            </div>
                                        </li>

                                        <li @click="templateStore.openShowReorderPagesModal">
                                            <div class="dropdown-item" href="#"
                                                style="display: flex; align-items: center;">
                                                <i class="bi bi-arrows-move"
                                                    style="font-size: 15px; margin-right: 6px;"></i>Move
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                            </div>

                        </div>


                        <div v-show="page.show_page === 1">




                            <draggable class="elements-list" v-model="page.elements" item-key="id" handle=".handle"
                                :animation="600" :move="templateStore.checkMove"
                                :group="{ name: 'elements', put: true }">



                                <template #item="{ element, index: elementIndex }">




                                    <div class="questionOnlyWrapper">


                                        <div
                                            v-if="element.element_type === 'question' || element.element_type === 'section_question'">




                                            <div class="elementItem">


                                                <div style="display: flex; width: 100%;">



                                                    <div class="dragBox">

                                                        <i class="bi bi-arrows-move handle"
                                                            style="cursor: pointer;"></i>

                                                    </div>

                                                    <div style="width: 60%; padding-right: 10px;">
                                                        <input v-model="element.template_question.question_text"
                                                            type="text" class="form-control titleInput"
                                                            placeholder="Type question..."
                                                            @click="templateStore.setCurrentElement(element, elementIndex)" />
                                                    </div>

                                                    <div style="width: 25%;"
                                                        @click="templateStore.setCurrentElement(element, elementIndex)">


                                                        <div type="text" class="form-control" id="dropdownMenuButton1"
                                                            placeholder="show selected option here"
                                                            data-bs-toggle="dropdown"
                                                            style="display: flex; justify-content: space-between;">


                                                            <div>

                                                                <div v-if="element.response_type === 'checkbox'">


                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/checkmark.png`)" />
                                                                    Checkbox


                                                                </div>

                                                                <div v-if="element.response_type === 'text_answer'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/text.png`)" />
                                                                    Text Answer

                                                                </div>

                                                                <div v-if="element.response_type === 'multiple_choice'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/test.png`)" />
                                                                    Multiple Choice

                                                                </div>

                                                                <div v-if="element.response_type === 'number'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/number.png`)" />
                                                                    Number

                                                                </div>

                                                                <div v-if="element.response_type === 'datetime'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/calendar.png`)" />
                                                                    Date & Time

                                                                </div>

                                                                <div v-if="element.response_type === 'date'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/calendar.png`)" />
                                                                    Date Only

                                                                </div>



                                                                <div v-if="element.response_type === 'time'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/clock.png`)" />
                                                                    Time

                                                                </div>

                                                                <div v-if="element.response_type === 'media'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/camera.png`)" />
                                                                    Media

                                                                </div>



                                                                <div v-if="element.response_type === 'slider'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/slider.png`)" />
                                                                    Slider

                                                                </div>

                                                                <div v-if="element.response_type === 'geostamp'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/map.png`)" />
                                                                    Geo Stamp

                                                                </div>

                                                                <div v-if="element.response_type === 'instruction'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/guidebook.png`)" />
                                                                    Instruction

                                                                </div>

                                                                <div v-if="element.response_type === 'pass_fail'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/pass_fail.png`)" />
                                                                    Pass/Fail

                                                                </div>

                                                                <div v-if="element.response_type === 'dropdown'">

                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/drop1.png`)" />
                                                                    Dropdown

                                                                </div>

                                                                <div v-if="element.response_type === 'signature'">


                                                                    <img width="15px" style="margin-right: 10px;"
                                                                        :src="require(`@/assets/images/sign2.png`)" />
                                                                    Signature


                                                                </div>

                                                            </div>


                                                            <i class="bi bi-chevron-down"></i>



                                                        </div>



                                                        <div class="dropdown">



                                                            <ul class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton1"
                                                                style="width: 300px; height: 400px; overflow-y: auto;">

                                                                <li v-for="(responseType, index) in templateStore.responseTypes"
                                                                    :key="index"
                                                                    @click="templateStore.setSelectedResponseType(responseType.type)">




                                                                    <div class="dropdown-item"
                                                                        :class="{ dropdownItemSelected: responseType.type === element.response_type }"
                                                                        href="#">

                                                                        <img width="20px" style="margin-right: 10px;"
                                                                            :src="require(`@/assets/images/${responseType.img_name}.png`)" />
                                                                        {{ responseType.name }}
                                                                    </div>


                                                                </li>


                                                            </ul>
                                                        </div>

                                                    </div>




                                                    <div v-if="element.response_type !== 'dropdown' && element.response_type !== 'multiple_choice'"
                                                        style="width: 10%; padding-left: 10px;"
                                                        @click="templateStore.setCurrentElement(element, elementIndex)">


                                                        <input
                                                            v-if="templateStore.template.is_scoring_enabled === 'yes'"
                                                            type="number" class="form-control titleInput"
                                                            placeholder="Score" />

                                                    </div>




                                                </div>





                                                <div class="optionsWrapper"
                                                    v-show="templateStore.currentElement.id === element.id">


                                                    <div class="outerCont" v-if="element.response_type === 'dropdown'">

                                                        <div class="dropdownOptionsCont">

                                                            <div v-if="element.dropdown_options.length === 0">

                                                                <div @click="templateStore.addNewDropownOption"
                                                                    class="addFirstOption">+ Add First Option</div>

                                                            </div>

                                                            <div v-else>

                                                                <draggable class="dropdown_list"
                                                                    v-model="element.dropdown_options"
                                                                    :move="templateStore.checkDropdownItemMove"
                                                                    item-key="id" handle=".handle" :animation="600">



                                                                    <template #item="{ element: dropdownOption }">

                                                                        <div class="theItemWrapper">





                                                                            <div class="deleteBox">

                                                                                <i @click="templateStore.deleteThisDropdownOption(dropdownOption)"
                                                                                    class="bi bi-trash"
                                                                                    style="cursor: pointer;"></i>

                                                                            </div>

                                                                            <div class="optionDragBox">



                                                                                <i class="bi bi-arrows-move handle"
                                                                                    style="cursor: pointer;"></i>
                                                                            </div>

                                                                            <div class="inputFieldBox">

                                                                                <input
                                                                                    v-model="dropdownOption.option_text"
                                                                                    class="form-control" type="text"
                                                                                    placeholder="Enter option">

                                                                            </div>

                                                                            <div class="colorBox">



                                                                                <div class="theColorBox"
                                                                                    @click="templateStore.toggleDropdownColorBox(dropdownOption)"
                                                                                    :style="{ backgroundColor: dropdownOption.color_code }">

                                                                                </div>

                                                                                <!-- <div class="colorPopup"
                                                                                    v-if="dropdownOption.show_color_popup === 1">




                                                                                    <div class="circleItem"
                                                                                        v-for="(color, index) in templateStore.colorArray"
                                                                                        :key="index"
                                                                                        :style="{ backgroundColor: color.hex_code }"
                                                                                        @click="templateStore.setDropdowColor(dropdownOption, color)">


                                                                                    </div>


                                                                                </div> -->

                                                                            </div>

                                                                            <div class="flagBox">

                                                                                <div class="theflagBox">


                                                                                    <span
                                                                                        style="margin-right: 5px; font-size: 12px;">Flag
                                                                                        Answer</span>




                                                                                    <div
                                                                                        @click="templateStore.toggleIsFlagged(dropdownOption)">

                                                                                        <input
                                                                                            v-if="dropdownOption.is_flagged"
                                                                                            class="myCheckBox"
                                                                                            type="checkbox" checked>

                                                                                        <input v-else class="myCheckBox"
                                                                                            type="checkbox">




                                                                                    </div>




                                                                                </div>



                                                                            </div>


                                                                            <div class="mainScoreBox"
                                                                                v-if="templateStore.template.is_scoring_enabled === 'yes'">



                                                                                <div
                                                                                    style="font-size: 11px; margin-right: 5px;">
                                                                                    Score
                                                                                </div>

                                                                                <input
                                                                                    v-model="dropdownOption.weight_value"
                                                                                    type="number"
                                                                                    class="form-control" />


                                                                            </div>






                                                                        </div>

                                                                    </template>

                                                                </draggable>




                                                                <div class="addOptionWrapper">

                                                                    <div class="addOptionCTA"
                                                                        style="margin-right: 18px;"
                                                                        @click="templateStore.addNewDropownOption">
                                                                        + Add Another Option
                                                                    </div>

                                                                    <div class="addOptionCTA"
                                                                        @click="templateStore.openShowSavedOptionsModal">

                                                                        <i class="bi bi-floppy"></i> View Saved Options
                                                                    </div>

                                                                    <div class="addOptionCTA" style="margin-left: 18px;"
                                                                        @click="templateStore.bookmarkThisDropdownOptions">
                                                                        <i class="bi bi-bookmark"></i> Bookmark
                                                                    </div>




                                                                </div>

                                                            </div>



                                                        </div>

                                                    </div>

                                                    <div class="outerCont"
                                                        v-if="element.response_type === 'multiple_choice'">



                                                        <div class="dropdownOptionsCont">

                                                            <div v-if="element.multiple_choice_options.length === 0">

                                                                <div @click="templateStore.addNewChoiceToElement"
                                                                    class="addFirstOption">+ Add First Choice</div>

                                                            </div>

                                                            <div v-else>

                                                                <div class="multipleAnswerToggleBox">

                                                                    <div style="font-size: 13px; margin-right: 10px">
                                                                        Multiple Select

                                                                    </div>



                                                                    <div
                                                                        @click="templateStore.toggleIsMultipleSelect(element)">

                                                                        <div v-if="element.is_multiple_answer_selection === 'yes'"
                                                                            class="form-check form-switch">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckDefault" checked>
                                                                        </div>

                                                                        <div v-else class="form-check form-switch">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckDefault">
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <draggable class="dropdown_list"
                                                                    v-model="element.multiple_choice_options"
                                                                    :move="templateStore.checkDropdownItemMove"
                                                                    item-key="id" handle=".handle" :animation="600">



                                                                    <template #item="{ element: choice }">

                                                                        <div class="theItemWrapper">




                                                                            <div class="deleteBox">

                                                                                <i class="bi bi-trash"
                                                                                    @click="templateStore.deleteChoice(choice)"
                                                                                    style="cursor: pointer;"></i>

                                                                            </div>

                                                                            <div class="optionDragBox">



                                                                                <i class="bi bi-arrows-move handle"
                                                                                    style="cursor: pointer;"></i>
                                                                            </div>

                                                                            <div class="inputFieldBox">

                                                                                <input v-model="choice.option_text"
                                                                                    class="form-control" type="text"
                                                                                    placeholder="Enter option">

                                                                            </div>


                                                                            <div class="colorBox">



                                                                                <div class="theColorBox"
                                                                                    @click="templateStore.toggleChoiceColorPopup(choice)"
                                                                                    :style="{ backgroundColor: choice.color_code }">

                                                                                </div>

                                                                                <!-- <div class="colorPopup"
                                                                                    v-if="choice.show_color_popup === 1">


                                                                                    <div class="circleItem"
                                                                                        v-for="(color, index) in templateStore.colorArray"
                                                                                        :key="index"
                                                                                        :style="{ backgroundColor: color.hex_code }"
                                                                                        @click="templateStore.setChoiceColor(choice, color)">


                                                                                    </div>


                                                                                </div> -->

                                                                            </div>

                                                                            <div class="flagBox">

                                                                                <div class="theflagBox">


                                                                                    <span
                                                                                        style="margin-right: 5px; font-size: 12px;">Flag
                                                                                        Answer</span>




                                                                                    <div
                                                                                        @click="templateStore.choiceToggleIsFlagged(choice)">

                                                                                        <input v-if="choice.is_flagged"
                                                                                            class="myCheckBox"
                                                                                            type="checkbox" checked>

                                                                                        <input v-else class="myCheckBox"
                                                                                            type="checkbox">




                                                                                    </div>




                                                                                </div>



                                                                            </div>


                                                                            <div class="mainScoreBox"
                                                                                v-if="templateStore.template.is_scoring_enabled === 'yes'">



                                                                                <div
                                                                                    style="font-size: 11px; margin-right: 5px;">
                                                                                    Score
                                                                                </div>

                                                                                <input v-model="choice.weight_value"
                                                                                    type="number"
                                                                                    class="form-control" />


                                                                            </div>





                                                                        </div>

                                                                    </template>

                                                                </draggable>


                                                                <div class="addOptionWrapper">

                                                                    <div class="addOptionCTA"
                                                                        style="margin-right: 18px;"
                                                                        @click="templateStore.addNewChoiceToElement">
                                                                        + Add Another Choice
                                                                    </div>

                                                                    <div class="addOptionCTA"
                                                                        @click="templateStore.openShowSavedOptionsModal">

                                                                        <i class="bi bi-floppy"></i> View Saved Choices
                                                                    </div>

                                                                    <div @click="templateStore.bookmarkTheseChoices"
                                                                        class="addOptionCTA" style="margin-left: 18px;">

                                                                        <i class="bi bi-bookmark"></i> Bookmark Choices
                                                                    </div>






                                                                </div>



                                                            </div>



                                                        </div>



                                                    </div>



                                                    <div class="innerAutomationBox"
                                                        v-if="element.automations && element.automations.length > 0">

                                                        <hr
                                                            style="width: 100%; height: 2px; background-color: #d3d3d3; border: none;">


                                                        <div class="theAutomatoinListBox">

                                                            <div class="autoLeft">

                                                                Automations:

                                                            </div>

                                                            <div class="autoRight">


                                                                <div v-for="(automation, index) in element.automations"
                                                                    :key="index" class="automationItem">


                                                                    <div class="theAutoDescBox">


                                                                        <div
                                                                            v-if="templateStore.currentElement.response_type === 'text_answer'">

                                                                            If answer {{ automation.logic_condition_name
                                                                            }} <b>{{ automation.string_value }}</b> then
                                                                            <b>{{ automation.trigger_type_name }}</b>

                                                                        </div>

                                                                        <div
                                                                            v-if="templateStore.currentElement.response_type === 'number'">

                                                                            If answer {{ automation.logic_condition_name
                                                                            }} <b>{{ automation.number_value }}</b> then
                                                                            <b>{{ automation.trigger_type_name }}</b>

                                                                        </div>

                                                                        <div
                                                                            v-if="templateStore.currentElement.response_type === 'checkbox'">

                                                                            <span
                                                                                v-if="automation.string_value === 'checked'">

                                                                                If checkbox is <b>Checked</b> then
                                                                                <b>{{ automation.trigger_type_name
                                                                                    }}</b>

                                                                            </span>

                                                                            <span v-else>

                                                                                If checkbox is <b>Not Checked</b> then
                                                                                <b>{{ automation.trigger_type_name
                                                                                    }}</b>

                                                                            </span>



                                                                        </div>


                                                                        <div
                                                                            v-if="templateStore.currentElement.response_type === 'multiple_choice'">


                                                                            <div v-if="automation.choice">

                                                                                If <b>{{ automation.choice.option_text
                                                                                    }}</b> {{
                        automation.logic_condition_name }} then
                                                                                <b>{{ automation.trigger_type_name
                                                                                    }}</b>

                                                                            </div>




                                                                        </div>

                                                                        <div
                                                                            v-if="templateStore.currentElement.response_type === 'dropdown'">


                                                                            <div v-if="automation.dropdown_option">

                                                                                If <b>{{
                        automation.dropdown_option.option_text
                    }}</b> {{
                            automation.logic_condition_name }}
                                                                                then
                                                                                <b>{{ automation.trigger_type_name
                                                                                    }}</b>

                                                                            </div>




                                                                        </div>

                                                                        <div
                                                                            v-if="templateStore.currentElement.response_type === 'slider'">

                                                                            If answer {{ automation.logic_condition_name
                                                                            }} <b>{{ automation.number_value }}</b> then
                                                                            <b>{{ automation.trigger_type_name }}</b>

                                                                        </div>


                                                                    </div>

                                                                    <div class="theAutoDescOptionsBox">

                                                                        <i @click="() => { templateStore.setCurrentAutomation(automation); templateStore.openEditAutomationModal(); }"
                                                                            class="bi bi-pencil-fill"
                                                                            style="margin-right: 27px; cursor: pointer;"></i>

                                                                        <i @click="templateStore.deleteAutomation(automation)"
                                                                            style="cursor: pointer;"
                                                                            class="bi bi-trash"></i>

                                                                    </div>



                                                                </div>



                                                            </div>


                                                        </div>



                                                    </div>


                                                    <div class="buttonOptionsWrapper">



                                                        <div v-if="element.response_type === 'instruction'"
                                                            class="instructionWrapper">

                                                            <div class="attachFileCont"
                                                                v-if="element.instruction_files && element.instruction_files.length > 0">


                                                                <div class="imgBox"
                                                                    v-if="element.instruction_files[0].type === 'image'">

                                                                    <img class="theAttachImage"
                                                                        :src="element.instruction_files[0].file_url">


                                                                </div>

                                                                <div class="pdfBox"
                                                                    v-if="element.instruction_files[0].type === 'pdf'">

                                                                    {{ element.instruction_files[0].name }}


                                                                </div>

                                                                <i @click="templateStore.deleteAttachmentFile"
                                                                    class="bi bi-trash3-fill deleteAttachIcon"
                                                                    style="cursor: pointer;"></i>


                                                            </div>



                                                            <div class="attachCont">


                                                                <div class="hasAttachment"
                                                                    v-if="element.instruction_files && element.instruction_files.length > 0">


                                                                    <div class="uploadAttach"
                                                                        @click="templateStore.triggerInstructionAttachmentInputReplacement">
                                                                        <b>Replace Attachment</b>
                                                                    </div>

                                                                    <div style="font-size: 12px;">
                                                                        Only 1 image or PDF document can be uploaded
                                                                    </div>

                                                                </div>

                                                                <div v-else class="theNoAttachCont">

                                                                    <div class="uploadAttach"
                                                                        @click="templateStore.triggerInstructionAttachmentInput">
                                                                        <b>Upload Attachment</b>
                                                                    </div>



                                                                    <div style="font-size: 12px;">
                                                                        Only 1 image or PDF document can be uploaded
                                                                    </div>



                                                                </div>





                                                            </div>

                                                        </div>

                                                        <div class="leftSide">

                                                            <div class="boxItem"
                                                                v-if="!templateStore.checkType(element.response_type)"
                                                                @click="templateStore.openAutomationsModal">

                                                                <span>
                                                                    + Add Logic
                                                                </span>

                                                            </div>



                                                            <div class="rangeBox"
                                                                v-if="element.response_type === 'slider'">


                                                                <span @click="templateStore.openShowRangeSelectorModal">
                                                                    Range: {{ element.min_range }} - {{
                        element.max_range
                    }}
                                                                </span>

                                                            </div>



                                                        </div>


                                                        <div class="rightSide"
                                                            v-if="element.response_type !== 'instruction'">




                                                            <div class="checkBoxWrapper">



                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        value="" id="flexCheckDefault">
                                                                    <label class="form-check-label"
                                                                        for="flexCheckDefault">
                                                                        Required
                                                                    </label>
                                                                </div>

                                                            </div>

                                                            <div class="checkBoxWrapper">



                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        value="" id="flexCheckDefault">
                                                                    <label class="form-check-label"
                                                                        for="flexCheckDefault">
                                                                        Require Media
                                                                    </label>
                                                                </div>

                                                            </div>



                                                            <i v-if="elementIndex !== 0"
                                                                @click="templateStore.deleteThisQuestion(element.id, pageIndex)"
                                                                class="bi bi-trash deleteIcon"
                                                                style="font-size: 15px; margin-left: 20px;"></i>

                                                        </div>

                                                    </div>




                                                </div>


                                            </div>



                                        </div>


                                        <div v-if="element.element_type === 'section_header'">




                                            <div class="sectionHeader">


                                                <div class="sectionLeft">

                                                    <i class="bi bi-arrows-move handle"
                                                        style="margin-right: 23px; cursor: pointer;"></i>

                                                    <i @click="templateStore.toggleSection(element)"
                                                        class="bi bi-chevron-down"
                                                        style="margin-right: 10px; cursor: pointer;">
                                                    </i>


                                                    <div v-if="element.template_section.is_editing_name === 0"
                                                        class="sectionTitleBox">

                                                        <div style="text-align: left; width: 500px;">
                                                            {{ element.template_section.name }} <i
                                                                @click="templateStore.toggleEditSectionName(element.template_section)"
                                                                style="font-size: 11px; margin-left: 14px;"
                                                                class="bi bi-pencil-fill"></i>
                                                        </div>

                                                        <div>


                                                        </div>


                                                    </div>

                                                    <div v-else class="editTitleBox">

                                                        <input v-model="element.template_section.name" type="text"
                                                            class="form-control" style="width: 500px;">

                                                        <button @click="element.template_section.is_editing_name = 0"
                                                            class="btn btn-dark doneBtn"><b><i
                                                                    class="bi bi-check2"></i></b></button>

                                                    </div>


                                                </div>

                                                <div style="display: flex; align-items: center;">

                                                    <button @click="templateStore.addQuestionToASection(element)"
                                                        class="button-14" style="margin-right: 16px;">+ Add
                                                        Question</button>


                                                    <div class="dropdown">



                                                        <i class="bi bi-three-dots moreSection" id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"></i>

                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">



                                                            <li
                                                                @click="templateStore.deleteASection(element, pageIndex)">
                                                                <a class="dropdown-item" href="#">Delete</a>
                                                            </li>

                                                            <li><a class="dropdown-item" href="#">Something else
                                                                    here</a></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="sectionOuter" v-show="element.show_section === 1">

                                                <div
                                                    style="display: flex; align-items: center; justify-content: center; width: 5%; padding-top: 10px; padding-bottom: 10px;">

                                                </div>

                                                <div class="sectionWrapper">


                                                    <div
                                                        style="display: flex; justify-content: flex-end; background-color: white; width: 100%;">


                                                        <div style="background-color: white; width: 100%;">




                                                            <draggable class="section-layouts-list"
                                                                v-model="element.section_layouts" item-key="id"
                                                                handle=".handle" :animation="600"
                                                                :group="{ name: 'elements', put: true }">



                                                                <template
                                                                    #item="{ element: sectionElement, index: sectionElementIndex }">

                                                                    <div class="sectionQuestionElementItem">

                                                                        <div style="width: 100%; display: flex;">




                                                                            <div style="width: 5%; margin-top: 7px;">

                                                                                <i v-if="element.section_layouts.length !== 1"
                                                                                    class="bi bi-arrows-move handle"
                                                                                    style="cursor: pointer;"></i>

                                                                            </div>

                                                                            <div
                                                                                style="width: 60%; padding-right: 10px;">
                                                                                <input
                                                                                    v-model="sectionElement.template_question.question_text"
                                                                                    type="text"
                                                                                    class="form-control titleInput"
                                                                                    placeholder="Type question..."
                                                                                    @click="templateStore.setCurrentElement(sectionElement, sectionElementIndex)" />
                                                                            </div>

                                                                            <div style="width: 25%;"
                                                                                @click="templateStore.setCurrentElement(sectionElement, sectionElementIndex)">

                                                                                <div type="text" class="form-control"
                                                                                    id="dropdownMenuButton1"
                                                                                    placeholder="show selected option here"
                                                                                    data-bs-toggle="dropdown"
                                                                                    style="display: flex; justify-content: space-between;">


                                                                                    <div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'checkbox'">


                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/checkmark.png`)" />
                                                                                            Checkbox


                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'text_answer'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/text.png`)" />
                                                                                            Text Answer

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'multiple_choice'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/test.png`)" />
                                                                                            Multiple Choice

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'number'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/number.png`)" />
                                                                                            Number

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'datetime'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/calendar.png`)" />
                                                                                            Date & Time

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'date'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/calendar.png`)" />
                                                                                            Date Only

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'time'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/clock.png`)" />
                                                                                            Time

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'media'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/camera.png`)" />
                                                                                            Media

                                                                                        </div>



                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'slider'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/slider.png`)" />
                                                                                            Slider

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'geostamp'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/map.png`)" />
                                                                                            Geo Stamp

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'instruction'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/guidebook.png`)" />
                                                                                            Instruction

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'pass_fail'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/pass_fail.png`)" />
                                                                                            Pass/Fail

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'dropdown'">

                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/drop1.png`)" />
                                                                                            Dropdown

                                                                                        </div>

                                                                                        <div
                                                                                            v-if="sectionElement.response_type === 'signature'">


                                                                                            <img width="15px"
                                                                                                style="margin-right: 10px;"
                                                                                                :src="require(`@/assets/images/sign2.png`)" />
                                                                                            Signature


                                                                                        </div>

                                                                                    </div>


                                                                                    <i class="bi bi-chevron-down"></i>



                                                                                </div>



                                                                                <div class="dropdown">



                                                                                    <ul class="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuButton1"
                                                                                        style="width: 300px; height: 400px; overflow-y: auto;">

                                                                                        <li v-for="(responseType, index) in templateStore.responseTypes"
                                                                                            :key="index"
                                                                                            @click="templateStore.setSelectedResponseType(responseType.type)">

                                                                                            <div class="dropdown-item"
                                                                                                :class="{ dropdownItemSelected: responseType.type === sectionElement.response_type }">


                                                                                                <img width="20px"
                                                                                                    style="margin-right: 10px;"
                                                                                                    :src="require(`@/assets/images/${responseType.img_name}.png`)" />
                                                                                                {{ responseType.name }}
                                                                                            </div>

                                                                                        </li>


                                                                                    </ul>
                                                                                </div>

                                                                            </div>

                                                                            <div v-if="sectionElement.response_type !== 'dropdown' && sectionElement.response_type !== 'multiple_choice'"
                                                                                style="width: 10%; padding-left: 10px;"
                                                                                @click="templateStore.setCurrentElement(sectionElement, elementIndex)">

                                                                                <input
                                                                                    v-if="templateStore.template.is_scoring_enabled === 'yes'"
                                                                                    type="number"
                                                                                    class="form-control titleInput"
                                                                                    placeholder="Score" />
                                                                            </div>

                                                                        </div>



                                                                        <div class="optionsWrapper"
                                                                            v-show="templateStore.currentElement.id === sectionElement.id">


                                                                            <div class="outerCont"
                                                                                v-if="sectionElement.response_type === 'dropdown'">

                                                                                <div class="dropdownOptionsCont">

                                                                                    <div
                                                                                        v-if="sectionElement.dropdown_options.length === 0">

                                                                                        <div @click="templateStore.sectionAddNewDropownOption"
                                                                                            class="addFirstOption">+ Add
                                                                                            First Option</div>

                                                                                    </div>

                                                                                    <div v-else>

                                                                                        <draggable class="dropdown_list"
                                                                                            v-model="sectionElement.dropdown_options"
                                                                                            :move="templateStore.checkDropdownItemMove"
                                                                                            item-key="id"
                                                                                            handle=".handle"
                                                                                            :animation="600">



                                                                                            <template
                                                                                                #item="{ element: dropdownOption }">

                                                                                                <div
                                                                                                    class="theItemWrapper">





                                                                                                    <div
                                                                                                        class="deleteBox">

                                                                                                        <i @click="templateStore.deleteThisDropdownOption(dropdownOption)"
                                                                                                            class="bi bi-trash"
                                                                                                            style="cursor: pointer;"></i>

                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="optionDragBox">



                                                                                                        <i class="bi bi-arrows-move handle"
                                                                                                            style="cursor: pointer;"></i>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="inputFieldBox">

                                                                                                        <input
                                                                                                            v-model="dropdownOption.option_text"
                                                                                                            class="form-control"
                                                                                                            type="text"
                                                                                                            placeholder="Enter option">

                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="colorBox">



                                                                                                        <div class="theColorBox"
                                                                                                            @click="templateStore.toggleDropdownColorBox(dropdownOption)"
                                                                                                            :style="{ backgroundColor: dropdownOption.color_code }">

                                                                                                        </div>

                                                                                                        <!-- <div class="colorPopup"
                                                                                                            v-if="dropdownOption.show_color_popup === 1">




                                                                                                            <div class="circleItem"
                                                                                                                v-for="(color, index) in templateStore.colorArray"
                                                                                                                :key="index"
                                                                                                                :style="{ backgroundColor: color.hex_code }"
                                                                                                                @click="templateStore.setDropdowColor(dropdownOption, color)">


                                                                                                            </div>


                                                                                                        </div> -->

                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="flagBox">

                                                                                                        <div
                                                                                                            class="theflagBox">


                                                                                                            <span
                                                                                                                style="margin-right: 5px; font-size: 12px;">Flag
                                                                                                                Answer</span>




                                                                                                            <div
                                                                                                                @click="templateStore.toggleIsFlagged(dropdownOption)">

                                                                                                                <input
                                                                                                                    v-if="dropdownOption.is_flagged"
                                                                                                                    class="myCheckBox"
                                                                                                                    type="checkbox"
                                                                                                                    checked>

                                                                                                                <input
                                                                                                                    v-else
                                                                                                                    class="myCheckBox"
                                                                                                                    type="checkbox">




                                                                                                            </div>




                                                                                                        </div>



                                                                                                    </div>


                                                                                                    <div class="mainScoreBox"
                                                                                                        v-if="templateStore.template.is_scoring_enabled === 'yes'">



                                                                                                        <div
                                                                                                            style="font-size: 11px; margin-right: 5px;">
                                                                                                            Score
                                                                                                        </div>

                                                                                                        <input
                                                                                                            v-model="dropdownOption.weight_value"
                                                                                                            type="number"
                                                                                                            class="form-control" />


                                                                                                    </div>






                                                                                                </div>

                                                                                            </template>

                                                                                        </draggable>




                                                                                        <div class="addOptionWrapper">

                                                                                            <div class="addOptionCTA"
                                                                                                style="margin-right: 18px;"
                                                                                                @click="templateStore.sectionAddNewDropownOption">
                                                                                                + Add Another Option
                                                                                            </div>

                                                                                            <div class="addOptionCTA"
                                                                                                @click="templateStore.openShowSavedOptionsModal">

                                                                                                <i
                                                                                                    class="bi bi-floppy"></i>
                                                                                                View Saved Options
                                                                                            </div>

                                                                                            <div class="addOptionCTA"
                                                                                                style="margin-left: 18px;"
                                                                                                @click="templateStore.bookmarkThisDropdownOptions">
                                                                                                <i
                                                                                                    class="bi bi-bookmark"></i>
                                                                                                Bookmark
                                                                                            </div>




                                                                                        </div>

                                                                                    </div>



                                                                                </div>

                                                                            </div>

                                                                            <div class="outerCont"
                                                                                v-if="sectionElement.response_type === 'multiple_choice'">



                                                                                <div class="dropdownOptionsCont">

                                                                                    <div
                                                                                        v-if="sectionElement.multiple_choice_options.length === 0">

                                                                                        <div @click="templateStore.sectionAddNewChoiceToElement"
                                                                                            class="addFirstOption">+ Add
                                                                                            First Choice</div>

                                                                                    </div>

                                                                                    <div v-else>

                                                                                        <div
                                                                                            class="multipleAnswerToggleBox">

                                                                                            <div
                                                                                                style="font-size: 13px; margin-right: 10px">
                                                                                                Multiple Select

                                                                                            </div>



                                                                                            <div
                                                                                                @click="templateStore.toggleIsMultipleSelect(sectionElement)">

                                                                                                <div v-if="sectionElement.is_multiple_answer_selection === 'yes'"
                                                                                                    class="form-check form-switch">
                                                                                                    <input
                                                                                                        class="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="flexSwitchCheckDefault"
                                                                                                        checked>
                                                                                                </div>

                                                                                                <div v-else
                                                                                                    class="form-check form-switch">
                                                                                                    <input
                                                                                                        class="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id="flexSwitchCheckDefault">
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                        <draggable class="dropdown_list"
                                                                                            v-model="sectionElement.multiple_choice_options"
                                                                                            :move="templateStore.checkDropdownItemMove"
                                                                                            item-key="id"
                                                                                            handle=".handle"
                                                                                            :animation="600">



                                                                                            <template
                                                                                                #item="{ element: choice }">

                                                                                                <div
                                                                                                    class="theItemWrapper">




                                                                                                    <div
                                                                                                        class="deleteBox">

                                                                                                        <i class="bi bi-trash"
                                                                                                            @click="templateStore.deleteChoice(choice)"
                                                                                                            style="cursor: pointer;"></i>

                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="optionDragBox">



                                                                                                        <i class="bi bi-arrows-move handle"
                                                                                                            style="cursor: pointer;"></i>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="inputFieldBox">

                                                                                                        <input
                                                                                                            v-model="choice.option_text"
                                                                                                            class="form-control"
                                                                                                            type="text"
                                                                                                            placeholder="Enter option">

                                                                                                    </div>


                                                                                                    <div
                                                                                                        class="colorBox">



                                                                                                        <div class="theColorBox"
                                                                                                            @click="templateStore.toggleChoiceColorPopup(choice)"
                                                                                                            :style="{ backgroundColor: choice.color_code }">

                                                                                                        </div>

                                                                                                        <div class="colorPopup"
                                                                                                            v-if="choice.show_color_popup === 1">


                                                                                                            <div class="circleItem"
                                                                                                                v-for="(color, index) in templateStore.colorArray"
                                                                                                                :key="index"
                                                                                                                :style="{ backgroundColor: color.hex_code }"
                                                                                                                @click="templateStore.setChoiceColor(choice, color)">


                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="flagBox">

                                                                                                        <div
                                                                                                            class="theflagBox">


                                                                                                            <span
                                                                                                                style="margin-right: 5px; font-size: 12px;">Flag
                                                                                                                Answer</span>




                                                                                                            <div
                                                                                                                @click="templateStore.choiceToggleIsFlagged(choice)">

                                                                                                                <input
                                                                                                                    v-if="choice.is_flagged"
                                                                                                                    class="myCheckBox"
                                                                                                                    type="checkbox"
                                                                                                                    checked>

                                                                                                                <input
                                                                                                                    v-else
                                                                                                                    class="myCheckBox"
                                                                                                                    type="checkbox">




                                                                                                            </div>




                                                                                                        </div>



                                                                                                    </div>


                                                                                                    <div class="mainScoreBox"
                                                                                                        v-if="templateStore.template.is_scoring_enabled === 'yes'">



                                                                                                        <div
                                                                                                            style="font-size: 11px; margin-right: 5px;">
                                                                                                            Score
                                                                                                        </div>

                                                                                                        <input
                                                                                                            v-model="choice.weight_value"
                                                                                                            type="number"
                                                                                                            class="form-control" />


                                                                                                    </div>





                                                                                                </div>

                                                                                            </template>

                                                                                        </draggable>


                                                                                        <div class="addOptionWrapper">

                                                                                            <div class="addOptionCTA"
                                                                                                style="margin-right: 18px;"
                                                                                                @click="templateStore.sectionAddNewChoiceToElement">
                                                                                                + Add Another Choice
                                                                                            </div>

                                                                                            <div class="addOptionCTA"
                                                                                                @click="templateStore.openShowSavedOptionsModal">

                                                                                                <i
                                                                                                    class="bi bi-floppy"></i>
                                                                                                View Saved Choices
                                                                                            </div>

                                                                                            <div @click="templateStore.bookmarkTheseChoices"
                                                                                                class="addOptionCTA"
                                                                                                style="margin-left: 18px;">

                                                                                                <i
                                                                                                    class="bi bi-bookmark"></i>
                                                                                                Bookmark Choices
                                                                                            </div>






                                                                                        </div>



                                                                                    </div>



                                                                                </div>



                                                                            </div>




                                                                            <div class="innerAutomationBox"
                                                                                v-if="sectionElement.automations && sectionElement.automations.length > 0">

                                                                                <hr
                                                                                    style="width: 100%; height: 2px; background-color: #d3d3d3; border: none;">


                                                                                <div class="theAutomatoinListBox">

                                                                                    <div class="autoLeft">

                                                                                        Automations:

                                                                                    </div>

                                                                                    <div class="autoRight">


                                                                                        <div v-for="(automation, index) in sectionElement.automations"
                                                                                            :key="index"
                                                                                            class="automationItem">


                                                                                            <div class="theAutoDescBox">


                                                                                                <div
                                                                                                    v-if="templateStore.currentElement.response_type === 'text_answer'">

                                                                                                    If answer {{
                                                                                                        automation.logic_condition_name
                                                                                                    }} <b>{{
                                                                                                            automation.string_value
                                                                                                        }}</b> then
                                                                                                                                                                                    <b>{{
                                                                                                            automation.trigger_type_name
                                                                                                        }}</b>

                                                                                                </div>

                                                                                                <div
                                                                                                    v-if="templateStore.currentElement.response_type === 'number'">

                                                                                                    If answer {{
                                                                                                            automation.logic_condition_name
                                                                                                        }} <b>{{
                                                                                                                automation.number_value
                                                                                                            }}</b> then
                                                                                                                                                                                        <b>{{
                                                                                                                automation.trigger_type_name
                                                                                                            }}</b>

                                                                                                </div>

                                                                                                <div
                                                                                                    v-if="templateStore.currentElement.response_type === 'checkbox'">

                                                                                                    <span
                                                                                                    v-if="automation.string_value === 'checked'">

                                                                                                    If checkbox is <b>Checked</b> then
                                                                                                    <b>{{ automation.trigger_type_name
                                                                                                        }}</b>

                                                                                                </span>

                                                                                                <span v-else>

                                                                                                    If checkbox is <b>Not Checked</b> then
                                                                                                    <b>{{ automation.trigger_type_name
                                                                                                        }}</b>

                                                                                                </span>
                                                                                                    



                                                                                                </div>


                                                                                                <div
                                                                                                    v-if="templateStore.currentElement.response_type === 'multiple_choice'">


                                                                                                    <div
                                                                                                        v-if="automation.choice">

                                                                                                        If <b>{{
                        automation.choice.option_text
                    }}</b> {{
                            automation.logic_condition_name
                        }}
                                                                                                        then
                                                                                                        <b>{{
                            automation.trigger_type_name
                        }}</b>

                                                                                                    </div>




                                                                                                </div>

                                                                                                <div
                                                                                                    v-if="templateStore.currentElement.response_type === 'dropdown'">


                                                                                                    <div
                                                                                                        v-if="automation.dropdown_option">

                                                                                                        If <b>{{
                        automation.dropdown_option.option_text
                    }}</b> {{
                            automation.logic_condition_name
                        }} then
                                                                                                        <b>{{
                            automation.trigger_type_name
                        }}</b>

                                                                                                    </div>




                                                                                                </div>

                                                                                                <div
                                                                                                    v-if="templateStore.currentElement.response_type === 'slider'">

                                                                                                    If answer {{
                        automation.logic_condition_name
                    }} <b>{{
                            automation.number_value
                        }}</b> then
                                                                                                    <b>{{
                            automation.trigger_type_name
                        }}</b>

                                                                                                </div>


                                                                                            </div>

                                                                                            <div
                                                                                                class="theAutoDescOptionsBox">

                                                                                                <i @click="() => { templateStore.setCurrentAutomation(automation); templateStore.openEditAutomationModal(); }"
                                                                                                    class="bi bi-pencil-fill"
                                                                                                    style="margin-right: 27px; cursor: pointer;"></i>

                                                                                                <i @click="templateStore.deleteAutomation(automation)"
                                                                                                    style="cursor: pointer;"
                                                                                                    class="bi bi-trash"></i>

                                                                                            </div>



                                                                                        </div>



                                                                                    </div>


                                                                                </div>



                                                                            </div>



                                                                            <div class="buttonOptionsWrapper">

                                                                                <div v-if="sectionElement.response_type === 'instruction'"
                                                                                    class="instructionWrapper">

                                                                                    <div class="attachFileCont"
                                                                                        v-if="sectionElement.instruction_files && sectionElement.instruction_files.length > 0">


                                                                                        <div class="imgBox"
                                                                                            v-if="sectionElement.instruction_files[0].type === 'image'">

                                                                                            <img class="theAttachImage"
                                                                                                :src="sectionElement.instruction_files[0].file_url">


                                                                                        </div>

                                                                                        <div class="pdfBox"
                                                                                            v-if="sectionElement.instruction_files[0].type === 'pdf'">

                                                                                            {{
                        sectionElement.instruction_files[0].name
                    }}


                                                                                        </div>

                                                                                        <i @click="templateStore.deleteAttachmentFile"
                                                                                            class="bi bi-trash3-fill deleteAttachIcon"
                                                                                            style="cursor: pointer;"></i>


                                                                                    </div>



                                                                                    <div class="attachCont">


                                                                                        <div class="hasAttachment"
                                                                                            v-if="sectionElement.instruction_files && sectionElement.instruction_files.length > 0">


                                                                                            <div class="uploadAttach"
                                                                                                @click="templateStore.triggerInstructionAttachmentInputReplacement">
                                                                                                <b>Replace
                                                                                                    Attachment</b>
                                                                                            </div>

                                                                                            <div
                                                                                                style="font-size: 12px;">
                                                                                                Only 1 image or PDF
                                                                                                document can be uploaded
                                                                                            </div>

                                                                                        </div>

                                                                                        <div v-else
                                                                                            class="theNoAttachCont">

                                                                                            <div class="uploadAttach"
                                                                                                @click="templateStore.triggerInstructionAttachmentInput">
                                                                                                <b>Upload Attachment</b>
                                                                                            </div>



                                                                                            <div
                                                                                                style="font-size: 12px;">
                                                                                                Only 1 image or PDF
                                                                                                document can be uploaded
                                                                                            </div>



                                                                                        </div>





                                                                                    </div>

                                                                                </div>

                                                                                <div class="leftSide">

                                                                                    <div class="boxItem"
                                                                                        v-if="!templateStore.checkType(element.response_type)"
                                                                                        @click="templateStore.openAutomationsModal">

                                                                                        <span>
                                                                                            + Add Logic
                                                                                        </span>

                                                                                    </div>



                                                                                    <div class="rangeBox"
                                                                                        v-if="sectionElement.response_type === 'slider'">


                                                                                        <span
                                                                                            @click="templateStore.openShowRangeSelectorModal">
                                                                                            Range: {{
                        sectionElement.min_range
                    }} - {{
                            sectionElement.max_range }}
                                                                                        </span>

                                                                                    </div>



                                                                                </div>


                                                                                <div class="rightSide"
                                                                                    v-if="sectionElement.response_type !== 'instruction'">




                                                                                    <div class="checkBoxWrapper">



                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox" value=""
                                                                                                id="flexCheckDefault">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="flexCheckDefault">
                                                                                                Required
                                                                                            </label>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div class="checkBoxWrapper">



                                                                                        <div class="form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox" value=""
                                                                                                id="flexCheckDefault">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="flexCheckDefault">
                                                                                                Require Media
                                                                                            </label>
                                                                                        </div>

                                                                                    </div>



                                                                                    <i v-if="elementIndex !== 0"
                                                                                        @click="templateStore.deleteThisQuestion(sectionElement.id, pageIndex)"
                                                                                        class="bi bi-trash deleteIcon"
                                                                                        style="font-size: 15px; margin-left: 20px;"></i>

                                                                                </div>

                                                                            </div>




                                                                        </div>


                                                                    </div>

                                                                </template>



                                                            </draggable>




                                                        </div>



                                                    </div>




                                                </div>

                                            </div>

                                        </div>

                                    </div>




                                </template>





                            </draggable>




                        </div>





                    </div>




                </div>

                <div class="mobilePreviewArea">



                    <div class="phoneContainer">

                        <div class="phoneContentWrapper">


                            <div v-if="templateStore.showImageAndVideoGalleryModal" class="mobile-modal-overlay">


                                <div class="topModalHeader">

                                    <div>

                                    </div>

                                    <div>

                                        <i class="bi bi-box-arrow-in-down"
                                            @click="templateStore.downloadFileFromGallery"
                                            style="font-size: 22px; margin-right: 25px; cursor: pointer;"></i>

                                        <i class="bi bi-trash" @click="templateStore.deleteFileFromGallery"
                                            style="font-size: 20px; margin-right: 25px; cursor: pointer;"></i>

                                        <i class="bi bi-x-lg" style="font-size: 20px; cursor: pointer;"
                                            @click="templateStore.closeShowImageAndVideoGalleryModal"></i>

                                    </div>

                                </div>

                                <div class="galleryConatiner">


                                    <div>
                                        <i @click="templateStore.prevFile" class="bi bi-chevron-left"
                                            style="font-size: 30px; cursor: pointer;"></i>
                                    </div>

                                    <div class="middleGallery">


                                        <div v-for="(file, index) in templateStore.galleryFiles" :key="index">

                                            <div v-if="index === templateStore.currentFileIndexInGallery">


                                                <img v-if="file.type === 'image'" class="imgInGallery" :src="file.url">

                                                <div v-if="file.type === 'video'">

                                                    <video class="galleryVideo" controls>
                                                        <source :src="file.url" type="video/mp4">
                                                        Your browser does not support HTML video.
                                                    </video>

                                                </div>


                                            </div>


                                        </div>


                                    </div>


                                    <div>
                                        <i @click="templateStore.nextFile" class="bi bi-chevron-right"
                                            style="font-size: 30px; cursor: pointer;"></i>
                                    </div>




                                </div>

                            </div>

                            <div v-if="templateStore.showSignatureModal" class="mobile-modal-overlay">


                                <div class="topModalHeader">

                                    <div>

                                    </div>

                                    <div>



                                        <i class="bi bi-x-lg" style="font-size: 20px; cursor: pointer;"
                                            @click="templateStore.closeShowSignatureModal"></i>

                                    </div>

                                </div>

                                <div class="signatureConatiner">




                                    <div class="theSignWrapper">


                                        <div class="myWrapper">

                                            <div class="signHeader">

                                                <div class="signTab"
                                                    v-for="(signOption, index) in templateStore.signOptions"
                                                    :key="index" @click="templateStore.setCurentSignOption(signOption)"
                                                    :class="{ selectedSignTab: signOption.type === templateStore.currentSignatureType }">

                                                    {{ signOption.name }}

                                                </div>



                                            </div>

                                            <div class="signBody">


                                                <div class="text_sign_box"
                                                    v-if="templateStore.currentSignatureType === 'text_sign'">


                                                    <input type="text" class="form-control theSignInput"
                                                        ref="theSignInput">


                                                    <div class="actionBox" @click="saveTextSignAsImage">

                                                        <b>Save</b>

                                                    </div>



                                                </div>

                                                <div class="digital_sign_box"
                                                    v-if="templateStore.currentSignatureType === 'digital_sign'">



                                                    <div class="canvas-container">

                                                        <VueSignaturePad class="thePad" ref="signaturePad" />

                                                        <div class="padbuttons">

                                                            <button @click="saveDigiSignature"
                                                                class="signActionBtn">Save</button>

                                                            <button @click="clearDigiSignature"
                                                                class="clearActionBtn">Clear</button>

                                                        </div>

                                                    </div>





                                                </div>



                                            </div>

                                        </div>





                                    </div>



                                </div>

                            </div>


                            <div class="mobileTopBox" @click="templateStore.togglePageBox">

                                <div class="topRow">

                                    <div class="leftBox">
                                        <i class="bi bi-chevron-down" style="margin-right: 7px;"></i> Page
                                        {{ templateStore.currentMobilePreviewPageIndex + 1 }} of
                                        {{ templateStore.templatePages.length }}
                                    </div>

                                    <div>
                                        Score
                                    </div>




                                </div>

                                <div class="bottomRow">

                                    <div style="font-size: 11px;"> 
                                        {{ templateStore.currentMobilePage.title }}
                                    </div>

                                    <div>
                                        0 / {{ templateStore.templatePages.length }} (100%)
                                    </div>


                                </div>


                                <div v-show="templateStore.showPageBox" class="showPagesBox">


                                    <div class="pageNavItem" v-for="(page, index) in templateStore.templatePages"
                                        :key="index" @click="templateStore.setCurrentMobilePreviewPageIndex(index)">

                                        Page {{ index + 1 }}

                                    </div>




                                </div>



                            </div>






                            <div class="scrollableArea">



                                <div v-for="(element, elementIndex) in templateStore.currentMobilePage.elements"
                                    :key="elementIndex">


                                    <div class="scrollItem" v-if="element.element_type !== 'section_header'"
                                        @click="templateStore.setCurrentElement(element, elementIndex)">

                                        <div v-if="element.response_type === 'text_answer'">

                                            <div class="questionTextArea">

                                                {{ element.template_question.question_text }}

                                            </div>

                                            <div class="inputElementArea">


                                                <div class="textAnswerInputArea">

                                                    <input type="text" class="form-control" placeholder="Enter text">

                                                </div>


                                            </div>

                                        </div>


                                        <div v-if="element.response_type === 'checkbox'">


                                            <div class="form-check checkyBox">

                                                <input class="form-check-input theCheckBox" type="checkbox" value=""
                                                    id="flexCheckDefault">

                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {{ element.template_question.question_text }}
                                                </label>
                                            </div>


                                        </div>

                                        <div v-if="element.response_type === 'number'">

                                            <div class="questionTextArea">

                                                {{ element.template_question.question_text }}

                                            </div>

                                            <div class="inputElementArea">


                                                <div class="textAnswerInputArea">

                                                    <input type="number" class="form-control"
                                                        placeholder="Enter number">

                                                </div>




                                            </div>




                                        </div>



                                        <div v-if="element.response_type === 'datetime'">

                                            <div class="dateTimeArea">

                                                {{ element.template_question.question_text }}


                                                <input class="form-control" type="date"
                                                    style="margin-bottom: 10px; margin-top: 10px;">

                                                <input class="form-control" type="time" style="margin-bottom: 18px;">


                                            </div>

                                        </div>

                                        <div v-if="element.response_type === 'date'">

                                            <div class="dateTimeArea">

                                                {{ element.template_question.question_text }}


                                                <input class="form-control" type="date"
                                                    style="margin-bottom: 10px; margin-top: 10px;">


                                            </div>

                                        </div>

                                        <div v-if="element.response_type === 'time'">

                                            <div class="dateTimeArea">

                                                {{ element.template_question.question_text }}


                                                <input class="form-control" type="time"
                                                    style="margin-bottom: 10px; margin-top: 10px;">


                                            </div>

                                        </div>


                                        <div v-if="element.response_type === 'media'">

                                            <div class="theMediaArea">

                                                {{ element.template_question.question_text }}




                                                <button class="mediaBtn" id="btnDialog"
                                                    @click="templateStore.triggerInput">

                                                    <i class="bi bi-cloud-arrow-up"
                                                        style="font-size: 19px; margin-right: 10px;"></i>

                                                    <span>
                                                        Add Media
                                                    </span>

                                                </button>


                                            </div>

                                        </div>

                                        <div v-if="element.response_type === 'slider'">

                                            <div class="sliderArea">

                                                <div>
                                                    {{ element.template_question.question_text }}
                                                </div>



                                                <div class="theSilderBox">


                                                    <input class="theRange" type="range"
                                                        v-model="element.default_range_value" :min="element.min_range"
                                                        :max="element.max_range" step="1" />


                                                    <div class="sliderRangeArea">

                                                        <div class="theSide">
                                                            {{ element.min_range }}
                                                        </div>

                                                        <div style="font-size: 18px;"><b>{{ element.default_range_value
                                                                }}</b></div>

                                                        <div class="theSide">
                                                            {{ element.max_range }}
                                                        </div>



                                                    </div>




                                                </div>



                                            </div>

                                        </div>


                                        <div v-if="element.response_type === 'instruction'" class="instructionBox">

                                            <div class="textTop">
                                                {{ element.template_question.question_text }}
                                            </div>

                                            <div class="fileBottom"
                                                v-if="element.instruction_files && element.instruction_files.length > 0">

                                                <div v-if="element.instruction_files[0].type === 'image'">
                                                    <img class="theAttachImg"
                                                        :src="element.instruction_files[0].file_url" alt="" srcset="">
                                                </div>

                                                <div class="pdfName" v-if="element.instruction_files[0].type === 'pdf'"
                                                    @click="templateStore.attachmentDownloadPDFfile(element.instruction_files[0])">

                                                    {{ element.instruction_files[0].name }}

                                                </div>




                                            </div>

                                        </div>


                                        <div v-if="element.response_type === 'dropdown'" class="parentContainer">

                                            <div class="textTop">
                                                {{ element.template_question.question_text }}
                                            </div>

                                            <div class="dropDownCont" @click="templateStore.toggleDropdown(element)">


                                                <div class="optionName" v-if="element.dropdown_option_id == null">
                                                    Select option
                                                </div>

                                                <div v-else class="optionName">

                                                    <div class="theCircle"
                                                        :style="{ backgroundColor: templateStore.showSelectedOption(element).color_code }">

                                                    </div>

                                                    <b>{{ templateStore.showSelectedOption(element).option_text }}</b>
                                                </div>



                                                <div>

                                                    <i v-if="element.show_dropdown === 0"
                                                        class="bi bi-chevron-down"></i>

                                                    <i v-else class="bi bi-chevron-up"></i>

                                                </div>


                                            </div>

                                            <div class="theDropdownCont" v-show="element.show_dropdown === 1">



                                                <div class="" v-for="(dropItem, index) in element.dropdown_options"
                                                    :key="index"
                                                    :class="{ optionItemSeelected: element.dropdown_option_id === dropItem.id }"
                                                    @click="() => { templateStore.setSelectedDropdownOption(element, dropItem); templateStore.toggleDropdown(element); }">


                                                    <div class="optionItem">

                                                        <div class="dotBox">

                                                            <div class="dropCircle"
                                                                :style="{ backgroundColor: dropItem.color_code }">
                                                            </div>

                                                        </div>

                                                        <div class="wordsBox">
                                                            {{ dropItem.option_text }}
                                                        </div>

                                                    </div>


                                                </div>



                                            </div>

                                        </div>

                                        <div v-if="element.response_type === 'multiple_choice'" class="choiceCont">

                                            <div class="textTop">
                                                {{ element.template_question.question_text }}
                                            </div>

                                            <div class="choiceItem"
                                                v-for="(choice, index) in element.multiple_choice_options" :key="index"
                                                :style="{
                                                    'background-color': choice.is_selected === 1 ?
                                                        choice.color_code : 'white',
                                                    'color': choice.is_selected === 1 ? 'white' : 'black',
                                                }" @click="templateStore.selectChoice(choice)">
                                                {{ choice.option_text }}
                                            </div>

                                        </div>

                                        <div v-if="element.response_type === 'signature'">


                                            <div class="signatureTextTop">
                                                {{ element.template_question.question_text }}
                                            </div>


                                            <button class="addSignatureBtn"
                                                @click="templateStore.openShowSignatureModal">
                                                Add Signature
                                            </button>


                                            <div class="signImgCont" v-if="element.signature_img_url !== null">

                                                <div class="imgSignBox">
                                                    <img class="theActualSignImg" :src="element.signature_img_url">
                                                </div>

                                                <div class="dateText"
                                                    v-if="element.signature_time && element.signature_date">
                                                    {{ element.signature_date }} {{ element.signature_time }}
                                                </div>

                                                <div>
                                                    <i class="bi bi-trash theTrash"
                                                        @click="templateStore.deleteSignatureImmage"></i>
                                                </div>


                                            </div>

                                        </div>

                                        <div v-show="element.response_type === 'geostamp'">



                                            <div class="geoStampWrapper">

                                                <div class="theGeoTextbox">

                                                    <input v-model="element.formatted_address" type="text"
                                                        class="form-control theMapInput" placeholder="Location">

                                                </div>


                                                <div v-if="element.show_map === 0" class="theGeoMapBtnBox"
                                                    @click="() => { templateStore.toggleShowMap(element); loadMap(); }">

                                                    <button class="mapBtn"><i class="bi bi-geo-alt-fill"></i>
                                                        Map</button>


                                                </div>

                                                <div v-else class="theGeoMapBtnBox" @click="loadMap">


                                                    <button class="locateMeBtn">Find Me</button>

                                                </div>

                                            </div>



                                            <div class="geoIframeBox" v-show="element.show_map === 1">



                                                <div :id="'mapContainer' + element.id"
                                                    style="width: 100%; height: 100%;">
                                                </div>


                                                <div class="mapBtnsWrapper">

                                                    <button class="closeMapBtn" @click="element.show_map = 0">Close
                                                        Map</button>

                                                    <button class="SaveLocationBtn">Save Location</button>

                                                </div>



                                            </div>



                                        </div>


                                        <div class="bottomOptionsArea"
                                            v-if="element.response_type !== 'instruction' && element.element_type !== 'section_header'">

                                            <div class="loadingState" v-if="element.is_file_uploading === 'true'">

                                                <div class="spinner-grow spinner-grow-sm" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>

                                            </div>

                                            <div class="filesArea"
                                                v-if="element.media_files && element.media_files.length > 0">



                                                <div v-for="(file, index) in templateStore.getImageAndVideoFilesOnly(element)"
                                                    :key="index" class="fileItem"
                                                    @click="() => { templateStore.setCurrentElement(element, elementIndex); templateStore.setCurrentFileIndex(file, element); }">


                                                    <img v-if="file.type === 'image'" class="theImg" :src="file.url">


                                                    <div v-if="file.type === 'video'" class="theVideo">

                                                        <i class="bi bi-play-circle" style="font-size: 26px;"></i>

                                                    </div>


                                                </div>




                                            </div>


                                            <div v-if="templateStore.getPdfFiles(element).length > 0">

                                                <div class="pdfsArea"
                                                    v-for="(file, index) in templateStore.getPdfFiles(element)"
                                                    :key="index">

                                                    <div class="pdfItem" v-if="file.type === 'pdf'">

                                                        <i class="bi bi-filetype-pdf"
                                                            style="margin-right: 7px; font-size: 20px;"></i>

                                                        <div class="thePDFname"
                                                            @click="templateStore.downloadPDFfile(file)">
                                                            {{ file.name }}
                                                        </div>

                                                        <div>
                                                            <i @click="() => { templateStore.setCurrentElement(element, elementIndex); templateStore.deletePdfFile(file); }"
                                                                class="bi bi-trash theTrash"></i>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>




                                            <div class="theNoteWrapper">

                                                <div v-if="element.show_note === 'yes'" class="editNote">


                                                    <textarea v-model="element.the_note" class="form-control textyArea"
                                                        cols="30" rows="3"></textarea>

                                                    <div class="noteBtnsArea">

                                                        <button class="saveBtn"
                                                            @click="templateStore.toggleOffEditMote(element)">
                                                            Save
                                                        </button>

                                                        <button class="cancelBtn"
                                                            @click="templateStore.toggleOffEditMote(element)">
                                                            Cancel
                                                        </button>


                                                    </div>


                                                </div>

                                                <div v-if="element.the_note !== null && element.show_note === 'no'"
                                                    class="noteView" @click="templateStore.toggleOnEditMote(element)">

                                                    {{ element.the_note }}

                                                </div>

                                            </div>

                                            <div class="buttonsArea">


                                                <div class="notaArea" @click="templateStore.toggleOnEditMote(element)">

                                                    <i class="bi bi-pencil"></i> Note

                                                </div>

                                                <div @click="templateStore.triggerInput" class="mediaArea"
                                                    v-if="element.response_type !== 'media'">
                                                    <i class="bi bi-images"></i> Media
                                                </div>

                                                <div class="actionArea">

                                                    <i class="bi bi-plus-lg"></i> Action
                                                </div>

                                            </div>


                                        </div>

                                    </div>



                                    <div v-if="element.element_type === 'section_header'">


                                        <div class="sectionScrollItem"
                                            @click="templateStore.toggleShowSection(element)">

                                            <div class="theSectioTab">


                                                <div class="nameSection">

                                                    <div style="margin-right: 7px;;">
                                                        <i v-if="element.show_section === 0"
                                                            class="bi bi-chevron-right"></i>
                                                        <i v-else class="bi bi-chevron-down"></i>
                                                    </div>

                                                    <div v-if="element.template_section">
                                                        {{element.template_section.name}}
                                                    </div>

                                                </div>

                                                <div>

                                                </div>



                                            </div>



                                        </div>

                                        <div v-if="element.show_section === 1">

                                            <div v-if="element.section_layouts && element.section_layouts.length > 0">


                                                <div v-for="(element, index) in element.section_layouts" :key="index">


                                                    <div class="scrollItem"
                                                        @click="templateStore.setCurrentElement(element, elementIndex)">

                                                        <div v-if="element.response_type === 'text_answer'">

                                                            <div class="questionTextArea">

                                                                {{ element.template_question.question_text }}

                                                            </div>

                                                            <div class="inputElementArea">


                                                                <div class="textAnswerInputArea">

                                                                    <input type="text" class="form-control"
                                                                        placeholder="Enter text">

                                                                </div>




                                                            </div>

                                                        </div>


                                                        <div v-if="element.response_type === 'checkbox'">


                                                            <div class="form-check checkyBox">

                                                                <input class="form-check-input theCheckBox"
                                                                    type="checkbox" value="" id="flexCheckDefault">

                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    {{ element.template_question.question_text }}
                                                                </label>
                                                            </div>


                                                        </div>

                                                        <div v-if="element.response_type === 'number'">

                                                            <div class="questionTextArea">

                                                                {{ element.template_question.question_text }}

                                                            </div>

                                                            <div class="inputElementArea">


                                                                <div class="textAnswerInputArea">

                                                                    <input type="number" class="form-control"
                                                                        placeholder="Enter number">

                                                                </div>

                                                            </div>

                                                        </div>


                                                        <div v-if="element.response_type === 'datetime'">

                                                            <div class="dateTimeArea">

                                                                {{ element.template_question.question_text }}


                                                                <input class="form-control" type="date"
                                                                    style="margin-bottom: 10px; margin-top: 10px;">

                                                                <input class="form-control" type="time"
                                                                    style="margin-bottom: 18px;">


                                                            </div>

                                                        </div>

                                                        <div v-if="element.response_type === 'date'">

                                                            <div class="dateTimeArea">

                                                                {{ element.template_question.question_text }}


                                                                <input class="form-control" type="date"
                                                                    style="margin-bottom: 10px; margin-top: 10px;">


                                                            </div>

                                                        </div>

                                                        <div v-if="element.response_type === 'time'">

                                                            <div class="dateTimeArea">

                                                                {{ element.template_question.question_text }}


                                                                <input class="form-control" type="time"
                                                                    style="margin-bottom: 10px; margin-top: 10px;">


                                                            </div>

                                                        </div>

                                                        <div v-if="element.response_type === 'media'">

                                                            <div class="theMediaArea">

                                                                {{ element.template_question.question_text }}

                                                                <button class="mediaBtn" id="btnDialog"
                                                                    @click="templateStore.triggerInput">

                                                                    <i class="bi bi-cloud-arrow-up"
                                                                        style="font-size: 19px; margin-right: 10px;"></i>

                                                                    <span>
                                                                        Add Media
                                                                    </span>

                                                                </button>


                                                            </div>

                                                        </div>

                                                        <div v-if="element.response_type === 'slider'">

                                                            <div class="sliderArea">

                                                                <div>
                                                                    {{ element.template_question.question_text }}
                                                                </div>



                                                                <div class="theSilderBox">


                                                                    <input class="theRange" type="range"
                                                                        v-model="element.default_range_value"
                                                                        :min="element.min_range"
                                                                        :max="element.max_range" step="1" />


                                                                    <div class="sliderRangeArea">

                                                                        <div class="theSide">
                                                                            {{ element.min_range }}
                                                                        </div>

                                                                        <div style="font-size: 18px;">
                                                                            <b>{{ element.default_range_value }}</b>
                                                                        </div>

                                                                        <div class="theSide">
                                                                            {{ element.max_range }}
                                                                        </div>



                                                                    </div>




                                                                </div>



                                                            </div>

                                                        </div>


                                                        <div v-if="element.response_type === 'instruction'"
                                                            class="instructionBox">

                                                            <div class="textTop">
                                                                {{ element.template_question.question_text }}
                                                            </div>

                                                            <div class="fileBottom"
                                                                v-if="element.instruction_files && element.instruction_files.length > 0">

                                                                <div
                                                                    v-if="element.instruction_files[0].type === 'image'">
                                                                    <img class="theAttachImg"
                                                                        :src="element.instruction_files[0].file_url"
                                                                        alt="" srcset="">
                                                                </div>

                                                                <div class="pdfName"
                                                                    v-if="element.instruction_files[0].type === 'pdf'"
                                                                    @click="templateStore.attachmentDownloadPDFfile(element.instruction_files[0])">

                                                                    {{ element.instruction_files[0].name }}

                                                                </div>




                                                            </div>

                                                        </div>


                                                        <div v-if="element.response_type === 'dropdown'"
                                                            class="parentContainer">

                                                            <div class="textTop">
                                                                {{ element.template_question.question_text }}
                                                            </div>

                                                            <div class="dropDownCont"
                                                                @click="templateStore.toggleDropdown(element)">


                                                                <div class="optionName"
                                                                    v-if="element.dropdown_option_id == null">
                                                                    Select option
                                                                </div>

                                                                <div v-else class="optionName">

                                                                    <div class="theCircle"
                                                                        :style="{ backgroundColor: templateStore.showSelectedOption(element).color_code }">

                                                                    </div>

                                                                    <b>{{
                        templateStore.showSelectedOption(element).option_text
                    }}</b>
                                                                </div>



                                                                <div>

                                                                    <i v-if="element.show_dropdown === 0"
                                                                        class="bi bi-chevron-down"></i>

                                                                    <i v-else class="bi bi-chevron-up"></i>

                                                                </div>


                                                            </div>

                                                            <div class="theDropdownCont"
                                                                v-show="element.show_dropdown === 1">



                                                                <div class=""
                                                                    v-for="(dropItem, index) in element.dropdown_options"
                                                                    :key="index"
                                                                    :class="{ optionItemSeelected: element.dropdown_option_id === dropItem.id }"
                                                                    @click="() => { templateStore.setSelectedDropdownOption(element, dropItem); templateStore.toggleDropdown(element); }">


                                                                    <div class="optionItem">

                                                                        <div class="dotBox">

                                                                            <div class="dropCircle"
                                                                                :style="{ backgroundColor: dropItem.color_code }">
                                                                            </div>

                                                                        </div>

                                                                        <div class="wordsBox">
                                                                            {{ dropItem.option_text }}
                                                                        </div>

                                                                    </div>


                                                                </div>



                                                            </div>

                                                        </div>

                                                        <div v-if="element.response_type === 'multiple_choice'"
                                                            class="choiceCont">

                                                            <div class="textTop">
                                                                {{ element.template_question.question_text }}
                                                            </div>

                                                            <div class="choiceItem"
                                                                v-for="(choice, index) in element.multiple_choice_options"
                                                                :key="index"
                                                                :style="{
                                                                    'background-color': choice.is_selected === 1 ?
                                                                        choice.color_code : 'white',
                                                                    'color': choice.is_selected === 1 ? 'white' : 'black',
                                                                }" 
                                                                @click="templateStore.selectChoice(choice)"
                                                                >
                                                                {{ choice.option_text }}
                                                            </div>

                                                        </div>

                                                        <div v-if="element.response_type === 'signature'">


                                                            <button class="addSignatureBtn"
                                                                @click="templateStore.openShowSignatureModal">
                                                                Add Signature
                                                            </button>


                                                            <div class="signImgCont"
                                                                v-if="element.signature_img_url !== null">

                                                                <div class="imgSignBox">
                                                                    <img class="theActualSignImg"
                                                                        :src="element.signature_img_url">
                                                                </div>

                                                                <div class="dateText"
                                                                    v-if="element.signature_time && element.signature_date">
                                                                    {{ element.signature_date }} {{
                        element.signature_time }}
                                                                </div>

                                                                <div>
                                                                    <i class="bi bi-trash theTrash"
                                                                        @click="templateStore.deleteSignatureImmage"></i>
                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div v-show="element.response_type === 'geostamp'">



                                                            <div class="geoStampWrapper">

                                                                <div class="theGeoTextbox">

                                                                    <input v-model="element.formatted_address"
                                                                        type="text" class="form-control theMapInput"
                                                                        placeholder="Location">

                                                                </div>


                                                                <div v-if="element.show_map === 0"
                                                                    class="theGeoMapBtnBox"
                                                                    @click="() => { templateStore.toggleShowMap(element); loadMap(); }">

                                                                    <button class="mapBtn"><i
                                                                            class="bi bi-geo-alt-fill"></i>
                                                                        Map</button>


                                                                </div>

                                                                <div v-else class="theGeoMapBtnBox" @click="loadMap">


                                                                    <button class="locateMeBtn">Find Me</button>

                                                                </div>

                                                            </div>



                                                            <div class="geoIframeBox" v-show="element.show_map === 1">



                                                                <div :id="'mapContainer' + element.id"
                                                                    style="width: 100%; height: 100%;">
                                                                </div>


                                                                <div class="mapBtnsWrapper">

                                                                    <button class="closeMapBtn"
                                                                        @click="element.show_map = 0">Close
                                                                        Map</button>

                                                                    <button class="SaveLocationBtn">Save
                                                                        Location</button>

                                                                </div>



                                                            </div>



                                                        </div>



                                                        <div class="bottomOptionsArea"
                                                            v-if="element.response_type !== 'instruction' && element.element_type !== 'section_header'">

                                                            <div class="loadingState"
                                                                v-if="element.is_file_uploading === 'true'">

                                                                <div class="spinner-grow spinner-grow-sm" role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>

                                                            </div>

                                                            <div class="filesArea"
                                                                v-if="element.media_files && element.media_files.length > 0">



                                                                <div v-for="(file, index) in templateStore.getImageAndVideoFilesOnly(element)"
                                                                    :key="index" class="fileItem"
                                                                    @click="() => { templateStore.setCurrentElement(element, elementIndex); templateStore.setCurrentFileIndex(file, element); }">


                                                                    <img v-if="file.type === 'image'" class="theImg"
                                                                        :src="file.url">


                                                                    <div v-if="file.type === 'video'" class="theVideo">

                                                                        <i class="bi bi-play-circle"
                                                                            style="font-size: 26px;"></i>

                                                                    </div>


                                                                </div>




                                                            </div>


                                                            <div v-if="templateStore.getPdfFiles(element).length > 0">

                                                                <div class="pdfsArea"
                                                                    v-for="(file, index) in templateStore.getPdfFiles(element)"
                                                                    :key="index">

                                                                    <div class="pdfItem" v-if="file.type === 'pdf'">

                                                                        <i class="bi bi-filetype-pdf"
                                                                            style="margin-right: 7px; font-size: 20px;"></i>

                                                                        <div class="thePDFname"
                                                                            @click="templateStore.downloadPDFfile(file)">
                                                                            {{ file.name }}
                                                                        </div>

                                                                        <div>
                                                                            <i @click="() => { templateStore.setCurrentElement(element, elementIndex); templateStore.deletePdfFile(file); }"
                                                                                class="bi bi-trash theTrash"></i>
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>




                                                            <div class="theNoteWrapper">

                                                                <div v-if="element.show_note === 'yes'"
                                                                    class="editNote">


                                                                    <textarea v-model="element.the_note"
                                                                        class="form-control textyArea" cols="30"
                                                                        rows="3"></textarea>

                                                                    <div class="noteBtnsArea">

                                                                        <button class="saveBtn"
                                                                            @click="templateStore.toggleOffEditMote(element)">
                                                                            Save
                                                                        </button>

                                                                        <button class="cancelBtn"
                                                                            @click="templateStore.toggleOffEditMote(element)">
                                                                            Cancel
                                                                        </button>


                                                                    </div>


                                                                </div>

                                                                <div v-if="element.the_note !== null && element.show_note === 'no'"
                                                                    class="noteView"
                                                                    @click="templateStore.toggleOnEditMote(element)">

                                                                    {{ element.the_note }}

                                                                </div>

                                                            </div>

                                                            <div class="buttonsArea">


                                                                <div class="notaArea"
                                                                    @click="templateStore.toggleOnEditMote(element)">

                                                                    <i class="bi bi-pencil"></i> Note

                                                                </div>

                                                                <div @click="templateStore.triggerInput"
                                                                    class="mediaArea"
                                                                    v-if="element.response_type !== 'media'">
                                                                    <i class="bi bi-images"></i> Media
                                                                </div>

                                                                <div class="actionArea">

                                                                    <i class="bi bi-plus-lg"></i> Action
                                                                </div>

                                                            </div>


                                                        </div>

                                                    </div>






                                                </div>


                                            </div>

                                        </div>








                                    </div>




                                </div>






                            </div>






                        </div>


                        <!-- <img class="phoneImg" :src="require(`@/assets/images/iphone-mock2.png`)" /> -->
                        <img class="phoneImg" :src="require(`@/assets/images/iphone-mock.svg`)" />

                    </div>





                </div>



            </div>

        </div>


        <div v-show="templateStore.currentEditorTab === 'report'" style="height: 100%;">

            REPORT - Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis ipsum architecto quaerat sit, omnis a
            deserunt qui totam animi excepturi provident blanditiis quos! Delectus, possimus fugiat. Iusto quasi itaque
            dolore!

        </div>


        <div v-show="templateStore.currentEditorTab === 'access'" style="height: 100%;">

            ACCESS - Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis ipsum architecto quaerat sit, omnis a
            deserunt qui totam animi excepturi provident blanditiis quos! Delectus, possimus fugiat. Iusto quasi itaque
            dolore!

        </div>



        <div v-if="templateStore.showReorderPagesModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">
                <div class="model-box-header">
                    <div style="font-size: 17px">
                        <b>Reorder Pages</b>
                    </div>

                    <div>
                        <i @click="templateStore.closeShowReorderPagesModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content"
                    style="text-align: left; max-height: 600px; overflow-y: auto; padding: 20px;">



                    <draggable v-model="templateStore.templatePages" item-key="id" handle=".handle" :animation="600">



                        <template #item="{ element }">

                            <div class="pageItem">
                                <i class="bi bi-arrows-move handle"
                                    style="font-size: 15px; margin-right: 6px; cursor: pointer;"></i> {{ element.title
                                }}
                            </div>
                        </template>





                    </draggable>




                </div>
            </div>
        </div>



        <div v-if="templateStore.showRangeSelectorModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">

                    <div style="font-size: 17px">
                        <b>Slider</b>
                    </div>


                    <div>
                        <i @click="templateStore.closeShowRangeSelectorModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div style="text-align: left;">
                    You can define the range of response with the slider.
                </div>

                <div class="modal-content"
                    style="text-align: left; max-height: 600px; overflow-y: auto; padding: 20px;">


                    <div class="rangeBoxesWrapper">




                        <div class="form-floating">

                            <input v-model="templateStore.currentElement.min_range" type="number" class="form-control"
                                id="floatingInputValue" placeholder="name@example.com" value="1">
                            <label for="floatingInputValue">Min</label>

                        </div>


                        <div class="form-floating">

                            <input v-model="templateStore.currentElement.max_range" type="number" class="form-control"
                                id="floatingInputValue" placeholder="name@example.com" value="10">
                            <label for="floatingInputValue">Max</label>

                        </div>

                        <div class="form-floating">

                            <input v-model="templateStore.currentElement.default_range_value" type="number"
                                class="form-control" id="floatingInputValue" placeholder="name@example.com" value="1">
                            <label for="floatingInputValue">Default Value</label>

                        </div>



                    </div>



                </div>
            </div>
        </div>

        <div v-if="templateStore.showSavedOptionsModal" class="modal-overlay">

            <div class="modal-box" style="width: 900px">

                <div class="model-box-header">

                    <div style="font-size: 17px">
                        <b>Saved Options</b>
                    </div>


                    <div>
                        <i @click="templateStore.closeShowSavedOptionsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>



                <div class="modal-content" style="text-align: left; height: 500px; overflow-y: auto;">


                    <div class="outerMainCont">

                        <div class="optionsContainer" v-if="templateStore.showOptionEditMode !== true">

                            <div class="theOptionItem" v-for="(option, optionIndex) in templateStore.allOptions"
                                :key="optionIndex">

                                <div class="theRow"
                                    v-if="option.template_saved_option_elements && option.template_saved_option_elements.length > 0">

                                    <span class="badge rounded-pill savedOptionItem"
                                        v-for="(optionElement, index) in option.template_saved_option_elements"
                                        :key="index" :style="{ backgroundColor: optionElement.color_code }">

                                        {{ optionElement.option_text }}
                                    </span>



                                </div>

                                <div v-if="option.created_by_admin !== 'yes'" class="editOptionWrapper"
                                    style="padding-left: 20px;">

                                    <button class="theBtn1" style="border-radius: 6px; width: 100%;"
                                        @click="() => { templateStore.setCurrentOptionElement(option); templateStore.showEditOptionArea(); }">Edit</button>

                                </div>

                                <div v-if="option.created_by_admin !== 'yes'" class="editOptionWrapper">

                                    <button @click="templateStore.deleteThisSavedOption(option, optionIndex)"
                                        class="theBtn1" style="border-radius: 6px; width: 100%;">Delete</button>

                                </div>






                                <div v-if="templateStore.currentElement.response_type === 'dropdown'"
                                    class="useOptionWrapper">

                                    <button v-if="templateStore.currentElement.is_a_section_element === 'no'"
                                        class="theBtn2" style="border-radius: 6px; width: 100%;"
                                        @click="templateStore.replaceSavedOption(option)">Use</button>

                                    <button v-if="templateStore.currentElement.is_a_section_element === 'yes'"
                                        class="theBtn2" style="border-radius: 6px; width: 100%;"
                                        @click="templateStore.sectionReplaceSavedOption(option)">Use</button>


                                </div>

                                <div v-if="templateStore.currentElement.response_type === 'multiple_choice'"
                                    class="useOptionWrapper">

                                    <button v-if="templateStore.currentElement.is_a_section_element === 'no'"
                                        class="theBtn2" style="border-radius: 6px; width: 100%;"
                                        @click="templateStore.replaceWithSavedChoices(option)">Use</button>


                                    <button v-if="templateStore.currentElement.is_a_section_element === 'yes'"
                                        class="theBtn2" style="border-radius: 6px; width: 100%;"
                                        @click="templateStore.sectionReplaceWithSavedChoices(option)">Use</button>



                                </div>







                            </div>


                        </div>

                        <div v-else>

                            <div class="upperSection">

                                <div style="margin-right: 10px; cursor: pointer;"
                                    @click="templateStore.hideEditOptionArea">
                                    <i class="bi bi-arrow-left" style="font-size: 20px;"></i>
                                </div>

                                <div>
                                    <button @click="templateStore.saveTheSavedOptionsChanges()"
                                        class="saveChangesBtn">Save
                                        Changes</button>
                                </div>

                            </div>

                            <!-- {{ templateStore.currentOption }} -->

                            <div class="lowerSection">





                                <div class="saveItemWrapper"
                                    v-for="(optionElement, index) in templateStore.currentOption.template_saved_option_elements"
                                    :key="index">



                                    <div class="itemDeleteBox">

                                        <i v-if="index !== 0" @click="templateStore.deleteASavedOptionElement(index)"
                                            class="bi bi-trash" style="cursor: pointer;"></i>

                                    </div>

                                    <div class="itemInputFieldBox">

                                        <input v-model="optionElement.option_text" class="form-control" type="text"
                                            placeholder="Enter option">

                                    </div>

                                    <div class="itemColorBox">

                                        <div class="theColorBox" @click="templateStore.toggleColorPopup(optionElement)"
                                            :style="{ backgroundColor: optionElement.color_code }">

                                        </div>

                                        <div class="colorPopup" v-if="optionElement.show_color_popup === 1">




                                            <div class="circleItem" v-for="(color, index) in templateStore.colorArray"
                                                :key="index" :style="{ backgroundColor: color.hex_code }"
                                                @click="templateStore.setDropdowColor(optionElement, color)">


                                            </div>


                                        </div>

                                    </div>

                                    <div class="myFlagBox">

                                        <div class="theSavedFlagBox">


                                            <span style="margin-right: 5px; font-size: 12px;">Flag
                                                Answer</span>




                                            <div>


                                                <div @click="templateStore.toggleIsFlagged(optionElement)">

                                                    <input v-if="optionElement.is_flagged" class="myCheckBox"
                                                        type="checkbox" checked>

                                                    <input v-else class="myCheckBox" type="checkbox">




                                                </div>


                                            </div>




                                        </div>

                                    </div>


                                    <div class="itemScoreBox">

                                        Score:
                                        <input v-model="optionElement.weight_value" type="number" class="form-control"
                                            style="margin-left: 5px;">

                                    </div>






                                </div>


                                <div class="newOptionBox" @click="templateStore.addNewSaveOption">
                                    + Add New Option
                                </div>


                            </div>






                        </div>





                    </div>




                </div>
            </div>
        </div>


        <div v-if="templateStore.showAutomationsModal" class="modal-overlay">

            <div class="modal-box" style="width: 800px">

                <div class="model-box-header">

                    <div style="font-size: 17px">
                        <b>Logic / Automation Builder</b>
                    </div>


                    <div>

                        <button v-if="templateStore.currentElement.is_a_section_element === 'no'"
                            @click="templateStore.saveNewAutomation" class="btn saveAutoBtn">Save</button>

                        <button v-if="templateStore.currentElement.is_a_section_element === 'yes'"
                            @click="templateStore.pageSectionSaveNewAutomation" class="btn saveAutoBtn">Save</button>

                        <i @click="templateStore.closeAutomationsModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>



                <div class="modal-content theLogicCont"
                    v-if="templateStore.currentElement && templateStore.currentElement.template_question">


                    <div class="logicInnerBox">

                        <div class="questinLogicBox">

                            <div class="theQuest">
                                {{ templateStore.currentElement.template_question.question_text }}
                            </div>

                            <div class="theAnsList">


                                <div v-if="templateStore.currentElement.response_type === 'multiple_choice'">

                                    <div class="theAnsItem"
                                        v-for="(choice, index) in templateStore.currentElement.multiple_choice_options"
                                        :key=index :style="{ border: '1px solid ' + choice.color_code }">

                                        {{ choice.option_text }}

                                    </div>

                                </div>

                                <div v-if="templateStore.currentElement.response_type === 'dropdown'">

                                    <div class="theAnsItem"
                                        v-for="(dropdownOption, index) in templateStore.currentElement.dropdown_options"
                                        :key=index :style="{ border: '1px solid ' + dropdownOption.color_code }">

                                        {{ dropdownOption.option_text }}

                                    </div>

                                </div>




                            </div>


                        </div>




                        <div>


                            <div class="theIfAnswerBlock" v-if="templateStore.currentElement.response_type === 'multiple_choice' ||
                        templateStore.currentElement.response_type === 'dropdown'">

                                <div class="ifAnsTextBox">
                                    If
                                </div>

                                <div class="theAnswerSelectBox">

                                    <select class="form-select"
                                        v-if="templateStore.currentElement.response_type === 'multiple_choice'"
                                        v-model="templateStore.newAutomation.selected_choice_id">

                                        <option :value="null" disabled selected>Select a choice</option>

                                        <option
                                            v-for="(choice, index) in templateStore.currentElement.multiple_choice_options"
                                            :key="index" :value="choice.id">
                                            {{ choice.option_text }}
                                        </option>

                                    </select>

                                    <select class="form-select"
                                        v-if="templateStore.currentElement.response_type === 'dropdown'"
                                        v-model="templateStore.newAutomation.template_dropdown_option_id">

                                        <option
                                            v-for="(dropdownOption, index) in templateStore.currentElement.dropdown_options"
                                            :key="index" :value="dropdownOption.id">
                                            {{ dropdownOption.option_text }}
                                        </option>

                                    </select>

                                </div>

                                <div class="theConditionSelectBox">

                                    <select v-if="templateStore.currentElement.is_multiple_answer_selection === 'no'"
                                        class="form-select" v-model="templateStore.newAutomation.logic_condition">



                                        <option v-for="(condition, index) in templateStore.singleChoiceLogicConditions"
                                            :key="index" :value="condition.type">
                                            {{ condition.name }}
                                        </option>


                                    </select>

                                    <select v-else class="form-select"
                                        v-model="templateStore.newAutomation.logic_condition">



                                        <option
                                            v-for="(condition, index) in templateStore.multipleChoiceLogicConditions"
                                            :key="index" :value="condition.type">
                                            {{ condition.name }}
                                        </option>


                                    </select>






                                </div>

                            </div>

                            <div v-else-if="templateStore.currentElement.response_type === 'checkbox'"
                                class="theIfAnswerBlock">


                                <div class="ifAnsTextBox">
                                    If Answer
                                </div>

                                <div class="theConditionSelectBox">



                                    <div>

                                        <select class="form-select"
                                            v-model="templateStore.newAutomation.logic_condition">

                                            <option v-for="(condition, index) in templateStore.checkboxLogicConditions"
                                                :key="index" :value="condition.type">
                                                {{ condition.name }}
                                            </option>


                                        </select>


                                    </div>



                                </div>

                                <div class="theValueBox">



                                    <select class="form-control" v-model="templateStore.newAutomation.string_value">

                                        <option v-for="(checkOption, index) in templateStore.checkboxOptions"
                                            :key="index" :value="checkOption.type">
                                            {{ checkOption.name }}
                                        </option>

                                    </select>






                                </div>



                            </div>

                            <div class="theIfAnswerBlock" v-else>

                                <div class="ifAnsTextBox">
                                    If Answer
                                </div>

                                <div class="theConditionSelectBox">


                                    <div
                                        v-if="templateStore.currentElement.response_type === 'number' || templateStore.currentElement.response_type === 'slider'">

                                        <select class="form-select"
                                            v-model="templateStore.newAutomation.logic_condition">

                                            <option v-for="(condition, index) in templateStore.numberLogicConditions"
                                                :key="index" :value="condition.type">
                                                {{ condition.name }}
                                            </option>


                                        </select>


                                    </div>


                                    <div v-else>

                                        <select class="form-select"
                                            v-model="templateStore.newAutomation.logic_condition">

                                            <option v-for="(condition, index) in templateStore.logicConditions"
                                                :key="index" :value="condition.type">
                                                {{ condition.name }}
                                            </option>


                                        </select>


                                    </div>



                                </div>



                                <div class="theValueBox">

                                    <input v-if="templateStore.currentElement.response_type === 'text_answer'"
                                        v-model="templateStore.newAutomation.string_value" class="form-control"
                                        type="text" style="width: 100%;">

                                    <input v-if="templateStore.currentElement.response_type === 'number'"
                                        v-model="templateStore.newAutomation.number_value" class="form-control"
                                        type="number" style="width: 100%;">


                                    <div v-if="templateStore.currentElement.response_type === 'checkbox'">

                                        <select class="form-control" v-model="templateStore.newAutomation.string_value">

                                            <option v-for="(checkOption, index) in templateStore.checkboxOptions"
                                                :key="index" :value="checkOption.type">
                                                {{ checkOption.name }}
                                            </option>

                                        </select>

                                    </div>

                                    <input v-if="templateStore.currentElement.response_type === 'slider'"
                                        v-model="templateStore.newAutomation.number_value" class="form-control"
                                        type="number" style="width: 100%;">


                                </div>

                            </div>





                        </div>

                        <div class="theVerticalLineBox">

                            <div class="theLine"></div>

                        </div>

                        <div class="theThenBoxWrapper">

                            <div class="theTheneBox">

                                THEN

                            </div>

                        </div>

                        <div class="theVerticalLineBox">

                            <div class="theLine"></div>

                        </div>

                        <div>


                            <div class="theThenBoxWrapper" v-if="templateStore.newAutomation.trigger_type === null">

                                <div class="theTriggerBox" @click="templateStore.openTriggerBox">

                                    + Trigger

                                </div>


                                <div class="triggerListBox" v-if="templateStore.showTriggerBox">

                                    <div class="triggerItem"
                                        v-for="(triggerItem, index) in templateStore.triggerOptions" :key=index
                                        @click="templateStore.setTriggerType(triggerItem)">
                                        <i :class="triggerItem.icon_name" style="margin-right: 5px;"></i>
                                        {{ triggerItem.name }}
                                    </div>

                                </div>

                            </div>

                            <div v-else class="theThenBoxWrapper">

                                <div class="triggerBoxSelected">

                                    <div>

                                    </div>

                                    <div>

                                        <div v-for="(triggerItem, index) in templateStore.triggerOptions" :key=index>

                                            <div v-if="triggerItem.type === templateStore.newAutomation.trigger_type">

                                                <i :class="triggerItem.icon_name" style="margin-right: 5px;"></i>

                                                {{ triggerItem.name }}

                                            </div>



                                        </div>

                                        <!-- {{ templateStore.newAutomation.trigger_type }} -->
                                    </div>

                                    <div style="padding-right: 10px;">
                                        <i class="bi bi-x-lg" style="font-size: 20px; cursor: pointer;"></i>
                                    </div>


                                </div>

                            </div>






                        </div>



                    </div>





                </div>


            </div>


        </div>

        <div v-if="templateStore.showEditAutomationModal" class="modal-overlay">

            <div class="modal-box" style="width: 800px">

                <div class="model-box-header">

                    <div style="font-size: 17px">
                        <b>Logic / Automation Builder</b>
                    </div>


                    <div>

                        <button @click="templateStore.saveUpdatedAutomation" class="btn saveAutoBtn">Save</button>

                        <i @click="templateStore.closeEditAutomationModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>



                <div class="modal-content theLogicCont"
                    v-if="templateStore.currentElement && templateStore.currentElement.template_question">




                    <div class="logicInnerBox">

                        <div class="questinLogicBox">

                            <div class="theQuest">
                                {{ templateStore.currentElement.template_question.question_text }}
                            </div>

                            <div class="theAnsList">


                                <div v-if="templateStore.currentElement.response_type === 'multiple_choice'">

                                    <div class="theAnsItem"
                                        v-for="(choice, index) in templateStore.currentElement.multiple_choice_options"
                                        :key=index :style="{ border: '1px solid ' + choice.color_code }">

                                        {{ choice.option_text }}

                                    </div>

                                </div>

                                <div v-if="templateStore.currentElement.response_type === 'dropdown'">

                                    <div class="theAnsItem"
                                        v-for="(dropdownOption, index) in templateStore.currentElement.dropdown_options"
                                        :key=index :style="{ border: '1px solid ' + dropdownOption.color_code }">

                                        {{ dropdownOption.option_text }}

                                    </div>

                                </div>




                            </div>


                        </div>



                        <div>


                            <div class="theIfAnswerBlock" v-if="templateStore.currentElement.response_type === 'multiple_choice' ||
                        templateStore.currentElement.response_type === 'dropdown'
                        ">

                                <div class="ifAnsTextBox">
                                    If
                                </div>

                                <div class="theAnswerSelectBox">

                                    <select class="form-select"
                                        v-if="templateStore.currentElement.response_type === 'multiple_choice'"
                                        @change="templateStore.onChangeUpdateChoice"
                                        v-model="templateStore.editAutomationObject.selected_choice_id">


                                        <option
                                            v-for="(choice, index) in templateStore.currentElement.multiple_choice_options"
                                            :key="index" :value="choice.id">
                                            {{ choice.option_text }}
                                        </option>

                                    </select>

                                    <select class="form-select"
                                        v-if="templateStore.currentElement.response_type === 'dropdown'"
                                        v-model="templateStore.editAutomationObject.template_dropdown_option_id">

                                        <option
                                            v-for="(dropdownOption, index) in templateStore.currentElement.dropdown_options"
                                            :key="index" :value="dropdownOption.id">
                                            {{ dropdownOption.option_text }}
                                        </option>

                                    </select>

                                </div>

                                <div class="theConditionSelectBox">



                                    <select v-if="templateStore.currentElement.is_multiple_answer_selection === 'no'"
                                        class="form-select"
                                        v-model="templateStore.editAutomationObject.logic_condition">

                                        <option v-for="(condition, index) in templateStore.singleChoiceLogicConditions"
                                            :key="index" :value="condition.type">
                                            {{ condition.name }}
                                        </option>


                                    </select>

                                    <select v-else class="form-select"
                                        v-model="templateStore.editAutomationObject.logic_condition">

                                        <option
                                            v-for="(condition, index) in templateStore.multipleChoiceLogicConditions"
                                            :key="index" :value="condition.type">
                                            {{ condition.name }}
                                        </option>


                                    </select>






                                </div>

                            </div>

                            <div class="theIfAnswerBlock"
                                v-else-if="templateStore.currentElement.response_type === 'checkbox'">

                                <div class="ifAnsTextBox">
                                    If Answer
                                </div>

                                <div class="theConditionSelectBox">


                                    <div>

                                        <select class="form-select"
                                            v-model="templateStore.editAutomationObject.logic_condition">

                                            <option v-for="(condition, index) in templateStore.checkboxLogicConditions"
                                                :key="index" :value="condition.type">
                                                {{ condition.name }}
                                            </option>


                                        </select>


                                    </div>



                                </div>



                                <div class="theValueBox">



                                    <select class="form-control"
                                        v-model="templateStore.editAutomationObject.string_value">

                                        <option v-for="(checkOption, index) in templateStore.checkboxOptions"
                                            :key="index" :value="checkOption.type">
                                            {{ checkOption.name }}
                                        </option>

                                    </select>




                                </div>

                            </div>


                            <div class="theIfAnswerBlock" v-else>

                                <div class="ifAnsTextBox">
                                    If Answer
                                </div>

                                <div class="theConditionSelectBox">


                                    <div>

                                        <select class="form-select"
                                            v-model="templateStore.editAutomationObject.logic_condition"
                                            v-if="templateStore.currentElement.response_type === 'number' || templateStore.currentElement.response_type === 'slider'">

                                            <option v-for="(condition, index) in templateStore.numberLogicConditions"
                                                :key="index" :value="condition.type">
                                                {{ condition.name }}
                                            </option>


                                        </select>

                                        <select v-else class="form-select"
                                            v-model="templateStore.editAutomationObject.logic_condition">

                                            <option v-for="(condition, index) in templateStore.logicConditions"
                                                :key="index" :value="condition.type">
                                                {{ condition.name }}
                                            </option>


                                        </select>


                                    </div>



                                </div>



                                <div class="theValueBox">

                                    <input v-if="templateStore.currentElement.response_type === 'text_answer'"
                                        v-model="templateStore.editAutomationObject.string_value" class="form-control"
                                        type="text" style="width: 100%;">

                                    <input
                                        v-if="templateStore.currentElement.response_type === 'number' || templateStore.currentElement.response_type === 'slider'"
                                        v-model="templateStore.editAutomationObject.number_value" class="form-control"
                                        type="number" style="width: 100%;">


                                    <div v-if="templateStore.currentElement.response_type === 'checkbox'">

                                        <select class="form-control"
                                            v-model="templateStore.editAutomationObject.string_value">

                                            <option v-for="(checkOption, index) in templateStore.checkboxOptions"
                                                :key="index" :value="checkOption.type">
                                                {{ checkOption.name }}
                                            </option>

                                        </select>

                                    </div>


                                </div>

                            </div>


                        </div>



                        <div class="theVerticalLineBox">

                            <div class="theLine"></div>

                        </div>

                        <div class="theThenBoxWrapper">

                            <div class="theTheneBox">

                                THEN

                            </div>

                        </div>

                        <div class="theVerticalLineBox">

                            <div class="theLine"></div>

                        </div>

                        <div>




                            <div class="theThenBoxWrapper">

                                <div class="triggerBoxSelected">

                                    <div>

                                    </div>

                                    <div>

                                        <div v-for="(triggerItem, index) in templateStore.triggerOptions" :key=index>

                                            <div
                                                v-if="triggerItem.type === templateStore.editAutomationObject.trigger_type">

                                                <i :class="triggerItem.icon_name" style="margin-right: 5px;"></i>

                                                {{triggerItem.name}}

                                            </div>



                                        </div>

                                    </div>

                                    <div style="padding-right: 10px;">
                                        <i class="bi bi-x-lg" style="font-size: 20px; cursor: pointer;"
                                            @click="templateStore.deleteAutomation(templateStore.editAutomationObject)"></i>
                                    </div>


                                </div>

                            </div>






                        </div>



                    </div>





                </div>


            </div>


        </div>

        <div v-if="templateStore.showColorBoxModal" class="modal-overlay">

            <div class="modal-box" style="width: auto;">

                <div class="model-box-header">

                    <div style="font-size: 17px">
                        <b>Color Box</b>
                    </div>


                    <div>
                        <i @click="templateStore.closeColorBoxModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                 <div class="colorPopup">


                        <div class="circleItem" v-for="(color, index) in templateStore.colorArray" :key="index"
                            :style="{ backgroundColor: color.hex_code }"
                            @click="templateStore.setChoiceColor(color)">


                        </div>


                    </div>


                <!-- <div class="modal-content"
                    style="padding: 20px;">



                   



                </div> -->


            </div>
        </div>



    </div>
</template>

<script setup>

import { onMounted, defineProps, watch, ref } from 'vue';
import { useTemplateStore } from "@/stores/TemplateStore";
import draggable from "vuedraggable";
// import { GoogleMap, Marker } from 'vue3-google-map'
import { Loader } from '@googlemaps/js-api-loader';


// const position = { lat: 18.5115899 , lng: -77.8791998 }




const templateStore = useTemplateStore();

const props = defineProps({
    random_string: String
})


const signaturePad = ref(null);
const theSignInput = ref(null);


const saveDigiSignature = () => {

    const { data } = signaturePad.value.saveSignature();
    // console.log(isEmpty);
    // console.log(data);

    templateStore.setSignatureImg(data)

};


const clearDigiSignature = () => {
    signaturePad.value.clearSignature(); // Clear the signature pad
};


const saveTextSignAsImage = () => {

    // Create a canvas element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas dimensions to match the text input box
    // You might need to adjust these values based on your layout
    canvas.width = 400; // Adjust width as needed
    canvas.height = 100; // Adjust height as needed

    // Style the canvas to match the background of the text input box
    ctx.fillStyle = '#f4f4f4'; // Match the background color of the text input box
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Set the font properties to match the text input box
    ctx.font = '25px cursive'; // Adjust font size and family as needed
    ctx.fillStyle = 'black'; // Set the text color

    // Center the text horizontally and vertically
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Get the text from the input box
    const text = theSignInput.value.value;

    // Draw the text onto the canvas
    // The x and y coordinates are set to the center of the canvas
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);

    // Convert the canvas to a base64 image
    const base64Image = canvas.toDataURL('image/png');

    // // Log the base64 image
    // console.log(base64Image);

    templateStore.setSignatureImg(base64Image)



}


// const locationDetails = ref(null);

// const getGeoCoordinates = () => {
//  if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition((position) => {
//       const latitude = position.coords.latitude;
//       const longitude = position.coords.longitude;

//       // Make an API call to Google Maps Geocoding API
//       fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCpDlAFp1SQFtPfDoDgEAgUt7IQq-EqNxU`)
//         .then(response => response.json())
//         .then(data => {
//           if (data.status === 'OK') {
//             const address = data.results[0].formatted_address;
//             locationDetails.value = address;

//             // Render the Google Maps embed on the page
//             // This part is more about how you handle the rendering in your Vue component
//             // You might want to use a ref to bind the embed URL to an iframe or similar
//           } else {
//             console.error('Geocoding failed:', data.status);
//           }
//         })
//         .catch(error => console.error('Error fetching geocoding data:', error));

//     }, (err) => {
//       alert(err.message);
//     });
//  } else {
//     console.log('Geolocation is not supported by your browser');
//  }
// };


// const mapContainer = ref(null);

// eslint-disable-next-line
const triggerGoogleMap = async () => {


    templateStore.getGeoCoordinates();

    //to avid errors just check make sure currentGeoPosition is populated 
    if (templateStore.currentGeoPosition.latitude !== null && templateStore.currentGeoPosition.longitude !== null) {

        const loader = new Loader({
            apiKey: 'AIzaSyCpDlAFp1SQFtPfDoDgEAgUt7IQq-EqNxU',
            version: 'weekly',
        });

        await loader.load();


        const mapContainer = document.getElementById("mapContainer" + templateStore.currentElement.id);


        let currentPosition = {
            lat: templateStore.currentGeoPosition.latitude,
            lng: templateStore.currentGeoPosition.longitude
        }

        // eslint-disable-next-line
        const map = new google.maps.Map(mapContainer, {
            center: currentPosition,
            zoom: 15,
            mapTypeControl: false, // Disable the Map and Satellite options
            streetViewControl: false, // Disable the Pegman control,
            fullscreenControl: false,
        });

        // Add a marker to the map
        // eslint-disable-next-line
        const marker = new google.maps.Marker({
            position: currentPosition,
            map: map,
            title: 'Your Location',
        });


        // Get the address from the latitude and longitude
        const address = await getAddressFromLatLng(currentPosition.lat, currentPosition.lng);
        console.log(address); // Log the address to the console


        //populate the current element with address, lat and long to save in db
        templateStore.currentElement.latitude = currentPosition.lat
        templateStore.currentElement.longitude = currentPosition.lng
        templateStore.currentElement.formatted_address = address


    }




};


const getAddressFromLatLng = async (lat, lng) => {

    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCpDlAFp1SQFtPfDoDgEAgUt7IQq-EqNxU`);

    const data = await response.json();

    if (data.status === 'OK') {
        return data.results[0].formatted_address;
    } else {
        console.error('Geocoding failed:', data.status);
        return null;
    }
};



const loadMap = async () => {


    await triggerGoogleMap();


};



onMounted(async () => {




});





watch(
    () => templateStore.currentElement,
    () => {

        // templateStore.debouncedSaveChanges()

    },
    { deep: true }
);








onMounted(() => {

    templateStore.fetchaATemplatePagesForEditor(props.random_string)

})




</script>

<style scoped>
.outer {
    /* background-color: rgb(157, 194, 225); */
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.topNavBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: red; */
    height: 80px;
    border-bottom: 1px solid #e2e3e3;
    padding: 13px;
}

.controlsNavBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #e2e3e3;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.builderArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: blue; */
    height: 100%;
}


.formArea {
    width: 80%;
    padding: 35px;
    /* background-color: green; */
    overflow-y: auto;
    border-right: 1px solid #e2e3e3;
    padding-bottom: 200px;
}

.mobilePreviewArea {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 30%;
    /* background-color: rgb(195, 211, 195); */
}

.tabsWrapper {
    display: flex;
    background-color: rgb(211, 22, 22);
    width: 450px;
    height: 50px;
}

.tabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fdfdfd;
    height: 50px;
    border: 1px solid #c3d4cb;
    cursor: pointer;
}

.tabItem:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    height: 50px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
}

.tabItemSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    height: 50px;
    border: 1.5px solid #c3d4cb;
    cursor: pointer;
}


.button-13 {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.button-13:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}


.button-14 {
    background-color: white;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 12px;

    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 80px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.button-14:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 20px;
}

.sectionWrapper {
    display: flex;
    flex-direction: column;
    /* background-color: #c3d4cb; */
    width: 95%;
    height: auto;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */

}

.sectionHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* height: 30px; */
    /* padding: 20px; */
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 16px;
}


.sectionElement {
    width: 100%;
    border-bottom: 1px solid #e2e3e3;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: antiquewhite; */
    /* padding: 6px; */
}


.elementItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 18px;
    border: 1px solid rgb(239, 237, 237);
}

.sectionQuestionElementItem {


    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 18px;
    border: 1px solid rgb(239, 237, 237);
    /* background-color: red; */

}

.titleInput {
    border: 1px solid rgb(197, 197, 197);
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;


}

.dropdown-item:hover {
    display: flex;
    align-items: center;
    background-color: rgb(217, 234, 222);
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;

}

.dropdownItemSelected {
    display: flex;
    align-items: center;
    background-color: rgb(217, 234, 222);
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
}

.pageHeader {
    display: flex;
    justify-content: space-between;
    /* font-size: 22px; */
    font-weight: bold;
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid rgb(213, 217, 217);
    /* background-color: gray; */
}

.pageSection {
    width: 100%;
    background-color: rgb(219, 219, 210);
    /* padding: 15px; */
}

.questionOnlyWrapper {
    width: 100%;
    /* border-bottom: 1px solid #e2e3e3; */
    /* padding-top: 10px;
     padding-bottom: 10px; */
    /* background-color: green; */
    /* padding: 15px; */
}

.sectionOuter {
    display: flex;
    justify-content: flex-end;
    /* flex-direction: column; */
    width: 100%;
    /* background-color: red; */

}


.buttonOptionsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: rgb(224, 224, 224); */
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.leftSide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    /* background-color: yellow; */
}


.rightSide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    /* background-color: rgb(12, 201, 75); */
}

.checkBoxWrapper {
    display: flex;

    font-size: 13px;
    /* width: 100px; */
    margin-right: 15px;
    /* background-color: antiquewhite; */
}

.boxItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 11px;
    background-color: white;
    margin-right: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    padding: 6px;
    /* border-radius: 5px; */
    color: rgb(23, 50, 4);
    text-decoration: underline;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
}

.deleteIcon {
    cursor: pointer;
}

.moreSection {
    cursor: pointer;
}

.topBtns {
    width: 100%;
    /* background-color: gray; */
}

.headerLeft {
    display: flex;
    align-items: center;
    /* background-color: gray; */
}

.thePageTitle {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    /* width: 100%; */

}

.headerRight {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* background-color: gray; */

}


.editTitleBox {
    display: flex;
    /* background-color: gray; */
}

.doneBtn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.sectionLeft {
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 17px;
    font-weight: bold;
    padding-left: 20px;
}


.sectionTitleBox {
    display: flex;
    /* background-color: gray; */
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.pageItem {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: bold;

}



.dragBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    /* background-color: gray; */
}



.phoneContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(177, 219, 139); */
    height: 700px;
}



.phoneImg {
    width: 320px;
    z-index: 1;
}


.phoneContentWrapper {
    background-color: rgb(232, 219, 219);
    width: 279px;
    height: 550px;
    position: absolute;
    z-index: 2;
}

.mobileTopBox {
    width: 280px;
    /* background-color: yellow; */
    height: auto;
    border-bottom: 1px solid #e2e3e3;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px;
    cursor: pointer;
    z-index: 1;
    position: absolute;

}

.mobileTopBox:hover {
    background-color: rgb(243, 248, 238);
}

.scrollableArea {
    background-color: white;
    height: 480px;
    overflow-y: auto;
    padding: 15px;
    margin-top: 70px;

}


.topRow {
    display: flex;
    justify-content: space-between;
    /* background-color: gray; */
    padding: 10px;
    font-size: 11px;

}

.bottomRow {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.scrollItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(241, 235, 235); */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-top: 10px;
    margin-bottom: 12px;
}

.sectionScrollItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 10px;
    padding: 15px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-top: 10px;
    margin-bottom: 12px;
    cursor: pointer;
}


.questionTextArea {
    /* background-color: yellow; */
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.inputElementArea {
    /* background-color: rgb(110, 110, 78); */
    width: 100%;
    margin-bottom: 10px;
}


.bottomOptionsArea {
    display: flex;
    flex-direction: column;
    /* background-color: rgb(138, 177, 229); */
    width: 100%;
    justify-content: space-between;
}

.notaArea {
    font-size: 13px;
    cursor: pointer;
}

.mediaArea {
    font-size: 13px;
    cursor: pointer;
}

.actionArea {
    font-size: 13px;
    cursor: pointer;
}


.showPagesBox {
    width: 100%;
    background-color: white;
    z-index: 2;
    position: absolute;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.pageNavItem {
    /* background-color: gray; */
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
}

.pageNavItem:hover {
    background-color: rgb(221, 237, 215);
    /* background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); */
}

.checkyBox {
    display: flex;
    /* background-color: #c3d4cb; */
    padding-top: 5px;
    padding-bottom: 18px;
    text-align: left;
}


.theCheckBox {
    border: 2px solid #535353;
    margin-right: 10px;
    cursor: pointer;
}

.dateTimeArea {
    display: flex;
    flex-direction: column;
    text-align: left;
}


.mediaBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: black;
    color: white;
    padding: 7px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    font-size: 13px;

}

.theMediaArea {
    display: flex;
    flex-direction: column;
    text-align: left;
}


.filesArea {
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    width: 100%;
    /* background-color: #c3d4cb; */
    margin-bottom: 9px;

}

.fileItem {
    background-color: rgb(237, 167, 167);
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    flex: 0 1 calc(33.333% - 10px);
    margin: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;

}


.buttonsArea {
    display: flex;
    justify-content: space-between;


}


.imgFile {
    width: 100%;
}


.theImg {

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.theVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.loadingState {
    display: flex;
    justify-content: center;
    width: 100%;

}

.pdfsArea {
    width: 100%;
    /* background-color: yellow; */
    /* margin-top: 15px; */
    margin-bottom: 10px;
}


.pdfItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    text-align: left;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* margin-bottom: 9px; */


}


.thePDFname {
    font-weight: bold;
    font-size: 13px;
    word-break: break-all;
    margin-right: 10px;
}

.thePDFname:hover {
    color: green;
    text-decoration: underline;
}

.theTrash {
    cursor: pointer;
}


.mobile-modal-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    color: white;
}

.topModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    height: 10%;

}


.galleryConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90%;
    /* background-color: #f1fbf6; */
}


.middleGallery {
    display: flex;
    align-items: center;
    /* background-color: rgb(185, 185, 185); */
    height: 450px;
    padding: 10px;
    overflow-y: auto;
}


.imgInGallery {
    width: 100%;
    height: auto;
    border-radius: 10px;
}


.galleryVideo {
    width: 100%;
    border-radius: 10px;
}

.editNote {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: #535353; */
    text-align: left;
}

.noteBtnsArea {
    display: flex;
    margin-bottom: 20px;

}

.textyArea {
    border: 1px solid #8ad28a;
    margin-bottom: 15px;

}


.saveBtn {
    border: none;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 10px;
    margin-right: 15px;
    color: black;
    border-radius: 10px;
    font-weight: bold;
    width: 80px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.saveBtn:hover {
    background: rgb(203, 234, 205);
}

.cancelBtn {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    color: black;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
    cursor: pointer;
}

.cancelBtn:hover {
    background: rgb(252, 253, 253);
}


.theNoteWrapper {
    margin-bottom: 15px;
}

.noteView {
    text-align: left;
    padding: 7px;
    color: black;
    word-break: normal;
    cursor: pointer;
}


.noteView:hover {
    background-color: rgb(240, 246, 240);
    border-radius: 4px;
    padding: 7px;
    cursor: pointer;
}


.sliderArea {
    display: flex;
    flex-direction: column;

}

.theSilderBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: rgb(249, 240, 240); */
    /* padding: 20px; */
}


.theRange {
    width: 100%;
    height: 50px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    cursor: pointer;
}

.theRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: #4CAF50;
    cursor: pointer;
}

.theRange::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #4CAF50;
    cursor: pointer;
}


.sliderRangeArea {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color: gray; */
}


.theSide {
    font-size: 13px;
    color: #737373;
}


.rangeBox {
    font-size: 14px;
    margin-left: 15px;
    color: #2b712c;
    font-weight: bold;
    cursor: pointer;

}

.rangeBox:hover {
    text-decoration: underline;
}

.rangeBoxesWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-floating {
    margin: 10px;
}

.instructionBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: #737373; */
}

.textTop {
    text-align: left;
    margin-bottom: 18px;
}

.signatureTextTop {
    text-align: left;
    margin-bottom: 9px;
}

.instructionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* background-color: rgb(241, 233, 233); */
}


.uploadAttach {
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
}

.uploadAttach:hover {
    color: #2b712c;
    text-decoration: underline;
}

.attachCont {
    display: flex;
    align-items: center;
    width: 100%;
    /* background-color: #535353 */
}


.attachFileCont {
    display: flex;
    align-items: center;
    width: 100%;
    /* background-color: yellow; */
    margin-bottom: 20px;
    margin-top: 20px
}

.imgBox {
    width: 100px;
    height: 100px;
    padding: 10px;
    /* background-color: #2b712c; */
}

.theAttachImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;

}

.pdfBox {
    display: flex;
    width: 300px;
    height: auto;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    border-radius: 8px;
    text-align: left;
    font-size: 12px;
    cursor: pointer;
    word-break: break-all;
}

.pdfBox:hover {
    text-decoration: underline;
    color: #2b712c;
    cursor: pointer;
}


.hasAttachment {
    display: flex;
    align-items: center;
    width: 100%;
}

.theNoAttachCont {

    display: flex;
    align-items: center;
    width: 100%;
}


.deleteAttachIcon {
    margin-left: 6px;
}


.fileBottom {
    width: 100%;
    /* background-color: #e0e3e0; */
}

.pdfName {
    font-size: 12px;
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    word-break: break-all;
    cursor: pointer;

}


.pdfName:hover {
    color: #2b712c;
    text-decoration: underline;
    cursor: pointer;
}

.theAttachImg {
    width: 100%;
    border-radius: 10px;
}

.optionsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: gray ; */
}


.dropdownOptionsCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    /* background-color: #120707; */
    margin-top: 20px;
    margin-bottom: 10px;
}

.theItemWrapper {
    display: flex;
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 14px;
}

.deleteBox {
    /* background-color: rgb(227, 223, 223); */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
}

.optionDragBox {
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
}

.inputFieldBox {
    /* background-color: rgb(38, 81, 254); */
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}

.flagBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: rgb(38, 254, 60); */
    width: 10%;

}


.theflagBox {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 13px;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
}

.colorBox {

    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(4, 9, 4); */
    width: 5%;
    /* position: absolute;
    z-index: 1; */
}



.myCheckBox {
    cursor: pointer;
}

.outerCont {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* background-color: #737373; */

}


.addOptionWrapper {
    display: flex;
    width: 100%;
    position: relative;
    /* background-color: #bcbcbc; */
}


.addOptionCTA {
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: bold;
}

.addOptionCTA:hover {
    text-decoration: underline;
    color: #2b712c;
}


.addFirstOption {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.addFirstOption:hover {
    color: #2b712c;
    font-weight: bold;
    text-decoration: underline;
}


.dropDownCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;


}


.parentContainer {
    position: relative;
}


.optionName {
    display: flex;
    align-items: center;
    font-size: 13px;

}


.theDropdownCont {
    position: absolute;
    top: 100%;
    /* Position it right below .dropDownCont */
    width: 100%;
    /* Match the width of .dropDownCont */
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    z-index: 2;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 5px;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;

    /* width: 230px;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    position: absolute;
    z-index: 2;
    margin-top: 5px;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto; */
}

.optionItem {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    font-size: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    /* padding-left: 10px; */
    padding-right: 10px;
    border-bottom: 1px solid #e2e3e3;
    cursor: pointer;
    word-wrap: break-word;

}

.optionItem:hover {
    background-color: #f1f3f3;
    cursor: pointer;
    border-radius: 3px;

}


.optionItemSeelected {
    background-color: #f1f3f3;
    cursor: pointer;
    border-radius: 3px;
}

.multipleAnswerToggleBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* background-color: #c3d4cb; */
    margin-bottom: 20px;
}

.choiceCont {
    display: flex;
    flex-direction: column;
}

.choiceItem {
    width: 100%;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #bcbcbc;
    margin-bottom: 8px;
    cursor: pointer;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 13px;
}

.choiceItem:hover {
    cursor: pointer;
}

.choiceItemSelected {
    width: 100%;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #bcbcbc;
    margin-bottom: 8px;
    cursor: pointer;
    background-color: red;
}

.theColorBox {
    background-color: #4CAF50;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    cursor: pointer;
    /* position: absolute;
    z-index: 1; */

}

.colorPopup {


    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 200px;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* position: absolute; */
    /* z-index: 2; */
    /* margin-top: 190px; */
    padding: 10px;



}

.circleItem {
    width: 35px;
    height: 35px;
    background-color: rgb(81, 0, 255);
    border-radius: 50%;
    margin: 5px;
    flex: 0 0 calc(25% - 10px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;

}


.dropCircle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: red;
    margin-right: 10px;
}

.dotBox {
    width: 10%;
    /* display: flex;
    align-items: center; */
    /* background-color: red; */
}

.wordsBox {
    width: 90%;
    /* background-color: #2b712c; */
}

.theCircle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 6px;
}

.outerMainCont {
    width: 100%;
    /* background-color: rgb(210, 202, 202); */
}

.optionsContainer {
    width: 100%;
    padding: 10px;
}

.theOptionItem {
    display: flex;
    /* align-items: center; */
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 10px;

    margin-bottom: 18px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    cursor: pointer;
}

.theOptionItem:hover {
    background-color: #fdfdfd;
}

.theRow {
    display: flex;
    align-items: center;
    width: 70%;
    /* background-color: #da8989; */
    padding: 5px;
    overflow-x: auto;
}


.editOptionWrapper {
    width: 15%;
    padding-right: 15px;


}

.useOptionWrapper {
    width: 15%;
}

.theBtn1,
.theBtn2 {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    font-size: 12px;
    padding: 5px;
    border: none;
}

.theBtn1:hover,
.theBtn2:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    font-weight: bold;
}

.savedOptionItem {
    font-size: 13px;
    margin-right: 7px;
}

.upperSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px;

}

.lowerSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px;
    /* background-color: #e94f4f; */

}

.saveChangesBtn {
    font-size: 12px;
    border-radius: 5px;
    border: none;
    padding: 5px;
    color: white;
    background-color: #0f1111;
    width: 110px;
}


.saveItemWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    /* background-color: rgb(226, 11, 216); */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 14px;
}


.itemDeleteBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    /* background-color: red; */
}

.itemInputFieldBox {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    /* background-color: green; */
}

.itemColorBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    /* background-color: purple; */
}

.myFlagBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    /* background-color: yellow; */
}


.itemScoreBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    /* background-color: gray; */
    font-size: 11px;
}



.theSavedFlagBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(234, 61, 9); */
    font-size: 13px;
    /* height: 100%; */
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;

}



.newOptionBox {
    width: 100%;
    /* background-color: #bcbcbc; */
    font-size: 13px;
    text-decoration: underline;
    color: #2b712c;
    font-weight: bold;
    cursor: pointer;
}


.mainScoreBox {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #dce8e8; */
    padding-left: 7px;
    width: 15%;

}


.addSignatureBtn {
    width: 100%;
    background-color: black;
    color: white;
    padding: 7px;
    border-radius: 5px;
    border: none;
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 13px;
    font-weight: bold;
}


.signatureConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90%;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: #f1fbf6; */
}

.theSignWrapper {
    width: 100%;
    height: auto;

    background-color: white;
}

.signHeader {
    display: flex;
    /* background-color: yellow; */
    height: 20%;
}

.signBody {
    display: flex;
    align-items: center;
    /* background-color: rgb(224, 153, 38); */
    padding: 5px;
    height: 90%;
    color: black;
}


.myWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.signTab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    padding: 6px;
    /* border: 1px solid black; */
    font-size: 11px;
    cursor: pointer;
    color: black;

}

.selectedSignTab {
    width: 100%;

    padding: 6px;
    /* border: 1px solid black; */
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: black;


}


.text_sign_box {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* background-color: #d41c1c; */
    width: 100%;


}


.actionBox {
    /* background-color: #8ad28a; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    color: black;
    cursor: pointer;

}

.theSignInput {
    height: 60px;
    margin-bottom: 18px;
    background-color: #f4f4f4;
    font-size: 20px;
    font-family: cursive;
    font-weight: bold;
    text-align: center;
    color: black;
    /* font-family:"Great Vibes"; */
}


.digital_sign_box {
    display: flex;
    flex-direction: column;

    /* background-color: #16eb13; */
    width: 100%;
    height: auto;
}

.canvas-container {
    width: 100%;
    height: auto;
    /* background-color: yellow; */
}

.thePad {
    width: 100%;
    height: 100px;
    background-color: white;
    border: 2px solid rgb(219, 219, 219);
    border-radius: 5px;
}

.padbuttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 3px;
    /* gap: 15px; */
}

.signActionBtn {
    width: 50%;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    color: rgb(3, 2, 2);
    border-radius: 10px;
    padding: 6px;
    margin-right: 10px;
    border: none;
    font-weight: bold;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

}

.clearActionBtn {
    width: 50%;
    background: white;
    color: rgb(3, 2, 2);
    border-radius: 10px;
    padding: 6px;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.signImgCont {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;

}

.imgSignBox {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    /* border: 1px solid black; */
}

.dateText {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 9px;

}

.theActualSignImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgb(198, 198, 198);

}


.geoStampWrapper {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 15px;
    /* background-color: #d0eb08; */
}


.theGeoTextbox {
    width: 60%;
    /* background-color: #bcbcbc; */
}

.theGeoMapBtnBox {
    width: 40%;
    /* background-color: #ed2222; */
}

.mapBtn {
    cursor: pointer;
    width: 100%;
    border: none;
    color: #040202;
    border-radius: 5px;
    font-size: 14px;
    ;
    /* background-color: #2b712c; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.locateMeBtn {
    width: 100%;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    font-size: 14px;
    ;
    /* background-color: #2b712c; */
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    padding: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.theMapInput {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}


.geoIframeBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(238, 238, 238); */
    height: 200px;

}

.mapBtnsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: gray; */
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 15px;
}

.closeMapBtn {
    width: 100%;
    border: none;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 12px;
}

.SaveLocationBtn {
    width: 100%;
    border: none;
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
}

.theSectioTab {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.nameSection {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    /* background-color: #4b6cb7; */
}

.theLogicCont {
    display: flex;
    flex-direction: column;

    height: 700px;
    overflow-y: auto;

    background-image: radial-gradient(circle, rgb(185, 190, 196) 1px, #fff 1px);
    background-size: 1rem 1rem;
    background-position: center center;



}

.logicInnerBox {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 20px;
}


.questinLogicBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;


}

.theIfAnswerBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ifAnsTextBox {
    /* background-color: #2b712c; */
    width: 100%;
    text-align: right;
    padding-right: 10px;
}

.theConditionSelectBox {
    /* background-color: #b4efb5; */
    width: 100%;
    padding-right: 10px;
}

.theAnswerSelectBox {
    /* background-color: #74a274; */
    width: 100%;
    padding-right: 10px;
}


.theVerticalLineBox {
    display: flex;
    justify-content: center;
    /* background-color: #fbf5f5; */
    height: 40px;
}

.theThenBoxWrapper {
    display: flex;
    justify-content: center;
    /* background-color: #999898; */
    height: 40px;
}

.theLine {
    width: 2.5px;
    height: 100%;
    background-color: #a6aba6;
}

.theTheneBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 13px;

}

.theTriggerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    width: 200px;
    height: 40px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

.theQuest {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
}

.theAnsList {
    /* width: 100%;
    background-color: rgb(229, 223, 223); */
}

.theAnsItem {
    width: 100%;
    background-color: white;
    padding: 4px;
    border-radius: 10px;
    border: 1.5px solid rgb(222, 222, 222);
    margin-bottom: 12px;
    padding: 5px;
}


.triggerListBox {
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: rgb(255, 255, 255);
    /* border: 1px solid black; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    margin-top: 50px;
    position: absolute;
    z-index: 2;
}

.triggerItem {
    width: 100%;
    padding: 7px;
    font-size: 13px;
    text-align: left;
    border-bottom: 1px solid rgb(234, 234, 234);
    cursor: pointer;
}

.triggerItem:hover {
    background-color: rgb(237, 237, 237);
    cursor: pointer;
}

.theValueBox {
    /* background-color: red; */
    width: 100%;
}


.saveAutoBtn {
    color: white;
    border-radius: 8px;
    margin-right: 25px;
    background-color: black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 14px;
}


.triggerBoxSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    width: 70%;
    height: 40px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

.innerAutomationBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: rgb(255, 252, 177); */
    margin-top: 10px;
    margin-bottom: 17px;

}


.theAutomatoinListBox {
    /* background-color: rgb(245, 172, 172); */
    width: 100%;
    display: flex;

}

.autoLeft {
    width: 10%;
    text-align: left;
    font-weight: bold;
    font-size: 13px;
}

.autoRight {
    width: 90%
}


.automationItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #edfdf5;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 18px;
}


.theOuterIfBlock {

    width: 100%;
    background-color: green;
}
</style>