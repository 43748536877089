<template>
    <div>
        <EmployeeMainNavBar />

        <div class="outerWrapper">

            <div class="myContainer"> 


                <div v-for="(appAccess, index) in authStore.appAccessList" :key="index">

                    <router-link v-if="appAccess.has_access === 'yes'" class="linkStyle"
                        :to="{ name: appAccess.app.sup_route_name }">

                        <div class="appItem" v-if="appAccess.app">

                            <i :class="appAccess.app.icon_name + ' myicon'"></i>

                            {{ appAccess.app.name }}

                        </div>


                    </router-link>



                    <div v-if="appAccess.has_access === 'no'" @click="authStore.showAppMarketingModal(appAccess.app)">

                        <div class="appItem" v-if="appAccess.app">

                            <i :class="appAccess.app.icon_name + ' myicon'"></i>

                            {{ appAccess.app.name }}

                        </div>


                    </div>




                </div>


            </div>


        </div>



        <div v-if="authStore.showToolboxTalkModal" class="modal-overlay">

            <div class="modal-box" style="max-width: 500px;">

                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Safety Toolbox Talks</b>
                    </div>

                    <div>
                        <i @click="authStore.closeToolboxTalkModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">

                    <div>
                        <div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;">
                            <figure
                                style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%; margin-block-end: 0; margin-block-start: 0; margin-inline-start: 0; margin-inline-end: 0;">
                                <iframe id="pv_B3m5xrop"
                                    src="https://media.publit.io/file/safelyio-toolbox-talk-app-video.html" scrolling="no"
                                    style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute; overflow:hidden;"
                                    allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
                            </figure>
                        </div>
                    </div>


                    <div class="ctaBox">
                        <b>Contact your account manager to get access!</b>
                    </div>

                </div>
            </div>
        </div>


        <div v-if="authStore.showSafetyDocsPolicyModal" class="modal-overlay">

            <div class="modal-box">

                <div class="model-box-header">
                    <div style="font-size: 19px">
                        <b>Chat With SOP PDFs</b>
                    </div>

                    <div>
                        <i @click="authStore.closeSafetyDocsPolicyModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <li style="margin-bottom: 10px;"><b>Chat with Your Documents: </b>Just ask questions and get answers
                        directly
                        from your safety documents.</li>

                    <li style="margin-bottom: 10px;"><b>AI-Powered: </b>Uses smart technology to find and explain the
                        information
                        you need fast.</li>

                    <li style="margin-bottom: 10px;"><b>Easy-to-Use: </b>Designed for simplicity, so you don't need to be a
                        tech expert.</li>



                    <div class="ctaBox">
                        <b>Contact your account manager to learn more!</b>
                    </div>


                </div>

            </div>
        </div>

        <div v-if="authStore.showSafetyDateSheetsModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">
                    <div style="font-size: 16px; text-align: left;">
                        <b>Safety Data Sheets Management Made Easy (Coming Soon)</b>
                    </div>

                    <div>
                        <i @click="authStore.openCloseSafetyDataSheetskModal" class="bi bi-x-lg"
                            style="font-size: 20px; cursor: pointer"></i>
                    </div>
                </div>

                <div class="modal-content" style="text-align: left;">


                    <li style="margin-bottom: 10px;">Have access to a central repository for all your safety data sheets
                        so you can find what you need with minimal effort.</li>

                    <li style="margin-bottom: 10px;">Have access to uploaded SDS's by document, title,
                        manufacturer, SDS, and CAS numbers.</li>


                    <div class="ctaBox">
                        <b>Contact your account manager to learn more!</b>
                    </div>

                </div>
            </div>
        </div>





    </div>
</template>
  
<script setup>
import EmployeeMainNavBar from "@/components/EmployeeMainNavBar.vue";
// import MainFooterVue from "@/components/MainFooter.vue";
import { onMounted } from "vue";
import { useAuthStore } from "@/stores/AuthStore";

const authStore = useAuthStore();






onMounted(() => {

    authStore.getASupervisorAppAccessInfo()


});


</script>
  
<style scoped>
.outerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: red; */
}

.myContainer {
    display: flex;
    /* background-color: rgb(200, 211, 211); */
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    /* height: calc(100vh - 90px - 50px); */


}

.appsWrapper {
    background-color: rgb(0, 255, 38);
}

.appsListWrapper {
    display: flex;
    width: 100%;
    background-color: #d9e7ff;
}

.appItemWrapper {
    /* background-color: bisque; */
    cursor: pointer;
    padding: 10px;
}

.appItem:hover {
    scale: 1.1;
    transition: 1s;
}

.appItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    width: 150px;
    height: 150px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-weight: bolder;
    font-size: 17px;

}

.appName {
    padding-top: 16px;
    font-size: 15px;
    font-weight: bold;
}


.myicon {
    font-size: 50px;
    color: black;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    max-width: 1100px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
    padding: 20px;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    /* height: 40px; */
    padding: 20px;
}

.ctaBox {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    padding: 10px;
}
</style>
  