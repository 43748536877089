<template>
  <div>
    <div class="mainWrapper">
      <MainSideBarVue />

      <div class="rightContentWrapper">
        <div class="topNav">
          <TopBarVue />
        </div>

        <div class="innerContainer">
          <div class="theHeaderWrapper">
            <div>Safety Campaigns</div>

            <button @click="campaignStore.openCreateCampaignModal" class="button-6" role="button">
              New Campaign +
            </button>
          </div>

          <div class="searchBarWrapper" v-if="campaignStore.allCampaigns.length > 0">
            <input v-model="searchQuery" type="text" class="form-control theBar" placeholder="Search campaigns by name..." />
          </div>


       


          <div v-if="campaignStore.allCampaigns.length > 0">

            <div class="tableWrapper">


              <table class="table table-hover my-custom-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Team</th>
                    <th scope="col">Created</th>
                    <th scope="col">Messages</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>



                <tbody>
                  <tr v-for="(campaign, index) in filteredCamp" :key="index">
                    <td>
                      <router-link class="campName" :to="{
                        name: 'ViewACampaign',
                        params: { random_string: campaign.random_string },
                      }">
                        <div>
                          <b>
                            {{ campaignStore.limitText(campaign.name) }}
                          </b>
                        </div>
                      </router-link>
                    </td>

                    <td>
                      {{ campaign.team ? campaign.team.name : '' }}
                    </td>

                    <td>{{ campaignStore.formatDate(campaign.created_at) }}</td>

                    <td>{{ campaign.scheduled_messages.length }}</td>

                    <td>
                      <div class="iconsRow">

                        <router-link class="campName" :to="{
                          name: 'ViewACampaign',
                          params: { random_string: campaign.random_string },
                        }">
                          <i class="bi bi-chat-left-dots theActionIcon"></i>
                        </router-link>


                        <i @click="() => { campaignStore.setCurrentCampaign(campaign); campaignStore.openEditCampaignModal(); }"
                          class="bi bi-pencil theActionIcon"></i>

                        <i @click="() => { campaignStore.setCurrentCampaign(campaign); campaignStore.openDeleteCampaignModal(); }"
                          class="bi bi-trash theActionIcon"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <button v-if="displayedCampCount < campaignStore.allCampaigns.length" class="btn btn-dark loadMoreBtn"
                                @click="loadMoreCamp">Load more</button>
            

          </div>

          <div v-else class="noCampBox">
            <p>No safety campaigns created yet.</p>
          </div>




        </div>
      </div>
    </div>

    <div v-if="campaignStore.showCreateCampaignModal" class="modal-overlay">
      <div class="modal-box" style="width: 550px">
        <div class="model-box-header">
          <div style="font-size: 17px">
            <b>New Campaign</b>
          </div>

          <div>
            <i @click="campaignStore.closeCreateCampaignModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content">

          <form @submit.prevent="campaignStore.storeNewCampaign">

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Name</b></label>
              <input v-model="campaignStore.newCampaignFormData.name" type="text" class="form-control inputStyle"
                placeholder="Q1 Safety Talks" required />
            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Team</b></label>

              <select v-model="campaignStore.newCampaignFormData.team_id" class="form-select custom-select" required>
                <option value="" disabled>Choose a team</option>

                <option v-for="(team, index) in campaignStore.teams" :key="index" :value="team.id">
                  {{ team.name }}
                </option>

              </select>


            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Timezone</b></label>

              <select v-model="campaignStore.newCampaignFormData.timezone_id" class="form-select custom-select" required>

                <option disabled value="">Choose a timezone</option>
                <option v-for="(timezone, index) in campaignStore.timezones" :key="index" :value="timezone.id">
                  {{ timezone.name }}
                </option>


              </select>

            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>From</b></label>
              <input v-model="campaignStore.newCampaignFormData.from_name" type="text" class="form-control inputStyle"
                placeholder="John Doe - Safety Manager" required />
            </div>

            <div>
              <button type="submit" class="btn btn-dark createBTN">
                <b>Create</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="campaignStore.showEditCampaignModal" class="modal-overlay">
      <div class="modal-box" style="width: 550px">
        <div class="model-box-header">
          <div style="font-size: 17px">
            <b>Edit Campaign</b>
          </div>

          <div>
            <i @click="campaignStore.closeEditCampaignModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content">

          <form @submit.prevent="campaignStore.updateCampaignDetails">

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Name</b></label>
              <input v-model="campaignStore.currentCampaign.name" type="text" class="form-control inputStyle"
                placeholder="Q1 Safety Talks" required />
            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Team</b></label>

              <select disabled v-model="campaignStore.currentCampaign.team_id" class="form-select custom-select"
                type="text" required>
                <option value="" disabled>Choose a team</option>

                <option v-for="(team, index) in campaignStore.teams" :key="index" :value="team.id">
                  {{ team.name }}
                </option>

              </select>


            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>Timezone</b></label>

              <select v-model="campaignStore.currentCampaign.timezone_id" class="form-select custom-select" type="text"
                required>

                <option disabled value="">Choose a timezone</option>
                <option v-for="(timezone, index) in campaignStore.timezones" :key="index" :value="timezone.id">
                  {{ timezone.name }}
                </option>


              </select>

            </div>

            <div class="mb-3">
              <label class="form-label" style="float: left"><b>From</b></label>
              <input v-model="campaignStore.currentCampaign.from_name" type="text" class="form-control inputStyle"
                placeholder="John Doe - Safety Manager" required />
            </div>

            <div>
              <button type="submit" class="btn btn-dark createBTN">
                <b>Update</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="campaignStore.showDeleteCampaignModal" class="modal-overlay">
      <div class="modal-box" style="width: 550px">
        <div class="model-box-header">
          <div style="font-size: 17px">
            <b>Are you sure you want to delete this campaign?</b>
          </div>

          <div>
            <i @click="campaignStore.closeDeleteCampaignModal" class="bi bi-x-lg"
              style="font-size: 20px; cursor: pointer"></i>
          </div>
        </div>

        <div class="modal-content" style="text-align: left;">


          <p><b style="color: red;">NOTE: </b> Deleting <b>{{ campaignStore.currentCampaign.name }}</b> campaign also
            deletes any scheduled messages related to it. You will not be able to recover any data for this campaign.</p>


          <div>
            <button @click="campaignStore.deleteCampaign" type="button" class="btn btn-danger createBTN">
              <b>Yes, Delete!</b>
            </button>
          </div>

        </div>
      </div>
    </div>




  </div>
</template>

<script setup>
import MainSideBarVue from "@/components/MainSideBar";
import TopBarVue from "@/components/TopBar.vue";
import { useCampaignStore } from "@/stores/CampaignStore";
import { useAuthStore } from "@/stores/AuthStore";
import { onMounted, ref, computed } from 'vue';

const campaignStore = useCampaignStore();
const authStore = useAuthStore();

const searchQuery = ref('');

const displayedCampCount = ref(35); // Start with displaying 5 teams
const incrementStep = 20; //


const filteredCamp = computed(() => {

    return campaignStore.allCampaigns
        .filter(camp => camp.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
        .slice(0, displayedCampCount.value);

});

const loadMoreCamp = () => {
    displayedCampCount.value += incrementStep;
};




onMounted(() => {

  campaignStore.fetchAllMyCampaigns()
  authStore.getAManagerAppAccessInfo('talks') 
  



})




</script>

<style scoped>
.mainWrapper {
  /* background-color: aliceblue; */
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rightContentWrapper {
  /* overflow-y: auto; */
  width: 83%;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  /* overflow-y: auto; */
  background-color: rgb(208, 28, 55);
  /* margin: 30px; */
  margin-top: 100px;
}

.rightColumns {
  /* display: flex;
  flex-direction: column; */
}

.topNav {
  background-color: blue;
}

.innerContainer {
  /* margin-top: 200px; */
  /* background-color: blueviolet; */
  overflow-y: auto;
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.theHeaderWrapper {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  /* background-color: aqua; */
}

.searchBarWrapper {
  margin-top: 15px;
  width: 100%;
  padding-bottom: 20px;
  /* background-color: rgb(22, 166, 37); */
}

.theBar {
  width: 350px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.button-6 {
  align-items: center;
  /* background-color: #FFFFFF; */
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.tableWrapper {
  margin-top: 20px;
  /* background-color: aqua; */
  width: 100%;
}

.my-custom-table tbody tr td {
  padding-top: 20px;
  /* Adjust the value as needed */
  padding-bottom: 20px;
  /* Adjust the value as needed */
}

.my-custom-table th {
  padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
  text-align: left;
  /* Aligns text to the left */
  padding-left: 20px;
  padding-right: 20px;
}

.campName {
  text-decoration: none;
  color: inherit;
}

.campName:hover {
  color: rgb(10, 159, 92);
  cursor: pointer;
  text-decoration: none;
}

.iconsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.theActionIcon {
  font-weight: bolder;
  font-size: 18px;
  color: rgb(5, 2, 2);
  cursor: pointer;
}

.theActionIcon:hover {
  cursor: pointer;
  color: rgb(30, 158, 30);
  scale: 1.3;
  transition: 1s;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  z-index: 999;
  /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
  background-color: white;
  width: 1100px;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
  text-align: center;
}

.model-box-header {
  display: flex;
  justify-content: space-between;
  /* background-color: antiquewhite; */
  height: 40px;
  margin-bottom: 15px;
}

.form-control {
  background-color: rgb(243, 246, 246);
}

.createBTN {
  width: 100%;
  margin-top: 20px;
}


.noCampBox {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 500px;
     width: 100%;
 }

</style>
